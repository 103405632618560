import { React } from '@/app'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { getFirebase } from '@/services/firebaseApp'
import { Navigation } from '@/utils'
import { onMount, onUpdate, useCallback, useUnmount, waitFor } from '@codeleap/common'
import { AuthErrors } from './errors'
import { QueryKeys } from './queryKeys'

export function useVerifyEmail(root = false) {
  const [isEmailVerified, setIsEmailVerified] = React.useState(false)
  const intervalEmailCheck = React.useRef(null)
  const { profile } = APIClient.Session.useSession()

  const cancelEmailCheck = () => {
    if (intervalEmailCheck.current != null) {
      clearInterval(intervalEmailCheck.current)
      intervalEmailCheck.current = null
    }
  }

  const registryEmailCheck = () => {
    if (intervalEmailCheck.current == null) {
      intervalEmailCheck.current = setInterval(() => {
        checkEmail()
      }, 1000)
    }
  }

  async function resendEmailConfirmation() {
    AppStatus.set('loading')

    const firebaseAuth = (await getFirebase()).auth

    try {
      await firebaseAuth().currentUser?.sendEmailVerification()

      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      AuthErrors.onError(err, 'Resend email - useVerifyEmail')
    }
  }

  const checkEmail = async () => {
    const firebaseAuth = (await getFirebase()).auth

    let user = firebaseAuth()?.currentUser

    if (!user) {
      return
    }

    firebaseAuth()?.currentUser?.reload?.()

    user = firebaseAuth()?.currentUser

    if (user?.emailVerified) {
      setIsEmailVerified(true)
      cancelEmailCheck()
    }
  }

  onUpdate(() => {
    if (root && isEmailVerified) {
      cancelEmailCheck()
    }
  }, [isEmailVerified])

  onMount(() => {
    if (root) {
      registryEmailCheck()
    }
  })

  useUnmount(() => {
    if (root) {
      cancelEmailCheck()
    }
  })

  const cancelVerification = async () => {
    cancelEmailCheck()

    AppStatus.set('splash')

    await waitFor(250)

    Navigation.navigate('Home')

    const firebase = await getFirebase()

    APIClient.Session.deleteProfile(profile?.id)
    QueryKeys.me.setData(null)

    await waitFor(1500)

    firebase.auth().signOut()

    AppStatus.set('idle')
  }

  return {
    resendEmailConfirmation,
    checkEmail,
    isEmailVerified,
    cancelVerification,
  }
}
