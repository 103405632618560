import { View, Text, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterDisclaimersProps = {
  variantStyles?: StylesOf<FooterComposition>
}

export const FooterDisclaimers = (props: FooterDisclaimersProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.disclaimersWrapper}>
      <Text css={variantStyles.disclaimerText}>
        Skamper Limited is registered in Scotland,
        company&nbsp;no.&nbsp;SC742632.
      </Text>
      <Text css={variantStyles.disclaimerText}>
        This website uses&thinsp;
        <Link css={variantStyles.disclaimerText} to='/about'>third-party assets and software
        </Link>
        . By using our website you agree to the&thinsp;
        <Link css={variantStyles.disclaimerText} to={'/terms_and_conditions'} target='_blank'>
          terms of use
        </Link>
        .
      </Text>
    </View>
  )
}
