import { CourseDistanceComposition, CourseDistanceStyles } from '@/app/stylesheets'
import { View, Text } from '@/components'
import { Course } from '@/types'
import { CourseUtils } from '@/utils'
import { ComponentVariants, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

type ViewProps = PropsOf<typeof View>

export type CourseDistanceProps = Omit<ViewProps, 'variants'> & ComponentVariants<typeof CourseDistanceStyles> & {
  course: Course
  styles?: StylesOf<CourseDistanceComposition>
}

export const CourseDistance = ({ course, variants, styles, responsiveVariants, ...rest }: CourseDistanceProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:CourseDistance',
    typeof CourseDistanceStyles
  >('u:CourseDistance', {
    variants,
    styles,
    responsiveVariants,
  })

  const distance = CourseUtils.getPreviewDistance({ course })

  return (
    <View css={variantStyles.wrapper} {...rest}>
      <Text css={variantStyles.value} text={distance} />
      <Text css={variantStyles.unit} text={'km'} />
    </View>
  )
}
