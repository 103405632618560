import { variantProvider } from '..'
import { TextEditorComposition, TextEditorPresets } from '@codeleap/web'

const createTextEditorStyle =
  variantProvider.createVariantFactory<TextEditorComposition>()

const editorStyles = {
  default: createTextEditorStyle(theme => ({
    wrapper: {
      '.tiptap': {
        ...theme.spacing.paddingRight(1),
        ...theme.spacing.paddingLeft(3),
        ...theme.spacing.paddingVertical(2),
        fontFamily: theme.typography.base.fontFamily,
        fontSize: theme.typography.base.styles.p1.size,
        fontWeight: theme.typography.base.styles.p1.weight,
        borderBottomLeftRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
        ...theme.border.neutral5({ width: 1, directions: ['bottom', 'left', 'right'] }),

        h1: { fontSize: theme.typography.base.styles.h1.size },
        h2: { fontSize: theme.typography.base.styles.h2.size },
        h3: { fontSize: theme.typography.base.styles.h3.size },
        h4: { fontSize: theme.typography.base.styles.h4.size },
        h5: { fontSize: theme.typography.base.styles.h5.size },
        h6: { fontSize: theme.typography.base.styles.h6.size },
      },
    },
  })),
}

export const TextEditorStyles = {
  ...TextEditorPresets,
  default: createTextEditorStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...editorStyles.default(theme).wrapper,
    },
    floatingMenu: {},
    bubbleMenu: {},
    bubbleMenuInnerWrapper: {},
    floatingMenuInnerWrapper: {},
  })),
}
