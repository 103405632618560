import { variantProvider } from '@/app'
import { Modal, View } from '@/components'
import { useIsTablet } from '@/utils'
import { PropsOf } from '@codeleap/common'
import GoogleMap from 'google-map-react'

type ModalProps = PropsOf<typeof Modal>

export type MapFullScreenProps = PropsOf<typeof GoogleMap> & Pick<ModalProps, 'visible' | 'toggle'> & {
  onClose?: () => void
}

// This component was created to support fullscreen on mobile as requestFullscreen is not supported
export const MapFullScreen = ({ visible, toggle, ...rest }: MapFullScreenProps) => {
  const isBelowTablet = useIsTablet()
  const isVisible = !!isBelowTablet && !!visible

  return (
    <Modal visible={visible} toggle={toggle} variants={['fullscreen', 'mapFullscreen']}>
      {isVisible && (
        <View css={styles.wrapper}>
          <GoogleMap {...rest} draggable />
        </View>)
      }
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(() => ({
  wrapper: {
    height: '100svh',
    width: '100vw',
  },
}), true)
