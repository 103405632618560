import { Theme, Settings } from '@/app'
import { View, Link, Icon } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'

export type FooterSocialMediaProps = {
  variantStyles?: StylesOf<FooterComposition>
}

const links = [
  {
    url: Settings.Social.FacebookURL,
    icon: 'facebook',
    size: Theme.values.iconSize[3],
    color: Theme.colors.light.neutral10,
  },
  {
    url: Settings.Social.InstagramURL,
    icon: 'instagram',
    size: Theme.values.iconSize[3],
    color: Theme.colors.light.neutral10,
  },
]

export const FooterSocialMedia = (props: FooterSocialMediaProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.socialMediaWrapper} component='ul'>
      {links.map((media, i) => (
        <View component='li'>
          <Link key={i + media?.icon} to={media?.url}>
            <Icon
              name={media?.icon}
              color={media?.color}
              size={media?.size}
              debugName={media?.icon + 'footer-icon'}
            />
          </Link>
        </View>
      ))}
    </View>
  )
}
