import { PropsOf, useCallback } from '@codeleap/common'
import { Text, View } from '..'
import { Course, CourseTag } from '@/types'
import React from 'react'

type CourseTagsProps = {
  course: Course
  wrapperProps?: PropsOf<typeof View>
  textProps?: PropsOf<typeof Text>
  maxTags?: number
}

export const CourseTags = (props: CourseTagsProps) => {
  const { course, wrapperProps = {}, textProps = {}, maxTags } = props

  const wrapperVariants = wrapperProps?.hasOwnProperty('variants') ? wrapperProps?.variants : []
  const textVariants = textProps?.hasOwnProperty('variants') ? textProps?.variants : []

  const renderItem = useCallback((item: CourseTag, index: number) => {
    const showEllipsis = !!maxTags && index === maxTags - 1

    if (index > maxTags - 1) return null

    return (
      // @ts-ignore
      <React.Fragment>
        <View
          {...wrapperProps}
          key={`${item?.id}${index}`}
          variants={['bg:neutral2', 'padding:1', 'border-radius:rounded', ...wrapperVariants]}
          // @ts-ignore
          component='li'
        >
          <Text
            {...textProps}
            variants={['p3', 'regular', ...textVariants]}
            text={item?.title}
          />
        </View>

        {showEllipsis && (
          <View
            {...wrapperProps}
            key={`${item?.id}${index}`}
            variants={['bg:neutral2', 'padding:1', 'border-radius:rounded', ...wrapperVariants]}
            // @ts-ignore
            component='li'
          >
            <Text
              {...textProps}
              variants={['p3', 'regular', 'paddingHorizontal:1', ...textVariants]}
              text={'. . .'}
            />
          </View>
        )}
      </React.Fragment >
    )
  }, [])

  if (!course?.tags?.length) return null

  return (course?.tags?.map?.((item, index) => renderItem(item, index)))
}
