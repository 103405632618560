import { NavigateOptions } from '@reach/router'
import { IS_SSR, ROUTES } from '@/app'
import { navigate as navigateGatsby } from 'gatsby'
import { TypeGuards } from '@codeleap/common'

// @ts-ignore
type CreateRoutes<T, Prefix extends string = ''> = {
  [K in keyof T & string]: T[K] extends string
    ? `${Prefix}${Prefix extends '' ? '' : '.'}${K}`
    : CreateRoutes<T[K], `${Prefix}${Prefix extends '' ? '' : '.'}${K}`>
}[keyof T]

export type RoutePath = CreateRoutes<typeof ROUTES>

function getRoutePath(path: RoutePath): string {
  let route = ROUTES

  if (path?.includes('.')) {
    const indexesAccess = path?.split('.')

    for (const index of indexesAccess) {
      route = route?.[index]
    }
  } else {
    route = route?.[path]
  }

  return (route ?? path) as string
}

function getRouteURL(path: RoutePath): string {
  const routePath = getRoutePath(path)

  return `${process.env.GATSBY_SITE_URL.slice(0, -1)}${routePath}`
}

function appendPathToSiteURL(path: string) {
  const siteUrl = process.env.GATSBY_SITE_URL.slice(0, -1)
  return `${siteUrl}${path}`
}

type NavigateOption<T> = {
  route?: any
  params?: Record<string, string>
} & NavigateOptions<T>

const defaultNavigateOptions = {
  route: null,
  params: null,
}

export function navigate<S = any>(
  route: RoutePath,
  options: NavigateOption<S> = defaultNavigateOptions
) {
  const { route: routeParam = null, params = null, ...navigateParams } = options

  let path = getRoutePath(route)

  if (!TypeGuards.isNil(routeParam)) {
    path = `${path}/${String(routeParam)}`
  }

  if (!TypeGuards.isNil(params)) {
    let searchParams = ''

    Object.keys(params)?.forEach((paramKey, index) => {
      const newParam = `${paramKey}=${encodeURIComponent(params?.[paramKey])}`
      const separator = index === 0 ? '' : '&'

      searchParams = `${searchParams}${separator}${newParam}`
    })

    path = `${path}?${searchParams}`
  }

  navigateGatsby(path, navigateParams)
}

function to<T extends {} = {}>(to: string, options?: NavigateOptions<T>) {
  navigateGatsby(String(to), options)
}

function isOnRoute(route: RoutePath) {
  if (!window) return false

  const path = window?.location?.pathname
  const routePath = getRoutePath(route)

  if (path?.includes(routePath)) {
    return true
  }

  return false
}

function goBack() {
  if (!window) return false

  window.history?.back()
}

function getPathSlugs(path?: string) {
  const pathName = !!path ? path : IS_SSR ? '' : window.location.pathname
  const pathSlugs = pathName?.split('/')?.slice(1, -1)

  return pathSlugs
}

import * as Course from './course'
import * as General from './general'
import * as Area from './area'

export const Navigation = {
  navigate,
  to,
  appendPathToSiteURL,
  getRoutePath,
  getPathSlugs,
  isOnRoute,
  getRouteURL,
  goBack,
  Course,
  General,
  Area,
}
