import { useCallback, useRef } from 'react'
import '@/app/stylesheets/css/slick.css'
import '@/app/stylesheets/css/slick-theme.css'
import { useIsMobile, useRefDimensions } from '@/utils'
import { Carousel, CarouselProps, View, AreaCard, AreaCardProps } from '@/components'
import { Theme, variantProvider } from '@/app'
import { Area } from '@/types'
import { PropsOf } from '@codeleap/common'
import { AREA_CARD_CONSTANTS } from '@/app/stylesheets/AreaCard'

export type AreaSliderProps = {
  areas: Area[]
  areaCardProps?: Partial<AreaCardProps>
  cardWrapperProps?: PropsOf<typeof View>
  itemWidth?: number
  roundSliders?: boolean
  smallCardWidth?: number
  desktopCardWidth?: number
  carouselProps?: Partial<CarouselProps>
  slidesToShow?: number
  responsiveWrapper?: boolean
  defaultCardWidth?: number
  gap?: number
} & PropsOf<typeof View>

export const AreaSlider = (props: AreaSliderProps) => {
  const {
    areas,
    areaCardProps = {},
    cardWrapperProps = {},
    itemWidth: _itemWidth,
    slidesToShow: _slidesToShow,
    roundSliders = false,
    smallCardWidth,
    defaultCardWidth,
    carouselProps = {},
    gap = Theme.spacing.value(2),
    style,
    ...wrapperProps
  } = props

  const isMobile = useIsMobile()
  const sliderRef = useRef(null)
  const { refWidth: sliderWidth } = useRefDimensions(sliderRef)

  const itemWidth = (isMobile ? AREA_CARD_CONSTANTS.mobile.size : AREA_CARD_CONSTANTS.default.size) + Theme.spacing.value(2)

  const slidesToShow = _slidesToShow ?? (roundSliders ? Math.floor(sliderWidth / itemWidth) : (sliderWidth / itemWidth))

  const renderItem = useCallback((item, key, dragging) => {
    const {
      onPress,
      ...otherItemProps
    } = areaCardProps

    const gapToPadding = (gap: number) => {
      return (gap / Theme.spacing.value(1)) / 2
    }

    const handleItemPress = (item: Area) => {
      if (!dragging) areaCardProps?.onPress?.(item)
    }

    return (
      <View
        key={key}
        variants={['center', _slidesToShow ? `paddingHorizontal:${gapToPadding(gap)}` : null]}
        {...cardWrapperProps}
      >
        <AreaCard
          area={item}
          onPress={() => handleItemPress(item)}
          {...otherItemProps}
        />
      </View>
    )
  }, [_slidesToShow])

  return (
    <View
      ref={sliderRef}
      style={{ ...styles.wrapper, ...style }}
      {...wrapperProps}
    >
      <Carousel
        items={areas}
        renderItem={renderItem}
        slidesToShow={slidesToShow}
        {...carouselProps}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.block,
    },
  }),
  true,
)
