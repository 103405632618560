import { variantProvider } from '@/app'
import { MapComponent } from '../Maps/MapComponent'
import { Place, Course, DrawedCourse, ShortCoords } from '@/types'
import { PropsOf, useRef, useState } from '@codeleap/common'
import { CourseUtils, MapUtils, Navigation, SpatialUtils } from '@/utils'
import { View } from '@/components'

export type PlaceMapProps = {
  place?: Place
  isLoading?: boolean
  mapProps?: PropsOf<typeof MapComponent>
  courses: Course[]
}

export const PlaceMap = (props?: PlaceMapProps) => {
  const { place, mapProps, courses = [] } = props
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const { mapRefs: { mapRef, mapsRef }} = MapUtils.useMap()
  const coursesRef = useRef<DrawedCourse[]>([])

  const position: ShortCoords = {
    lat: place?.coords?.lat,
    lng: place?.coords?.lng,
  }

  const mapInstances = { map: mapRef.current, maps: mapsRef.current }

  CourseUtils.Maps.useDrawCourses({
    ...mapInstances,
    isMapLoaded,
    courses,
    coursesRef,
    selectedCourse: null,
  })

  const handleApiLoaded = ({ map, maps }) => {
    setIsMapLoaded(true)
    const boundingBox = place?.boundingbox

    if (!!boundingBox) {
      const southWest = {
        lat: parseFloat(boundingBox?.[0]),
        lng: parseFloat(boundingBox?.[2]),
      }
      const northeast = {
        lat: parseFloat(boundingBox?.[1]),
        lng: parseFloat(boundingBox?.[3]),
      }

      MapUtils.fitToMarkers({ map, maps, markers: [southWest, northeast] })
    }
  }

  return (
    <View variants={['flex']}>
      <MapComponent
        onGoogleApiLoaded={handleApiLoaded}
        mapRef={mapRef}
        mapsRef={mapsRef}
        isLoading={!place}
        style={styles.map}
        defaultCenter={position}
        mapButtonProps={{
          icon: 'maximize',
          onPress: () => Navigation.Course.navigateToExplore({ ...position, radius: SpatialUtils.boundingBoxToRadius(place?.boundingbox) }),
        }}
        {...mapProps}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    map: {
      borderRadius: theme.borderRadius.medium,
      overflow: 'hidden',
      width: '100%',
      height: 300,
    },
  }),
  true,
)
