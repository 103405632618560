import { MapDefaults, useBreakpointMatch } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  CourseSection, CourseSectionProps,
} from '@/components'
import { CourseFilters } from '@/types'
import { Navigation } from '@/utils'

export const MountainUkSection = (props: Partial<CourseSectionProps>) => {

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 3,
    desktopLarge: 4,
    desktopHuge: 5,
  })

  const sectionFilters: CourseFilters = {
    lat: MapDefaults.center.latitude,
    lng: MapDefaults.center.longitude,
    min_elevation: 450,
  }

  return (
    <CourseSection
      title={'The 10 best mountain runs in the UK'}
      defaultCardWidth={COURSE_CARD_CONSTANTS.SQUARE.WIDTH.DEFAULT}
      smallCardWidth={COURSE_CARD_CONSTANTS.SQUARE.WIDTH.MOBILE}
      sectionFilters={sectionFilters}
      slidesToShow={slidesToShow}
      courseCardProps={{
        variants: ['square'],
        onPress: (item) => Navigation.Course.navigateToDetails(item),
      }}
      {...props}
    />
  )
}
