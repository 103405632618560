import { Course } from '@/types'
import { LineChart, LineChartProps } from '../Charts'
import { useMemo } from 'react'
import { Units } from '@/utils'
import { View, Text } from '@/components'
import { PropsOf } from '@codeleap/common'

export type CourseAscentChartProps = Partial<LineChartProps> & PropsOf<typeof View> & {
  course: Course
}

const DEFAULT_ELEVATION = 1
const MIN_ELEVATION_RANGE = 100

function formatXData(total: number, parts: number) {
  const remainder = total / parts
  const results = []

  for (let i = 0; i <= parts; i++) {
    const result = remainder * i
    const value = Units.convert({ value: result, from: 'm', to: 'km', withSuffix: true, toFixed: 1 })
    results.push({ value })
  }

  return results
}

const X_AXIS_COLUMNS = 4

export const CourseAscentChart = ({ course, title = 'Elevation', variants = [], style, ...rest }: CourseAscentChartProps) => {

  const { convert } = Units.useConversor({ from: 'm', to: 'm', withSuffix: true, format: (value, { to }) => `${Math.round(value)} ${to}` })

  const { data, xData, yData, max, minRangeReached } = useMemo(() => {
    const originalPoints = course?.original_points

    let min = originalPoints[0]?.elevation || DEFAULT_ELEVATION
    let max = originalPoints[0]?.elevation || DEFAULT_ELEVATION

    const data: LineChartProps['data'] = originalPoints.map((point, index) => {
      const pointElevation = point.elevation || DEFAULT_ELEVATION

      if (pointElevation < min) min = pointElevation
      if (pointElevation > max) max = pointElevation

      return { y: pointElevation, x: index }
    })

    const xData: LineChartProps['xData'] = formatXData(course?.distance_total, X_AXIS_COLUMNS)

    const range = max - min
    const minRangeReached = range > MIN_ELEVATION_RANGE

    if (!minRangeReached) {
      max = min + MIN_ELEVATION_RANGE
      data.push({ y: max, x: null })
    }

    const yData: LineChartProps['yData'] = [{ value: convert({ value: max }) }, { value: convert({ value: min }) }]

    return { data, xData, yData, max, minRangeReached }
  }, [course?.original_points])

  return (
    <View
      variants={['column', 'paddingRight:2', ...variants as any]}
      responsiveVariants={{ tabletSmall: ['paddingRight:2'] }}
      style={style}
    >
      {!!title && (<Text text={title} variants={['p4', 'neutral-7', 'medium']} />)}

      <LineChart
        {...{ data, xData, yData }}
        forceMax={minRangeReached ? null : max}
        {...rest}
      />
    </View>)
}
