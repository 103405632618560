import { UnitsOptions } from '@/types'
import { TypeGuards } from '@codeleap/common'

type ConvertProps = {
  from: UnitsOptions
  to: UnitsOptions
  value: number | string
  withSuffix?: boolean
  toFixed?: number
  round?: boolean
  floor?: boolean
  format?: (
    value: number,
    options: { to: UnitsOptions; from: UnitsOptions }
  ) => any
}

const Multipliers = {
  'km->m': 1000,
  'mi->m': 1609.34,
  'ft->m': 0.3048,
  'km->mi': 0.621371192,
  'h->s': 3600,
  'h->min': 60,
  'min->s': 60,
  'm->ft': 3.28084,
}

/**
 * Convert value from one unit to another based on given conversion parameters.
 * @param {ConvertProps} params - Object containing conversion parameters.
 * @returns {string | number} - Converted value.
 * @example Units.convert({ value: 1000, from: 'm', to: 'km' }) -> 1
 */
const convert = (params: ConvertProps) => {
  const { from, to: _to, value, withSuffix = false, toFixed = 1, format, round = false, floor = false } = params
  const to = _to?.toLowerCase()

  const multiplierKey = Object.keys(Multipliers).find(
    (key) => key.includes(from) && key.includes(to),
  )

  const formatResult = (result: number) => {
    if (TypeGuards.isFunction(format)) return format(result, { to: _to, from })

    let _result: string | number = result

    if (round) { _result = Math.round(result) }
    if (floor) { _result = Math.floor(result) }
    if (toFixed) { _result = result.toFixed(toFixed) }
    if (withSuffix) { _result = `${_result}${to}` }

    return _result
  }

  const _value = Number(value)
  const cannotConvert = from === to || !multiplierKey

  if (cannotConvert) return formatResult(_value)
  const [base, _] = multiplierKey.split('->')

  if (from === base) return formatResult(_value * Multipliers[multiplierKey])
  return formatResult(_value * (1 / Multipliers[multiplierKey]))
}

type UseConversorConvertProps = Partial<ConvertProps> & {
  from: ConvertProps['from']
  to: ConvertProps['to']
}

/**
 * Hook for performing conversions using a default set of conversion parameters.
 * @param {UseConversorConvertProps} defaultParams - Default conversion parameters.
 * @returns {Object} - Object containing the conversion function.
 */
const useConversor = (defaultParams: UseConversorConvertProps) => {
  const _convert = (
    params?: Partial<ConvertProps> & { value: ConvertProps['value'] },
  ) => convert({ ...defaultParams, ...params })
  return { convert: _convert }
}

export const Units = {
  convert,
  useConversor,
  Multipliers,
}
