import { hexToRgb, matchInitialToColor } from '@codeleap/common'

const letters = 'abcdefghijklmnopqrstuvwxyz'

const rgbToHex = (rgb: { r: number; g: number; b: number }) => {
  const clamp = (value) => Math.max(0, Math.min(255, value))

  const red = clamp(rgb.r).toString(16).padStart(2, '0')
  const green = clamp(rgb.g).toString(16).padStart(2, '0')
  const blue = clamp(rgb.b).toString(16).padStart(2, '0')

  const hexColor = `#${red}${green}${blue}`

  return hexColor
}

const combineColors = (color1: string, color2: string) => {
  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  const combinedColor = {
    r: Math.floor((rgb1.r + rgb2.r) / 2),
    g: Math.floor((rgb1.g + rgb2.g) / 2),
    b: Math.floor((rgb1.b + rgb2.b) / 2),
  }

  const combinedHexColor = rgbToHex(combinedColor)
  return combinedHexColor
}

const generateColorMap = () => {
  const colorMap = {}
  for (let i = 0; i < letters.length; i++) {
    for (let j = 0; j < letters.length; j++) {
      const key = letters[i] + letters[j]
      const color = combineColors(
        matchInitialToColor(letters[i]),
        matchInitialToColor(letters[j]),
      )
      colorMap[key] = color
    }
  }
  return colorMap
}

const getAvatarColors = (initials: string) => {
  const colorMap = generateColorMap()
  const combinedColor = colorMap[initials?.toLowerCase()]
  const lighterCombinedColor = `${combinedColor}59`

  return {
    colorMap,
    combinedColor,
    lighterCombinedColor,
  }
}

const adjustHexColorBrightness = (hex, darkenBy) => {
  const num = parseInt(hex?.replace('#', ''), 16)
  let R = (num >> 16)
  let G = ((num >> 8) & 0x00FF)
  let B = (num & 0x0000FF)

  function darken(colorComponent) {
    return Math.max(0, colorComponent - darkenBy)
  }

  R = darken(R)
  G = darken(G)
  B = darken(B)

  return '#' + (0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1).padStart(6, '0')
}

export const ColorUtils = {
  generateColorMap,
  getAvatarColors,
  rgbToHex,
  combineColors,
  adjustHexColorBrightness,
}
