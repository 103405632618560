
function getPageTitle(adminType: string) {
  switch (adminType) {
    case 'country':
      return "Discover top running trails worldwide with Skamper's guide."
    case 'state':
      return 'Discover running routes across states with Skamper.'
    case 'county':
      return "Find the best running trails by county with Skamper's guide."
    case 'city':
      return "Find top running trails by city with Skamper's guide."
    case 'neighbourhood':
      return "Discover neighborhood running routes tailored for you."
    default:
      return `Directory - ${adminType}`
  }
}

export const DirectoryUtils = {
  getPageTitle,
}