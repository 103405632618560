import { useState } from 'react'
import { copyToClipboard as _copyToClipboard } from '../misc'

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = (text: string) => {
    _copyToClipboard(text).then(() => {
      setCopied(true)
    })
  }

  return { copyToClipboard, copied, setCopied }
}
