import { CourseUtils } from '@/utils'
import { Course, CourseStat } from '@/types'
import { View, Icon, Text } from '@/components'
import { CourseStatsStyles, CourseStatsComposition } from '@/app/stylesheets'
import { ComponentVariants, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

export type CourseStatsProps = ComponentVariants<typeof CourseStatsStyles> & PropsOf<typeof View> & {
  style?: StyleSheet
  course: Course
  styles?: StylesOf<CourseStatsComposition>
  stats?: CourseStat[]
}

export type StatItemProps = Pick<CourseStatsProps, 'course'> & {
  stat: CourseStat
  variantStyles: CourseStatsProps['styles']
}

const StatItem = ({ stat, course, variantStyles }: StatItemProps) => {
  const { value, label, icon } = CourseUtils.getStatValues(course, stat)
  return (
    <View css={variantStyles.itemWrapper} component='li'>
      {!!icon && <Icon debugName={`${stat}:Icon`} name={icon} style={variantStyles.itemIcon} />}
      <Text text={value} css={variantStyles.itemText} />
      <Text text={label} css={variantStyles.itemLabel} />
    </View>
  )
}

export const CourseStats = ({
  stats = ['runtime', 'ascent', 'paved', 'hilly', 'waypoints'],
  course,
  variants,
  styles,
  responsiveVariants,
  ...rest }: CourseStatsProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:CourseStats',
    typeof CourseStatsStyles
  >('u:CourseStats', {
    variants,
    styles,
    responsiveVariants,
  })

  return (
    <View css={variantStyles.wrapper} component='ul' {...rest}>
      {stats.map(stat => (
        <StatItem course={course} key={stat} stat={stat} variantStyles={variantStyles} />
      ))}
    </View>
  )
}
