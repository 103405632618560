import { View, ActivityIndicator } from '@/components'
import { PlacesList, PlacesListProps } from './PlacesList'
import { RecentPlacesList } from './RecentPlacesList'
import { SearchPrediction } from '@/types'

export type PlacesResultsProps = Partial<PlacesListProps> & {
  places: SearchPrediction[]
  isLoading: boolean
  searchText?: string
  noEmptyPlaceholder?: boolean
}

export const PlacesResults = ({ isLoading, noEmptyPlaceholder = false, places, searchText, ...placeListProps }: PlacesResultsProps) => {
  if (isLoading) {
    return (
      <View variants={['fullWidth', 'justifyCenter', 'padding:8']}>
        <ActivityIndicator
          debugName='PlacesAutoComplete:Loader'
        />
      </View>
    )
  }

  const isEmpty = !noEmptyPlaceholder ? !!searchText && !places?.length : false

  return (
    <View variants={['column', 'fullWidth']}>
      <PlacesList
        {...placeListProps}
        places={places}
        emptyPlaceholderProps={isEmpty ? {
          title: `No results matching "${searchText}"`,
          description: 'Try searching for another name',
        } : null}
      />

      {!places?.length && <RecentPlacesList {...placeListProps} />}
    </View>
  )
}
