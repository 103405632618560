import { DropzoneComposition, DropzonePresets } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '..'

const createDropzoneStyle =
  variantProvider.createVariantFactory<DropzoneComposition>()

export const DropzoneStyles = {
  ...DropzonePresets,
  default: createDropzoneStyle((theme) => ({
    wrapper: {},
    dropzone: {
      ...theme.presets.flex,
      ...theme.presets.column,
      borderRadius: theme.borderRadius.medium,
      padding: theme.spacing.value(3),
      border: `1.5px dashed ${theme.colors.neutral4}`,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      cursor: 'pointer',
    },
    iconWrapper: {
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral2,
      ...theme.sized(7),
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
      marginBottom: theme.spacing.value(2),
    },
    icon: {
      ...theme.sized(3),
    },
    placeholder: {
      ...assignTextStyle('p3')(theme).text,
      fontWeight: 'normal',
      width: '78%',
      color: theme.colors.neutral7,
      ...theme.presets.textCenter,
    },
    filesWrapper: {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      marginBottom: theme.spacing.value(2),
      gap: theme.spacing.value(1),
    },
    fileWrapper: {
      ...theme.presets.row,
      ...theme.presets.fullWidth,
      height: theme.spacing.value(6),
      paddingRight: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(2),
      borderRadius: theme.borderRadius.medium,
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.alignCenter,
    },
    'fileWrapper:error': {
      border: `1.5px solid ${theme.colors.destructive1}`,
    },
    fileErrors: {},
    fileError: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.destructive2,
    },
    fileNameWrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    fileName: {
      ...theme.presets.flex,
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    fileLeftIcon: {
      marginRight: theme.spacing.value(1),
      color: theme.colors.neutral7,
      ...theme.sized(2.5),
    },
    fileRightIconTouchableWrapper: {
      marginLeft: theme.spacing.value(1),
      background: 'none',
      ...theme.sized(2.5),
    },
    fileRightIconIcon: {
      color: theme.colors.neutral8,
    },
    fileImage: {
      ...theme.sized(4),
      borderRadius: theme.borderRadius.rounded,
      marginRight: theme.spacing.value(1),
      objectFit: 'cover',
    },
  })),
}
