import {
  AreaSection, AreaSectionProps,
} from '@/components'
import { Navigation, useIsMobile } from '@/utils'
import { AreaFilters, ShortCoords } from '@/types'
import { MapDefaults } from '@/app'

export const UKCitySectionSection = (props: Partial<AreaSectionProps>) => {

  const isMobile = useIsMobile()
  const sectionFilters: AreaFilters & Partial<ShortCoords> = {
    key: 'city',
    lat: MapDefaults.center.latitude,
    lng: MapDefaults.center.longitude,
  }

  return (
    <AreaSection
      title="The best runs in around the UK's major cities"
      sectionFilters={sectionFilters}
      areaCardProps={{
        variants: [isMobile ? 'small' : null],
        onPress: (area) => Navigation.Area.navigateToDetails(area),
      }}
      {...props}
    />
  )
}

