import { React } from '@/app'
import { List } from '@/components'
import { IconPlaceholder, PropsOf, QueryManager, QueryManagerItem, usePagination } from '@codeleap/common'
import { ListProps, PaginationIndicator } from '@codeleap/web'

type GetFlatlistPropsOptions = {
  noMoreItemsText?: string
  forceLoading?: boolean
  forceHasNextPage?: boolean
}

export const useFlatlistProps = <
  TItem extends QueryManagerItem,
  T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>, options?: GetFlatlistPropsOptions) => {
  const {
    noMoreItemsText = 'No more items to show',
    forceHasNextPage = false,
    forceLoading = false,
  } = (options || {})

  const listQuery = hookReturn.list.query

  const hasMore = hookReturn.list.query.isLoading || hookReturn.list.query.hasNextPage

  const showPaginationIndicator = hookReturn.list.query.isFetchingNextPage || !hasMore

  const ListPaginationIndicator = React.useCallback(({ isEmpty = false }) => {
    if (!showPaginationIndicator || isEmpty) return null

    return (
      <PaginationIndicator
        isFetching={hookReturn.list.query.isFetchingNextPage || forceHasNextPage}
        noMoreItemsText={noMoreItemsText}
        hasMore={hasMore}
      />
    )
  }, [showPaginationIndicator, hasMore])

  return {
    data: hookReturn.items as TItem[],
    fetchNextPage: () => {
      if (!hasMore || !hookReturn?.items?.length) return
      return hookReturn.getNextPage?.()
    },
    keyExtractor: (item) => item?.id,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    loading: forceLoading || hookReturn.list.query.isLoading,
    ListFooterComponent: ListPaginationIndicator,
    isFetchingNextPage: hookReturn.list.query.isFetchingNextPage,
    ListLoadingIndicatorComponent: () => null,
    hasNextPage: hasMore,
    isLoading: hookReturn.list.query.isLoading,
    placeholder: {
      loading: (listQuery.isFetching || listQuery?.isLoading || listQuery?.loading) && !listQuery?.isRefreshing,
      icon: 'placeholderNoItems-select' as IconPlaceholder,
    },
    showPaginationIndicator,
  }
}

type PaginationFlatListProps<TData = any> = Pick<
  ListProps<PropsOf<typeof List>, TData>,
  'onRefresh' | 'data' | 'renderItem' | 'placeholder' |
  'keyExtractor' | 'ListFooterComponent' | 'refreshing' | 'ListLoadingIndicatorComponent'
>

export function getFlatListProps<
  P extends ReturnType<typeof usePagination>
>(pagination: P): PaginationFlatListProps<P['items'][number]> {

  const listQuery = pagination.queries.list

  return {
    ...listQuery,
    data: pagination?.items,
    renderItem: () => null,
    keyExtractor: (item) => pagination.params.keyExtractor(item).toString(),
    refreshing: listQuery.isRefreshing,
    // @ts-ignore
    placeholder: {
      loading: listQuery.isFetching || listQuery?.isLoading || listQuery?.loading,
      icon: 'placeholderNoItems-select' as IconPlaceholder,
    },
    onRefresh: () => listQuery.refresh(),
    ListFooterComponent: ({ isEmpty }) => {
      const limited = pagination?.items?.length <= pagination?.options?.limit

      if (!pagination?.items?.length || limited || isEmpty) return null

      return <PaginationIndicator
        hasMore={listQuery.hasNextPage}
        noMoreItemsText={`No more ${pagination.itemName}`}
        isFetching={listQuery.isFetchingNextPage}
      />
    },
  }
}

export const onScrollToBottom = (scrollEvent, onEndReached = () => { }) => {
  const { scrollTop, clientHeight, scrollHeight } = scrollEvent.target

  if (scrollTop + clientHeight >= scrollHeight) {
    onEndReached?.()
  }
}

export const getPaginationProps = <
TItem extends QueryManagerItem,
T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>) => {
  return hookReturn?.list?.query?.data?.pages[0]
}

export const areaPropsEquals = <T extends any> (props: T, prevProps: T) => {
  if (props === prevProps) return

  const keys = Object.keys(props)
  const prevKeys = Object.keys(prevProps)

  if (keys.length !== prevKeys.length) return

  const isEqual = keys.every((key) => {
    return props[key] === prevProps[key]
  })

  return isEqual
}

