import { CourseCardStyles } from '@/app/stylesheets'
import { CourseCardComposition } from '@/app/stylesheets/CourseCard'
import { View, Text, CardBaseProps, CardBase, Tag, Image, CourseDistance } from '@/components'
import { Course, CourseStat } from '@/types'
import { CourseUtils, Units } from '@/utils'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { ComponentVariants, StylesOf, arePropsEqual, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'

export type CourseCardProps = Omit<CardBaseProps, 'variants' | 'styles' | 'style' | 'debugName'> &
  ComponentVariants<typeof CourseCardStyles> & {
    styles?: StylesOf<CourseCardComposition>
    course: Course
  }

export type CardStatsProps = {
  title: string
  subtitle: CourseStat
}

const INFO_ARR_ORDER = ['hilly', 'ascent', 'paved', 'runtime']

export const CourseCardComponent = (props: CourseCardProps) => {

  const {
    variants,
    responsiveVariants,
    styles,
    course,
    ...rest
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:CourseCard',
    typeof CourseCardStyles
  >('u:CourseCard', {
    variants,
    styles,
    responsiveVariants,
  })

  const {
    previewDistance: distanceAway,
  } = CourseUtils.useDistanceFromStart(course)

  const coverImage = useCallback(CourseUtils.getCoverImage(course), [course])
  const courseTypeText = useMemo(() => CourseUtils.courseTypeText[course?.course_type], [course?.course_type])
  const courseTypeIcon = useMemo(() => CourseUtils.courseIcons[course?.course_type], [course?.course_type])

  const isBanner = variants?.includes('banner')

  const getStyles = useCallback((key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  }), [variantStyles])

  const suffixes = {
    hilly: '/5',
    paved: '%',
    ascent: 'm',
  }

  const coursesInfo = useMemo(() => ({
    runtime: Units.convert({ from: 's', to: 'min', value: course?.runtime, floor: true, toFixed: 0, withSuffix: true }),
    ascent: course?.ascent,
    paved: course?.paved,
    hilly: course?.hilly,
  }), [course])

  const sortedCourseInfoArr = useMemo(() => {
    return INFO_ARR_ORDER.map(key => {
      const value = coursesInfo[key]
      return { icon: key, title: `${value}${suffixes[key] || ''}`, subtitle: key as CourseStat }
    })
  }, [coursesInfo])

  const renderCourseStats = useCallback((
    { title, subtitle }: CardStatsProps,
    index: number,
  ) => {

    const isLast = index === sortedCourseInfoArr.length - 1

    const { value, label } = CourseUtils.getStatValues(course, subtitle)

    return (
      <View css={getStyles('statsItem')} key={title}>
        <Text css={getStyles('statsSubtitle')} text={`${label}:`} />
        <Text css={getStyles('statsTitle')} text={value} />
        {!isLast ? <Text css={getStyles('statsDot')} text='•' /> : null}
      </View>
    )
  }, [sortedCourseInfoArr, course])

  return (
    <CardBase
      debugName='Course Card'
      styles={getStyles('card')}
      component='article'
      {...rest}
    >
      <CourseDistance course={course} styles={getStyles('distance')} />

      <View css={getStyles('contentWrapper')}>
        <View css={getStyles('gradient')} />

        <View css={getStyles('topContent')}>

          <Tag
            debugName='Course Type'
            styles={getStyles('tag')}
            text={courseTypeText ?? null}
            rightIcon={courseTypeIcon ?? null}
            rightIconProps={{
              variants: ['white'],
            }}
          />
        </View>

        <View css={getStyles('mainContent')}>

          <Text css={getStyles('title')} text={course?.title} component='h3' />

          <View css={getStyles('infosWrapper')}>
            <Text css={getStyles('awayText')} text={`${distanceAway} away`} />
            <Text css={getStyles('location')} text={course?.location_name} />
            {isBanner && (
              <>
                <Text
                  css={getStyles('awayText')}
                  variants={['link', 'marginHorizontal:2']}
                  text={'Directions'}
                />
                <Text
                  css={getStyles('awayText')}
                  variants={['link']}
                  text={'Relevant info here'}
                />
              </>
            )}
          </View>

        </View>

        <Image
          source={coverImage}
          alt={CourseUtils.getImageAlt(course)}
          css={getStyles('image')}
          objectFit='cover'
          style={{
            backfaceVisibility: 'hidden',
          }}
        />

      </View>

      <View css={getStyles('bottomContent')}>
        <Text css={getStyles('bottomTitle')} text={course?.title} component='h3' />
        <Text css={getStyles('bottomAwayText')} text={`${distanceAway} away`} />
        <View css={getStyles('statsWrapper')}>
          {sortedCourseInfoArr.map((e, index) => renderCourseStats(
            {
              title: `${e?.title ?? '0'}`,
              subtitle: `${e?.subtitle}`,
            },
            index,
          ))}
        </View>
        <Text css={getStyles('description')} text={course?.description} />
      </View>

    </CardBase>
  )
}

export const CourseCardRef = forwardRef(CourseCardComponent) as unknown as (props: CourseCardProps) => JSX.Element

//@ts-ignore
export const CourseCard: (props: CourseCardProps) => ReactJSXElement = React.memo(CourseCardRef, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['course'],
  })
})
