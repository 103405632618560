import { useBreakpointMatch } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  CourseSection, CourseSectionProps,
} from '@/components'
import { CourseFilters } from '@/types'
import { Navigation, UserLocationUtils } from '@/utils'

export const RoadSection = (props: Partial<CourseSectionProps>) => {

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 3,
    desktopLarge: 4,
    desktopHuge: 5,
  })

  const { location } = UserLocationUtils.useCurrentLocation()
  const sectionFilters: CourseFilters = {
    lat: location?.latitude,
    lng: location?.longitude,
    min_paved: 100,
  }

  return (
    <CourseSection
      title={'Best paved runs nearby'}
      defaultCardWidth={COURSE_CARD_CONSTANTS.SMALL.WIDTH.DEFAULT}
      smallCardWidth={COURSE_CARD_CONSTANTS.SMALL.WIDTH.MOBILE}
      sectionFilters={sectionFilters}
      slidesToShow={slidesToShow}
      courseCardProps={{
        variants: ['small'],
        onPress: (item) => Navigation.Course.navigateToDetails(item),
      }}
      {...props}
    />
  )
}

