export const imageIcons = {
  'adjacent-loops': require('./adjacent-loops.svg'),
  apple: require('./apple.svg'),
  archive: require('./archive.svg'),
  'arrow-down-2': require('./arrow-down-2.svg'),
  'arrow-down': require('./arrow-down.svg'),
  'arrow-left-disabled': require('./arrow-left-disabled.svg'),
  'arrow-left': require('./arrow-left.svg'),
  'arrow-right-disabled': require('./arrow-right-disabled.svg'),
  'arrow-right': require('./arrow-right.svg'),
  'arrow-up-2': require('./arrow-up-2.svg'),
  'arrow-up': require('./arrow-up.svg'),
  badge: require('./badge.svg'),
  bell: require('./bell.svg'),
  'bookmark-filled': require('./bookmark-filled.svg'),
  bookmark: require('./bookmark.svg'),
  bug: require('./bug.svg'),
  calculator: require('./calculator.svg'),
  camera: require('./camera.svg'),
  check: require('./check.svg'),
  'chevron-down': require('./chevron-down.svg'),
  'chevron-left': require('./chevron-left.svg'),
  'chevron-right': require('./chevron-right.svg'),
  'chevron-up': require('./chevron-up.svg'),
  clock: require('./clock.svg'),
  close: require('./close.svg'),
  'cloud-lightning': require('./cloud-lightning.svg'),
  cloud: require('./cloud.svg'),
  contact: require('./contact.svg'),
  contrast: require('./contrast.svg'),
  'diagonal-arrow-left': require('./diagonal-arrow-left.svg'),
  'download-circle': require('./download-circle.svg'),
  'edit-2': require('./edit-2.svg'),
  edit: require('./edit.svg'),
  embed: require('./embed.svg'),
  'eye-off': require('./eye-off.svg'),
  eye: require('./eye.svg'),
  facebook: require('./facebook.svg'),
  'file-plus': require('./file-plus.svg'),
  'file-text': require('./file-text.svg'),
  file: require('./file.svg'),
  filter: require('./filter.svg'),
  fingerprint: require('./fingerprint.svg'),
  folder: require('./folder.svg'),
  google: require('./google.svg'),
  'gps-pin': require('./gps-pin.svg'),
  'heart-filled': require('./heart-filled.svg'),
  heart: require('./heart.svg'),
  image: require('./image.svg'),
  info: require('./info.svg'),
  instagram: require('./instagram.svg'),
  instapapper: require('./instapapper.svg'),
  key: require('./key.svg'),
  'lamp-floor': require('./lamp-floor.svg'),
  languages: require('./languages.svg'),
  layers: require('./layers.svg'),
  leaf: require('./leaf.svg'),
  linkedin: require('./linkedin.svg'),
  list: require('./list.svg'),
  'loader-2': require('./loader-2.svg'),
  loader: require('./loader.svg'),
  'log-out': require('./log-out.svg'),
  lollipop: require('./lollipop.svg'),
  mail: require('./mail.svg'),
  'map-focus': require('./map-focus.svg'),
  'map-pin': require('./map-pin.svg'),
  'map-search': require('./map-search.svg'),
  maximize: require('./maximize.png'),
  menu: require('./menu.svg'),
  'message-circle': require('./message-circle.svg'),
  mic: require('./mic.svg'),
  minus: require('./minus.svg'),
  'more-vertical': require('./more-vertical.svg'),
  'multiple-laps': require('./multiple-laps.svg'),
  'navigation-outline': require('./navigation-outline.svg'),
  navigation: require('./navigation.svg'),
  'out-and-return': require('./out-and-return.svg'),
  'person-run': require('./person-run.svg'),
  'pin-2': require('./pin-2.svg'),
  pin: require('./pin.svg'),
  placeholderNoItems_select: require('./placeholderNoItems_select.png'),
  placeholder_select: require('./placeholder_select.png'),
  plus: require('./plus.svg'),
  'point-to-point': require('./point-to-point.svg'),
  recent: require('./recent.svg'),
  road: require('./road.svg'),
  search: require('./search.svg'),
  send: require('./send.svg'),
  settings: require('./settings.svg'),
  share: require('./share.svg'),
  'single-loop': require('./single-loop.svg'),
  skamper_logo: require('./skamper_logo.png'),
  sound: require('./sound.svg'),
  star: require('./star.svg'),
  telegram: require('./telegram.svg'),
  terrain: require('./terrain.svg'),
  'thunder-sliced': require('./thunder-sliced.svg'),
  thunder: require('./thunder.svg'),
  trash: require('./trash.svg'),
  tumblr: require('./tumblr.svg'),
  twitter: require('./twitter.svg'),
  user: require('./user.svg'),
  whatsapp: require('./whatsapp.svg'),
  'wifi-off': require('./wifi-off.svg'),
  wifi: require('./wifi.svg'),
  x: require('./x.svg'),
  copy: require('./copy.svg'),
  'check-circle': require('./check-circle.svg'),
  warm: require('./warm.svg'),
  'warm-mobile': require('./warm-mobile.svg'),
  'new-logo': require('./new-logo.svg'),
  'warm-horizontal': require('./warm-horizontal.svg'),
}
