import { variantProvider } from '@/app'
import { FileInputRef, Touchable, View } from '@codeleap/web'
import { toolbarOptions } from './ToolbarOptions'
import { Editor } from '@tiptap/react'
import { RefObject } from 'react'
import { useMemo } from '@codeleap/common'

export * from './Extensions'

type ToolBarProps = {
  editor: Editor
  fileInputRef?: RefObject<FileInputRef>
  excludeIds?: string[]
}

const ToolBarOption = ({ option, editor, styles, fileInputRef, ...props }) => {
  return (
    <Touchable
      debugName={`toggle ${option.id}`}
      onPress={() => option.action({ editor, fileInputRef })}
      styles={styles}
      {...props}
    >
      {option.icon}
    </Touchable>
  )
}

export const ToolBar = (props: ToolBarProps) => {
  const { editor, fileInputRef, excludeIds } = props

  const options = useMemo(() => {
    if (excludeIds) {
      return toolbarOptions.map(group => group.filter(option => !excludeIds.includes(option.id)))
    } else {
      return toolbarOptions
    }
  }, [excludeIds?.length])

  return (
    <View style={styles.toolbarWrapper}>
      {options.map((group) => {
        return (
          <View style={styles.toolbarGroup}>
            {group.map((option) => {
              const isActive = option?.isActive?.({ editor }) || editor?.isActive?.(option.id)
              const optionStyles = {
                wrapper: { ...styles.option, ...(isActive && styles['option:active']) },
                'wrapper:disabled': styles['option:disabled'],
              }
              const optionComponentProps = { editor, option, styles: optionStyles, fileInputRef }
              return option?.custom ?
                option.custom({ defaultBtn: (props) => <ToolBarOption {...optionComponentProps} {...props}/>, ...optionComponentProps })
                : <ToolBarOption key={option.id} {...optionComponentProps}/>
            })}
          </View>
        )
      })}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  toolbarWrapper: {
    ...theme.spacing.padding(2),
    ...theme.spacing.gap(2),
    ...theme.presets.flex,
    ...theme.presets.justifyStart,
    ...theme.presets.alignCenter,
    flexWrap: 'wrap',
    borderTopLeftRadius: theme.borderRadius.small,
    borderTopRightRadius: theme.borderRadius.small,
    ...theme.border.neutral5({ width: 1 }),
    ...theme.presets.relative,
  },
  toolbarGroup: {
    backgroundColor: theme.colors.neutral5,
    borderRadius: theme.borderRadius.small,
    overflow: 'hidden',

  },
  option: {
    width: '100%',
    height: '100%',
    ...theme.spacing.padding(1),
    background: theme.colors.neutral3,
    ...theme.border.neutral2({ width: 1, directions: ['left', 'right'] }),
  },
  'option:active': {
    background: theme.colors.primary1,
  },
  'option:disabled': {},
}), true)
