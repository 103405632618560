import { View, Text, BottomDrawerBase, RatingFilterStars, RatingFilterStarsProps, ActionIcon } from '@/components'
import { AnyFunction } from '@codeleap/common'

type RatingFilterProps = {
  open: boolean
  onDismiss: AnyFunction
} & RatingFilterStarsProps

export const RatingFilterBottomDrawer = (props: RatingFilterProps) => {
  const { open, onDismiss, filters, onApply, onClear, setInternalFilterValues, internalFilterValues, ...rest } = props

  return (
    <BottomDrawerBase
      open={open}
      variants={['innerWrapperHeight:auto']}
      onDismiss={onDismiss}
      snapPoints={({ minHeight }) => minHeight}
      {...rest}
    >
      <View variants={['gap:3', 'column']}>
        <View variants={['fullWidth', 'justifySpaceBetween']}>
          <Text text='Rating' variants={['h3', 'color:neutral9']} />

          <ActionIcon
            icon={'close'}
            variants={['normalize', 'neutral9']}
            debugName='Close Rating Filters Drawer'
            onPress={onDismiss}
          />
        </View>
        <RatingFilterStars
          filters={filters}
          onApply={onApply}
          onClear={onClear}
          setInternalFilterValues={setInternalFilterValues}
          internalFilterValues={internalFilterValues}
        />
      </View>
    </BottomDrawerBase>
  )
}
