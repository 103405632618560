import { AppImages, Theme } from "@/app";
import { PaceCalculatorCalculateResponse } from "@/types";
import { formatToDecimal } from "./misc";
import { SEOProps } from "@/components";

const QUALITY_RESOLUTION = 5;
const ROW_HEIGHT = 14 * QUALITY_RESOLUTION;
const Colors = Theme.colors.light;
const FONT_FAMILY = "DM Sans";
const FONT_SIZE = 8 * QUALITY_RESOLUTION;
const H1_FONT__SIZE = 22 * QUALITY_RESOLUTION;
const CANVAS_WIDTH = 114 * QUALITY_RESOLUTION;
const CANVAS_HEIGHT = 1070 * QUALITY_RESOLUTION;
const PADDING = Theme.spacing.value(0.75) * QUALITY_RESOLUTION;

function generateBracelet(data: PaceCalculatorCalculateResponse) {
  if (!data) return;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = CANVAS_WIDTH;
  canvas.height = CANVAS_HEIGHT;

  const tableHeight = ROW_HEIGHT * (data.split_times.length + 2);

  context.fillStyle = Colors.background;
  context.fillRect(0, 0, canvas.width, canvas.height);

  const logo = new Image();
  const logoWidth = CANVAS_WIDTH * 0.76;
  const logoHeight = logoWidth * 0.2;
  logo.src = AppImages.NewLogo;
  logo.height = logoHeight;

  logo.onload = () => {
    context.drawImage(
      logo,
      canvas.width / 2 - logoWidth / 2,
      PADDING * 1.5,
      logoWidth,
      logoHeight
    );

    const tableHeaderY = logoHeight + CANVAS_HEIGHT * 0.017;
    const tableHeaderHeight = ROW_HEIGHT * 0.71;

    drawTableRow(context, PADDING, tableHeaderY, "Distance", "Time", {
      backgroundColor: Colors.primary1,
      fontWeight: "bold",
      rowHeight: tableHeaderHeight,
      fontSize: FONT_SIZE * 0.85,
    });

    data.split_times.forEach((split, index) => {
      const y = tableHeaderY + tableHeaderHeight + index * ROW_HEIGHT;
      const x = PADDING;

      drawTableRow(context, x, y, formatToDecimal(split.distance), split.time);
    });

    context.textAlign = "center";
    context.fillStyle = Colors.neutral10;
    context.font = `bold ${H1_FONT__SIZE}px ${FONT_FAMILY}`;
    context.fillText(
      data.target_time,
      CANVAS_WIDTH / 2,
      tableHeight + logoHeight + CANVAS_HEIGHT * 0.022
    );

    const imgUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "pace_table.png";
    link.href = imgUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

function drawTableRow(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  text1: string,
  tex2: string,
  layout = {
    backgroundColor: Colors.background,
    fontWeight: "normal",
    rowHeight: ROW_HEIGHT,
    fontSize: FONT_SIZE,
  }
) {
  const columnWidth = CANVAS_WIDTH / 2 - PADDING;
  context.fillStyle = layout?.backgroundColor;
  context.fillRect(x, y, columnWidth * 2, layout.rowHeight);

  context.textAlign = "center";
  context.fillStyle = Colors.neutral9;
  context.font = `${layout?.fontWeight} ${layout.fontSize}px ${FONT_FAMILY}`;

  const text1values = {
    x: x + columnWidth / 2,
    y: y + layout.rowHeight * 0.7,
  };

  context.fillText(text1, text1values.x, text1values.y);
  context.fillText(tex2, text1values.x + columnWidth, text1values.y);

  // Draw borders
  context.strokeStyle = Colors.neutral4;
  context.lineWidth = 1;
  context.strokeRect(x, y, columnWidth, layout.rowHeight);
  context.strokeRect(columnWidth + x, y, columnWidth, layout.rowHeight);
}

function getSEOProps(): Partial<SEOProps> {
  const description =
    "Use the free Skamper Pace Calculator to determine your ideal running pace for races and training. Get accurate results for 5K, 10K, half marathon, and more.";

  return {
    title: "Calculate your ideal running pace to reach your race goals.",
    description,
    pathname: "/resources/pace-calculator",
    script: `
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Pace Calculator",
        "url": "https://skamper.com/resources/pace-calculator",
        "description": ${description},
        "publisher": {
          "@type": "Organization",
          "name": "Skamper",
          "url": "https://skamper.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://skamper.com/images/logo.png",
            "width": 300,
            "height": 300
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@id": "https://skamper.com",
               "name": "Home",
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@id": "https://skamper.com/resources",
              "name": "Resources",
            }
          },{
            "@type": "ListItem",
            "position": 3,
            "item": {
              "@id": "https://skamper.com/resources/pace-calculator",
              "name": "Pace Calculator",
            }
          }]
        },
        "@type": "SoftwareApplication",
        "name": "Skamper Pace Calculator",
        "operatingSystem": "All",
        "applicationCategory": "SportsApplication",
        "about": "A pace calculator tool for runners to determine their running pace for different race distances and training plans.",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "GBP"
        },
        "creator": {
          "@type": "Organization",
          "name": "Skamper"
        }
      }
    `,
  };
}

export const PaceCalculatorUtils = {
  generateBracelet,
  getSEOProps,
};
