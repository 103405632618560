import { ActivityIndicator, AreaCardProps, View, AreaCard, SeeMoreGrid, SeeMoreGridProps } from '@/components'
import { Area } from '@/types'
import { useCallback, useEffect } from 'react'
import { Navigation, onScrollToWindowBottom, useIsMobile } from '@/utils'
import { EmptyPlaceholder, useBreakpointMatch } from '@codeleap/web'
import { Theme } from '@/app'

export type AreaGridProps = Partial<SeeMoreGridProps> & {
  id?: string
  areas: Area[]
  cardVariants?: AreaCardProps['variants']
  onPress?: (area: Area) => void
}

export const AreaGrid = ({
  areas,
  cardVariants,
  onPress,
  ...rest }: AreaGridProps) => {
  const isMobile = useIsMobile()

  const onEndReached = () => {
    if (!rest.hasNextPage || rest?.isFetchingNextPage) return
    rest?.fetchNextPage()
  }

  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 2,
    desktopLarge: 3,
    desktopHuge: 4,
  })

  useEffect(() => {
    if (!isMobile) return

    const handleScroll = () => {
      onScrollToWindowBottom(onEndReached)
    }
    window.addEventListener('scroll', handleScroll)

    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [rest?.fetchNextPage, rest?.hasNextPage, rest?.isFetchingNextPage, isMobile])

  const renderItem = useCallback(({ item }) => {

    return (
      <AreaCard
        area={item}
        variants={['fullWidth', 'areaGrid']}
        onPress={() => Navigation.Area.navigateToDetails(item)}
      />
    )
  }, [cardVariants, onPress])

  if (rest.isLoading) {
    return (
      <View variants={['justifyCenter', 'flex', 'alignCenter']} style={rest.style}>
        <ActivityIndicator debugName='AreaList:Loading' />
      </View>)
  }

  if (!areas?.length) {
    return (
      <EmptyPlaceholder
        title='No areas'
        description='There are no areas on this location'
        style={rest.style}
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  return (
    <SeeMoreGrid
      {...rest}
      data={areas as unknown as string[]}
      debugName='AreaGrid'
      numColumns={numColumns}
      rowItemsSpacing={Theme.spacing.value(4)}
      columnItemsSpacing={Theme.spacing.value(4)}
      renderItem={renderItem}
      isLoading={rest?.isLoading}
      isFetchingNextPage={rest?.isFetchingNextPage}
    />
  )

}

