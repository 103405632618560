import { Course } from '@/types'
import { ExploreCourseCard, ExploreCourseCardProps, Touchable } from '@/components'
import { PropsOf } from '@codeleap/common'

export type ExploreMapCourseCardProps = Partial<ExploreCourseCardProps> & {
  course?: Course
  wrapperStyle?: PropsOf<typeof Touchable>['style']
}

export const ExploreMapCourseCard = ({ course, onPress, wrapperStyle, ...rest }: ExploreMapCourseCardProps) => {
  if (!course) return null

  return (
    <Touchable style={wrapperStyle} variants={['opacity']} debugName='ExploreMapCourseCard:Touchable' onPress={onPress}>
      <ExploreCourseCard
        debugName='ExploreCourseCard'
        course={course}
        {...rest}
      />
    </Touchable>
  )
}
