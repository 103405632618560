import { variantProvider } from '../theme'

export type BottomDrawerComposition =
  | 'wrapper'
  | 'innerWrapper'

const createBottomDrawerStyle =
  variantProvider.createVariantFactory<BottomDrawerComposition>()

export const BottomDrawerStyles = {
  default: createBottomDrawerStyle((theme) => {
    return {
      wrapper: {
        ...theme.presets.justifySpaceBetween,
        gap: theme.spacing.value(2),
      },
      innerWrapper: {
        ...theme.presets.column,
        padding: theme.spacing.value(2),
        height: '80svh',
      },
    }
  }),
  'innerWrapperHeight:auto': createBottomDrawerStyle((theme) => {
    return {
      innerWrapper: {
        height: 'auto',
      },
    }
  }),
}
