import { CookiesKeys, MapDefaults } from '@/app'
import { IPInfo, Location } from '@/types'
import { createSlice } from '@codeleap/common'
import Cookies from 'js-cookie'

type UserLocationState = {
  location: Location
  ipInfo: IPInfo
  city: string
}
const _location = Cookies.get(CookiesKeys.USER_LOCATION)
const _ipInfo = Cookies.get(CookiesKeys.IP_INFO)

const initialState: UserLocationState = {
  location: _location ? JSON.parse(_location) : MapDefaults.center,
  ipInfo: _ipInfo ? JSON.parse(_ipInfo) : null,
  city: null,
}

export const userLocationSlice = createSlice({
  name: 'UserLocation',
  initialState,
  reducers: {
    setLocation: (_, location: Location) => ({ location }),
    setIPInfo: (_, ipInfo: IPInfo) => ({ ipInfo }),
    setCity: (_, city: string) => ({ city }),
  },
  asyncReducers: {},
})
