import { React, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { Icon, Overlay, View, ActivityIndicator, Text } from '@/components'
import { Logo } from './Logo'
import { useAnimatedStyle } from '@codeleap/web'

const indicatorSize = 60

export const AppStatusOverlay = () => {
  const status = useAppSelector((store) => store.AppStatus.status)

  onUpdate(() => {
    if (status === 'done') {
      setTimeout(() => {
        AppStatus.set('idle')
      }, 2000)
    }
  }, [status])

  const visibilityStyle = React.useCallback((appStatus) => {
    const isStatusVisible = status === appStatus

    return ({
      transform: `scale(${isStatusVisible ? 1 : 0})`,
      transition: 'transform 0.3s ease',
    })
  }, [status])

  const visibleOverlay = ['loading', 'done'].includes(status)

  const logoAnimation = useAnimatedStyle(() => ({
    scale: status == 'splash' ? 1 : 0.2,
    opacity: status == 'splash' ? 1 : 0.5,
    transition: {
      duration: .15,
    },
  }), [status])

  return (
    <>
      <Overlay visible={visibleOverlay} style={styles.overlayWrapper} />

      <View css={[styles.wrapper]}>
        <View style={styles.overlay}>
          <View css={[styles.container, visibilityStyle('done')]}>
            <Icon
              debugName='AppStatus:done'
              name='check'
              variants={['primary3', 'size:4']}
              style={visibilityStyle('done')}
            />
          </View>
        </View>

        <View style={styles.overlay}>
          <View css={[styles.container, visibilityStyle('loading')]}>
            <ActivityIndicator debugName='AppStatus:indicator' css={visibilityStyle('loading')} />
          </View>
        </View>
      </View>

      <View
        variants={['column']}
        css={[styles.splashWrapper, { opacity: status === 'splash' ? 1 : 0 }]}>
        <Logo
          debugName='AppStatus:splash'
          variants={['splash']}
          responsive={false}
          animated
          component={'div'}
          animatedProps={logoAnimation}
        />

        <Text
          variants={['h3', 'color:primary3', 'marginTop:2', 'marginLeft:3']}
          text='Britain’s Best Runs'
          animatedProps={logoAnimation}
          animated
          responsiveVariants={{
            mobile: ['p3', 'color:primary3', 'marginTop:1', 'bold', 'marginLeft:1'],
          }}
        />
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  overlay: {
    ...theme.presets.full,
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.center,
  },
  container: {
    width: indicatorSize,
    height: indicatorSize,
    backgroundColor: theme.colors.neutral1,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
  },
  wrapper: {
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'transform 0.3s ease',
  },
  splashWrapper: {
    display: 'flex',
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 200,
    pointerEvents: 'none',
    transition: 'all 0.3s ease',
    backgroundColor: theme.colors.primary1,
  },
  overlayWrapper: {
    zIndex: 199,
  },
}), true)
