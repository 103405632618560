/* eslint-disable max-len */
import { Course, ShortCoords, CourseFilters } from '@/types'
import {
  PaginationResponse,
  QueryManager,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'

const BASE_URL = 'web_course/'

export type ListFilters = UsePaginationTypes.PaginationParams &
  Partial<ShortCoords> &
  CourseFilters

export async function list(params?: ListFilters) {
  const response = await api.get<PaginationResponse<Course>>(BASE_URL, {
    params: {
      ...params,
    },
  })
  return response.data
}

export async function retrieve(
  courseId: Course['id'],
  params = { original_points: true }
) {
  const response = await api.get<Course>(`${BASE_URL}${courseId}/`, {
    params: {
      ...params,
    },
  })
  return response.data
}

export const coursesManager = new QueryManager<Course>({
  itemType: {} as Course,
  name: 'courses',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
  retrieveItem: async (id: Course['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

export type UseCoursesProps = Partial<ListFilters> & {
  enabled?: boolean
}

export const useCourses = (props?: UseCoursesProps) => {
  const { enabled = true, ...filters } = props || {}

  const useDefaultLocationRadius = !props.area_id

  const params = {
    ...filters,
    lat: useDefaultLocationRadius ? props?.lat : null,
    lng: useDefaultLocationRadius ? props?.lng : null,
    radius: useDefaultLocationRadius ? props?.radius : null,
    area_id: useDefaultLocationRadius ? null : props?.area_id,
  }

  const courses = coursesManager.use({
    filter: params,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled,
      },
    },
  })

  const totalCourses = courses?.list?.query?.data?.pages?.[0]?.count || 0

  return { courses, totalCourses }
}
