import { Settings } from '@/app'
import { ShortCoords } from '@/types'
import axios from 'axios'

type LocationDetails = {
  plus_code: google.maps.places.PlacePlusCode
  results: google.maps.GeocoderResponse
  status: string
}

const getIPInfo = async () => axios.get(Settings.Fetch.IPURL)
const getLocationDetails = async (coords: ShortCoords) => {
  return axios.get<LocationDetails>(`${Settings.Fetch.GeocodingURL}latlng=${coords.lat},${coords.lng}&key=${Settings.ApiCredentials.GoogleMaps.ApiKey}`)
}

const getLocation = (onSuccess: (position: GeolocationPosition) => void) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (pos) => onSuccess?.(pos),
      (error) => {
        console.error('Error getting location:', error.message)
      },
    )
  } else {
    console.error('Geolocation is not supported by this browser.')
  }
}

const getCityName = (details: LocationDetails) => {
  let city = null
  const result = details.results?.[0]
  if (result) {
    city = result.address_components.filter(ac => (ac.types.includes('locality') || ac.types.includes('administrative_area_level_2')))?.[0].long_name
  }

  return city
}

export const LocationUtils = {
  getLocation,
  getIPInfo,
  getLocationDetails,
  getCityName,
}
