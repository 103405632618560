import { useCodeleapContext } from '@codeleap/common'
import { useWindowSize } from '@codeleap/web'

export const useMaxContentWidth = () => {
  const { Theme } = useCodeleapContext()
  const [width, height] = useWindowSize()

  let effectiveWidth = width

  if (typeof window !== 'undefined') {
    effectiveWidth = width !== window.innerWidth ? window.innerWidth : width
  }

  const breakpoints: Record<string, number> = Theme.breakpoints
  const safeHorizontalPaddings = Theme.safeHorizontalPaddings()
  const entries = Object.keys(safeHorizontalPaddings)
  const sortedBreakpointsValues = Object.entries(breakpoints).sort((a, b) => b?.[1] - a?.[1])
  const highestBreakpoint = sortedBreakpointsValues[0]
  let currentBreakpoint = null
  let maxContentWidth = Theme.values.maxContentWidth
  const maxBreakpointEntryName = highestBreakpoint[0]
  const hasScreenReachedMaxWidth = effectiveWidth >= Theme.values.maxContentWidth

  entries.forEach(breakpoint => {
    if (effectiveWidth <= breakpoints[breakpoint]) {
      currentBreakpoint = breakpoint
    }
  })

  if (currentBreakpoint === maxBreakpointEntryName || !currentBreakpoint) {
    maxContentWidth = Theme.values.maxContentWidth
  } else {
    maxContentWidth = (hasScreenReachedMaxWidth ? Theme.values.maxContentWidth : effectiveWidth) - (safeHorizontalPaddings[currentBreakpoint] * 2)
  }

  const padding = (effectiveWidth - maxContentWidth) / 2

  return {
    width: maxContentWidth,
    padding,
  }
}
