import React, { useContext } from "react"
import { ComponentVariants, TypeGuards, useI18N } from "@codeleap/common"
import { useSearchParams, UseSearchParamsReturn } from '@codeleap/web'
import { useLocation, WindowLocation, NavigateFn } from "@reach/router"
import { PageProps, navigate } from "gatsby"
import { NavigationStyles } from '../../app/stylesheets'

type GetDynamicParamsProps<T extends string = string> = {
  defaultValues?: Partial<Record<T | undefined, string>>
}

type SearchParams = UseSearchParamsReturn<Record<string, string>>

type TNavigationContext<S = any> = {
  basepath: string
  location: WindowLocation<S>
  matchPath: (path: string) => boolean
  searchParams: SearchParams[0]
  setSearchParams: SearchParams[1]
  resetSearchParams: SearchParams[2]
  searchParamsURL: SearchParams[3]
  url: string
  getCurrentPath: (path: string) => string
  getDynamicParams: <T extends string = string>(listParams: Array<T>, props?: GetDynamicParamsProps<T>) => Record<T | undefined, string>
  params: PageProps['params']
  navigation: NavigateFn
  variants?: ComponentVariants<typeof NavigationStyles>['variants']
} & Partial<Omit<PageProps, 'children'>>

const NavigationContext = React.createContext({} as TNavigationContext)

type NavigationContainerProps = Partial<Omit<PageProps, 'children'>> & {
  children?: React.ReactNode
  basepath?: string
  variants?: ComponentVariants<typeof NavigationStyles>['variants']
}

export const NavigationContainer = (props: NavigationContainerProps) => {
  const {
    children,
    basepath = "/",
    params = {},
    ...rest
  } = props

  const location = useLocation()
  const { locale } = useI18N()

  const [
    searchParams, 
    setSearchParams, 
    resetSearchParams, 
    searchParamsURL
  ] = useSearchParams()

  const getCurrentPath = (path: string) => {
    const _path = path?.endsWith('/') ? path : path + '/'

    return basepath + _path
  }

  const matchPath = React.useCallback((path?: string) => {
    const prefix = locale === 'en-GB' ? '' : '/' + locale
    const _path = prefix + getCurrentPath(path)

    const pathname = location?.pathname

    const isIndex = path === '/'

    return isIndex ? (pathname === _path) : pathname?.startsWith(_path)
  }, [location?.pathname, locale])

  function getDynamicParams<T extends string = string>(listParams: Array<T>, props: GetDynamicParamsProps<T>): Record<T | undefined, string> {
    const {
      defaultValues = {}
    } = props

    const parseParams = (arr: string[]) => {
      let result = {}

      listParams?.forEach((name, i) => {
        const value = arr?.[i] as string

        const isNil = TypeGuards.isNil(value) || value?.length <= 0

        const defaultValue = defaultValues?.[name as any] ?? null

        result = {
          ...result,
          [name]: isNil ? defaultValue : value
        }
      })

      return result as any
    }

    if (!TypeGuards.isNil(params)) {
      const isDynamicParams = Object.keys(params)?.includes('*') 
      
      const _params = isDynamicParams
        ? Object.values(params)?.[0]?.split('/') 
        : params

      return parseParams(Object.values(_params))
    }

    return parseParams([])
  }

  const value: TNavigationContext = {
    ...rest,
    basepath,
    location,
    matchPath,
    searchParams,
    setSearchParams,
    resetSearchParams,
    searchParamsURL,
    url: location.href,
    getCurrentPath,
    getDynamicParams,
    params,
    navigation: navigate,
  }

  return <NavigationContext.Provider value={value}>
    {children}
  </NavigationContext.Provider>
}

export const useNavigation = () => {
  const ctx = useContext(NavigationContext)

  if (!ctx) {
    throw new Error("NavigationContext not found")
  }

  return ctx
}
