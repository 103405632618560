import React from 'react'

export * from './Settings'
export * from './logger'
export * from './theme'
export * from './performance'
export * from './constants'
export * from './localStorage'
export * from './api'

export type { AppIcon } from './assets/icons'
export { IconNames, iconImages } from './assets/icons'
import * as AppForms from './forms'
import * as AppImages from './assets/images'
import * as ComponentStyleSheets from './stylesheets'

export { I18N } from './i18n'

export { default as licenses } from './license.json'
export { default as timestamp } from './timestamp.json'

export { assignTextStyle } from './stylesheets/Text'

import { ThemeBreakpoint } from './theme'

import { useBreakpointMatch as _useBreakpointMatch } from '@codeleap/web'
import { CreateOSAlert } from '@codeleap/common'

export const useBreakpointMatch = _useBreakpointMatch<ThemeBreakpoint>

export const OSAlert = CreateOSAlert()

export {
  React,
  AppForms,
  AppImages,
  ComponentStyleSheets,
}
