/* eslint-disable max-lines */
import { Checkpoint, CourseMapCluster, MapTooltip } from '@/components'
import { CourseUtils } from '../courses/index'
import {
  Course,
  DrawMarkerProps,
  DrawCourseStartingPointTooltipProps,
  DrawPolylineProps,
  ExploreMapRefs,
  FitToMarkersProps,
  MapInstances,
  MapMarker,
  MapPolyline,
  UseDrawCoursesProps,
  DrawedCourse,
  CourseCluster,
  UseDrawCourseClustersProps,
} from '@/types'
import { AppImages, MapDefaults } from '@/app'
import { MapUtils } from './index'
import { StyleUtils } from '../styles'
import { onUpdate, useCallback, useMemo, useRef, useState } from '@codeleap/common'
import { SpatialUtils } from '../spatial'
import { useIsMobile } from '../hooks'
import { store } from '@/redux'

const fitToCourse = ({ map, maps, course, ...rest }: Partial<FitToMarkersProps> & { course: Course }) => MapUtils.fitToMarkers({
  map,
  maps,
  markers: course?.waypoints,
  ...rest,
})

const fitToCourses = ({ map, maps, courses, ...rest }: Partial<FitToMarkersProps> & { courses: Course[] }) => {
  const startingPoints = courses.map(c => CourseUtils.getStartingPoint(c)).filter(point => point !== null && point !== undefined)

  if (startingPoints.length > 0) {
    MapUtils.fitToMarkers({
      map,
      maps,
      markers: startingPoints,
      paddings: 100,
      ...rest,
    })
  }
}

const drawStartingPoint = ({ course, maps, map, ...rest }: Omit<DrawMarkerProps, 'position'> & { course: Course }) => {
  const startingPoint = CourseUtils.getStartingPoint(course)
  return MapUtils.drawMarker({ position: startingPoint, maps, map, id: course.id, zIndex: MapDefaults.zIndex.startingPoint, ...rest })
}

const setPolylinesOptions = (polylines: MapPolyline[], options: Partial<MapPolyline>) => {
  polylines.forEach((l) => {
    l.setOptions(options)
  })
}

const drawPolylines = ({
  course,
  map,
  maps,
  strokeColor = StyleUtils.randomVibrantColor(),
  ...rest
}: Omit<DrawPolylineProps, 'path'> & { course: Course }) => {
  const polylines = []
  const borders = []
  const hasTracks = course?.tracks?.length > 0

  if (hasTracks) {
    course?.tracks?.forEach((track) => {
      const points = track.points
      const isDashed = CourseUtils.isTrackDashed(track.name)

      if (isDashed) {
        const dashedLine = MapUtils.drawDashedPolyline({ map, maps, path: points })
        polylines.push(dashedLine)
        return
      }

      const { polyline, border } = MapUtils.drawPolyline({ map, maps, path: points })
      polylines.push(polyline)
      borders.push(border)
    })
  } else {
    if (course?.original_points?.length > 0) {

      const { polyline, border } = MapUtils.drawPolyline({
        map,
        maps,
        path: course?.original_points,
        strokeColor,
        borderColor: StyleUtils.darken(strokeColor, 20),
        borderWith: 4,
        id: `polyline:${course.id}`,
        ...rest,
      })

      polylines.push(polyline)
      borders.push(border)
    }
  }

  return { polylines, borders }
}

const removePolylines = (polylines: MapPolyline[]) => {
  polylines.forEach((line) => {
    MapUtils.removePolyline(line)
  })
  return []
}

const removeCheckpoints = (checkpoints: MapMarker[]) => {
  if (checkpoints?.length <= 0) return []

  checkpoints.forEach((cp) => {
    MapUtils.removeMarker(cp)
  })
  return []
}

const drawCheckpoints = ({
  course,
  map,
  maps }:
  Omit<DrawMarkerProps, 'position'> & { course: Course }) => {
  const { start, end } = CourseUtils.getStartAndEndCps(course)
  const checkpoints = []

  course?.waypoints?.forEach(async (cp) => {
    const { display_order, real_order } = cp

    let text =
      typeof display_order === 'number' ? String(display_order) : undefined

    if (start?.real_order === real_order) text = 'S'
    if (end?.real_order === real_order) text = 'F'

    const checkpoint = await MapUtils.drawComponent({ map, maps, position: cp, component: <Checkpoint text={text} /> })
    checkpoints.push(checkpoint)
  })

  return checkpoints
}

type DrawCourseProps = MapInstances & {
  index: number
  course: Course
  drawPolylinesProps?: Partial<DrawPolylineProps>
  drawStartingPointProps?: Partial<DrawMarkerProps>
  drawedCourse?: DrawedCourse
  noStartingPoint?: boolean
}

const removeCourse = (course) => {
  const { startingPoint, checkpoints, polylines, polylineBorders } = course

  removePolylines(polylines)
  removeCheckpoints(checkpoints)
  removePolylines(polylineBorders)
  if (!!startingPoint) { MapUtils.removeMarker(startingPoint) }
}

const drawCourse = async ({
  index,
  course,
  map,
  maps,
  noStartingPoint = false,
  drawPolylinesProps,
  drawStartingPointProps }: DrawCourseProps) => {
  const drawProps = { map, maps, course }
  let startingPoint = null

  if (!noStartingPoint) startingPoint = await drawStartingPoint({ ...drawProps, ...drawStartingPointProps })
  const checkpoints = []

  const { polylines, borders } = drawPolylines({
    ...drawProps,
    ...drawPolylinesProps,
    strokeColor: index < MapDefaults.courses.fixed ? StyleUtils.vibrantColors[index] : null,
  })

  return { startingPoint, checkpoints, polylines, polylineBorders: borders }
}

const isCourseClustered = (course: Pick<Course, 'id'>, clusters: CourseCluster[]) => {
  return clusters?.some(cluster => cluster.courses.some(c => c.id === course.id))
}

// clusterDistance -> distance in meters to cluster courses
function clusterCourses(courses: Course[], clusterDistance = MapDefaults.courses.clusteringDistance) {
  const clusters: CourseCluster[] = []

  courses.forEach(course => {
    const alreadyClustered = isCourseClustered(course, clusters)
    if (alreadyClustered) { return }

    const startingPoint = CourseUtils.getStartingPoint(course)

    const nearbyCourses = courses.filter(c => {
      return (SpatialUtils.distanceTo(startingPoint, CourseUtils.getStartingPoint(c)) <= clusterDistance) && c.id !== course.id
    })

    if (!!nearbyCourses.length) {
      clusters.push({
        id: course.id,
        position: startingPoint,
        courses: [course, ...nearbyCourses],
      })
    }
  })

  return clusters
}

const useDrawCourseClursters = ({
  map,
  maps,
  isMapLoaded,
  courseClusters,
  selectedCluster,
  onClusterPinBlur,
  onClusterPinHover,
  onClusterPinPress,
  onClusterPress,
  onNextCourse,
  onPrevCourse,
}: UseDrawCourseClustersProps,
) => {
  const isMobile = useIsMobile()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const clusterCourses = selectedCluster?.courses || []
  const selectedCourse = clusterCourses?.[selectedIndex]

  const handleNext = (cluster: CourseCluster) => {
    const isNextDisabled = (selectedIndex + 1) >= clusterCourses.length
    if (isNextDisabled) return
    const nextIndex = selectedIndex + 1
    setSelectedIndex(nextIndex)
    onNextCourse(cluster, cluster?.courses?.[nextIndex])

  }

  const handlePrev = (cluster: CourseCluster) => {
    const isPrevDisabled = selectedIndex <= 0
    if (isPrevDisabled) return
    const prevIndex = selectedIndex - 1
    setSelectedIndex(prevIndex)
    onPrevCourse(cluster, cluster?.courses?.[prevIndex])
  }

  const renderItem = useCallback(({ item }) => {
    const isSelected = selectedCluster?.id === item.id

    return (
      <CourseMapCluster
        isMobile={isMobile}
        index={selectedIndex}
        cluster={item}
        course={isSelected ? selectedCourse : null}
        zIndex={isSelected ? MapDefaults.zIndex.focusedPin : MapDefaults.zIndex.clusterPin}
      />
    )
  }, [selectedIndex, selectedCourse, isMobile])

  onUpdate(() => {
    if (!selectedCluster) setSelectedIndex(0)
  }, [selectedCluster])

  MapUtils.useRenderComponents<CourseCluster>({
    map,
    maps,
    isMapLoaded,
    data: courseClusters,
    renderItem,
    onItemPress: (cluster, { event }) => {
      const target = event.domEvent.target.id

      switch (target) {
        case `MapArrowCluster:${cluster.id}:prev`: {
          handlePrev(cluster)
          return
        }
        case `MapArrowCluster:${cluster.id}:next`: {
          handleNext(cluster)
          return
        }
      }

      if (target.includes(`MapCluster:NumberPin:${cluster.id}`)) {
        onClusterPinPress?.(cluster)
        return
      }

      onClusterPress?.(cluster)
    },
    onItemHover: (item) => {
      onClusterPinHover?.(item)
    },
    onItemBlur: (item) => {
      onClusterPinBlur?.(item)
    },
  })

}

const useDrawCourses = ({
  map,
  maps,
  isMapLoaded,
  coursesRef,
  courses = [],
  onPolylinePress,
  onPolylineHover,
  onPolylineBlur,
  onStartingPointPress,
  onStartingPointHover,
  onStartingPointBlur,
}: UseDrawCoursesProps) => {
  const deleteDrawedCourse = (drawedCourse: DrawedCourse) => {
    removeCourse(drawedCourse)
    coursesRef.current = coursesRef.current?.filter(({ course }) => course.id !== drawedCourse.course.id)
  }

  const checkUpdates = () => {
    const coursesToDelete: DrawedCourse[] = []
    const coursesToDraw = courses.filter(c => !coursesRef.current?.some(({ course }) => course.id === c.id))

    coursesRef.current?.forEach(drawedCourse => {
      const { course } = drawedCourse
      const currentCourse = courses.find(c => course.id === c.id)

      if (!currentCourse) {
        coursesToDelete.push(drawedCourse)
      }
    })

    const isEqual = coursesToDelete.length === 0 && coursesToDraw.length === 0

    return { isEqual, coursesToDelete, coursesToDraw }
  }

  const _drawCourse = (course: Course, isClustered: boolean, index: number) => {
    return drawCourse({
      index,
      map,
      maps,
      course,
      noStartingPoint: isClustered,
      drawPolylinesProps: {
        onPress: () => { onPolylinePress?.(course) },
        onHover: () => { onPolylineHover?.(course) },
        onBlur: () => { onPolylineBlur?.(course) },
      },
      drawStartingPointProps: {
        onPress: () => { onStartingPointPress?.(course) },
        onBlur: () => onStartingPointBlur?.(course),
        onHover: () => { onStartingPointHover?.(course) },
      },
    })
  }

  onUpdate(() => {
    if (!isMapLoaded || !map || !maps) return

    const { isEqual, coursesToDelete, coursesToDraw } = checkUpdates()

    if (isEqual) return

    coursesToDelete.forEach(deleteDrawedCourse)

    const courseClusters = store.getState().ExploreMapRedux.courseClusters

    coursesToDraw.forEach(async (course, index) => {
      const data = await _drawCourse(course, isCourseClustered(course, courseClusters), index)
      coursesRef.current.push({ ...data, course: { id: course.id }})
    })
  }, [
    map,
    maps,
    courses,
    isMapLoaded,
    onStartingPointHover,
    onStartingPointPress,
    onStartingPointBlur,
    onPolylinePress,
    onPolylineHover,
    onPolylineBlur,
  ])
}

const drawStartingPointTooltip = async ({
  tooltipId = 'sp-map-tooltip',
  position,
  onSetStartingPoint,
  onClose,
  ...rest }: DrawCourseStartingPointTooltipProps) => {

  return MapUtils.drawComponent({
    position,
    zIndex: MapDefaults.zIndex.tooltip + 1,
    onPress: (e) => {
      e.domEvent.stopPropagation()
      const targetId = e.domEvent.target.id

      if (targetId === `${tooltipId}:button`) {
        onSetStartingPoint?.(position)
        onClose?.()
      }

      if (targetId === `${tooltipId}:close`) {
        onClose?.()
      }
    },
    component:
      <MapTooltip
        id={tooltipId}
        title='Selected point'
        buttonProps={{
          text: 'Start here',
          debugName: 'Explore:StartHereButton',
        }} />,
    ...rest,
  })
}

const useExploreMap = ({ courseList }) => {
  const { mapRefs, ...useMapProps } = MapUtils.useMap()
  const startingPointsRef = useRef<MapMarker[]>([])
  const tooltipRef = useRef<MapMarker>(null)
  const detailsCourseRef = useRef<MapMarker>(null)
  const coursesRef = useRef<DrawedCourse[]>([])
  const fixedCoursesRef = useRef<Course[]>([])

  const fixedCourses = useMemo(() => {
    return courseList?.filter(course => !!course.original_points.length) || []
  }, [courseList])

  fixedCoursesRef.current = fixedCourses

  const { mapRef, mapsRef } = mapRefs

  const removeCourseDetails = () => {
    if (!!detailsCourseRef.current) { MapUtils.removeElement(detailsCourseRef.current) }
    detailsCourseRef.current = null
  }

  const setFixedCoursesVisibility = (visible = true, focusedCourseId?: Course['id']) => {
    const fixedDrawedCourses = coursesRef.current.filter(({ course }) => fixedCourses?.some(c => c.id === course.id) && course.id !== focusedCourseId)

    fixedDrawedCourses?.forEach(({ polylines, polylineBorders }) => {
      setPolylinesOptions(polylines, { visible, strokeWeight: visible ? 5 : 7 })
      MapUtils.setPolylinesVisible(polylineBorders, visible)
    })
  }

  const focusCourse = async ({ course: focusedCourse }: { course: Course }) => {
    if (!mapRefs.mapRef.current || !mapRefs.mapsRef.current) return

    const drawedCourse = coursesRef.current.find(({ course }) => course.id === focusedCourse.id)
    const otherCourses = coursesRef.current.filter(({ course }) => course.id !== focusedCourse.id)

    if (!!drawedCourse) {
      drawedCourse?.startingPoint?.setIcon({
        url: AppImages.CourseStartingPoint,
        scaledSize: new mapsRef.current.Size(40, 40),
      })
      drawedCourse?.startingPoint?.setZIndex(MapDefaults.zIndex.focusedPin)
    }

    otherCourses.forEach(({ polylines, polylineBorders }) => {
      MapUtils.setPolylinesVisible(polylines, false)
      MapUtils.setPolylinesVisible(polylineBorders, false)
    })

    setPolylinesOptions(drawedCourse?.polylines, { zIndex: 1, strokeWeight: 7 })
  }

  const unfocusCourse = (courseId: Course['id']) => {
    const drawedCourse = coursesRef.current.find(({ course }) => course.id === courseId)

    if (mapsRef.current && !!drawedCourse) {
      drawedCourse?.startingPoint?.setIcon({
        url: AppImages.CourseStartingPoint,
        scaledSize: new mapsRef.current.Size(32, 32),
      })

      drawedCourse.startingPoint?.setZIndex(MapDefaults.zIndex.startingPoint)
    }

    setFixedCoursesVisibility(true)
  }

  const _drawPolylines = ({ course }) => {
    const mapProps = { map: mapRef.current, maps: mapsRef.current }
    const drawedCourse = coursesRef.current.find(({ course: c }) => c.id === course.id)
    if (!drawedCourse) return
    if (drawedCourse?.polylines?.length > 0) {
      MapUtils.setPolylinesVisible(drawedCourse.polylines, true)
      return
    }

    const { polylines, borders } = drawPolylines({ course, zIndex: 1, strokeWeight: 7, borderWith: 0, ...mapProps })
    drawedCourse.polylines = polylines
    drawedCourse.polylineBorders = borders
  }

  const _drawCheckpoints = async ({ course }) => {
    const mapProps = { map: mapRef.current, maps: mapsRef.current }
    const drawedCourse = coursesRef.current.find(({ course: c }) => c.id === course.id)
    if (!drawedCourse) return

    if (drawedCourse?.checkpoints?.length > 0) {
      return
    }

    const checkpoints = await drawCheckpoints({ course, ...mapProps })
    drawedCourse.checkpoints = checkpoints
  }

  const _removeCheckpoints = (courseId: Course['id']) => {
    const drawedCourse = coursesRef.current.find(({ course: c }) => c.id === courseId)
    if (!drawedCourse) return

    removeCheckpoints(drawedCourse.checkpoints)
    drawedCourse.checkpoints = []
  }

  const hidePolylines = (courseId: Course['id']) => {
    const drawedCourse = coursesRef.current.find(({ course: c }) => c.id === courseId)
    if (!drawedCourse) return

    MapUtils.setPolylinesVisible(drawedCourse.polylines, false)
    MapUtils.setPolylinesVisible(drawedCourse.polylineBorders, false)
  }

  const _mapRefs = {
    ...mapRefs,
    coursesRef,
    detailsCourseRef,
    tooltipRef,
    startingPointsRef,
  } as ExploreMapRefs

  return {
    ...useMapProps,
    mapRefs: _mapRefs,
    fixedCourses,
    fixedCoursesRef,
    drawPolylines: _drawPolylines,
    drawCheckpoints: _drawCheckpoints,
    hidePolylines,
    removeCourseDetails,
    focusCourse,
    unfocusCourse,
    removeCheckpoints: _removeCheckpoints,
  }
}

export const Maps = {
  removeCourse,
  useExploreMap,
  useDrawCourses,
  fitToCourse,
  fitToCourses,
  drawStartingPoint,
  drawPolylines,
  removePolylines,
  drawCheckpoints,
  drawCourse,
  removeCheckpoints,
  drawStartingPointTooltip,
  useDrawCourseClursters,
  clusterCourses,
}

