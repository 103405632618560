export const IS_SSR = typeof window === 'undefined'

export const ROUTES = {
  // add a new route following the pattern of the others
  Home: '/',
  Profile: {
    Edit: '/profile/edit',
  },
  Auth: {
    Login: '/auth/login',
    Signup: '/auth/signup',
    ForgotPassword: '/auth/forgot',
    VerifyEmail: '/auth/verifyEmail',
  },
  Directory: {
    Countries: '/directory/countries',
    States: '/directory/states',
    Counties: '/directory/counties',
    Cities: '/directory/cities',
    Neighbourhoods: '/directory/neighborhoods',
  },
  About: '/about',
  CrudExample: '/crud',
  Playground: '/playground',
  Components: '/components',
  Run: '/run',
  Areas: '/areas',
  Resources: {
    PaceCalculator: '/resources/pace-calculator',
  },
  Runs: {
    Explore: '/runs/explore',
  },
  RunsIn: '',
  '404': '/404',
  Terms: '/terms_and_conditions',
}

export const DefaultDateFormat = 'dd/MM/yyyy'

export const CookiesKeys = {
  IP_INFO: '@Session.ipInfo',
  USER_LOCATION: '@Session.userLocation',
}

export const MapDefaults = {
  pixelsPerMeter: 156543.03392,
  center: {
    //** Londres */
    latitude: 51.5153874,
    longitude: -0.1425702,
  },
  zoom: {
    default: 11.3,
    followUser: 15,
    city: 12,
    max: 20,
    min: 0,
  },
  distance: {
    toSearch: 50, //mts
  },
  zIndex: {
    filters: 1,
    detailCard: 1,
    startingPoint: 1,
    clusterPin: 2,
    focusedPin: 3,
    tooltip: 4,
  },
  radius: {
    // In km
    default: 15,
    startingPoint: 1,
  },
  courses: {
    fixed: 5,
    limit: 50,
    clusteringDistance: 10,
  },
}

export const ElementIDs = {
  exploreCourseList: 'explore-course-list',
}

export const MyLocation = {
  name: 'My Location',
  description: 'Current Position',
}

export const HOME_AREA_ID = 93

export const HOME_AREA_KEY = 'Protected landscape'

export const Home_Section_IDs = {
  nearest: "Home_Section:Nearest",
  trail: "Home_Section:Trail",
  national_parks: "Home_Section:National_Parks",
  road: "Home_Section:Road",
  personal_bests: "Home_Section:Personal_Bests",
  uk_city: "Home_Section:Uk_City",
  weekend: "Home_Section:Weekend",
  mountain: "Home_Section:Monutain",
  areas: "Home_Section:Areas",
  latest: "Home_Section:Latest",
};

export const Slug_Names = {
  home: 'Home',
  runs: 'All runs'
}
