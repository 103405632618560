import { variantProvider } from '@/app'
import { Modal, View, Button, Text, ActionIcon, Touchable } from '@/components'
import { useCopyToClipboard } from '@/utils'
import { PropsOf } from '@codeleap/common'

export type EmbedModalProps = PropsOf<typeof Modal> & {
  iframeSource: string
  description?: string
  showBack?: boolean
  onBack?: () => void
}

export const EmbedModal = ({
  toggle,
  title,
  iframeSource,
  showClose,
  showBack = false,
  description,
  onBack,
  ...rest }: EmbedModalProps) => {
  const { copied, copyToClipboard, setCopied } = useCopyToClipboard()

  const handleCopy = () => {
    copyToClipboard(iframeSource)
  }

  const handleToggle = () => {
    setCopied(false)
    toggle?.()
  }

  const handleBack = () => {
    if (!showBack) return
    onBack?.()
  }

  return (
    <Modal
      {...rest}
      showClose={false}
      toggle={handleToggle}
      variants={['centered', 'titleLeft']}
      css={styles.modalWrapper}
    >
      <View variants={['column', 'alignCenter']}>
        <View variants={['fullWidth', 'paddingTop:4', 'paddingBottom:3']}>
          <Touchable debugName='EmbedModal:Back Wrapper' onPress={handleBack}>
            {!!showBack && (<ActionIcon debugName='EmbedModal:Back' name='chevron-left' variants={['minimal', 'neutral9']} />)}
            <Text text={title} variants={['h3']} />
          </Touchable>

          {!showClose && (<ActionIcon debugName='EmbedModal:Close' onPress={toggle} name='close' variants={['minimal', 'neutral9', 'marginLeft:auto']} />)}
        </View>

        {!!description && (<Text text={description} variants={['neutral-7', 'p3', 'marginBottom:3']} />)}

        <View css={styles.textWrapper}>
          <Text
            css={styles.text}
            variants={['neutral-9', 'p3']}
            text={iframeSource}
          />
        </View>

        <Button
          onPress={handleCopy}
          debugName='EmbedModal:Copy Code' variants={['large']}
          css={styles.button}
          text={copied ? 'Copied!' : 'Copy Code'}
        />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  modalWrapper: {
    maxWidth: 480,
    paddingTop: 0,
  },
  textWrapper: {
    ...theme.border.neutral6(theme.values.borderWidth.small),
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing.value(1),
    maxWidth: '100%',
    overflow: 'hidden',
  },
  text: {
    flex: 1,
    whiteSpace: 'pre-line',
  },
  button: {
    ...theme.presets.fullWidth,
    marginTop: theme.spacing.value(3),
    maxWidth: 320,
  },
}), true)

