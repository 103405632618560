import { View, Text, Link } from '@/components'
import { RoutePath, getCategoriesTree } from '@/utils'
import { StylesOf } from '@codeleap/common'
import { NavigationCategory } from '@/types'
import { FooterComposition } from '../../../app/stylesheets/Footer'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
  categories: NavigationCategory[]
}

function Column({ category, variantStyles }) {
  const renderLink = (item: NavigationCategory) => (

    <Link
      to={item?.link}
      about={item?.alt}
      route={item?.link as RoutePath}
      text={item?.title}
      css={variantStyles.navigateLink}
    />
  )

  return (
    <View css={variantStyles.navigateColumn} component='ul'>
      <Text css={variantStyles.navigateTitle} text={category.title} />
      {category.children?.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles, categories } = props
  const categoriesTree = getCategoriesTree(categories, 1)

  return (
    <View css={variantStyles.navigateWrapper} component='nav'>
      {categoriesTree.map(category => (
        <Column
          key={category.id}
          variantStyles={variantStyles}
          category={category}
        />
      ))}
    </View>
  )
}
