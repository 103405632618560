import { React } from '@/app'
import { ActionIcon, SpotlightModal, SpotlightModalProps } from '..'
import { PropsOf, TypeGuards, useBooleanToggle } from '@codeleap/common'

export type SpotlightComponentProps = {
    actionIconProps?: Omit<PropsOf<typeof ActionIcon>, 'debugName'>
    modalProps?: SpotlightModalProps
    renderWrapper?: (
      visible: SpotlightComponentProps['modalProps']['visible'],
      toggle: SpotlightComponentProps['modalProps']['toggle']
      )
    => React.ReactElement
}

export const SpotlightComponent = (props: SpotlightComponentProps) => {

  const { actionIconProps, modalProps, renderWrapper } = props

  const _variants: SpotlightComponentProps['actionIconProps']['variants'] = actionIconProps?.variants || []

  const [internalVisible, internalToggle] = useBooleanToggle(false)

  const externalVisible = modalProps?.visible
  const externalToggle = modalProps?.toggle

  const visible = modalProps?.hasOwnProperty?.('visible') ? externalVisible : internalVisible
  const toggle = modalProps?.hasOwnProperty?.('toggle') ? externalToggle : internalToggle

  const Wrapper = () => {

    if (TypeGuards.isFunction(renderWrapper)) {
      return renderWrapper(visible, toggle)
    }

    return (
      <ActionIcon
        icon='maximize'
        debugName='Maximize Course Photos Button'
        onPress={toggle}
        {...actionIconProps}
        variants={['backgroundColor:neutral2', 'iconSize:3', 'padding:2', 'border-radius:smallish', 'imageSlider', ..._variants]}
      />
    )
  }

  return (
    <>

      <Wrapper />

      <SpotlightModal
        visible={visible}
        toggle={toggle}
        {...modalProps}
      />
    </>
  )
}

