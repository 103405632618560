import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { useFlatlistProps } from '@/utils'
import { NavigationCategory, NavigationCategoryFilters } from '@/types'

const BASE_URL = 'crm/web-categories/'

export async function list(params?: Record<string, any>) {
  const response = await api.get<PaginationResponse<NavigationCategory>>(BASE_URL, {
    params,
  })
  return response.data
}

export async function retrieve(
  id: NavigationCategory['id'],
  params = {},
) {
  const response = await api.get<NavigationCategory>(`${BASE_URL}${id}/`, {
    params: {
      ...params,
    },
  })
  return response.data
}

export const categoriesManager = new QueryManager<NavigationCategory>({
  itemType: {} as NavigationCategory,
  name: 'navigationcategories',
  queryClient: queryClient.client,
  limit: 30,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit: limit, offset, ...filter })
    return response
  },

  retrieveItem: async (id: NavigationCategory['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

export type UseCategoriesProps = Partial<NavigationCategoryFilters> & {}

export const useCategories = (props?: UseCategoriesProps) => {
  const { type, ...rest } = props

  const categories = categoriesManager.use({
    filter: { type, ...rest },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    },
  })

  const listProps = useFlatlistProps(categories)

  return { categories, listProps }
}
