import { PaceCalculatorCalculateBody, PaceCalculatorCalculateResponse } from '@/types'
import { api } from '@/app'

const BASE_URL = 'locations/pace_calculator/'

export async function calculate(body?: PaceCalculatorCalculateBody) {
  const response = await api.post<PaceCalculatorCalculateResponse>(BASE_URL, body)
  return response.data
}

