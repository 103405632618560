import { onUpdate } from "@codeleap/common"
import { Dispatch, SetStateAction } from "react"


type useFilterByTextProps = {
  params: Record<string, string>
  setText: Dispatch<SetStateAction<string>>
}

export const useFilterByText = (props: useFilterByTextProps) => {
  const { params, setText } = props

  onUpdate(() => {
    if (params?.stars) {
      const orderedFilterStars = params?.stars?.split(',')?.sort((a, b) => Number(a) - Number(b))?.join(', ')
      setText(`${orderedFilterStars} stars`)
    } else {
      setText('All stars')
    }
  }, [params])
}
