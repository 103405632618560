import { View, Text } from '@/components'
import { Course } from '@/types'
import { Units } from '@/utils'
import { PropsOf } from '@codeleap/common'

export type CourseDisaclaimersProps = Partial<PropsOf<typeof View>> & {
  course?: Course
}

export const CourseDisaclaimers = ({ course, ...rest }: CourseDisaclaimersProps) => {
  const displayElevationDisclaimer = !!course?.show_height_disclaimer
  const displayDashedAreasDisclaimer = course?.distance_dashed > 0

  const elevationDisclaimer = 'This course has sections close to or above 500m. Weather conditions can change at short notice, so plan accordingly.'
  const hasDashedAreasDescription =
    'This course includes one or more areas where higher levels of caution are recommended and your time is temporarily paused. We suggest reviewing the map in close up, where these sections are shown with dashed lines.'

  const totalCourseDashedDistance = Units.convert({
    value: course?.distance_dashed,
    from: 'm',
    to: 'km',
    toFixed: 1,
  })

  const totalCourseDistance = Units.convert({
    value: course?.distance_total,
    from: 'm',
    to: 'km',
    toFixed: 1,
  })

  return (
    <View variants={['column']} {...rest}>
      {displayElevationDisclaimer && (
        <Text variants={['p1', 'marginTop:1']} text={elevationDisclaimer} />
      )}

      {displayDashedAreasDisclaimer && (
        <View variants={['column']}>
          <Text
            variants={['p1', 'marginTop:2']}
            text={hasDashedAreasDescription}
          />
          <Text variants={['p1', 'marginTop:2']}>
            This course includes {totalCourseDashedDistance} km
            sections of dashed lines. The total distance for the course
            including these sections is {totalCourseDistance} {'km'}
          </Text>
        </View>
      )}
    </View>)
}
