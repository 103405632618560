import { Theme } from '@/app'

export type MapIconProps = {
  name: string
  id: string
  wrapperStyle?: React.CSSProperties
}

export const MapIcon = ({ name, id, wrapperStyle, ...rest }) => {
  const Component = Theme?.icons?.[name]

  return (
    <div id={id} style={wrapperStyle}>
      <Component {...rest} pointerEvents='none'/>
    </div>
  )
}
