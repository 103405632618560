import { createForm } from '@codeleap/common'
import { signup } from './signup'

export const editProfile = () => {
  const signupForm = signup()

  return createForm('editProfile', {
    email: signupForm.config.email,
    birthday: signupForm.config.birthday,
    first_name: signupForm.config.first_name,
    last_name: signupForm.config.last_name,
    avatar: signupForm.config.avatar,
  })
}
