import { useTranslation } from "gatsby-plugin-react-i18next"
import { useI18N } from "@codeleap/common"
import { useEffect } from "react"

export const useUpdateLocale = () => {
  const { i18n } = useTranslation()
  const { setLocale } = useI18N()

  useEffect(() => {
    setLocale(i18n.language)
  }, [i18n.language])
}
