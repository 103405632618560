import { TAppStatus, useAppSelector } from '@/redux'
import { create } from 'zustand'

type AppStatusStore = {
  status: TAppStatus['status']
}

export const appStatusStore = create<AppStatusStore>(() => ({ status: 'idle' }))

export const useAppStatus = () => {
  const status = useAppSelector(store => store.AppStatus.status)

  const showingFeedback = status === 'done' || status === 'loading'

  const showingSplash = status === 'splash'

  return {
    showingFeedback,
    showingSplash,
    status,
  }
}
