import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from '@codeleap/common'
import { NavigationCategory } from '@/types'
import { fetchAllData } from 'gatsby-utils'

const BASE_URL = 'crm/web-categories/'
export const useHeaderCategories = () => {
  const [freshedData, setFreshedData] = useState<NavigationCategory[]>(null)

  useEffect(() => {
    const fetch = async () => {
      const response: NavigationCategory[] = await fetchAllData(
        `${process.env.GATSBY_API_URL}${BASE_URL}?type=HEADER`
      )

      setFreshedData(response)
    }
    fetch()
  }, [])
  const data = useStaticQuery(graphql`
    query HeaderCategories {
      headerCategories {
        categories {
          id
          title
          type
          link
          level
          parent {
            id
            title
            type
            link
            level
          }
        }
      }
    }
  `)

  return freshedData || data.headerCategories.categories
}
