import { variantProvider } from '../theme'
import { assignTextStyle } from '@codeleap/common'

export type GetAppBannerComposition = 'wrapper' | 'innerWrapper' | 'textsWrapper' | 'title' | 'description' | 'getAppButton' | 'closeButton' |
'closeButtonIcon' | 'logo' | 'logoWrapper' | 'leftWrapper'

const createGetAppBannerStyles = variantProvider.createVariantFactory<GetAppBannerComposition>()

export const GetAppBannerStyles = {
  default: createGetAppBannerStyles((theme) => {
    const DEFAULT_CLOSE_ICON_SIZE = 20
    const DEFAULT_LOGO_WRAPPER_SIZE = 48

    return {
      wrapper: {
        ...theme.presets.fixed,
        ...theme.presets.center,
        paddingTop: theme.spacing.value(1),
        paddingBottom: theme.spacing.value(1),
        bottom: 0,
        backgroundColor: theme.colors.neutral1,
        ...theme.presets.fullWidth,
        zIndex: 9999999999,
      },
      innerWrapper: {
        ...theme.presets.center,
      },
      textsWrapper: {
        ...theme.presets.column,
      },
      title: {
        fontWeight: 'bold',
        color: theme.colors.neutral9,
      },

      description: {
        ...assignTextStyle('p4')(theme).text,
      },
      getAppButton: {
        marginLeft: 'auto',
        borderRadius: theme.borderRadius.smallish,
        height: 45,
      },
      closeButton: {
        backgroundColor: 'transparent',
      },
      closeButtonIcon: {
        color: theme.colors.neutral10,
        width: DEFAULT_CLOSE_ICON_SIZE,
        height: DEFAULT_CLOSE_ICON_SIZE,
      },
      logoWrapper: {
        width: DEFAULT_LOGO_WRAPPER_SIZE,
        height: DEFAULT_LOGO_WRAPPER_SIZE,
        backgroundColor: theme.colors.primary3,
        borderRadius: theme.borderRadius.smallish,
        ...theme.presets.center,
      },
      logo: {
        width: 16,
        height: 30,
      },
      leftWrapper: {
        gap: theme.spacing.value(1),
        ...theme.presets.center,
      },
    }
  }),
  'bannerTop': createGetAppBannerStyles((theme) => {
    return {
      getAppButton: {
        borderRadius: theme.borderRadius.mediumish,
        backgroundColor: theme.colors.azure,
      },
    }
  }),
}

