import { variantProvider } from '@/app'
import {
  CenterWrapper,
  Text,
  View,
  Icon,
  GetAppButton,
} from '@/components'
import { useIsMobile } from '@/utils'
import { StaticImage } from 'gatsby-plugin-image'

export const DownloadSection = () => {

  const isMobile = useIsMobile()

  return (
    <CenterWrapper variants={['marginTop:4']} component='section'>
      <View
        style={styles.innerWrapper}
      >
        <View
          style={styles.leftContent}
        >
          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/Get-Britains-best-runs-completely-free.webp'}
            alt='Home:Download Section image'
          />
        </View>
        <View
          variants={['column', 'center', 'gap:5', 'padding:2']}
          responsiveVariants={{
            mobile: ['gap:1'],
          }}
          style={styles.rightContent}
        >
          <Icon
            debugName='Download Section Background icon'
            name='warm-horizontal'
            style={styles.icon}
          />
          <View
            variants={['column', 'center', 'gap:5', 'fullHeight']}
            responsiveVariants={{
              mobile: ['gap:2'],
            }}
            style={styles.rightContentInnerWrapper}
          >
            <Text
              text="Get Britain's Best Runs Completely Free"
              variants={['h0', 'color:primary5', 'textCenter']}
              responsiveVariants={{
                mobile: ['h5', 'color:primary5', 'textCenter', 'bold'],
              }}
              style={styles.text}
              component='h2'
            />
            <GetAppButton
              text={isMobile ? 'Download' : 'Get the app'}
              variants={['large', 'downloadSectionAppButton']}
              style={styles.getAppButton}
              icon={null}
            />
          </View>
        </View>
      </View>
    </CenterWrapper>
  )
}

const SECTION_HEIGHT = 400
const ICON_SIZE = 170
const TEXT_WIDTH = 500

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    overflow: 'hidden',
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    height: SECTION_HEIGHT,
    borderRadius: theme.borderRadius.large,

    [theme.media.down('mobile')]: {
      height: 160,
    },
  },
  leftContent: {
    ...theme.presets.flex,
  },
  image: {
    ...theme.presets.fullWidth,
  },
  rightContent: {
    ...theme.presets.relative,
    backgroundColor: theme.colors.primary1,
    ...theme.presets.flex,
    ...theme.presets.fullHeight,
  },
  rightContentInnerWrapper: {
    maxWidth: TEXT_WIDTH,
  },
  icon: {
    ...theme.presets.absolute,
    ...theme.presets.top,

    [theme.media.down('mobile')]: {
      width: '150%',
      height: '100%',
      right: 0,
    },
  },
  text: {
    [theme.media.down('mobile')]: {
      lineHeight: '19.2px',
    },
  },
  getAppButton: {
    [theme.media.down('mobile')]: {
      ...theme.spacing.marginTop(0),
    },
  },
}), true)
