import { View, Icon } from '@/components'
import { PropsOf } from '@codeleap/common'

type StarsProps = {
  stars: number
  score?: number
} & Partial<PropsOf<typeof View>>

export const RatingStars = (props: StarsProps) => {
  const { stars, score, ...rest } = props

  return (
    <View {...rest}>
      <View variants={['gap:0.5']}>
        {Array(stars).fill(0).map((_, index) => {
          const isFilled = !score || score >= index + 1
          return (
            <Icon
              key={`Star:${index}`}
              name='star'
              variants={['medium', isFilled ? 'orange1' : 'neutral3']}
              debugName='Rating Stars Group'
            />
          )
        })}
      </View>
    </View>
  )
}
