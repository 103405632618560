import { PropsOf } from '@codeleap/common'
import { variantProvider } from '@/app'
import { useAnimatedStyle } from '@codeleap/web'
import { Button, View } from '@/components'
import { useIsMobile } from '@/utils'

export type ExploreMapSearchButtonProps = Partial<PropsOf<typeof Button>> & {
  isSearchAreaVisible: boolean
}

export const ExploreMapSearchButton = ({ loading, isSearchAreaVisible, ...rest }: ExploreMapSearchButtonProps) => {
  const isVisible = isSearchAreaVisible || loading
  const isMobile = useIsMobile()

  const topHidden = isMobile ? -160 : 0
  const topVisible = isMobile ? 60 : 69

  const searchAnimation = useAnimatedStyle(() => ({
    top: isVisible ? topVisible : topHidden,
    transition: {
      duration: isMobile ? 0.30 : 0.15,
    },
  }), [isVisible, isMobile])

  return (
    <View
      variants={['absolute', 'fullWidth', 'justifyCenter', 'paddingHorizontal:2']}
      animated
      animatedProps={searchAnimation}
    >
      <Button
        disabled={loading}
        variants={['pill', 'card']}
        loading={loading}
        icon={loading ? null : 'search'}
        loadingShowText
        debugName='ExploreMap:SearchArea'
        text={loading ? 'Searching...' : 'Search this area'}
        css={styles.searchAreaButton}
        {...rest}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  searchAreaButton: {
    ...theme.presets.absolute,
    width: 180,
    height: theme.values.itemHeight.small + theme.spacing.value(1),
    top: 16,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}), true)
