import { variantProvider } from '@/app'
import { Button } from '@/components'
import { PropsOf } from '@codeleap/common'

export type SeeMoreButtonProps = Partial<PropsOf<typeof Button>> & {
  debugName: string
}

export const SeeMoreButton = ({ variants = [], ...rest }: SeeMoreButtonProps) => {
  return (
    <Button
      text='See more'
      rightIcon='arrow-down'
      variants={['secondary', ...variants]}
      css={styles.wrapper}
      styles={{
        rightIcon: styles.rightIcon,
      }}
      {...rest}
    />
  )
}

const MAX_WIDTH = 132

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: MAX_WIDTH,
  },
  rightIcon: {
    marginLeft: theme.spacing.value(1),
  },
}), true)
