import { View, Text } from '@/components'
import { LineChartYAxisProps } from '../types'

export const LineChartYAxis = ({ data = [], variantStyles }: LineChartYAxisProps) => {
  return (
    <View css={variantStyles.yAxisWrapper}>
      {data.map((d, index) => {
        const isFirst = index === 0

        return (
          <View key={index} css={{ ...variantStyles.yAxisTextWrapper, height: isFirst ? 'unset' : '90%' }}>
            {!isFirst && <View css={variantStyles.yAxisLine} />}
            <Text css={variantStyles.yAxisText} text={d.value} />
          </View>
        )
      })}
    </View>
  )
}
