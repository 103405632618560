import { I18N, IS_SSR, Settings } from '@/app'
import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'
import { create } from 'zustand'

type ShareStore = {
  setShare: (data: ShareData) => void
  data: ShareData
  visible: boolean
  toggle: (to?: boolean) => void
}

type ShareContent = {
  message: string
  url: string
  title: string
}

const shareStore = create<ShareStore>((set) => ({
  data: null,
  visible: false,
  setShare: (data: ShareData | null) => set({ data }),
  toggle: (to = null) => set(state => ({
    visible: TypeGuards.isBoolean(to) ? to : !state?.visible
  }))
}))

const getWebsite = () => {
  if (IS_SSR) return ''

  return window?.location?.origin
}

const share = async (content: ShareContent) => {
  if (!navigator) return
  
  const isMobileShare = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

  const shareData: ShareData = {
    text: content?.message,
    url: content?.url,
    title: content?.title,
  }

  const shareable = (!!navigator.share && navigator.canShare(shareData)) && isMobileShare

  if (!shareable) {
    shareStore.setState({ data: shareData, visible: true })
  } else {
    await navigator.share(shareData)
  }
}

export const sharePost = (post: APIClient.Post.Post) => {
  const address = `${getWebsite()}/crud/${post?.id}`

  const message = I18N.t('share.post.message', {
    appName: Settings.AppName,
    postName: post.title,
  })

  share({
    message,
    url: address,
    title: post.title,
  })
}

export const Share = {
  getWebsite,
  useShareStore: shareStore,
  post: sharePost,
}
