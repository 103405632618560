import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { assignTextStyle } from './Text'
import { ActionIconComposition, ButtonComposition, DrawerComposition } from '@codeleap/web'
import { AvatarComposition } from './Avatar'

export type HeaderComposition =
  'wrapper' |
  'innerWrapper' |
  `drawer${Capitalize<DrawerComposition>}` |
  `signInButton${Capitalize<ButtonComposition>}` |
  `menuIcon${Capitalize<ActionIconComposition>}` |
  'navContentWrapper' |
  'navItem' |
  'navItem:selected' |
  'logoWrapper' |
  'logo' |
  'profileWrapper' |
  'profileInfos' |
  'firstName' |
  'email' |
  'drawerLogo' |
  `avatar${Capitalize<AvatarComposition>}`

const createHeaderStyle = variantProvider.createVariantFactory<HeaderComposition>()

const presets = includePresets((s) => createHeaderStyle(() => ({ wrapper: s })))

export const BREAKPOINT = 'tabletSmall'
const logoSize = 24

export const AppHeaderStyles = {
  ...presets,
  default: createHeaderStyle((theme) => ({
    wrapper: {
      position: 'sticky',
      zIndex: theme.zIndexes.header,
      top: theme.spacing.value(0),
      left: theme.spacing.value(0),
      right: theme.spacing.value(0),
      backgroundColor: theme.colors.background + '55',
      backdropFilter: 'blur(4px)',
      borderBottom: `1px solid ${theme.colors.neutral3}`,
      ...theme.spacing.paddingVertical(2),
    },
    innerWrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
    },
    logo: {
      width: logoSize * 8,

      [theme.media.down(BREAKPOINT)]: {
        width: logoSize,
      },
    },
    logoWrapper: {
      marginRight: 'auto',

      [theme.media.down(BREAKPOINT)]: {
        marginRight: theme.spacing.value(0),
        display: 'flex',
        ...theme.presets.justifyCenter,
      },
    },
    drawerBox: {
      ...theme.presets.column,
      ...theme.presets.alignCenter,
      gap: theme.spacing.value(2),
      backgroundColor: theme.colors.background,
      alignItems: 'stretch',
      ...theme.presets.elevated,
    },
    drawerCloseButtonIcon: {
      color: theme.colors.neutral10,
    },
    drawerCloseButtonTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
      marginLeft: 'auto',
    },
    profileWrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1 }),
      ...theme.presets.centerRow,
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(2),
      ...theme.spacing.marginBottom(1),
    },
    profileInfos: {
      width: `calc(100% - ${theme.values.itemHeight.small + theme.spacing.value(3)}px)`,
      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.spacing.gap(0.5),
    },
    email: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral8,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    firstName: {
      ...assignTextStyle('h3')(theme).text,
    },
    signInButtonWrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(1),
      height: theme.values.itemHeight.small,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.fullWidth,
        height: theme.values.itemHeight.default,
      },
    },
    signInButtonText: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '500',
    },
    signInButtonIcon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],

      [theme.media.down(BREAKPOINT)]: {
        width: theme.values.iconSize[2],
        height: theme.values.iconSize[2],
      },
    },
    menuIconTouchableWrapper: {
      marginLeft: 'auto',
      background: 'transparent',
    },
    menuIconIcon: {
      marginLeft: 'auto',
      color: theme.colors.neutral10,
    },
    navItem: {
      ...assignTextStyle('h4')(theme).text,
      ...theme.presets.textCenter,
      color: theme.colors.neutral9,
      fontWeight: '600',
      textDecoration: 'none',

      [theme.media.down(BREAKPOINT)]: {
        width: '100%',
        ...assignTextStyle('h5')(theme).text,
        ...theme.presets.textCenter,
        ...theme.spacing.padding(2),
        backgroundColor: theme.colors.transparent,
        borderRadius: theme.borderRadius.small,
        color: theme.colors.primary3,
        fontWeight: '400',
      },
    },
    'navItem:selected': {
      color: theme.colors.primary3,

      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.primary1,
        color: theme.colors.primary3,
        fontWeight: '600',
      },
    },
    navContentWrapper: {
      gap: theme.spacing.value(4),
      ...theme.presets.center,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
        gap: theme.spacing.value(2),
        ...theme.presets.alignStart,
        ...theme.spacing.paddingVertical(1),
      },
    },
    drawerLogo: {
      ...theme.presets.absolute,
      top: theme.spacing.value(2),
    },
  })),
}
