import { PropsOf } from '@codeleap/common'
import { Button } from '@/components'
import { variantProvider } from '@/app'

export const MapButton = ({ text, onPress, ...rest }: PropsOf<typeof Button>) => {
  return <button style={styles.default} onClick={onPress} {...rest}>{text}</button>
}

const styles = variantProvider.createComponentStyle((theme) => ({
  default: {
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    ...theme.presets.row,
    ...theme.presets.relative,
    backgroundColor: theme.colors.primary3,
    borderRadius: theme.borderRadius.small,
    transitionProperty: 'background, color, border, filter',
    transitionDuration: '0.2s',
    ...theme.presets.alignCenter,
    ...theme.presets.justifyCenter,
    width: '100%',
    color: theme.colors.neutral1,

    '&:hover': {
      backgroundColor: theme.colors.primary4,
    },
    ...theme.spacing.padding(1),
    ...theme.spacing.paddingHorizontal(2),
  },
}), true)
