import { Theme } from '@/app'
import { ActionIcon } from '@/components'
import { PropsOf } from '@codeleap/common'

export type MapFocusButtonProps = Partial<PropsOf<typeof ActionIcon>> & {}

export const MapFocusButton = ({ ...rest }: MapFocusButtonProps) => {
  const handleFocus = () => {}

  return (
    <ActionIcon
      debugName='MapFocusButton'
      icon={'map-focus'}
      variants={['elevated', 'size:5', 'primary3']}
      onPress={handleFocus}
      style={{ zIndex: Theme.zIndexes.focusButton }}
      {...rest} />
  )
}
