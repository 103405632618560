import { TouchableComposition, assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type PlaceRowComposition =
  | `touchable${Capitalize<TouchableComposition>}`
  | 'icon'
  | 'contentWrapper'
  | 'title'
  | 'infoWrapper'
  | 'distance'
  | 'separator'
  | 'description'

const createPlaceRowStyle =
  variantProvider.createVariantFactory<PlaceRowComposition>()

export const PlaceRowStyles = {
  default: createPlaceRowStyle(theme => ({
    touchableWrapper: {
      ...theme.presets.fullWidth,
      ...theme.border.neutral2({
        directions: ['bottom'],
        width: theme.values.borderWidth.small / 2,
      }),
      ...theme.spacing.padding(2),
      ...theme.presets.justifyStart,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    icon: {
      color: theme.colors.neutral6,
      minWidth: theme.values.iconSize[3],
      minHeight: theme.values.iconSize[3],
    },
    rightIcon: {
      color: theme.colors.neutral6,
      minWidth: theme.values.iconSize[3],
      minHeight: theme.values.iconSize[3],
    },
    contentWrapper: {
      flex: 1,
      ...theme.spacing.marginLeft(2),
      ...theme.presets.column,
    },
    title: {
      color: theme.colors.neutral8,
    },
    infoWrapper: {
      ...theme.spacing.gap(1),
    },
    distance: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
    },
    separator: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
    },
    description: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
    },
  })),

  borderTopRadius: createPlaceRowStyle(theme => ({
    touchableWrapper: {
      borderTopLeftRadius: theme.values.borderRadius.medium,
      borderTopRightRadius: theme.values.borderRadius.medium,
      ...theme.border.neutral2({
        directions: ['bottom'],
        width: theme.values.borderWidth.small / 2,
      }),
      ...theme.spacing.padding(2),
      ...theme.presets.justifyStart,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),

  borderBottomRadius: createPlaceRowStyle(theme => ({
    touchableWrapper: {
      borderBottomLeftRadius: theme.values.borderRadius.medium,
      borderBottomRightRadius: theme.values.borderRadius.medium,
    },
  })),

  overlay: createPlaceRowStyle(theme => ({
    touchableWrapper: {
      ...theme.spacing.paddingHorizontal(0),
    },
  })),
}
