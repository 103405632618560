import { getNestedStylesByKey, ComponentVariants, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { AccordionStyles } from '@/app/stylesheets'
import { AppIcon } from '@/app'
import { View, Text, Button } from '@/components'
import { PropsWithChildren, useState } from 'react'
import { AccordionComposition } from '@/app/stylesheets/Accordion'

export type AccordionProps = PropsWithChildren & ComponentVariants<typeof AccordionStyles>
  & {
    styles?: StylesOf<AccordionComposition>
    title: string
    initiallyOpen?: boolean
  }

export const Accordion = ({
  variants = [],
  styles = {},
  responsiveVariants = {},
  title,
  initiallyOpen = false,
  children,
  ...rest
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen)

  const variantStyles = useDefaultComponentStyle<'u:Accordion', typeof AccordionStyles>('u:Accordion', {
    variants,
    rootElement: 'wrapper',
    styles,
    responsiveVariants,
  })

  const getStyles = (key: AccordionComposition) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  return (
    <View style={getStyles('wrapper')} {...rest}>
      <Button
        onPress={() => setIsOpen(!isOpen)}
        style={getStyles('header')}
        styles={{
          text: variantStyles.headerText,
          rightIcon: variantStyles.headerRightIcon,
        }}
        rightIcon={isOpen ? 'chevron-up' : 'chevron-down' as AppIcon}
        debugName='Accordion Button'
        text={title}
      />
      {isOpen && (
        <View style={getStyles('content')}>
          {children}
        </View>
      )}
    </View>
  )
}
