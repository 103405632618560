import { LocalStorage, LocalStorageKeys } from '@/app'
import { PlacesList, PlacesListProps } from './PlacesList'
import { useState } from 'react'
import { onMount } from '@codeleap/common'
import { SearchPrediction } from '@/types'
import { AutocompleteUtils } from '@/utils'

export type RecentPlacesListProps = Partial<PlacesListProps>

export const RecentPlacesList = ({ onItemPress, ...rest }: RecentPlacesListProps) => {
  const myLocation = AutocompleteUtils.userLocationToPlace()
  const [recentSearches, setRecentSearches] = useState<SearchPrediction[]>([myLocation])

  onMount(() => {
    const recentLocations = LocalStorage.getItem(LocalStorageKeys.RECENT_SEARCH_PLACES as unknown as any) as unknown as SearchPrediction[]
    if (recentLocations?.length > 0) {
      setRecentSearches([myLocation, ...recentLocations])
    }
  })

  return (
    <PlacesList
      {...rest}
      label='Recents'
      noItemTopBorder
      places={recentSearches}
      onItemPress={onItemPress}
    />
  )
}
