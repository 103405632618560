import { assignTextStyle, variantProvider } from '@/app'

export type LineChartComposition =
  | 'wrapper'
  | 'leftWrapper'
  | 'lineChart'
  | 'lineChartWrapper'
  | 'xAxisWrapper'
  | 'xAxisLine'
  | 'xAxisText'
  | 'yAxisWrapper'
  | 'yAxisLine'
  | 'yAxisTextWrapper'
  | 'yAxisText'
  | 'xAxisTextWrapper'
  | 'xAxisDashedLine'

const createLineChart =
  variantProvider.createVariantFactory<LineChartComposition>()

export const LineChartStyles = {
  default: createLineChart((theme) => {
    const innerMargin = theme.spacing.value(0.5)

    return ({
      wrapper: {
        ...theme.presets.relative,
        right: innerMargin,
      },
      leftWrapper: {
        ...theme.presets.column,
        '.rv-xy-plot': {
          zIndex: 1,
        },
      },
      lineChartWrapper: {
        margin: innerMargin,
      },
      lineChart: {
        color: theme.colors.primary3,
        strokeWidth: 3,
      },
      xAxisWrapper: {
        ...theme.presets.justifySpaceBetween,
        marginLeft: innerMargin,
      },
      xAxisTextWrapper: {
        ...theme.presets.relative,
        ...theme.presets.justifyCenter,
      },
      xAxisDashedLine: {
        ...theme.presets.absolute,
        width: 3,
        borderLeft: `3px dotted ${theme.colors.neutral3}`,
      },
      xAxisLine: {
        ...theme.presets.absolute,
        height: 2,
        backgroundColor: theme.colors.neutral3,
      },
      xAxisText: {
        ...assignTextStyle('p3')(theme).text,
        ...theme.presets.medium,
        color: theme.colors.neutral7,
        marginTop: theme.spacing.value(1),
      },
      yAxisWrapper: {
        ...theme.presets.column,
        ...theme.spacing.marginLeft(1.5),
        ...theme.presets.fullHeight,
        width: 51,
      },
      yAxisLine: {
        ...theme.spacing.marginVertical(1),
        width: 3,
        backgroundColor: theme.colors.neutral3,
        ...theme.presets.fullHeight,
      },
      yAxisTextWrapper: {
        ...theme.presets.column,
        ...theme.presets.alignCenter,
      },
      yAxisText: {
        ...assignTextStyle('p3')(theme).text,
        ...theme.presets.medium,
        color: theme.colors.neutral7,
      },
    })
  }),
}
