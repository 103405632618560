import { variantProvider } from '@/app'
import { View, Text } from '@/components'
import { PaceCalculatorSplitTime } from '@/types'
import { formatToDecimal } from '@/utils'
import { PropsOf } from '@codeleap/common'

export type PaceCalculatorTableProps = PropsOf<typeof View> & {
  splitTimes: PaceCalculatorSplitTime[]
  total?: string
}

type TableRowProps = {
  text1: string
  text2: string
  header?: boolean
  footer?: boolean
}

const defaultSplitTimes: PaceCalculatorSplitTime[] = [
  { distance: 1, time: '-' },
  { distance: 2, time: '-' },
  { distance: 3, time: '-' },
  { distance: 4, time: '-' },
  { distance: 5, time: '-' },
]

const TableRow = ({ text1, text2, header, footer }: TableRowProps) => {
  const textVariant = header ? 'h5' : 'p1'
  const textComponent = header ? 'h5' : 'p'

  return (
    <View css={[styles.rowWrapper, header && styles.rowHeader, footer && styles.rowFooter]}>
      <Text
        text={text1}
        component={textComponent}
        variants={[textVariant, 'neutral-8', 'flex']}
        css={[styles.rowText1]}
      />
      <Text
        text={text2}
        component={textComponent}
        variants={[textVariant, 'neutral-8', 'flex']}
        css={[styles.rowText2, footer && styles.rowBgPrimary]}
      />
    </View>
  )
}

export const PaceCalculatorTable = ({ splitTimes = [], total = '-', ...rest }: PaceCalculatorTableProps) => {
  const _splitTimes = !!splitTimes.length ? splitTimes : defaultSplitTimes

  return (
    <View {...rest} css={[styles.wrapper]} >
      <TableRow header text1='Distance' text2='Time' />

      {_splitTimes.map(data => {
        return (<TableRow key={`${data.distance}:${data.time}`} text1={formatToDecimal(data.distance)} text2={data.time} />)
      })}

      <TableRow footer text1='Total' text2={total} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.column,
    border: `1px solid ${theme.colors.neutral3}`,
    borderRadius: theme.borderRadius.medium,
  },
  rowWrapper: {
    borderBottom: `1px solid ${theme.colors.neutral3}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rowText1: {
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.paddingVertical(1.5),
  },
  rowText2: {
    ...theme.spacing.paddingHorizontal(2),
    borderLeft: `1px solid ${theme.colors.neutral3}`,
    ...theme.spacing.paddingVertical(1.5),
  },
  rowBgPrimary: {
    backgroundColor: theme.colors.primary1,
  },
  rowHeader: {
    borderTopLeftRadius: theme.borderRadius.medium,
    borderTopRightRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.primary1,
  },
  rowFooter: {
    borderBottomLeftRadius: theme.borderRadius.medium,
    borderBottomRightRadius: theme.borderRadius.medium,
  },
}), true)
