import { React, Settings, variantProvider } from '@/app'
import { useAppSelector } from '@/redux'
import { useDebug } from '@/utils'
import { ActionIcon, Button, Modal, toggleModal, View } from '@codeleap/web'
import { LanguageSelector } from '../LanguageSelector'
import { OnboardingStore } from '../Onboarding'

export const DebugButtons = () => {
  const debug = useDebug()

  return <View variants={['gap:2', 'column']}>
    <Button
      debugName={'Submit logs'}
      text={'Submit logs'}
      onPress={debug.submitLogs}
    />

    <Button
      debugName={'Test Sentry crash'}
      text={'Test Sentry crash'}
      onPress={debug.testSentryCrash}
    />

    <Button
      debugName={'Test undefined.fuuuuu()'}
      text={'Test undefined.fuuuuu()'}
      onPress={debug.testUndefinedFunctionCall}
    />

    <Button
      debugName={'Test try catch'}
      text={'Test try catch'}
      onPress={debug.testTryCatch}
    />

    <Button
      debugName={'Test throwing an error'}
      text={'Test throwing an error'}
      onPress={debug.testThrowError}
    />

    <Button
      debugName={'Test logger.warn'}
      text={'Test logger.warn'}
      onPress={debug.testLoggerWarn}
    />

    <Button
      debugName={'Test logger.error'}
      text={'Test logger.error'}
      onPress={debug.testLoggerError}
    />

    <Button
      debugName={'Test logging circular dependency'}
      text={'Test logging circular dependency'}
      onPress={debug.testLoggingCircularDeps}
    />

    <Button
      debugName={'Test onboarding'}
      text={'Test onboarding'}
      onPress={() => {
        toggleModal('debug')
        OnboardingStore.getState().show()
      }}
    />

    <LanguageSelector />
  </View>
}

export const DebugModal: React.FC = () => {
  const isDev = useAppSelector(store => store.AppStatus.isDev)

  if (!isDev && !Settings.Environment.IsDev) return null

  return <>
    <ActionIcon
      icon='bug'
      onPress={() => toggleModal('debug')}
      debugName={'Debug modal button'}
      variants={['bg:primary3']}
      iconProps={{ variants: ['medium'] }}
      css={styles.bugButton}
    />

    <Modal modalId='debug' debugName={'Debug modal'} title='Debug' showClose variants={['centered']}>
      <DebugButtons />
    </Modal>
  </>
}

const styles = variantProvider.createComponentStyle((theme) => ({
  bugButton: {
    ...theme.presets.fixed,
    right: theme.spacing.value(2),
    bottom: theme.spacing.value(2),
    zIndex: 9,
  },
  wrapper: {
    ...theme.presets.center,
    backgroundColor: theme.colors.primary4,
    ...theme.spacing.padding(1),
    borderRadius: theme.borderRadius.small,
  },
}), true)
