import { deepMerge, PropsOf } from '@codeleap/common'
import { View } from '@/components'
import { variantProvider } from '@/app'
import { AppViewStyles } from '@/app/stylesheets'

export type MapViewProps = {
  variants?: (keyof typeof AppViewStyles)[]
} & PropsOf<typeof View>

export const MapView = ({ children, variants = ['default'], style, ...rest }: MapViewProps) => {
  const { styles } = useStyles(variants)
  { /* @ts-ignore */ }
  return <div {...rest} style={deepMerge(styles?.wrapper, style)} >{children}</div>
}

const useStyles = (variants: MapViewProps['variants']) => {

  const styles = variantProvider.createComponentStyle((theme) => {
    let variantStyles = {}

    variants.forEach((variant) => {
      variantStyles = deepMerge(variantStyles, AppViewStyles[variant](theme).wrapper)
    })

    return {
      wrapper: variantStyles,
    }
  }, true)

  return { styles }
}

