import {
  Page,
  Text,
  CenterWrapper,
  Section,
  View,
  CourseGrid,
  ImageSection,
  AllCoursesFilters,
  CourseFiltersProps,
  SearchInput,
} from '@/components'
import { variantProvider } from '@/app'
import { useFlatlistProps, useIsMobile, PlaceUtils, Navigation, SpatialUtils, CourseUtils } from '@/utils'
import { APIClient } from '@/services'
import { Place } from '@/types'
import { useBreakpointMatch, useSearchParams } from '@codeleap/web'
import { PlaceMap } from './PlaceMap'
import { useState } from '@codeleap/common'

type CoursesSectionsProps = {
  isMobile: boolean
  isLoading: boolean
  courses: ReturnType<typeof APIClient.Courses.useCourses>['courses']
  onPressViewAll?: () => void
  listProps: ReturnType<typeof useFlatlistProps>
}

const CoursesSections = (props: CoursesSectionsProps) => {

  const { courses, onPressViewAll, listProps } = props

  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 3,
    desktopHuge: 4,
  })

  return (
    <Section
      title={'Trails'}
      variants={['column', 'marginTop:2', 'marginBottom:4', 'title:h2'] as any}
      centerContent={false}
      isLoading={courses.list.query.isLoading}
      showButton
      buttonProps={{
        debugName: 'Trail section button',
        variants: ['minimal', 'small', 'padding:0'],
        onPress: onPressViewAll,
        text: 'View all',
      }}

    >
      <CourseGrid
        debugName='Trail section grid'
        courses={courses?.items}
        numColumns={numColumns}
        {...listProps}
      />
    </Section>
  )
}

export type PlacePageProps = {
  place?: Place
  slug?: Place['slug']
}

export const PlacePage = ({ place: placeProps, slug }: PlacePageProps) => {
  const { data } = APIClient.Places.placesManager.useRetrieve({ id: slug as any, queryOptions: { enabled: !!slug } })
  const place = placeProps || data
  const [params, setParams, reset] = useSearchParams()
  const [searchInput, setSearchInput] = useState(params?.title)

  const location = params?.criteria === 'nearest' ? {
    lat: place?.coords?.lat,
    lng: place?.coords?.lng,
  } : {
    lat: null,
    lng: null,
  }

  const { courses } = APIClient.Courses.useCourses({
    enabled: !!place,
    limit: 12,
    place_slug: place?.slug,
    lat: location?.lat,
    lng: location?.lng,
    ...CourseUtils.getFiltersByParams(params),
  })

  const coverTitle = PlaceUtils.getTitle(place)

  const isMobile = useIsMobile()

  const listProps = useFlatlistProps(courses, {
    noMoreItemsText: '',
  })

  const coverImage = PlaceUtils.getCoverImage(place)

  const courseFiltersProps: CourseFiltersProps = {
    params: params,
    setParams: setParams,
    results: listProps?.data?.length,
    isLoading: courses?.list?.query?.isLoading || courses?.list?.query?.isFetching || courses?.list?.query?.isRefetching,
    variants: ['noRightContent'],
  }

  async function onSearchChange(searchValue: string) {
    setParams({
      ...params,
      title: searchValue,
    })
  }

  return (
    <Page
      loading={!place}
      centerContent={false}
      SEOProps={PlaceUtils.getSEOProps(place)}
    >
      <ImageSection
        image={coverImage}
        imageProps={{
          alt: PlaceUtils.getImageAlt(place),
        }}
        title={coverTitle}
        showBreadcrumbs
        breadcrumbsProps={{
          onNavigate: reset,
        }}
        variants={['marginTop:auto', 'marginBottom:3'] as any}
      />

      <CenterWrapper innerProps={{ variants: ['column'] }}>

        <View
          css={styles.sectionWrapper}
          variants={['gap:4', 'marginBottom:6']}
          responsiveVariants={{
            tabletSmall: ['marginBottom:4'],
            mobile: ['marginBottom:4'],
          }}
        >
          {!!place?.description && (
            <View variants={['column', 'gap:2', 'flex']} responsiveVariants={{
              mobile: ['fullWidth'],
            }}>
              {!isMobile ? <Text text={'Description'} /> : null}
              <Text
                text={`${place?.description}`}
              />
            </View>
          )}

          <PlaceMap
            place={place}
            courses={courses?.items}
          />
        </View>

        <AllCoursesFilters
          showLoader={false}
          variants={['marginBottom:3']}
          courseFiltersProps={courseFiltersProps}
          preservedParams={['title']}
          SearchBar={
            <SearchInput
              debugName='search input: PlacePage'
              onSearchChange={onSearchChange}
              placeholder='Search for a run'
              debounce={800}
              value={searchInput}
              onValueChange={setSearchInput}
              variants={['noError', 'border:smallish', 'searchPlaces']}
            />
          }
        />

        <CoursesSections
          courses={courses}
          isMobile={isMobile}
          isLoading={courses.list.query.isLoading}
          onPressViewAll={() => Navigation.Course.navigateToExplore({
            lat: place?.coords?.lat,
            lng: place?.coords?.lng,
            radius: SpatialUtils.boundingBoxToRadius(place?.boundingbox),
          })}
          listProps={listProps}
        />

      </CenterWrapper>
    </Page>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    imageSectionWrapper: {
      minHeight: 400,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    bottomRadius: {
      [theme.media.up('mobile')]: {
        borderBottomRightRadius: theme.borderRadius.medium,
        borderBottomLeftRadius: theme.borderRadius.medium,
      },
    },
    imageContent: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      ...theme.spacing.paddingHorizontal(2),
      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)`,
      zIndex: 1,
    },
    image: {
      objectFit: 'cover',
      ...theme.presets.absolute,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      zIndex: 0,
    },
    sectionWrapper: {
      [theme.media.down('mobile')]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
      },
    },
  }),
  true,
)

