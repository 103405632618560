import { React, variantProvider } from '@/app'
import { Icon, Modal, View, Text, Button } from '@/components'
import { PropsOf } from '@codeleap/common'

type MapInformationModalProps = Pick<PropsOf<typeof Modal>, 'visible' | 'toggle'> & {}

const Row = ({ text, icon }) => {
  return (
    <View variants={['gap:1', 'flex', 'fullWidth']}>
      <View variants={['flex', 'alignCenter', 'justifyCenter']} style={styles.iconWrapper}>
        <Icon debugName='Map information icon' name={`${icon}`} variants={['alignCenter', 'justifyCenter']} style={styles.icon} />
      </View>
      <Text variants={['neutral-8']} text={`${text}`} />
    </View>
  )
}

export const MapInformationModal = (props: MapInformationModalProps) => {
  const { toggle } = props

  return (
    <Modal
      title={'Map Information'}
      debugName={'Map Information Modal'}
      showClose={false}
      closable={false}
      {...props}
      variants={['centered']}
      visible={props?.visible}
      styles={{
        body: styles.body,
      }}
    >
      <Row text={'These are START ANYWHERE runs which can be started anywhere along the run line.'} icon={'pin-2'} />
      <Row text={'These runs have a fixed start location.'} icon={'pin'} />
      <View variants={['row', 'flex', 'gap:1', 'paddingLeft:4']} >

        <View variants={['flex', 'gap:1']}>
          <Text variants={['inlineBlock', 'neutral-8']}>
            <Text text={'Search Area:'} variants={['h5', 'neutral-8', 'inlineBlock']} />
            {" Run locations are shown only in the search area circle. Scroll the map then press the 'Search this Area' button for new searches."}
          </Text>
        </View>
      </View>
      <Button
        debugName='Dismiss Map Information Button'
        onPress={toggle}
        text={'Dismiss'}
      />
    </Modal>
  )
}

const ICON_SIZE = 24

const styles = variantProvider.createComponentStyle((theme) => ({
  body: {
    ...theme.spacing.gap(2),
  },
  iconWrapper: {
    flex: 0,
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
}), true)
