import { createDefaultVariantFactory } from '@codeleap/common'

export type NavigationMarkerComposition = 'wrapper' | 'icon' | 'circle' | 'gradient' | 'accuracyCircle' | 'iconWrapper'

const createNavigationMarker =
  createDefaultVariantFactory<NavigationMarkerComposition>()

export const NavigationMarkerStyle = {
  default: createNavigationMarker((theme) => {
    const size = 20
    const backgroundColor = theme.colors.primary3

    return {
      wrapper: {
        ...theme.presets.center,
        ...theme.presets.elevated,
        borderRadius: theme.borderRadius.rounded,

        /** As default we use Google Marker which is a Pin. This part of code is to center our marker */
        ...theme.presets.relative,
      },
      gradient: {
        position: 'absolute',
        bottom: '50%',
        left: '-100%',
        transform: 'rotate(35deg)',
      },
      accuracyCircle: {
        height: size * 2,
        width: size * 2,
        borderRadius: theme.borderRadius.rounded,
        backgroundColor: `${backgroundColor}33`,
        ...theme.presets.elevated,
        ...theme.presets.absolute,
        top: '-50%',
        right: '-50%',
        animation: `scaleInfinite 2s infinite;`,
      },
      circle: {
        height: size,
        width: size,
        backgroundColor: backgroundColor,
        borderRadius: theme.borderRadius.rounded,
        ...theme.presets.elevated,
        zIndex: 1,
      },
      iconWrapper: {
        ...theme.presets.relative,
        top: theme.spacing.value(0.5),
        left: theme.spacing.value(0.5),
      },
      icon: {
        size: theme.values.iconSize[1],
      },
    }
  }),
}
