import { Theme, variantProvider } from '@/app'
import { View, Text, Button, BottomDrawerContentProps, ActivityIndicator } from '@/components'
import { APIClient } from '@/services'
import { CourseCriteria, CourseOrder } from '@/types'
import { CourseUtils } from '@/utils'
import { CourseFilterOption } from '@/utils/courses/filters'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { ButtonProps, Slider } from '@codeleap/web'
import { useState } from 'react'

type Criteria = {
  key: CourseCriteria
  label: string
}

type Order = Partial<ButtonProps> & {
  key: CourseOrder
}

export const AllCoursesMobileFilters = (props: BottomDrawerContentProps) => {
  const { params, setParams, onApply, onClear, isOpen, preservedParams = [] } = props

  const filterOptions = CourseUtils.getAllRunsFilterOptions(params)

  const { tags: data, listProps } = APIClient.Tags.useTags({})

  const tags = data?.items

  const isLoading = listProps.isLoading

  const orderButtons: Order[] = [
    {
      key: 'ascending',
      text: 'Sort Ascending',
      icon: 'arrow-up-2' as any,
    },
    {
      key: 'descending',
      text: 'Sort Descending',
      icon: 'arrow-down-2' as any,
    },
  ]

  const criterias: Criteria[] = [
    {
      key: 'paved',
      label: 'Paved',
    },
    {
      key: 'hilly',
      label: 'Hilly',
    },
    {
      key: 'ascent',
      label: 'Ascent',
    },
    {
      key: 'distance',
      label: 'Course Distance',
    },
    {
      key: 'nearest',
      label: 'Nearest',
    },
  ]

  const [sliderValues, setSliderValues] = useState(() => filterOptions.reduce((acc, option) => {
    const min = params?.[`min_${option.key}`]
    const max = params?.[`max_${option.key}`]
    acc[option.key] = [min || null, max || null]
    return acc
  }, {}))

  const [initialSliderValues] = useState(sliderValues)

  const initialTags = params?.tags ? new Set(params?.tags?.split(',')) : new Set()
  const initialTopTags = params?.voice_directions === 'true' ? new Set(['Voice Directions']) : new Set()

  const initialCriteria = params.criteria || null

  const [selectedTags, setSelectedTags] = useState(initialTags)
  const [selectedTopTags, setSelectedTopTags] = useState(initialTopTags)
  const [selectedCriteria, setSelectedCriteria] = useState(initialCriteria)

  const initialOrder = params.order || null
  const [selectedOrder, setSelectedOrder] = useState(initialOrder)

  const [hasChanges, setHasChanges] = useState(false)

  const TopTags = [
    { label: 'Voice Directions', debugName: 'FilterTags:VoiceDirections', icon: 'sound' },
  ]

  const BottomTags = tags?.map(tag => ({
    id: tag.id,
    label: tag.title,
    debugName: `FilterTags:${tag.title}`,
  }))

  const handleClear = () => {
    const resetSliderValues = filterOptions.reduce((acc, option) => {
      acc[option.key] = [null, null]
      return acc
    }, {})

    setSliderValues(resetSliderValues)
    setSelectedTags(new Set())
    setSelectedTopTags(new Set())
    setSelectedCriteria(null)
    setSelectedOrder(null)
    setParams(state => {
      if (preservedParams.length === 0) {
        return {}
      } else {
        const preservedKeys = preservedParams.reduce((acc, key) => {
          if (state?.hasOwnProperty(key)) {
            acc[key] = state[key]
          }
          return acc
        }, {})
        return preservedKeys
      }
    })
    setHasChanges(false)
    if (onClear) onClear()
  }

  const handleToggleTag = (tagLabel: string) => {
    setHasChanges(true)
    setSelectedTags(prev => {
      const newSet = new Set(prev)
      if (newSet.has(tagLabel)) {
        newSet.delete(tagLabel)
      } else {
        newSet.add(tagLabel)
      }
      return newSet
    })
  }

  const handleSliderChange = (key: string, value: [number | null, number | null]) => {
    setHasChanges(true)
    setSliderValues(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const applyFilters = () => {
    const additionalParams = {
      tags: Array.from(selectedTags).join(','),
      voice_directions: selectedTopTags.has('Voice Directions') ? 'true' : 'false',
      criteria: selectedCriteria,
      order: selectedOrder,
    }
    preservedParams.forEach((key) => {
      if (params[key] !== undefined) {
        additionalParams[key] = params[key]
      }
    })
    CourseUtils.applyMobileFilters({
      sliderValues,
      setHasChanges,
      setParams,
      onApply,
      additionalParams,
    })
  }

  const BottomCriteriasComponent = () => {

    return (
      <View variants={['row', 'gap:1', 'wrap']}>
        {criterias?.map((criteria) => {
          const isSelected = selectedCriteria === criteria.key
          return (
            <Button
              key={criteria.key}
              onPress={() => handleToggleTag(criteria.key)}
              text={criteria.label}
              variants={['fitlerButton', isSelected && 'primary6']}
              debugName={`FilterCriterias:${criteria.key}`}
            />
          )
        })}
      </View>
    )
  }

  const BottomOrderComponent = () => {
    return (
      <View variants={['column', 'gap:1']}>
        {orderButtons.map(orderButton => {
          const isSelected = selectedOrder === orderButton.key
          return (
            <Button
              debugName={`button-${orderButton.key}`}
              text={orderButton.text}
              onPress={() => handleToggleOrder(orderButton.key)}
              key={orderButton.key}
              variants={['flex', 'large', 'minimal', 'sortFilter', isSelected && 'sortFilter:selected']}
              icon={orderButton.icon}
              style={styles.orderButton}
            />
          )
        })}
      </View>
    )
  }

  onUpdate(() => {
    if (!isOpen && hasChanges) {
      setSliderValues(initialSliderValues)
      setSelectedTags(initialTags)
      setSelectedTopTags(initialTopTags)
      setSelectedCriteria(initialCriteria)
      setSelectedOrder(initialOrder)
      setHasChanges(false)
    }
  }, [isOpen, hasChanges, initialSliderValues, initialCriteria])

  const TagsHeader = () => {
    const tagCount = Array.from(selectedTags).length

    if (tags?.length === 0 || !tags || isLoading) return null

    return (
      <View variants={['justifySpaceBetween', 'marginBottom:2']}>
        <Text text={'Tags'} />
        <View variants={['border-radius:rounded', 'bg:primary3', 'center']} style={{ width: 16, height: 16 }}>
          <Text text={String(tagCount)} variants={['p4', 'neutral-1']} />
        </View>
      </View>
    )
  }

  const handleToggleTopTag = (tagLabel: string) => {
    setHasChanges(true)
    setSelectedTopTags(prev => {
      const newSet = new Set(prev)
      if (newSet.has(tagLabel)) {
        newSet.delete(tagLabel)
      } else {
        newSet.add(tagLabel)
      }
      return newSet
    })
  }

  const BottomTagsComponent = () => {
    if (!BottomTags && !isLoading) return null

    if (isLoading) {
      return (
        <View variants={['flex', 'alignCenter', 'justifyCenter']}>
          <ActivityIndicator debugName={'Filter bottomTags Loading'} variants={['center']} />
        </View>
      )
    }

    return (
      <View variants={['row', 'gap:1', 'wrap']}>
        {BottomTags?.map((tag) => (
          <Button
            key={tag.id}
            onPress={() => handleToggleTag(tag.label)}
            rightIcon={selectedTags.has(tag.label) ? 'check' : null}
            styles={{ rightIcon: { color: selectedTags.has(tag.label) ? Theme.colors.light.primary3 : Theme.colors.light.neutral9 }}}
            text={tag.label}
            variants={['fitlerButton', selectedTags.has(tag.label) ? 'primary6' : '']}
            debugName={tag.debugName}
          />
        ))}
      </View>
    )
  }

  return (
    <View variants={['column', 'flex', 'marginBottom:12']}>

      <View variants={['gap:1', 'marginBottom:2']}>
        {TopTags.map((tag) => (
          <Button
            key={tag.label}
            variants={['fitlerButton', selectedTopTags.has(tag.label) ? 'primary6' : '']}
            text={tag.label}
            rightIcon={selectedTopTags.has(tag.label) ? 'check' : null}
            debugName={tag.debugName}
            styles={{ rightIcon: { color: selectedTopTags.has(tag.label) ? Theme.colors.light.primary3 : Theme.colors.light.neutral9 }}}
            onPress={() => handleToggleTopTag(tag.label)}
          />
        ))}
      </View>

      {filterOptions.map((_filter) => {

        const filter = _filter as CourseFilterOption
        const min = filter?.min
        const max = filter?.max
        const defaultValue = filter?.defaultValue
        const suffix = filter?.suffix

        const _value = sliderValues[filter.key] || defaultValue

        const minValue = TypeGuards.isNil(_value[0]) ? min : _value[0]
        const maxValue = TypeGuards.isNil(_value[1]) ? max : _value[1]

        const minTrack = `${minValue}${suffix ? suffix : ''}`
        const maxTrack = `${maxValue}${suffix ? suffix : ''}`

        return (
          <Slider
            {...filter}
            debugName={`filter-${filter.key}`}
            key={filter.key}
            minStepsBetweenThumbs={0}
            value={[minValue, maxValue]}
            onValueChange={(value) => handleSliderChange(filter.key, value)}
            trackMarks={{
              [filter.min]: minTrack,
              [filter.max]: maxTrack,
            }}
          />
        )
      })}
      <View variants={['column', 'gap:2']}>
        <View variants={['column', 'marginBottom:2']}>
          <TagsHeader />
          <BottomTagsComponent />
        </View>
        <Text text={'Sort by'} />
        <BottomCriteriasComponent />
        <View variants={['fullWidth']} style={styles.separator} />
        <BottomOrderComponent />
      </View>
      <View variants={['gap:1', 'fixed', 'padding:2', 'fullWidth', 'center', 'bg:neutral1']} style={styles.buttonsWrapper}>
        <Button
          variants={['flex', 'primary6', 'border-radius-smallish']}
          debugName={'Clear Filters Explore'}
          onPress={() => handleClear()}
          text={'Clear'}
        />
        <Button
          variants={['flex', 'border-radius-smallish']}
          debugName={'Apply Filters Explore'}
          onPress={applyFilters}
          text={'Apply'}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  buttonsWrapper: {
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndexes.activityIndicator + 1,
  },
  separator: {
    height: 1,
    backgroundColor: theme.colors.neutral3,
  },
  orderButton: {
    minHeight: theme.values.itemHeight.default,
  },
}), true)
