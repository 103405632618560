import { ExploreMapRedux, useAppSelector } from '@/redux'
import { UseDrawCourseClustersProps, Course, UseDrawCoursesProps } from '@/types'
import { CourseUtils } from '@/utils'
import { onUpdate } from '@codeleap/common'

export type ExploreMapDrawHooksProps = {
  courses: Course[]
  useDrawCourseClustersProps: Omit<UseDrawCourseClustersProps, 'courseClusters' | 'selectedCluster'>
  useDrawCoursesProps: Omit<UseDrawCoursesProps, 'courses'>
}

export const ExploreMapDrawHooks = ({ courses, useDrawCourseClustersProps, useDrawCoursesProps }: ExploreMapDrawHooksProps) => {
  const courseClusters = useAppSelector((state) => state.ExploreMapRedux.courseClusters)
  const selectedCluster = useAppSelector((state) => state.ExploreMapRedux.selectedCluster)

  onUpdate(() => {
    const clusters = CourseUtils.Maps.clusterCourses(courses)
    ExploreMapRedux.setCourseClusters(clusters)
  }, [courses])

  CourseUtils.Maps.useDrawCourseClursters({
    courseClusters,
    selectedCluster,
    ...useDrawCourseClustersProps,
  })

  CourseUtils.Maps.useDrawCourses({
    courses,
    ...useDrawCoursesProps,
  })

  return null
}
