import { assignTextStyle, ButtonComposition, TooltipComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

export type OptionsDropDownComposition = 'outerWrapper'
 | 'wrapper'
 | `tooltip${Capitalize<TooltipComposition> | ''}`
 | 'tooltipInnerWrapper'
 | 'icon'
 | 'text'
 | 'option'
 | 'textIconWrapper'
 | `option${Capitalize<ButtonComposition>}`
 | 'optionHeader'

const createOptionsDropDownStyle = variantProvider.createVariantFactory<OptionsDropDownComposition>()

export const OptionsDropDownStyles = {
  default: createOptionsDropDownStyle((theme) => ({
    outerWrapper: {
      position: 'relative',
      width: '100vw',
    },

    wrapper: {
      backgroundColor: 'transparent',
      width: '100vw',
    },
    'wrapper:focused': {
      backgroundColor: theme.colors.neutral2,
    },
    icon: {
      color: theme.colors.neutral10,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    optionHeader: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
      marginBottom: theme.spacing.value(2),
    },
    optionText: {
      textDecoration: 'none',
      color: theme.colors.neutral9,
      '&:hover': {
        color: theme.colors.primary3,
        textDecoration: 'none',
      },
    },
    text: {
      ...assignTextStyle('p1')(theme).text,
      fontWeight: 600,
      color: theme.colors.primary5,
    },

    // tooltip
    tooltipInnerWrapper: {
      cursor: 'pointer',
      width: '100vw',
      ...theme.presets.alignEnd,
    },
    tooltipWrapper: {
      ...theme.spacing.padding(0),
      ...theme.presets.column,
      backgroundColor: theme.colors.neutral1,
      width: '100vw',
      // ...theme.effects.light,
    },
    tooltipArrow: {
      display: 'none',
      width: 0,
      height: 0,
    },

    option: {
      // ...theme.presets.fullWidth,
      // width: '100vw',
      cursor: 'pointer',
      ...theme.presets.noWrap,
      borderRadius: theme.spacing.value(0),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.justifyStart,
      height: theme.values.itemHeight.default,
      ...theme.border.neutral3({ directions: ['bottom'], width: theme.values.borderWidth.small }),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },

      '&:first-of-type': {
        borderTopRightRadius: theme.borderRadius.small,
        borderTopLeftRadius: theme.borderRadius.small,
      },

      '&:last-of-type': {
        borderBottomRightRadius: theme.borderRadius.small,
        borderBottomLeftRadius: theme.borderRadius.small,
      },
    },

    optionBadgeText: {
      color: 'black',
    },

  })),

  'optionLines:none': createOptionsDropDownStyle((theme) => ({
    option: {
      ...theme.border.neutral3({ directions: ['bottom'], width: 0 }),
    },
  })),
  'optionText:primary5': createOptionsDropDownStyle((theme) => ({
    optionText: {
      color: theme.colors.primary5,
    },
  })),
  'optionNormalize': createOptionsDropDownStyle((theme) => ({
    option: {
      padding: theme.spacing.value(0),
      height: 'unset',
    },
  })),
  'optionTextHover:underline': createOptionsDropDownStyle((theme) => ({
    optionText: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })),
  'optionTextHover:primary3': createOptionsDropDownStyle((theme) => ({
    optionText: {
      '&:hover': {
        color: theme.colors.primary3,
      },
    },
  })),
  'textIcon:reverse': createOptionsDropDownStyle((theme) => ({
    textIconWrapper: {
      flexDirection: 'row-reverse',
      cursor: 'pointer',
    },
  })),
  'text:neutral9': createOptionsDropDownStyle((theme) => ({
    text: {
      color: theme.colors.neutral9,
    },
  })),
  'textIcon:spacing1': createOptionsDropDownStyle((theme) => ({
    textIconWrapper: {
      gap: theme.spacing.value(1),
    },
  })),
  'textIconPadding:1': createOptionsDropDownStyle((theme) => ({
    textIconWrapper: {
      padding: theme.spacing.value(1),
    },
  })),
  'textIconBgHover:primary1': createOptionsDropDownStyle((theme) => ({
    textIconWrapper: {
      '&:hover': {
        backgroundColor: theme.colors.primary1,
      },
    },
  })),
  'textIconRadius:smallish': createOptionsDropDownStyle((theme) => ({
    textIconWrapper: {
      borderRadius: theme.borderRadius.smallish,
    },
  })),
}
