import { variantProvider } from '@/app'
import { View, Button } from '@/components'
import { CourseCriteria, CourseOrder } from '@/types'
import { ButtonProps } from '@codeleap/web'

type CoursesSortOptionsProps = {
  internalFilters: {
    order: string | null
    criteria: string | null
  }
  setInternalFilters: React.Dispatch<React.SetStateAction<{
    order: string;
    criteria: string;
  }>>
}

type SortButtonProps = Partial<ButtonProps> & {
  isSelected?: boolean,
}

export const CoursesSortOptions = (props: CoursesSortOptionsProps) => {
  const { internalFilters, setInternalFilters } = props

  function onOrderPress(key: CourseOrder) {
    setInternalFilters(state => {
      return { ...state, order: key }
    })
  }

  function onCriteriaPress(key: CourseCriteria) {
    setInternalFilters(state => {
      return { ...state, criteria: key }
    })
  }

  const orderButtons: SortButtonProps[] = [
    {
      key: 'ascending',
      text: 'Sort Ascending',
      onPress: () => onOrderPress('ascending'),
      icon: 'arrow-up-2' as any,
    },
    {
      key: 'descending',
      text: 'Sort Descending',
      onPress: () => onOrderPress('descending'),
      icon: 'arrow-down-2' as any,
    },
  ]

  const criteriaButtons: SortButtonProps[] = [
    {
      key: 'paved',
      text: 'Paved',
      onPress: () => onCriteriaPress('paved'),
    },
    {
      key: 'hilly',
      text: 'Hilly',
      onPress: () => onCriteriaPress('hilly'),
    },
    {
      key: 'ascent',
      text: 'Ascent',
      onPress: () => onCriteriaPress('ascent'),
    },
    {
      key: 'distance',
      text: 'Course Distance',
      onPress: () => onCriteriaPress('distance'),
    },
    {
      key: 'nearest',
      text: 'Nearest',
      onPress: () => onCriteriaPress('nearest'),
    },
  ]

  return (
    <View variants={['column', 'gap:2']}>
      <View variants={['column', 'gap:1', 'fullWidth']}>
        {orderButtons.map(orderButton => {
          const isSelected = internalFilters.order === orderButton.key
          return (
            <Button
              debugName={`button-${orderButton.key}`}
              text={orderButton.text}
              onPress={orderButton.onPress}
              key={orderButton.key}
              variants={['flex', 'small', 'pill', 'minimal', 'sortFilter', isSelected && 'sortFilter:selected']}
              icon={orderButton.icon}
            />
          )
        })}
      </View>

      <View variants={['fullWidth']} style={styles.separator} />

      <View variants={['column', 'gap:1', 'fullWidth']}>
        {criteriaButtons.map(criteriaButton => {
          const isSelected = internalFilters.criteria === criteriaButton.key
          return (
            <Button
              debugName={`button-${criteriaButton.key}`}
              text={criteriaButton.text}
              onPress={criteriaButton.onPress}
              key={criteriaButton.key}
              variants={['flex', 'small', 'pill', 'minimal', 'sortFilter', isSelected && 'primary1']}
              rightIcon={criteriaButton.isSelected ? 'check' : null}
            />
          )
        })}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  separator: {
    height: 1,
    backgroundColor: theme.colors.neutral3,
  },
}), true)
