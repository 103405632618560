import { MapDefaults, variantProvider } from '@/app'
import { MapView, MapText, MapViewProps } from '@/components'
import { Course } from '@/types'
import { CourseUtils } from '@/utils'
import { deepMerge } from '@codeleap/common'

export type ExploreCourseCardProps = MapViewProps & {
  course: Course
  onPress?: () => void
}

export const ExploreCourseCard = ({ id = 'ExploreCourseCard', course, style, onPress, ...rest }: ExploreCourseCardProps) => {
  const coverImage = CourseUtils.getCoverImage(course)
  const distance = CourseUtils.getPreviewDistance({ course })

  return (
    <MapView id={id} style={deepMerge(styles.wrapper, style)} onClick={onPress} {...rest}>
      {/* @ts-ignore */}
      <img alt={CourseUtils.getImageAlt(course)} src={coverImage} style={styles.image} id={`${id}:Image`} />

      <MapView style={styles.rightWrapper} id={`${id}:RightWrapper`}>
        <MapText
          className='truncate-1'
          text={course?.title}
          variants={['h4', 'neutral-9']}
          style={styles.title}
          id={`${id}:Title`}
        />

        <MapText
          className='truncate-1'
          text={`${distance} km`}
          variants={['p4', 'neutral-9']}
          style={styles.distance}
          id={`${id}:Distance`}
        />

        <MapText
          className='truncate-1'
          text={course?.description}
          variants={['p3', 'neutral-8']}
          style={styles.description}
          id={`${id}:Description`}
        />
      </MapView>
    </MapView>
  )
}

export const EXPLORE_CARD_HEIGHT = 105
const IMAGE_SIZE = EXPLORE_CARD_HEIGHT

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    display: 'flex',
    zIndex: MapDefaults.zIndex.detailCard,
    /* @ts-ignore */
    borderRadius: theme.borderRadius.smallish,
    backgroundColor: theme.colors.background,
    minWidth: 200,
    maxWidth: 435,
    maxHeight: EXPLORE_CARD_HEIGHT,
    ...theme.presets.elevated,

    [theme.media.down('mobile')]: {
      ...theme.presets.fullWidth,
      maxWidth: 'unset',
    },
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...theme.spacing.padding(2),
  },
  image: {
    minHeight: IMAGE_SIZE,
    minWidth: IMAGE_SIZE,
    maxWidth: IMAGE_SIZE,
    maxHeight: IMAGE_SIZE,
    /* @ts-ignore */
    borderTopLeftRadius: theme.borderRadius.smallish,
    /* @ts-ignore */
    borderBottomLeftRadius: theme.borderRadius.smallish,
    objectFit: 'cover',
  },
  title: {
    lineHeight: '19.2px',
    wordBreak: 'break-all',
    ...theme.spacing.marginBottom(1),
  },
  distance: {
    lineHeight: '19.2px',
    wordBreak: 'break-all',
    ...theme.spacing.marginBottom(1),
  },
  description: {
    lineHeight: '16.8px',
  },
}), true)
