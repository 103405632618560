/* eslint-disable max-lines */
import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'
import { TagComposition } from '@codeleap/web'

export type AreaCardComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | 'contentWrapper'
  | 'title'
  | `tag${Capitalize<TagComposition>}`
  | 'image'
  | 'gradient'

const createAreaCardStyle = variantProvider.createVariantFactory<AreaCardComposition>()

export const AREA_CARD_CONSTANTS = {
  default: {
    size: 400,
    tagHeight: 33,
  },
  mobile: {
    size: 155,
    iconSize: 12,
  },
}

export const AreaCardStyles = {
  default: createAreaCardStyle((theme) => ({
    cardWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(0),
      borderRadius: theme.borderRadius.medium,
      maxWidth: AREA_CARD_CONSTANTS.default.size,
      height: AREA_CARD_CONSTANTS.default.size,
      ...theme.presets.fullWidth,
      overflow: 'hidden',
    },
    cardInnerWrapper: {
      ...theme.presets.relative,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      ...theme.presets.center,
      ...theme.spacing.gap(1),
      ...theme.spacing.padding(3),
    },
    contentWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      ...theme.presets.alignCenter,
      zIndex: 1,
    },
    title: {
      ...assignTextStyle('h2')(theme).text,
      ...theme.presets.textCenter,
      color: theme.colors.neutral1,
    },
    tagWrapper: {
      height: 'auto',
      backgroundColor: theme.colors.lightWhite,
    },
    tagLeftIcon: {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
    },
    tagText: {
      ...assignTextStyle('p2')(theme).text,
      fontWeight: '700',
      color: theme.colors.neutral10,
    },
    image: {
      ...theme.presets.absolute,
      ...theme.presets.left,
      ...theme.presets.top,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      maxWidth: AREA_CARD_CONSTANTS.default.size,
    },
    gradient: {
      ...theme.presets.absolute,
      ...theme.presets.left,
      ...theme.presets.top,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      background: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
      zIndex: 1,
    },
  })),
  small: createAreaCardStyle((theme) => ({
    cardWrapper: {
      maxWidth: AREA_CARD_CONSTANTS.mobile.size,
      height: AREA_CARD_CONSTANTS.mobile.size,
      borderRadius: theme.borderRadius.smallish,
    },
    cardInnerWrapper: {
      ...theme.spacing.padding(2),
    },
    contentWrapper: {
      ...theme.presets.center,
    },
    title: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: '700',
    },
    tagWrapper: {
      ...theme.spacing.padding(0.5),
      width: 'max-content',
      height: 'auto',
    },
    tagText: {
      ...assignTextStyle('p4')(theme).text,
      lineHeight: '15.62px',
      fontWeight: '500',
    },
    tagLeftIcon: {
      height: AREA_CARD_CONSTANTS.mobile.iconSize,
      width: AREA_CARD_CONSTANTS.mobile.iconSize,
    },
  })),
  fullWidth: createAreaCardStyle((theme) => ({
    cardWrapper: {
      maxWidth: '100%',
      width: '100%',
    },
    image: {
      maxWidth: '100%',
    },
  })),
  minCardDefaultHeight: createAreaCardStyle((theme) => ({
    cardWrapper: {
      height: AREA_CARD_CONSTANTS.default.size,
    },
  })),
  areaGrid: createAreaCardStyle((theme) => {
    const size = 296
    return {
      cardWrapper: {
        height: size,
      },
    }
  }),
}
