import { MapDefaults } from '../constants'
import { variantProvider } from '../theme'

export type CourseFiltersComposition =
  | 'outerWrapper'
  | 'wrapper'
  | 'rightContent'

const createCourseFiltersStyle = variantProvider.createVariantFactory<CourseFiltersComposition>()


export const CourseFiltersStyles = {
  default: createCourseFiltersStyle((theme) => ({
    outerWrapper: {
      ...theme.presets.relative,
      minWidth: '100%',
      overflowX: 'auto',
      padding: theme.spacing.value(2),
      zIndex: MapDefaults.zIndex.filters,
      display: 'flex',
      background: theme.colors.neutral1,
    },
    wrapper: {
      gap: theme.spacing.value(1),
      display: 'flex',
      background: theme.colors.neutral1,
      ...theme.presets.relative,
      [theme.media.down('mobile')]: {
        paddingRight: theme.spacing.value(7),
      },
    },
    rightContent: {
      ...theme.presets.center
    }
  })),
  noRightContent: createCourseFiltersStyle((theme) => ({
    rightContent: {
      ...theme.presets.hidden
    }
  }))
}