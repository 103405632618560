import { variantProvider } from '@/app'
import { View, Button, Tag, TagFilter } from '@/components'
import { InternalRatingFilters } from '@/types'
import { AnyFunction } from '@codeleap/common'
import { Dispatch, SetStateAction } from 'react'


export type RatingFilterStarsProps = {
  filters: TagFilter[]
  internalFilterValues: InternalRatingFilters
  setInternalFilterValues: Dispatch<SetStateAction<InternalRatingFilters>>
  onClear: AnyFunction
  onApply: AnyFunction
}

export const RatingFilterStars = (props: RatingFilterStarsProps) => {
  const { filters, onClear, setInternalFilterValues, onApply, internalFilterValues } = props

  const handleStarTagPress = (item: TagFilter) => {
    setInternalFilterValues(state => {
      if (!state.stars?.includes(item.value)) {
        if (item.key === 'all_stars') {
          return {
            ...state,
            stars: ['all_stars']
          }
        }
        return {
          ...state,
          stars: [...state.stars.filter(s => s !== 'all_stars'), item.value]
        }
      } else {
        return {
          ...state,
          stars: state.stars.filter(star => star !== item.value)
        }
      }
    })
  }

  return (
    <View style={styles.filterWrapper}>
      <View>
        <View variants={['gap:1', 'wrap', 'row']}>
          {filters.map((filter, index) => {
            const isSelected = internalFilterValues?.stars?.includes(filter.value)
            return (
              <Tag
                key={`Filter star:${filter.key}${index}`}
                text={filter.title}
                variants={['ratingFilter', isSelected ? 'selected' : null]}
                onPress={() => handleStarTagPress(filter)}
                debugName={`Rating Tag:${filter.key}`}
              />
            )
          })}
        </View>
      </View>

      <View css={styles.filterFooter}>
        <Button
          text='Clear'
          debugName='Filter:Clear'
          variants={['flex', 'primary6']}
          onPress={onClear}
          css={styles.clearButton}
        />

        <Button
          variants={['flex']}
          debugName='Filter:Apply'
          text={`Apply`}
          onPress={onApply}
          css={styles.applyButton}
        />
      </View>
    </View>
  )
}

const ACTION_BTN_HEIGHT = 45

const styles = variantProvider.createComponentStyle((theme) => ({
  filterWrapper: {
    ...theme.presets.column,

    [theme.media.down('mobile')]: {
      width: `90vw`,
    },
  },
  filterFooter: {
    ...theme.spacing.marginTop(2),
    gap: theme.spacing.value(1),
  },
  applyButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,

  },
  clearButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,

  },
}), true)
