import { AppImages, variantProvider } from '@/app'
import { MapArrowCluster, MapNumberPin } from '@/components'
import { Course, CourseCluster } from '@/types'

export type CourseMapClusterProps = {
  cluster: CourseCluster
  course?: Course
  index: number
  zIndex?: number
  isMobile?: boolean
}

export const CourseMapCluster = ({ index, isMobile, cluster, course }: CourseMapClusterProps) => {
  const total = cluster?.courses?.length

  return (
    <div id={`CourseMapCluster:${cluster.id}`}>
      {!course && <MapNumberPin total={total} id={`MapCluster:NumberPin:${cluster.id}`}/>}

      {isMobile && !!course && (
        <img src={AppImages.CourseStartingPoint} style={{
          height: 40,
          width: 40,
        }}/>
      )}

      {!isMobile && !!course && (
        <MapArrowCluster
          page={index + 1}
          id={`MapArrowCluster:${cluster.id}`}
          total={total}
          style={styles.arrows}
        />
      )}
    </div>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  arrows: {
    ...theme.presets.relative,
    bottom: theme.spacing.value(1.7),
    left: '50%',
    transform: `translateX(-50%)`,
    ...theme.spacing.marginTop(3),
  },
}), true)
