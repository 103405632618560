export type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyA4HwvdG4UQ9kIEATteul7vrX-NnVSjCL0',
  authDomain: 'x-skamper-79c3d.firebaseapp.com',
  projectId: 'x-skamper-79c3d',
  storageBucket: 'x-skamper-79c3d.appspot.com',
  messagingSenderId: '479030060492',
  appId: '1:479030060492:web:9b6f8a50ded3b2975ae969',
  measurementId: 'G-WV0WCBPPXG',
}

export let cachedFirebaseInstance: FirebaseInstance = null

export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedFirebaseInstance = _firebase
    initialized = true
  }

  return _firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
