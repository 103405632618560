import { includePresets } from '@codeleap/common'
import { Theme, variantProvider } from '..'

export type CenterWrapperComposition = 'wrapper' | 'innerWrapper'

const createCenterWrapperStyle =
  variantProvider.createVariantFactory<CenterWrapperComposition>()

const presets = includePresets((styles) => createCenterWrapperStyle(() => ({ innerWrapper: styles })))

const MAX_WIDTH = Theme.values.maxContentWidth

const safeHorizontalPaddings = Theme.safeHorizontalPaddings()

export const CenterWrapperStyles = {
  ...presets,
  default: createCenterWrapperStyle((theme) => ({
    wrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    innerWrapper: {
      flex: 1,
      display: 'flex',
      width: '100%',
      maxWidth: MAX_WIDTH,
      [theme.media.up('desktopHuge')]: {
        paddingLeft: safeHorizontalPaddings.desktopHuge,
        paddingRight: safeHorizontalPaddings.desktopHuge,
      },
      [theme.media.down('desktopLarge')]: {
        paddingLeft: safeHorizontalPaddings.desktopLarge,
        paddingRight: safeHorizontalPaddings.desktopLarge,
      },
      [theme.media.down('desktop')]: {
        paddingLeft: safeHorizontalPaddings.desktop,
        paddingRight: safeHorizontalPaddings.desktop,
      },
      [theme.media.down('laptop')]: {
        paddingLeft: safeHorizontalPaddings.laptop,
        paddingRight: safeHorizontalPaddings.laptop,
      },
      [theme.media.down('tablet')]: {
        paddingLeft: safeHorizontalPaddings.tablet,
        paddingRight: safeHorizontalPaddings.tablet,
      },
      [theme.media.down('tabletSmall')]: {
        paddingLeft: safeHorizontalPaddings.tabletSmall,
        paddingRight: safeHorizontalPaddings.tabletSmall,
      },
      [theme.media.down('mobile')]: {
        paddingLeft: safeHorizontalPaddings.mobile,
        paddingRight: safeHorizontalPaddings.mobile,
      },
    },
  })),
  noMobilePadding: createCenterWrapperStyle((theme) => ({
    innerWrapper: {
      [theme.media.down('mobile')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }
  }))
}
