import { Theme } from '@/app'
import {
  CourseTypeItem,
  Grid,
  Section,
} from '@/components'
import { CourseCategory } from '@/types'
import { CourseUtils, Navigation, useIsMobile } from '@/utils'

export const CategorySection = () => {

  const handleItemPress = (item: CourseCategory) => {
    Navigation.Course.navigateToExplore(item.filters)
  }

  const isMobile = useIsMobile()

  return (
    <Section
      title='Show me…'
      variants={['column', 'marginTop:4']}
    >
      <Grid
        data={CourseUtils.CourseCategories as unknown as string[]}
        renderItem={({ item }) => <CourseTypeItem item={item as CourseCategory} onPress={() => handleItemPress(item as CourseCategory)} />}
        rowItemsSpacing={Theme.spacing.value(2)}
        columnItemsSpacing={Theme.spacing.value(2)}
        numColumns={isMobile ? 2 : 3}
        debugName='CourseType:list'
        variants={['fullWidth']}
        style={{
          minHeight: 'auto',
        }}
      />
    </Section>
  )
}
