import { variantProvider } from '@/app'
import { BannerSection, Page } from '@/components'
import { GridAreaSection } from '@/components/Area'
import { APIClient } from '@/services'
import { UserLocationUtils } from '@/utils'
import { AreaUtils } from '@/utils/areas'
import { useSearchParams } from '@codeleap/web'

export const AreasPage = () => {

  const [params, setParams] = useSearchParams()
  const { location } = UserLocationUtils.useCurrentLocation()

  const { areas, listProps } = APIClient.Areas.useAreas({
    ...AreaUtils.getFiltersByParams(params),
    lat: location?.latitude,
    lng: location?.longitude,
    limit: 9,
  })

  return (
    <Page
      pageTitle="Find top running spots across Britain with Skamper's guide"
      centerContent={false}
      showFooter={false}
      styles={{ innerWrapper: styles.innerWrapper }}
    >
      <BannerSection />
      <GridAreaSection
        areas={areas.items}
        sectionProps={{ variants: ['column', 'marginTop:10'] }}
        {...listProps}
      />
    </Page>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    innerWrapper: {
      ...theme.spacing.paddingBottom(10),
    },
  }),
  true,
)
