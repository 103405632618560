import { deepMerge, PropsOf } from '@codeleap/common'
import { Text } from '@/components'
import { variantProvider } from '@/app'
import { AppTextStyles } from '@/app/stylesheets'

export type MapTextProps = {
  text: string | number
  variants?: (keyof typeof AppTextStyles)[]
} & PropsOf<typeof Text>

export const MapText = ({ text, variants = ['default'], style, ...rest }: MapTextProps) => {
  const { styles } = useStyles(variants)
  return <p {...rest} style={deepMerge(styles.text, style)} >{text}</p>
}

const useStyles = (variants: MapTextProps['variants']) => {

  const styles = variantProvider.createComponentStyle((theme) => {
    let variantStyles = AppTextStyles.default(theme).text

    variants.forEach((variant) => {
      variantStyles = deepMerge(variantStyles, AppTextStyles[variant](theme).text)
    })

    return {
      text: variantStyles,
    }
  }, true)

  return { styles }
}

