import React, { useRef, useState } from 'react'
import { Course, Location, SearchPrediction } from '@/types'
import { MapDefaults, MyLocation } from '@/app'
import { SearchBarProps, View, Scroll, SearchBar } from '@/components'
import { PlacesAutocompleteStyles } from '@/app/stylesheets'
import { AutocompleteUtils, Navigation, useClickClosest, useIsMobile } from '@/utils'
import { PlacesAutoCompleteModal, PlacesResults, PlacesResultsProps } from './components'
import { ComponentVariants, StylesOf, getNestedStylesByKey, onMount, useBooleanToggle, useDefaultComponentStyle } from '@codeleap/common'
import { PlacesAutocompleteComposition } from '@/app/stylesheets/PlacesAutocomplete'
import { APIClient } from '@/services'
import { UsePlacesSearchProps } from '@/services/api/places'

const ELEMENT_IDS = {
  searchInput: 'places-autocomplete-search-input',
  results: 'places-autocomplete-results',
  clearIcon: 'places-autocomplete-clear-icon',
}
const SEARCH_INPUT_DEBOUNCE = 1000

export type PlacesAutoCompleteProps = {
  searchText: string
  defautlValue?: string
  onChangeSearch: SearchBarProps['onSearchChange']
  onClear?: SearchBarProps['onClear']
  styles?: StylesOf<PlacesAutocompleteComposition>
  showResults?: boolean
  placeholder?: SearchBarProps['placeholder']
  noEmptyPlaceholder?: PlacesResultsProps['noEmptyPlaceholder']
  searchBarProps?: Partial<SearchBarProps>
  onItemPress?: PlacesResultsProps['onItemPress']
  origin?: Location
  value?: string
  setValue?: (value: string) => void
  searchProps?: UsePlacesSearchProps
} & ComponentVariants<typeof PlacesAutocompleteStyles>

const _PlacesAutoComplete = ({
  variants,
  responsiveVariants,
  searchText,
  defautlValue,
  onChangeSearch,
  onItemPress,
  onClear,
  placeholder = 'Search locations',
  searchBarProps,
  styles,
  origin,
  value,
  noEmptyPlaceholder,
  setValue,
  searchProps,
  ...rest
}: PlacesAutoCompleteProps) => {
  const textInputRef = useRef(null)
  const [inputInternalValue, setInternalInputValue] = useState(defautlValue)
  const [isFocused, toggleFocused] = useBooleanToggle(false)
  const isMobile = useIsMobile()

  const inputValue = value !== null ? value : inputInternalValue
  const setInputValue = setValue || setInternalInputValue

  const variantStyles = useDefaultComponentStyle<
    'u:PlacesAutoComplete',
    typeof PlacesAutocompleteStyles
  >('u:PlacesAutoComplete', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const { places, query } = APIClient.Places.useSearchPlaces({
    search: searchText !== MyLocation.name ? searchText : '',
    origin_lat: origin?.latitude || MapDefaults.center?.latitude,
    origin_lng: origin?.longitude || MapDefaults.center.longitude,
    ...searchProps,
  })

  onMount(() => {
    if (textInputRef.current && !!searchText) {
      const inputRef = textInputRef.current?.getInputRef()
      inputRef.value = searchText
    }
  })

  const isLoading = query.isFetching

  const handleItemPress = (place: SearchPrediction) => {
    const placeTitle = AutocompleteUtils.getPlaceTitle(place)
    setInputValue(placeTitle)
    AutocompleteUtils.saveSearch(place)

    if (textInputRef.current) {
      const inputRef = textInputRef.current?.getInputRef()
      inputRef.value = placeTitle
    }

    if (place?.type == 'course') {
      Navigation.Course.navigateToDetails(place.data as Course)
      return
    }

    if (!!place?.coords) {
      onItemPress?.(place)
      return
    }
  }

  const handleSearch = async (inputValue: string) => {
    onChangeSearch(inputValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault()
  }

  useClickClosest({
    elementIds: [ELEMENT_IDS.searchInput, ELEMENT_IDS.searchInput, ELEMENT_IDS.clearIcon],
    onNotClickClose: () => toggleFocused(false),
    enabled: !isMobile,
  })

  const placesResultsProps: PlacesResultsProps = {
    searchText,
    isLoading,
    places,
    onItemPress: handleItemPress,
    noEmptyPlaceholder: inputValue === MyLocation.name,
  }

  const searchBarDefaultProps: SearchBarProps = {
    value: inputValue,
    defaultValue: inputValue,
    onClear,
    onValueChange: setInputValue,
    onSearchChange: handleSearch,
    debugName: 'PlacesAutoComplete:SearchInput',
    debounce: SEARCH_INPUT_DEBOUNCE,
    placeholder: placeholder,
    variants: ['searchPlaces', 'noError'],
    wrapperProps: { id: ELEMENT_IDS.searchInput, component: 'form' },
    styles: getStyles('searchInput'),
  }

  return (
    <View css={getStyles('wrapper')} {...rest}>
      <SearchBar
        ref={textInputRef}
        onFocus={() => toggleFocused(true)}
        clearIconId={ELEMENT_IDS.clearIcon}
        onKeyDown={handleKeyDown}
        {...searchBarDefaultProps}
        {...searchBarProps}
      />

      {isFocused && !isMobile &&
        <View css={variantStyles.resultsWrapper} id='SearchInputLocationResults'>
          <Scroll>
            <PlacesResults {...placesResultsProps} />
          </Scroll>
        </View>
      }

      {isMobile && (
        <PlacesAutoCompleteModal
          {...searchBarDefaultProps}
          placesResultsProps={placesResultsProps}
          visible={isFocused}
          toggle={toggleFocused}
          getStyles={getStyles}
        />)}
    </View>
  )
}

export const PlacesAutoComplete = (props: PlacesAutoCompleteProps) => {
  if (!props?.origin) return null
  return <_PlacesAutoComplete {...props} />
}

