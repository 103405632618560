import { AppImages } from '@/app'
import { RatingFilters } from '@/components'
import { InternalRatingFilters, Shoe } from '@/types'

const ratingFilters: (keyof RatingFilters)[] = [
  'stars',
]

const defaultFilters: InternalRatingFilters = {
  stars: ['all_stars']
}

const getFiltersByParams = (params) => {
  if (!params) return {}

  const filters = {}
  Object.entries(params).map(([key, value]: [string, string]) => {
    if (!value || !ratingFilters.includes(key as keyof RatingFilters)) return null

    if (key === 'stars') {
      filters[key] = value.split(',')?.map(v => Number(v))
      return
    }

    filters[key] = value
  })

  if (Object.keys(filters).length === 0) {
    return defaultFilters
  }

  return filters
}

export const RatingUtils = {
  getFiltersByParams,
  defaultFilters,
}
