import { useMemo } from 'react'
import { interpolate } from './interpolate'
import { LineChartProps } from '../types'

export const useLineChartMetrics = ({
  data,
  xData,
  height,
  forceMin,
  forceMax,
}: Pick<
  LineChartProps,
  'data' | 'xData' | 'height' |
  'forceMax' | 'forceMin'
>) => {
  const metrics = useMemo(() => {
    let maxY = data[0]?.y
    let minY = data[0]?.y
    let maxX = data[0]?.x
    let minX = data[0]?.x

    data.forEach((d) => {
      if (d.y > maxY) maxY = d.y
      if (d.y < minY) minY = d.y
      if (d.x > maxX) maxX = d.x
      if (d.x < minX) minX = d.x
    })

    return { minY, maxY, minX, maxX }
  }, [data])

  const formmatedXData = useMemo(() => {
    const divisor = Math.ceil(data.length / (xData?.length - 1))

    return xData?.map((item, index) => {
      const isLast = index === xData?.length - 1
      const lastPosition = !!forceMax ? data.length - 2 : data.length - 1
      const dataIndex = isLast ? lastPosition : divisor * index

      const nearestItem = data[dataIndex]
      const itemY = nearestItem?.y

      const lineHeight = interpolate(
        itemY,
        forceMin || metrics.minY,
        forceMax || metrics.maxY,
        0,
        height,
      )
      return { ...item, height: lineHeight }
    })
  }, [xData, data])

  return { metrics, formmatedXData }
}
