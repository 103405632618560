import { MapDefaults, Theme } from '@/app'
import { useAppSelector } from '@/redux'
import { Course, LongCoords } from '@/types'
import { MapComponent, MapComponentProps } from '../Maps'
import { CourseUtils, MapUtils, StyleUtils, toShortCoords } from '@/utils'

export type CourseMapProps = MapComponentProps & {
  course: Course
  fitToMarkers?: boolean
  customLocation?: LongCoords
}

const FIT_TO_MARKERS_PADDING = 50

export const CourseMap = ({
  course,
  fitToMarkers = true,
  customLocation,
  isLoading = !CourseUtils.isReadyToRenderOnMap(course),
  ...rest }: CourseMapProps) => {
  const { location } = useAppSelector(state => state.UserLocation)
  const checkpoints = course?.waypoints
  const { mapRefs } = MapUtils.useMap()

  const shortLocation = toShortCoords(customLocation || location || MapDefaults.center)

  const handleApiLoaded = ({ map, maps }) => {
    MapUtils.hideControls(map)
    if (fitToMarkers) { MapUtils.fitToMarkers({ map, maps, markers: checkpoints, paddings: FIT_TO_MARKERS_PADDING }) }
    CourseUtils.Maps.drawPolylines({ course, map, maps, strokeColor: StyleUtils.opacity(Theme.colors.light.primary3, 35), borderColor: null, borderWith: 0 })
    CourseUtils.Maps.drawCheckpoints({ course, map, maps })
  }

  return (
    <MapComponent
      {...rest}
      {...mapRefs}
      isLoading={isLoading}
      userLocation={shortLocation}
      defaultCenter={shortLocation}
      zoom={MapDefaults.zoom.default}
      onGoogleApiLoaded={handleApiLoaded}
    />
  )
}

