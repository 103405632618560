import { CropPickerComposition, CropPickerPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createCropPickerVariant = variantProvider.createVariantFactory<CropPickerComposition>()

const defaultStyles = CropPickerPresets

export const AppCropPickerStyles = {
  ...defaultStyles,
  default: createCropPickerVariant(theme => ({
    previewSize: {
      maxHeight: '70vh',
      [theme.media.down('medium')]: {
        maxHeight: 'unset',
        maxWidth: '80vh',
      },
    },
    confirmButtonWrapper: {
      ...theme.presets.fullWidth,
    },
  })),
}
