import { Theme } from '@/app'
import { RGB } from '@/types'

function opacityToHex(opacityPercentage: number) {
  const opacity = opacityPercentage / 100
  const opacityHex = Math.round(opacity * 255)
    .toString(16)
    .toUpperCase()
  const paddedOpacityHex =
    opacityHex.length === 1 ? '0' + opacityHex : opacityHex
  return paddedOpacityHex
}

const opacity = (color: string, opacity = 100) => `${color}${opacityToHex(opacity)}`

function randomColor() {
  // Generate random RGB values within a darker range
  const red = Math.floor(Math.random() * 100) + 100 // Range: 100-255
  const green = Math.floor(Math.random() * 100) + 100 // Range: 100-255
  const blue = Math.floor(Math.random() * 100) + 100 // Range: 100-255

  // Convert RGB values to hexadecimal format
  const hexRed = red.toString(16).padStart(2, '0')
  const hexGreen = green.toString(16).padStart(2, '0')
  const hexBlue = blue.toString(16).padStart(2, '0')

  // Construct the color string in hexadecimal format
  const color = `#${hexRed}${hexGreen}${hexBlue}`

  return color
}

const rgbToHex = ({ red, green, blue }: RGB) => {
  const hexRed = red.toString(16).padStart(2, '0')
  const hexGreen = green.toString(16).padStart(2, '0')
  const hexBlue = blue.toString(16).padStart(2, '0')

  return `#${hexRed}${hexGreen}${hexBlue}`
}

export const vibrantColors = [
  '#56B0F6',
  '#D04141',
  '#00C466',
  '#8C45FF',
  '#FF29B6',
  '#F473FF',
  '#33A400',
  '#C800D9',
  '#5B7FFF',
  '#56E3F6',
  '#79FCBD',
  '#82EB53',
  '#FF7676',
  '#FF922D',
  '#4E00CD',
  '#2E56E4',
  '#0073CD',
  '#0091A5',
  '#33A400',
  '#CC6200',
  '#B3A100',
  '#C800D9',
]

function randomVibrantColor() {
  const index = Math.floor(Math.random() * vibrantColors.length)
  return vibrantColors[index]
}

function darken(hexColor, percent) {
  if (!hexColor) return Theme.colors.light.primary1
  if (hexColor[0] === '#' && (hexColor.length === 7 || hexColor.length === 4)) {
    if (hexColor.length === 4) {
      hexColor = '#' + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3]
    }

    let r = parseInt(hexColor.substring(1, 3), 16)
    let g = parseInt(hexColor.substring(3, 5), 16)
    let b = parseInt(hexColor.substring(5, 7), 16)

    r = Math.max(0, Math.min(255, Math.floor(r * (1 - percent / 100))))
    g = Math.max(0, Math.min(255, Math.floor(g * (1 - percent / 100))))
    b = Math.max(0, Math.min(255, Math.floor(b * (1 - percent / 100))))

    const toHex = (c) => c.toString(16).padStart(2, '0')
    const darkenedColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`

    return darkenedColor
  } else {
    return hexColor
  }
}

export const StyleUtils = {
  darken,
  opacity,
  randomColor,
  randomVibrantColor,
  vibrantColors,
  rgbToHex,
}
