import { variantProvider } from '@/app'
import { View, Text, Image } from '@/components'
import { Shoe } from '@/types'
import { RatingUtils, ShoesUtils } from '@/utils'

type ShoeTipProps = {
  shoe: Shoe
}

export const ShoeTip = (props: ShoeTipProps) => {
  const { shoe } = props

  const image = ShoesUtils.getShoeImage(shoe)

  return (
    <View variants={['gap:1', 'padding:1', 'row']} style={styles.wrapper}>
      <Image source={image} style={styles.image} objectFit='contain' />
      <View variants={['column']}>
        <Text text={'Suggests'} variants={['p3', 'color:neutral9']} />
        <Text text={`${shoe?.title} shoes`} variants={['h5', 'color:neutral9']} />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.border.neutral3(theme.values.borderWidth.medium),
    },
    image: {
      width: theme.spacing.value(2),
    },
  }),
  true,
)
