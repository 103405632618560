import { variantProvider } from '@/app'
import { View, Tooltip, Button, RatingFilterBottomDrawer, RatingFilterStars, RatingFilterStarsProps, Text } from '@/components'
import { RatingUtils, useClickClosest, useFilterByText, useIsMobile } from '@/utils'
import { AnyFunction, PropsOf, onUpdate, useBooleanToggle, useState } from '@codeleap/common'
import { Dispatch, SetStateAction } from 'react'
import { InternalRatingFilters } from '@/types'

type RatingFilterProps = {
  params: Record<string, string>
  setParams: Dispatch<SetStateAction<Record<string, string>>>
} & PropsOf<typeof View>

export type TagFilter = {
  key: string
  title: string
  value: string | number
}

type DesktopFilterProps = {
  onClose?: AnyFunction
  params: Record<string, string>
} & RatingFilterStarsProps

const DesktopFilter = (props: DesktopFilterProps) => {
  const { onApply, onClose: _onClose, params, onClear, ...filterStarsProps } = props
  const [filterOpen, toggleFilter] = useBooleanToggle(false)
  const [filterByTxt, setFilterByTxt] = useState('All stars')

  const onApplyPress = () => {
    onApply?.()
    toggleFilter(false)
  }

  const onClose = () => {
    _onClose?.()
    toggleFilter(false)
  }

  const onClearPress = () => {
    onClear?.()
    toggleFilter(false)
  }

  useClickClosest({
    elementIds: ['rating-stars-filter', `filter-content-rating-stars-filter`],
    onNotClickClose: onClose,
    enabled: filterOpen,
  })

  useFilterByText({
    params,
    setText: setFilterByTxt,
  })

  return (
    <Tooltip
      /* @ts-ignore */
      id={'rating-stars-filter'}
      debugName='RatingFilter'
      variants={['bare', 'overlap', 'small'] as any}
      open={filterOpen}
      content={
        <View style={styles.filterWrapper} id={`filter-content-rating-stars-filter`}>
          <RatingFilterStars
            onApply={onApplyPress}
            onClear={onClearPress}
            {...filterStarsProps}
          />
        </View>
      }
    >
      <Button
        debugName='Rating Filter Button'
        onPress={() => toggleFilter(true)}
        variants={['filterRating', 'primary6']}
        text={filterByTxt}
        rightIcon='chevron-down'
      />
    </Tooltip>
  )
}


type MobileFilterProps = {
  onClose?: AnyFunction
  params: Record<string, string>
} & RatingFilterStarsProps

const MobileFilter = (props: MobileFilterProps) => {
  const { onApply, onClose, onClear, params, ...bottomDrawerRatingProps } = props
  const [filterOpen, toggleFilter] = useBooleanToggle(false)
  const [filterByTxt, setFilterByTxt] = useState('All stars')

  const onApllyPress = () => {
    onApply()
    toggleFilter(false)
  }

  const onClearPress = () => {
    onClear?.()
    toggleFilter(false)
  }

  const onDismiss = () => {
    onClose?.()
    toggleFilter(false)
  }

  useFilterByText({
    params,
    setText: setFilterByTxt,
  })

  // onUpdate(() => {
  //   if (params?.stars) {
  //     const orderedFilterStars = params?.stars?.split(',')?.sort((a, b) => Number(a) - Number(b))?.join(', ')
  //     setFilterByTxt(`${orderedFilterStars} stars`)
  //   } else {
  //     setFilterByTxt('All stars')
  //   }
  // }, [params])

  return (
    <View>
      <Button
        debugName='Rating Filter Button'
        onPress={toggleFilter}
        variants={['filterRating', 'primary6']}
        text={filterByTxt}
        rightIcon='chevron-down'
      />
      <RatingFilterBottomDrawer
        open={filterOpen}
        onDismiss={onDismiss}
        onApply={onApllyPress}
        onClear={onClearPress}
        {...bottomDrawerRatingProps}
      />
    </View>
  )
}

export const RatingFilter = (props: RatingFilterProps) => {
  const { params, setParams, ...rest } = props

  // @ts-ignore
  const filterParams: InternalRatingFilters = RatingUtils.getFiltersByParams(params)

  const [internalFilterValues, setInternalFilterValues] = useState(filterParams)

  const isMobile = useIsMobile()

  const filters: TagFilter[] = [
    {
      key: 'all_stars',
      title: 'All stars',
      value: 'all_stars',
    },
    {
      key: 'one_stars',
      title: '1 star',
      value: 1,
    },
    {
      key: 'two_stars',
      title: '2 stars',
      value: 2,
    },
    {
      key: 'three_stars',
      title: '3 stars',
      value: 3,
    },
    {
      key: 'four_stars',
      title: '4 stars',
      value: 4,
    },
    {
      key: 'five_stars',
      title: '5 stars',
      value: 5,
    }
  ]

  const onApply = () => {
    const isAllStars = internalFilterValues.stars.includes('all_stars')
    setParams(state => {
      if (isAllStars) {
        return {
          ...state,
          stars: null
        }
      } else {
        return {
          ...state,
          stars: internalFilterValues.stars.join(','),
        }
      }
    })
  }

  const onClear = () => {
    setInternalFilterValues(RatingUtils.defaultFilters)
    setParams(state => {
      return {
        ...state,
        stars: null
      }
    })
  }

  const onClose = () => {
    setInternalFilterValues(state => ({
      ...state,
      stars: params?.stars?.length > 0
        ? params?.stars?.split(',')?.map(star => Number(star))
        : ['all_stars']
    }))
  }

  const filterProps = {
    filters,
    onApply,
    onClear,
    onClose,
    internalFilterValues,
    setInternalFilterValues,
    params,
  }

  return (
    <View {...rest}>
      <View variants={['fullWidth', 'gap:1', 'alignCenter']}>
        <Text text='Filter by:' variants={['p3', 'color:neutral9']} />
        {isMobile ? (
          <MobileFilter {...filterProps} />
        ) : (
          <DesktopFilter {...filterProps} />
        )}
      </View>
    </View>
  )
}

const ACTION_BTN_HEIGHT = 45

const styles = variantProvider.createComponentStyle((theme) => ({
  filterWrapper: {
    ...theme.presets.relative,
    ...theme.presets.elevated,
    ...theme.spacing.padding(2),
    borderRadius: theme.borderRadius.smallish,
    width: 400,
    backgroundColor: theme.colors.neutral1,
    bottom: theme.spacing.value(1),

    [theme.media.down('mobile')]: {
      width: `90vw`,
    },
  },
  filterFooter: {
    ...theme.spacing.marginTop(2),
    gap: theme.spacing.value(2),

    [theme.media.down('mobile')]: {
      ...theme.presets.column,
    },
  },
  applyButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 1,
    },
  },
  clearButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 2,
    },
  },
}), true)
