import { variantProvider } from '../theme'
import { ColorPickerComposition, ColorPickerPresets } from '@codeleap/web'

const createColorPickerStyle =
  variantProvider.createVariantFactory<'', ColorPickerComposition>()

const MIN_WIDTH = 300
const MIN_HEIGHT = 300

export const ColorPickerStyles = {
  ...ColorPickerPresets,
  default: createColorPickerStyle(theme => ({
    wrapper: {},
    dropdown: {
      ...theme.presets.absolute,
      maxWidth: MIN_WIDTH,
      left: 0,
      right: 0,
      top: 50,
      zIndex: 3,
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.medium,
      border: 'none',
    },
    'dropdown:open': {
      ...theme.effects.smooth,
      minHeight: MIN_HEIGHT,
    },
    dropdownInnerWrapper: {
      width: '100%',
      minHeight: MIN_HEIGHT,

      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.spacing.gap(2),
      ...theme.spacing.padding(2),
    },

    picker: {
      '.react-colorful': {
        width: '100%',
        ...theme.spacing.gap(1),
      },

      '.react-colorful__saturation': {
        borderRadius: theme.borderRadius.small,
      },

      '.react-colorful__hue, .react-colorful__alpha': {
        borderRadius: theme.borderRadius.rounded,
        height: theme.values.itemHeight.tiny,
      },

      '.react-colorful__saturation-pointer, .react-colorful__hue-pointer, .react-colorful__alpha-pointer': {
        ...theme.sized(2.8),
      },
    },

    footerWrapper: {
      ...theme.spacing.gap(1),
      marginLeft: 'auto',
    },
  })),
}
