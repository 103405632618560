import { Rating } from '@/types'
import { View, Text, Avatar, RatingStars, ShoeTip } from '@/components'
import { ComponentVariants, PropsOf, StylesOf, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import { CourseRatingStyles } from '@/app/stylesheets'
import { CourseRatingComposition } from '@/app/stylesheets/CourseRating'
import { getAbreviatedDate } from '@/utils'

type CourseRatingProps = Omit<PropsOf<typeof View>, 'variants' | 'styles' | 'style'> & {
  rating: Rating
  styles?: StylesOf<CourseRatingComposition>
} & ComponentVariants<typeof CourseRatingStyles>

export const CourseRating = (props: CourseRatingProps) => {
  const {
    variants,
    responsiveVariants,
    styles,
    rating,
    ...rest
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:CourseRating',
    typeof CourseRatingStyles
  >('u:CourseRating', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const date = getAbreviatedDate(rating?.created_datetime)

  return (
    <View style={getStyles('wrapper')} component='article' {...rest}>
      <View style={getStyles('topContent')} component='header'>
        <Avatar
          debugName='Course Rating Avatar'
          image={rating?.profile?.avatar}
          name={rating?.profile?.full_name}
          firstNameOnly={false}
          randomizeColor
        />

        <View style={getStyles('headline')}>
          <Text text={rating?.profile?.full_name} style={getStyles('user')} component='h4' />

          <Text text={date} style={getStyles('date')} />
        </View>
      </View>

      <View style={getStyles('middleContent')}>
        <RatingStars stars={5} score={rating?.stars} />

        {rating?.shoe_type ? (
          <ShoeTip shoe={rating?.shoe_type} />
        ) : null}
      </View>

      <View style={getStyles('bottomContent')}>
        {!!rating?.feedback ? (
          <Text text={rating?.feedback} style={getStyles('feedback')} />
        ) : null}
      </View>
    </View>
  )
}
