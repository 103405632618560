import { MapDefaults } from '@/app'
import { View } from '@/components'
import { PropsOf } from '@codeleap/common'
import { MapComponent, MapComponentProps } from '../../Maps'
import { ReactElement, useCallback, useState } from 'react'
import { MapUtils, debounce } from '@/utils'
import { ExploreMapControls, ExploreMapDrawHooks, ExploreMapFooter, ExploreMapFooterProps, ExploreMapSearchButton } from './components'
import { Course, ExploreMapRefs, ShortCoords, UseDrawCourseClustersProps, UseDrawCoursesProps } from '@/types'
import { store } from '@/redux'

type ViewProps = PropsOf<typeof View>

export type ExploreMapProps = MapComponentProps & {
  courses: Course[]
  mapRefs: ExploreMapRefs
  onSearchArea?: (position: ShortCoords) => void
  onSetStartingPoint?: (position: ShortCoords) => void
  onLoad?: () => void
  position: ShortCoords & { radius: number }
  selectedCourse?: Course
  userLocation?: ShortCoords
  onFollowUser?: () => void
  wrapperStyle?: ViewProps['style']
  onCourseUnfocus?: () => void
  HeaderComponent?: ReactElement
  useDrawCoursesProps?: Partial<UseDrawCoursesProps>
  useDrawCourseClustersProps?: Partial<UseDrawCourseClustersProps>
  isLoadingArea?: boolean
  isSearchAreaVisible?: boolean
  isFetching?: boolean
  footerProps?: Partial<ExploreMapFooterProps>
}

export const ExploreMap = ({
  isLoading,
  HeaderComponent,
  courses = [],
  position,
  wrapperStyle,
  zoom = MapDefaults.zoom.default,
  selectedCourse,
  isFetching,
  userLocation,
  isLoadingArea,
  onCourseUnfocus,
  onSearchArea,
  onSetStartingPoint,
  onFollowUser,
  onDrag,
  onLoad,
  onChange,
  footerProps,
  isSearchAreaVisible,
  useDrawCoursesProps,
  useDrawCourseClustersProps,
  mapRefs,
  ...rest }: ExploreMapProps,
) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const { coursesRef, mapRef, mapsRef, stopPropagationRef } = mapRefs
  const mapProps = { map: mapRef.current, maps: mapsRef.current }

  const handleApiLoaded = async ({ map }) => {
    MapUtils.hideControls(map)
    setIsMapLoaded(true)
    onLoad?.()
  }

  MapUtils.useDrawCircles({
    ...mapProps,
    isMapLoaded,
    circles: [{ center: position, radius: position.radius }],
  })

  const handleMapClick = async () => {
    if (!!stopPropagationRef.current) return
    const selectedCluster = store.getState().ExploreMapRedux.selectedCluster

    if (!!selectedCourse || !!selectedCluster) { onCourseUnfocus(); return }

    // ## Draw starting point tooltip
    // const position = { lat: e.lat, lng: e.lng }
    // const targetId = e.event.target.id

    // if (!targetId) {
    //   const removeTooltip = () => {
    //     MapUtils.removeElement(tooltipRef.current)
    //     tooltipRef.current = null
    //   }

    //   if (tooltipRef.current) {
    //     removeTooltip()
    //     return
    //   }

    //   tooltipRef.current = await CourseUtils.Maps.drawStartingPointTooltip({
    //     ...mapProps,
    //     position,
    //     tooltipId: 'explore-map-tooltip',
    //     onClose: removeTooltip,
    //     onSetStartingPoint: onSetStartingPoint,
    //   })
    // }
  }

  const handleMapDrag = useCallback((e) => {
    onDrag?.(e)
  }, [onDrag])

  const handleMapChange = useCallback((e) => {
    onChange?.(e)
  }, [onChange])

  const handleFollowUser = () => {
    MapUtils.followUser({ map: mapRef.current, maps: mapsRef.current })
    onFollowUser?.()
  }

  return (
    <View variants={['fullWidth', 'relative']} style={wrapperStyle}>
      {HeaderComponent}

      <MapComponent
        {...rest}
        isLoading={isLoading}
        zoom={zoom}
        {...mapRefs}
        id='explore-map'
        center={position}
        onClick={handleMapClick}
        onGoogleApiLoaded={handleApiLoaded}
        onDrag={debounce(handleMapDrag, 300)}
        userLocation={userLocation}
        onChange={handleMapChange}
      />

      <ExploreMapControls
        selectedCourse={selectedCourse}
        onFollowUser={handleFollowUser}
      />

      <ExploreMapSearchButton
        loading={isFetching}
        onPress={() => {
          const position = MapUtils.getCenter(mapRef.current)
          onSearchArea?.(position)
        }}
        isSearchAreaVisible={isSearchAreaVisible}
      />

      <ExploreMapFooter
        selectedCourse={selectedCourse}
        onFollowUser={handleFollowUser}
        {...footerProps}
      />

      <ExploreMapDrawHooks
        courses={courses}
        useDrawCoursesProps={{
          ...mapProps,
          isMapLoaded,
          courses,
          coursesRef,
          selectedCourse,
          ...useDrawCoursesProps,
        }}
        useDrawCourseClustersProps={{
          ...mapProps,
          isMapLoaded,
          selectedCourse,
          ...useDrawCourseClustersProps,
        }} />
    </View>
  )
}

