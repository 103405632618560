import { AvatarComposition, AvatarStyles } from '../app/stylesheets/Avatar'
import {
  ComponentVariants,
  matchInitialToColor,
  PropsOf,
  StylesOf,
  TypeGuards,
  useDefaultComponentStyle,
  useMemo,
  IconPlaceholder,
  getNestedStylesByKey,
  WebInputFile,
} from '@codeleap/common'
import {
  Image,
  View,
  Text,
  Touchable,
  Icon,
  Badge,
  CropPicker,
} from '@/components'
import {
  BadgeComponentProps,
  useFileInput,
  BaseCropProps,
} from '@codeleap/web'
import { ColorUtils } from '@/utils'

type ImageProps = PropsOf<typeof Image>

export type AvatarProps = Omit<
  PropsOf<typeof Touchable>,
  'styles' | 'variants'
> &
  BadgeComponentProps & {
    styles?: StylesOf<AvatarComposition>
    onChange?: (picture: WebInputFile) => void
    image?: ImageProps['source']
    badge?: boolean
    name?: string | string[]
    debugName: string
    firstNameOnly?: boolean
    text?: string
    description?: string
    icon?: IconPlaceholder
    badgeIcon?: IconPlaceholder
    cropPickerProps?: Partial<BaseCropProps>
    randomizeColor?: boolean
  } & ComponentVariants<typeof AvatarStyles>

export const Avatar = (props: AvatarProps) => {
  const {
    debugName,
    name = '',
    firstNameOnly = true,
    image,
    responsiveVariants = {},
    variants = [],
    styles = {},
    style = {},
    icon,
    badgeIcon,
    onChange,
    onPress,
    text = '',
    description,
    badge = false,
    badgeProps = {},
    cropPickerProps = {},
    randomizeColor = false,
    ...viewProps
  } = props as AvatarProps

  const input = useFileInput()

  const editable = !!onChange
  const hasImage = !!image

  const handlePress = async () => {
    if (!editable) onPress?.()
    else {
      const files = await input.openFilePicker()
      if (!files.length) return
      onChange?.(files[0])
    }
  }

  const variantStyles = useDefaultComponentStyle<
    'u:Avatar',
    typeof AvatarStyles
  >('u:Avatar', {
    responsiveVariants,
    variants,
    styles,
  })

  const { initials, randomColor, textColor } = useMemo(() => {
    const [first = '', last = ''] = TypeGuards.isString(name)
      ? name.split(' ')
      : name
    const initials = [first[0]]
    if (!firstNameOnly) {
      initials.push(last[0])
    }

    const { combinedColor } = ColorUtils.getAvatarColors(initials.join(''))
    const brightness = (
      parseInt(combinedColor?.substring(1, 3), 16) * 299
      + parseInt(combinedColor?.substring(3, 5), 16) * 587
      + parseInt(combinedColor?.substring(5, 7), 16) * 114
    ) / 1000
    const shouldDarkenBy = brightness > 186 ? 120 : 60
    const textColor = ColorUtils.adjustHexColorBrightness(combinedColor, shouldDarkenBy)

    return {
      initials: initials.join(' '),
      randomColor: matchInitialToColor(first[0]),
      textColor,
    }
  }, [name, firstNameOnly])

  const renderContent = () => {
    if (hasImage) return <Image source={image} style={variantStyles.image} />
    if (icon) {
      return (
        <Icon name={icon} style={variantStyles.icon} debugName='Avatar icon' />
      )
    }
    return <Text text={text || initials} style={[variantStyles.initials, { color: textColor }]} />
  }

  const hasBackgroundColor = !!variantStyles?.touchable?.backgroundColor

  const badgeStyles = getNestedStylesByKey('badge', variantStyles)

  return (
    <View css={[variantStyles.wrapper, style]}>
      <CropPicker
        debugName='Avatar CropPicker'
        ref={input.ref}
        targetCrop={cropPickerProps}
      />
      <Touchable
        debugName={'Avatar ' + debugName}
        onPress={handlePress}
        css={[
          variantStyles.touchable,
          !hasBackgroundColor || randomizeColor && {
            backgroundColor: randomColor,
          },
        ]}
        {...viewProps}
      >
        {renderContent()}
        {!!description ? (
          <View style={variantStyles.descriptionOverlay}>
            <Text text={description} style={variantStyles.description} />
          </View>
        ) : null}
        <Badge
          badge={badge}
          styles={badgeStyles}
          {...badgeProps}
          debugName='Avatar Badge'
        />
      </Touchable>
      {badgeIcon && (
        <Touchable
          debugName={`${debugName} badge icon`}
          style={variantStyles.badgeIconWrapper}
          onPress={onPress}
        >
          <Icon
            name={badgeIcon}
            style={variantStyles.badgeIcon}
            debugName='Avatar badge'
          />
        </Touchable>
      )}
    </View>
  )
}
