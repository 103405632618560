import { useEffect } from '@codeleap/common'

type UseMouseClosestProps = {
  elementIds: string[]
  enabled?: boolean
  onClickClose?: (event: MouseEvent) => void
  onNotClickClose?: (event: MouseEvent) => void
}

export const useClickClosest = ({
  elementIds,
  onClickClose,
  onNotClickClose,
  enabled = true,
}: UseMouseClosestProps) => {
  const checkIsClosest = (event) => elementIds.some((elementId) => !!event.target.closest(`#${elementId}`))

  useEffect(() => {
    if (!enabled) return

    const handleClickOutside = (event) => {
      if (checkIsClosest(event)) onClickClose?.(event)
      else onNotClickClose?.(event)
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [elementIds, onClickClose, onNotClickClose, enabled])
}
