import { Breadcrumbs, AllCoursesFilters, CourseFiltersProps, CourseGrid, Page, Text, View } from '@/components'
import { APIClient } from '@/services'
import { CourseUtils, UserLocationUtils, useFlatlistProps } from '@/utils'
import { useBreakpointMatch, useSearchParams } from '@codeleap/web'

export const CoursesPage = () => {
  const [params, setParams, reset] = useSearchParams()
  const { location: userLocation } = UserLocationUtils.useCurrentLocation()
  const location = params?.criteria === 'nearest' ? {
    lat: userLocation?.latitude,
    lng: userLocation?.longitude,
  } : {
    lat: null,
    lng: null,
  }

  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 3,
    desktopHuge: 4,
  })
  const { courses, totalCourses } = APIClient.Courses.useCourses({
    limit: numColumns * 4,
    lat: location?.lat,
    lng: location?.lng,
    ...CourseUtils.getFiltersByParams(params),
  })

  const listProps = useFlatlistProps(courses, {
    noMoreItemsText: '',
  })

  const courseFiltersProps: CourseFiltersProps = {
    params: params,
    setParams: setParams,
    results: listProps?.data?.length,
    isLoading: courses?.list?.query?.isLoading || courses?.list?.query?.isFetching || courses?.list?.query?.isRefetching,
    variants: ['noRightContent'],
  }

  return (
    <Page
      SEOProps={CourseUtils.getSEOAllCourses()}
      variants={['fullContentViewport']}
      contentProps={{ variants: ['paddingVertical:5'] as any }}
    >
      <View variants={['flex', 'column', 'fullHeight']}>
        <Text text='All runs' variants={['h1', 'marginBottom:5']} component='h1' />

        <Breadcrumbs variants={['marginBottom:2']} onNavigate={reset} disableFirstFakePadding />

        <AllCoursesFilters
          totalCourses={totalCourses}
          variants={['marginBottom:5']}
          courseFiltersProps={courseFiltersProps}
          preservedParams={['search', 'lat', 'lng']}
        />

        <CourseGrid
          debugName='Runs grid'
          courses={courses?.items}
          numColumns={numColumns}
          {...listProps}
        />
      </View>
    </Page>
  )
}

