import { variantProvider } from '@/app'
import { Link, Logo, View } from '@/components'
import { Course } from '@/types'
import { Navigation } from '@/utils'
import { getCoursePathname } from 'gatsby-utils'

export type CourseEmbedHeaderProps = {
  course: Course
}

export const CourseEmbedHeader = ({ course }: CourseEmbedHeaderProps) => {
  const coursePath = getCoursePathname(course)
  const courseLink = Navigation.appendPathToSiteURL(coursePath)
  const homeLink = Navigation.getRouteURL('Home')

  return (
    <View css={styles.wrapper}>
      <Logo
        breakpoint='tablet'
        debugName='CourseEmbedHeader:Logo' style={styles.logo}
        onPress={() => {
          window.open(homeLink)
        }} />

      <Link
        text={course?.title}
        variants={['color:primary3']}
        to={courseLink}
        target='_blank'
      />
    </View>)
}

export const COURSE_EMBED_HEADER_HEIGHT = 77
export const COURSE_EMBED_MOBILE_HEADER_HEIGHT = 40
const LOGO_WIDTH = 155
const MOBILE_LOGO_WIDTH = 24

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    height: COURSE_EMBED_HEADER_HEIGHT,
    backgroundColor: theme.colors.neutral1,
    ...theme.presets.justifySpaceBetween,
    ...theme.presets.fullWidth,
    ...theme.presets.alignCenter,
    ...theme.spacing.paddingHorizontal(4),

    [theme.media.down('tablet')]: {
      height: COURSE_EMBED_MOBILE_HEADER_HEIGHT,
      ...theme.spacing.paddingHorizontal(2),
    },
  },
  logo: {
    width: LOGO_WIDTH,

    [theme.media.down('tablet')]: {
      width: MOBILE_LOGO_WIDTH,
    },
  },
}), true)
