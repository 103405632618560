import { variantProvider } from '@/app'
import { ActionIcon, Touchable, View } from '@/components'
import { FEATURE_SECTION_IMAGE_WIDTH } from '@/utils'

export const DotsWrapper = ({ pages, page, onPress }) => {

  const onNext = (to: number) => {
    if (to > (pages.length - 1)) {
      onPress(0)
    } else {
      onPress(to)
    }
  }

  return (
    <View
      variants={['row', 'gap:2', 'center']}
      style={styles.controls}
    >
      <View
        style={styles.dotsWrapper}
      >
        {pages.map((_, index) => {
          const isSelected = index === page
          const css = [
            styles[isSelected ? 'dot:selected' : 'dot'],
          ]

          return (
            <Touchable
              debugName='Feature dot'
              key={index}
              onPress={() => onPress?.(index)}
              css={css}
            />
          )
        })}
      </View>

      <ActionIcon
        debugName='Feature next slide'
        icon='arrow-right'
        variants={['large', 'featuresSection']}
        onPress={() => onNext(page + 1)}
        debounce={600}
      />
    </View>
  )
}

const DOT_SIZE = 8

const styles = variantProvider.createComponentStyle(theme => ({
  controls: {
    width: `calc(100% - ${theme.spacing.value(10) + FEATURE_SECTION_IMAGE_WIDTH + theme.spacing.value(7.5)}px)`,
    ...theme.presets.absolute,
    right: theme.spacing.value(5),
    bottom: theme.spacing.value(5),
    zIndex: 1,
  },
  dotsWrapper: {
    ...theme.presets.row,
    ...theme.presets.fullWidth,
    gap: theme.spacing.value(1),
    ...theme.presets.justifyCenter,
    ...theme.spacing.paddingVertical(2),
  },
  dot: {
    height: DOT_SIZE,
    width: DOT_SIZE,
    cursor: 'pointer',
    borderRadius: theme.borderRadius.rounded,
    backgroundColor: theme.colors.neutral6,
  },
  'dot:selected': {
    height: DOT_SIZE,
    width: DOT_SIZE,
    transform: 'scale(1.5)',
    backgroundColor: theme.colors.neutral1,
    cursor: 'pointer',
    borderRadius: theme.borderRadius.rounded,
  },
}), true)
