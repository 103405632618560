import { React, Theme } from '@/app'
import { ActionIcon } from '@/components'
import { PropsOf, useBooleanToggle } from '@codeleap/common'
import { MapInformationModal } from '../Courses/ExploreMap/components'

export type MapInformationButtonProps = Partial<PropsOf<typeof ActionIcon>> & {}

export const MapInformationButton = ({ ...rest }: MapInformationButtonProps) => {
  const [informationModal, toggleInformationModal] = useBooleanToggle(false)

  return (
    <>
      <MapInformationModal
        visible={informationModal}
        toggle={toggleInformationModal}
      />
      <ActionIcon
        debugName='MapInformationButton'
        icon={'info'}
        variants={['elevated', 'size:5', 'primary3']}
        onPress={toggleInformationModal}
        style={{ zIndex: Theme.zIndexes.focusButton }}
        {...rest} />
    </>
  )
}
