import { Theme } from '@/app'
import { ActivityIndicator, View } from '@/components'
import {
  ComponentVariants,
  PropsOf,
  StylesOf,
  TypeGuards,
  getNestedStylesByKey,
  onUpdate,
  useCallback,
  useDefaultComponentStyle,
  useRef,
  useState,
} from '@codeleap/common'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel'
import { Dot, useDot } from './Dot'
import { EmblaCarouselComposition, EmblaCarouselStyles } from '@/app/stylesheets/EmblaCarousel'
import { Arrow, usePrevNextButtons } from './Arrow'
import { EngineType } from 'embla-carousel/components/Engine'

export type EmblaCustomOptionsType = EmblaOptionsType & {
  slidesToShow?: number
  dots?: boolean
  arrows?: boolean
  fakeFirstPadding?: boolean
  gap?: number
  fixedWidth?: number
  onEndReached?: () => void
  hasNextPage?: boolean
  enablePagination?: boolean
}

type EmblaCarouselProps = Omit<PropsOf<typeof View>, 'variants' | 'styles'> & {
  styles?: StylesOf<EmblaCarouselComposition>
  items: any[]
  options?: EmblaCustomOptionsType
  renderItem: ({ item, isFirst, isLast, index }) => React.ReactElement
} & ComponentVariants<typeof EmblaCarouselStyles>

const defaultOptions: Partial<EmblaCustomOptionsType> = {
  slidesToShow: 1,
  dots: false,
  arrows: false,
  fakeFirstPadding: false,
  gap: 0,
  onEndReached: null,
  enablePagination: false,
  slidesToScroll: 1,
}

export const EmblaCarousel = (props: EmblaCarouselProps) => {
  const {
    variants = [],
    styles = {},
    responsiveVariants,
    items: propSlides,
    options: _options,
    renderItem: RenderItem,
    ...rest
  } = props

  const allOptions = {
    ...defaultOptions,
    ..._options,
  }

  const variantStyles = useDefaultComponentStyle<
    'u:EmblaCarousel',
    typeof EmblaCarouselStyles
  >('u:EmblaCarousel', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = useCallback((key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  }), [variantStyles])

  const {
    slidesToShow,
    dots,
    arrows,
    fakeFirstPadding,
    gap,
    fixedWidth,
    onEndReached,
    hasNextPage,
    enablePagination,
    ...options
  } = allOptions

  const scrollListenerRef = useRef<() => void>(() => undefined)
  const listenForScrollRef = useRef(true)
  const hasMoreToLoadRef = useRef(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...options,
    watchSlides: (emblaApi) => {
      const reloadEmbla = (): void => {
        const oldEngine = emblaApi.internalEngine()

        emblaApi.reInit()
        const newEngine = emblaApi.internalEngine()
        const copyEngineModules: (keyof EngineType)[] = [
          'location',
          'target',
          'scrollBody',
        ]
        copyEngineModules.forEach((engineModule) => {
          Object.assign(newEngine[engineModule], oldEngine[engineModule])
        })

        newEngine.translate.to(oldEngine.location.get())
        const { index } = newEngine.scrollTarget.byDistance(0, false)
        newEngine.index.set(index)
        newEngine.animation.start()

        setLoadingMore(false)
        listenForScrollRef.current = true
      }

      const reloadAfterPointerUp = (): void => {
        emblaApi.off('pointerUp', reloadAfterPointerUp)
        reloadEmbla()
      }

      const engine = emblaApi.internalEngine()

      if (hasMoreToLoadRef.current && engine.dragHandler.pointerDown()) {
        const boundsActive = engine.limit.reachedMax(engine.target.get())
        engine.scrollBounds.toggleActive(boundsActive)
        emblaApi.on('pointerUp', reloadAfterPointerUp)
      } else {
        reloadEmbla()
      }
    },
  })

  const {
    prevArrowDisabled,
    nextArrowDisabled,
    onPrevArrowPress,
    onNextArrowPress,
    prevArrowVisible,
    nextArrowVisible,
  } = usePrevNextButtons(emblaApi)

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDot(emblaApi)
  const slideFlexBasis = 100 / slidesToShow
  const isFixedWidth = TypeGuards.isNumber(fixedWidth)
  const slideFlex = isFixedWidth ? (fixedWidth + gap) : slideFlexBasis

  const onScroll = useCallback((emblaApi: EmblaCarouselType) => {
    if (!listenForScrollRef.current) return

    setLoadingMore((loadingMore) => {
      const lastSlide = emblaApi.slideNodes().length - 1
      const lastSlideInView = emblaApi.slidesInView().includes(lastSlide)
      const loadMore = !loadingMore && lastSlideInView

      if (loadMore) {
        listenForScrollRef.current = false
        if (!hasNextPage) {
          emblaApi.off('scroll', scrollListenerRef.current)
        }
        setTimeout(() => {
          onEndReached?.()
        }, 500)
      }

      return loadingMore || lastSlideInView
    })
  }, [])

  const addScrollListener = useCallback(
    (emblaApi: EmblaCarouselType) => {
      scrollListenerRef.current = () => onScroll(emblaApi)
      emblaApi.on('scroll', scrollListenerRef.current)
    },
    [onScroll],
  )

  const showLoader = enablePagination && hasNextPage && !TypeGuards.isNull(onEndReached)

  const RenderItemT = useCallback(({ item, isFirst, isLast, index }) => {
    return <RenderItem item={item} isFirst={isFirst} isLast={isLast} index={index} />
  }, [])

  onUpdate(() => {
    if (!emblaApi || !enablePagination) return
    addScrollListener(emblaApi)

    const onResize = () => emblaApi.reInit()
    window.addEventListener('resize', onResize)
    emblaApi.on('destroy', () => window.removeEventListener('resize', onResize))
  }, [emblaApi, addScrollListener])

  onUpdate(() => {
    hasMoreToLoadRef.current = hasNextPage
  }, [hasNextPage])

  return (
    <View
      css={getStyles('wrapper')}
      {...rest}
    >
      {arrows ? (
        <Arrow
          getStyles={getStyles}
          onPress={onPrevArrowPress}
          type='prev'
          disabled={prevArrowDisabled}
          visible={prevArrowVisible}
        />
      ) : null}

      <View className='embla' css={getStyles('innerWrapper')} ref={emblaRef}>
        <View className='embla__container' css={getStyles('container')} component='ol'>
          {propSlides?.map((item, index) => {
            const isFirst = index === 0
            const isLast = index === propSlides?.length - 1
            const _slideFlex = (isFirst && fakeFirstPadding && isFixedWidth) ? slideFlex + Theme.spacing.value(2) : slideFlex

            return (
              <View
                key={`carousel: ${index}`}
                className='embla__slide'
                css={{
                  ...getStyles('slide'),
                  ...{
                    flex: `0 0 ${_slideFlex}${isFixedWidth ? 'px' : '%'}`,
                    paddingRight: gap / 2,
                    paddingLeft: gap / 2,
                  },
                }}
                component='li'
              >
                {fakeFirstPadding && isFirst ? (
                  <View css={getStyles('fakePadding')} />
                ) : null}
                <RenderItemT index={index} item={item} isFirst={isFirst} isLast={isLast} />
              </View>
            )
          })}
          {showLoader && (
            <ActivityIndicator debugName='carousel loader' styles={getStyles('loader')} />
          )}
        </View>

        {dots ? (
          <View className='embla__dots' css={getStyles('dots')}>
            {scrollSnaps?.map((_, index) => {
              const isSelected = index === selectedIndex
              const style = [
                variantStyles[isSelected ? 'dot:selected' : 'dot'],
              ]

              return (
                <Dot
                  key={index}
                  onPress={() => onDotButtonClick(index)}
                  index={index}
                  style={style}
                />
              )
            })}
          </View>
        ) : null}
      </View>

      {arrows ? (
        <Arrow
          getStyles={getStyles}
          onPress={onNextArrowPress}
          type='next'
          disabled={nextArrowDisabled}
          visible={nextArrowVisible}
        />
      ) : null}
    </View>
  )
}
