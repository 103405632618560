import { NavigationCategory } from '@/types'

export const getCategoriesTree = (categories: NavigationCategory[], maxDepth = 1): NavigationCategory[] => {
  const topLevelCategories = categories?.filter(cat => cat?.level === 0)

  const buildTree = (category: NavigationCategory, currentDepth: number): NavigationCategory => {
    if (currentDepth >= maxDepth) {
      return category
    }

    const children = categories?.filter(cat => cat.parent?.id === category.id)
    return {
      ...category,
      children: children.map(child => buildTree(child, currentDepth + 1)),
    }
  }

  return topLevelCategories?.map(cat => buildTree(cat, 0))
}
