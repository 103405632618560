import { Area, AreaFilters } from "@/types";
import { navigate } from "./index";
import { AppStatus } from "@/redux";

export const navigateToAreas = (filters?: AreaFilters) => {
  navigate("Areas", {
    params: filters as any,
  });
};

export const navigateToDetails = (area: Area) => {
  AppStatus.set("loading");

  navigate("RunsIn", {
    route: area?.slug,
  });
};
