import { LocalStorage, React, variantProvider, OSAlert } from '@/app'
import { Modal, Text, View, Button, TextInput } from '@/components'
import { APIClient } from '@/services'
import { useAppStatus } from '@/utils'
import { AnyFunction, PropsOf, useI18N, useState } from '@codeleap/common'

type ReauthenticationModalProps = Pick<PropsOf<typeof Modal>, 'visible' | 'toggle'> & {
  onSubmit?: (password: string) => void
  onCancel?: AnyFunction
}

export const ReauthenticationModal = (props: ReauthenticationModalProps) => {
  const { onSubmit, onCancel } = props

  const [password, setPassword] = useState('')

  const appStatus = useAppStatus()
  const { reauthenticate } = APIClient.Session.useSession()
  const { t } = useI18N()

  const onContinue = async () => {
    try {
      await reauthenticate(password)
      props?.toggle()
      await onSubmit(password)
      LocalStorage.setItem('IN_REAUTHENTICATION', true)
    } catch (e) {
      props?.toggle()
      logger.error(e)
      OSAlert.info({
        title: 'Could not authenticate',
        body: 'Please check your credentials.',
        onDismiss: props?.toggle,
      })
    }
  }

  return (
    <Modal
      title={t('Reauthetication.title')}
      debugName={'Profile reauthentication modal'}
      showClose={false}
      closable={false}
      {...props}
      variants={['centered']}
      visible={props?.visible && !appStatus.showingFeedback}
      styles={{
        body: styles.body,
      }}
    >
      <Text 
        variants={['p1', 'textCenter']} 
        text={t('Reauthetication.body')} 
      />

      <TextInput
        value={password}
        onChangeText={setPassword}
        debugName={'Profile edit password input'}
        leftIcon={{ icon: 'key', debugName: 're-auth password icon' }}
        password
        visibilityToggle
        placeholder={t('Reauthetication.inputPlaceholder')}
        variants={['noError']}
      />

      <View variants={['row', 'gap:2', 'marginTop:0.5']}>
        <Button
          text={t('Reauthetication.buttonCancel')}
          debugName={'Cancel reauthentication'}
          onPress={onCancel}
          variants={['flex', 'outline']}
        />
        <Button
          text={t('Reauthetication.buttonSubmit')}
          debugName={'Reauthenticate user to update profile'}
          onPress={onContinue}
          variants={['flex']}
          disabled={!password.trim()}
        />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  body: {
    ...theme.spacing.gap(2)
  }
}), true)
