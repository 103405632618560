import { createSlice } from '@codeleap/common'

type HomeState = {
  loadedSections: string[]
}

const initialState: HomeState = {
  loadedSections: [],
}

export const homeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setLoadedSections(state, section: string) {
      if (!state?.loadedSections.includes(section)) {
        return {
          ...state,
          loadedSections: [...state.loadedSections, section],
        }
      }

      return state
    },
  },
  asyncReducers: {},
})
