import { variantProvider } from '../theme'

export type BreadcrumbsComposition =
  | 'wrapper'
  | 'innerWrapper'
  | 'icon'
  | 'label'
  | 'itemWrapper'

const createBreadcrumbsStyle = variantProvider.createVariantFactory<BreadcrumbsComposition>()


export const BreadcrumbsStyles = {
  default: createBreadcrumbsStyle((theme) => ({
    wrapper: {
      flexWrap: 'wrap',
      maxWidth: '100%',
      overflow: 'visible',
    },
    innerWrapper: {
      listStyleType: 'none',
      flexWrap: 'nowrap',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    label: {
      lineHeight: '20.83px',
      whiteSpace: 'nowrap',
      ...theme.spacing.marginBottom(1),
    },
    itemWrapper: {
      flexWrap: 'nowrap',
    }
  })),
}
