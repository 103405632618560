import { Area, AreaFilters, ShortCoords } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'web_area_page/'

export async function list(params?: Record<string, any>) {
  const response = await api.get<PaginationResponse<Area>>(BASE_URL, {
    params,
  })
  return response.data
}

export async function retrieve(areaId: Area['id'], params?: Record<string, any>) {
  const response = await api.get<Area>(`${BASE_URL}${areaId}/`, { params })
  return response.data
}

export const areaManager = new QueryManager<Area>({
  itemType: {} as Area,
  name: 'area',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
  retrieveItem: async (id: Area['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

export type UseAreasProps = Partial<AreaFilters> & Partial<ShortCoords> & {
  enabled?: boolean
}

export const useAreas = (props?: UseAreasProps) => {
  const { enabled = true, ...rest } = props

  const areas = areaManager.use({
    filter: rest,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled,
      },
    },
  })

  const listProps = useFlatlistProps(areas)

  return { areas, listProps }
}

export const useRetrieveArea = (areaId: Area['id']) => {
  const area = areaManager.useRetrieve({
    id: areaId,
    queryOptions: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  })
  return { area }
}
