import { AppImages, variantProvider } from '@/app'
import { MapText } from './MapText'
import { CSSProperties } from 'react'
import { MapView, MapViewProps } from './MapView'

export type MapNumberPinProps = Partial<MapViewProps> & {
  total: number
  size?: number
  style?: CSSProperties
}

export const MapNumberPin = ({ total = 0, size = 32, style, id, ...rest }: MapNumberPinProps) => {

  return (
    <MapView style={{ ...styles.wrapper, width: size, ...style }} id={id} {...rest}>
      <img src={AppImages.MapPinWrapper} style={{ width: size }} id={`${id}:Image`} />
      <MapText text={total} variants={['p2', 'neutral-1']} style={styles.total} id={`${id}:Text`} />
    </MapView>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.relative,
  },
  total: {
    ...theme.presets.absolute,
    top: '15%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}), true)
