import { LocalStorage, LocalStorageKeys, MyLocation } from '@/app'
import { UserLocationUtils } from './UserLocation'
import { toShortCoords } from './spatial'
import { SearchPrediction } from '@/types'

const getPlaceRowIconName = (place: SearchPrediction) => {
  const { type, recent, myLocation } = place
  if (myLocation) return 'navigation-outline'
  if (recent) return 'recent'
  if (type === 'course') return 'person-run'

  return 'gps-pin'
}

const saveSearch = (place: SearchPrediction) => {
  if (place?.id === MY_LOCATION_ID || place?.type !== 'place') return

  // @ts-ignore
  const recentSearches = LocalStorage.getItem(
    LocalStorageKeys.RECENT_SEARCH_PLACES as any
  ) as any[]
  const existingIndex = recentSearches?.findIndex(
    recentItem => recentItem.place_id === place.id
  )

  if (existingIndex !== -1) {
    recentSearches?.splice(existingIndex, 1)
  }
  const recentPlace = { ...place, recent: true }
  const updatedSearches = recentSearches
    ? [recentPlace, ...recentSearches]
    : [recentPlace]
  LocalStorage.setItem(
    LocalStorageKeys.RECENT_SEARCH_PLACES as any,
    updatedSearches
  )
}

const placeLocation = (placeDetails: any) => {
  const lng = placeDetails?.geometry?.location?.lng()
  const lat = placeDetails?.geometry?.location?.lat()

  return {
    lng,
    lat,
  }
}

const MY_LOCATION_ID = 'my-location'

const userLocationToPlace = () => {
  const userLocation = UserLocationUtils.getCurrentLocation()
  if (!userLocation) return null

  return {
    main_text: MyLocation.name,
    secondary_text: MyLocation.description,
    id: MY_LOCATION_ID,
    coords: toShortCoords(userLocation),
    type: 'place',
    myLocation: true,
  } as SearchPrediction
}

const getPlaceTitle = (place: SearchPrediction) => place?.main_text || ''

export const AutocompleteUtils = {
  getPlaceTitle,
  getPlaceRowIconName,
  saveSearch,
  userLocationToPlace,
  placeLocation,
}
