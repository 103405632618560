import { ViewComposition, ViewPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle(t => ({
    wrapper: {
      display: 'flex',
    },
  })),

  smallSeparator: createViewStyle(theme => ({
    wrapper: {
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.neutral5,
    },
  })),

  separator: createViewStyle(theme => ({
    wrapper: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down('tabletSmall')]: {
        height: 4,
      },
    },
  })),
  fullViewport: createViewStyle(theme => ({
    wrapper: {
      width: '100vw',
      height: '100svh',
    },
  })),
  'pointerEvents:none': createViewStyle(theme => ({
    wrapper: {
      pointerEvents: 'none',
    },
  })),
  'column-reverse': createViewStyle(theme => ({
    wrapper: {
      flexDirection: 'column-reverse',
    },
  })),
  borderBottom: createViewStyle(theme => ({
    wrapper: {
      borderBottomColor: theme.colors.neutral5,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  })),
  dot: createViewStyle(theme => {
    const size = theme.spacing.value(0.5)
    return {
      wrapper: {
        height: size,
        width: size,
        borderRadius: size / 2,
        backgroundColor: theme.colors.neutral9,
      },
    }
  }),
}
