import { AppImages, Theme, variantProvider } from '@/app'
import {
  View,
  Text,
  Image,
  Section,
  EmblaCarousel,
} from '@/components'
import { Home, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { Mocks, useIsMobile, useRefDimensions } from '@/utils'
import { onUpdate } from '@codeleap/common'
import { useRef } from 'react'

const ITEM_DIMENSIONS = {
  default: {
    width: 400,
    height: 300,
  },
  mobile: {
    width: 250,
    height: 200,
  },
}

const renderItem = (item, key, isMobile) => {

  const itemStyle = { ...styles.itemWrapper, ...(isMobile ? styles.itemMobile : styles.itemDefault) }

  return (
    <View
      key={key}
      variants={['fullWidth']}
    >
      <View
        style={itemStyle}
        variants={['alignEnd']}
      >
        <View style={styles.itemGradient} />
        <Image
          alt={`The latest from Skamper - Photo ${key + 1}`}
          source={item.file || AppImages.CoursePhotoExample}
          style={styles.image}
          objectFit='cover'
        />
        <Text
          text={item.user}
          variants={['p2', 'color:neutral1', 'medium']}
          responsiveVariants={{
            mobile: ['p4', 'color:neutral1', 'medium'],
          }}
          style={styles.text}
        />
      </View>
    </View>
  )
}

type LatestSectionProps = {
  sectionId?: string
  sectionBlocker?: string
}

export const LatestSection = (props: LatestSectionProps) => {
  const { sectionBlocker, sectionId } = props

  const loadedSections = useAppSelector(store => store.Home.loadedSections)
  const readyToFetch = !sectionBlocker || loadedSections.includes(sectionBlocker)

  const { items, isLoading: _isLoading, isFetched } = APIClient.LatestSectionItems.useLatestSectionItems({
    enabled: readyToFetch,
  })

  const isMobile = useIsMobile()

  const sliderRef = useRef(null)
  const { refWidth: sliderWidth } = useRefDimensions(sliderRef)

  const itemWidth = (isMobile ? ITEM_DIMENSIONS.mobile.width : ITEM_DIMENSIONS.default.width) + Theme.spacing.value(1)
  const slidesToShow = (sliderWidth - Theme.spacing.value(2)) / itemWidth

  const isLoading = _isLoading || !readyToFetch

  const handleCompleteInitialLoad = () => {
    Home.setLoadedSections(sectionId)
  }

  onUpdate(() => {
    if (isFetched) {
      handleCompleteInitialLoad()
    }
  }, [isFetched])

  return (
    <Section
      title='The latest from Skamper'
      variants={['column', 'marginTop:4']}
      description='The latest updates, community news and collaborations from Skamper'
      centerContent={!isMobile}
      headerStyle={isMobile ? styles.sectionHeader : null}
      isLoading={isLoading}
      isVisible={!!items?.items?.length}
    >
      <View variants={['fullWidth']} ref={sliderRef} />
      <EmblaCarousel
        items={items?.items || Mocks.latestPosts}
        renderItem={({ item, index }) => renderItem(item, index, isMobile)}
        options={{
          slidesToShow,
          dragFree: true,
          fakeFirstPadding: isMobile,
          gap: isMobile ? Theme.spacing.value(1) : Theme.spacing.value(2),
          fixedWidth: isMobile ? ITEM_DIMENSIONS.mobile.width : ITEM_DIMENSIONS.default.width,
        }}
      />
    </Section>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  itemWrapper: {
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.primary2,
    ...theme.presets.relative,
    overflow: 'hidden',
    ...theme.presets.relative,
  },
  image: {
    ...theme.presets.absolute,
    ...theme.presets.left,
    ...theme.presets.top,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
  },
  itemDefault: {
    height: ITEM_DIMENSIONS.default.height,
    width: ITEM_DIMENSIONS.default.width,
  },
  itemMobile: {
    height: ITEM_DIMENSIONS.mobile.height,
    minWidth: ITEM_DIMENSIONS.mobile.width,
  },
  text: {
    zIndex: 1,
  },
  sectionHeader: {
    ...theme.spacing.marginHorizontal(2),
  },
  itemGradient: {
    ...theme.presets.absolute,
    ...theme.presets.left,
    ...theme.presets.top,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    background: 'linear-gradient(to bottom, rgba(163, 151, 151, 0), rgba(0, 0, 0, 0.35))',
    zIndex: 1,
  },
}), true)
