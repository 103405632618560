import { createDefaultVariantFactory } from '@codeleap/common'

export type CheckpointComposition = 'wrapper' | 'circle' | 'text' | 'icon'

const createCheckpointStyle =
  createDefaultVariantFactory<CheckpointComposition>()

export const CheckpointStyles = {
  default: createCheckpointStyle((theme) => {
    const size = 24
    const backgroundColor = theme.colors.primary3
    const color = theme.colors.neutral1

    return {
      wrapper: {
        height: size,
        width: size,
        ...theme.presets.elevated,
        borderRadius: theme.borderRadius.rounded,
        backgroundColor: backgroundColor,

        /** As default we use Google Marker which is a Pin. This part of code is to center our marker */
        ...theme.presets.relative,
        bottom: theme.spacing.value(-1.5),
      },
      text: {
        fontSize: 14,
        color,
        ...theme.presets.mapCenter,
      },
      icon: {
        size: theme.values.iconSize[1],
        color,
      },
    }
  }),

  punched: createCheckpointStyle((theme) => {
    const backgroundColor = theme.colors.grey30

    return {
      wrapper: { borderColor: backgroundColor },
      circle: {
        backgroundColor,
      },
    }
  }),

  next: createCheckpointStyle((theme) => {
    const backgroundColor = theme.colors.primary

    return {
      circle: {
        backgroundColor,
      },
    }
  }),

  disabled: createCheckpointStyle(() => ({})),

  offset: createCheckpointStyle((theme) => {
    const backgroundColor = theme.colors.background
    const size = 24
    const borderWidth = 2.5

    return {
      wrapper: {
        height: size,
        width: size,
        backgroundColor,
        borderWidth,
        borderColor: theme.colors.primary,
        borderStyle: 'dotted',
      },
      circle: {
        height: size - borderWidth,
        width: size - borderWidth,
        backgroundColor: theme.colors.primary,
      },
    }
  }),
}
