import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type CourseRatingComposition =
  | 'wrapper'
  | 'topContent'
  | 'headline'
  | 'user'
  | 'date'
  | 'middleContent'
  | 'bottomContent'
  | 'feedback'

const createCourseRatingStyle = variantProvider.createVariantFactory<CourseRatingComposition>()

export const CourseRatingStyles = {
  default: createCourseRatingStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(1),
    },
    topContent: {
      ...theme.spacing.gap(2),
    },
    headline: {
      ...theme.presets.column,
      ...theme.spacing.gap(0.5),
    },
    user: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: 'bold',
      color: theme.colors.neutral8,
    },
    date: {
      ...assignTextStyle('p3')(theme).text,
    },
    middleContent: {
      ...theme.spacing.gap(4),
      ...theme.presets.alignCenter,
    },
    feedback: {
      ...assignTextStyle('p3')(theme).text,
    },
  })),
}
