import { AppImages } from '@/app'
import { SEOProps } from '@/components'
import { Place, PlaceAdminType } from '@/types'
import { TypeGuards, capitalize } from '@codeleap/common'

const getCoverImage = (place: Place) => {
  return place?.files?.[0]?.file || AppImages.AreaExample
}

const getImageAlt = (place: Place, imageNumber = 1) => `${place?.name} - Photo ${imageNumber}`
const getSEOProps = (place: Place): Partial<SEOProps> => {
  const title = getTitle(place)
  return {
    title,
    description: place?.description,
    image: getCoverImage(place),
    appendNameToPageTitle: true,
  }
}

function getPlacePathname(place: Place) {
  if (TypeGuards.isNil(place)) return
  const {
    slug,
    country_slug,
    state_slug,
    county_slug,
    city_slug,
  } = place

  let path = `/runs/`;

  if (country_slug) {
    path += `${country_slug}/`;
  }

  if (state_slug) {
    path += `${state_slug}/`;
  }

  if (county_slug) {
    path += `${county_slug}/`;
  }

  if (city_slug) {
    path += `${city_slug}/`;
  }

  if (slug) {
    path += `${slug}/`;
  }

  return path;
}

function extractAdminTypeFromSlug(slug: string) {
  if (!slug) return
  const pattern = /--(country|state|county|city|neighbourhood)/
  const match = slug.match(pattern)
  return match ? match[1] as PlaceAdminType : null
}

function getTitle(place: Place) {
  const slugAdminType = extractAdminTypeFromSlug(place?.slug)
  return slugAdminType ? `Best runs in ${place?.name} ${capitalize(slugAdminType)}` : `Best runs in ${place?.name}`
}

export const PlaceUtils = {
  getSEOProps,
  getImageAlt,
  getCoverImage,
  getPlacePathname,
  extractAdminTypeFromSlug,
  getTitle,
}
