import { Theme, variantProvider } from '@/app'
import { View, Text, Button, ActivityIndicator, BottomDrawerContentProps } from '@/components'
import { APIClient } from '@/services'
import { CourseUtils } from '@/utils'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { Slider } from '@codeleap/web'
import { useState } from 'react'

export const ExploreMobileFilters = (props: BottomDrawerContentProps) => {
  const { params, setParams, onApply, onClear, isOpen } = props

  const filterOptions = CourseUtils.getExploreFilterOptions(params)

  const { tags: data, listProps } = APIClient.Tags.useTags({})

  const tags = data?.items

  const isLoading = listProps.isLoading

  const TopTags = [
    { label: 'Voice Directions', debugName: 'FilterTags:VoiceDirections', icon: 'sound' },
  ]

  const BottomTags = tags?.map(tag => ({
    id: tag.id,
    label: tag.title,
    debugName: `FilterTags:${tag.title}`,
  }))

  const [sliderValues, setSliderValues] = useState(() => filterOptions.reduce((acc, option) => {
    const min = params?.[`min_${option.key}`]
    const max = params?.[`max_${option.key}`]
    acc[option.key] = [min || null, max || null]
    return acc
  }, {}))

  const [initialSliderValues] = useState(sliderValues)

  const initialTags = params.tags ? new Set(params.tags.split(',')) : new Set()
  const initialTopTags = params.voice_directions === 'true' ? new Set(['Voice Directions']) : new Set()

  const [selectedTags, setSelectedTags] = useState(initialTags)
  const [selectedTopTags, setSelectedTopTags] = useState(initialTopTags)
  const [hasChanges, setHasChanges] = useState(false)

  const handleClear = () => {
    const resetSliderValues = filterOptions.reduce((acc, option) => {
      acc[option.key] = [null, null]
      return acc
    }, {})

    setSliderValues(resetSliderValues)
    setSelectedTags(new Set())
    setSelectedTopTags(new Set())
    setParams({})
    setHasChanges(false)
    if (onClear) onClear()
  }

  onUpdate(() => {
    if (!isOpen && hasChanges) {
      setSliderValues(initialSliderValues)
      setSelectedTags(initialTags)
      setSelectedTopTags(initialTopTags)
      setHasChanges(false)
    }
  }, [isOpen, hasChanges, initialSliderValues, initialTags, initialTopTags])

  const handleToggleTag = (tagLabel: string) => {
    setHasChanges(true)
    setSelectedTags(prev => {
      const newSet = new Set(prev)
      if (newSet.has(tagLabel)) {
        newSet.delete(tagLabel)
      } else {
        newSet.add(tagLabel)
      }
      return newSet
    })
  }

  const handleToggleTopTag = (tagLabel: string) => {
    setHasChanges(true)
    setSelectedTopTags(prev => {
      const newSet = new Set(prev)
      if (newSet.has(tagLabel)) {
        newSet.delete(tagLabel)
      } else {
        newSet.add(tagLabel)
      }
      return newSet
    })
  }

  const handleSliderChange = (key: string, value: [number | null, number | null]) => {
    setHasChanges(true)
    setSliderValues(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const TagsHeader = () => {
    const tagCount = Array.from(selectedTags).length

    if (tags?.length === 0 || !tags || isLoading) return null

    return (
      <View variants={['justifySpaceBetween', 'marginBottom:2']}>
        <Text text={'Tags'} />
        <View variants={['border-radius:rounded', 'bg:primary3', 'center']} style={{ width: 16, height: 16 }}>
          <Text text={String(tagCount)} variants={['p4', 'neutral-1']} />
        </View>
      </View>
    )
  }

  const applyFilters = () => CourseUtils.applyMobileFilters({
    sliderValues,
    setParams,
    setHasChanges,
    onApply,
    additionalParams: {
      tags: Array.from(selectedTags).join(','),
      voice_directions: selectedTopTags.has('Voice Directions') ? 'true' : 'false'
    },
  })

  const BottomTagsComponent = () => {
    if (!BottomTags && !isLoading) return null

    if (isLoading) {
      return (
        <View variants={['flex', 'alignCenter', 'justifyCenter']}>
          <ActivityIndicator debugName={'Filter bottomTags Loading'} variants={['center']} />
        </View>
      )
    }

    return (
      <View variants={['row', 'gap:1', 'wrap']}>
        {BottomTags?.map((tag) => (
          <Button
            key={tag.id}
            onPress={() => handleToggleTag(tag.label)}
            text={tag.label}
            variants={['fitlerButton', selectedTags.has(tag.label) ? 'primary6' : '']}
            debugName={tag.debugName}
          />
        ))}
      </View>
    )
  }

  return (
    <View variants={['column', 'flex', 'marginBottom:12']}>
      <View variants={['gap:1', 'marginBottom:2']}>
        {TopTags.map((tag) => (
          <Button
            key={tag.label}
            variants={['fitlerButton', selectedTopTags.has(tag.label) ? 'primary6' : '']}
            text={tag.label}
            rightIcon={tag.icon}
            debugName={tag.debugName}
            styles={{ rightIcon: { color: selectedTopTags.has(tag.label) ? Theme.colors.light.primary3 : Theme.colors.light.neutral9 } }}
            onPress={() => handleToggleTopTag(tag.label)}
          />
        ))}
      </View>
      {filterOptions.map((filter) => {

        const min = filter?.min
        const max = filter?.max
        const defaultValue = filter?.defaultValue
        const suffix = filter?.suffix

        const _value = sliderValues[filter.key] || defaultValue

        const minValue = TypeGuards.isNil(_value[0]) ? min : _value[0]
        const maxValue = TypeGuards.isNil(_value[1]) ? max : _value[1]

        const minTrack = `${minValue}${suffix ? suffix : ''}`
        const maxTrack = `${maxValue}${suffix ? suffix : ''}`

        return (
          <Slider
            {...filter}
            debugName={`filter-${filter.key}`}
            key={filter.key}
            minStepsBetweenThumbs={0}
            value={[minValue, maxValue]}
            onValueChange={(value) => handleSliderChange(filter.key, value)}
            trackMarks={{
              [filter.min]: minTrack,
              [filter.max]: maxTrack,
            }}
          />
        )
      })}
      <View variants={['column', 'marginBottom:2']}>
        <TagsHeader />
        <BottomTagsComponent />
      </View>
      <View variants={['gap:1', 'fixed', 'padding:2', 'fullWidth', 'center', 'bg:neutral1']} style={styles.buttonsWrapper}>
        <Button
          variants={['flex', 'primary6', 'border-radius-smallish']}
          debugName={'Clear Filters Explore'}
          onPress={() => handleClear()}
          text={'Clear'}
        />
        <Button
          variants={['flex', 'border-radius-smallish']}
          debugName={'Apply Filters Explore'}
          onPress={applyFilters}
          text={'Apply'}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  buttonsWrapper: {
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndexes.activityIndicator + 1,
  },
}), true)
