import { AppImages, Theme, variantProvider } from '@/app'
import {
  CenterWrapper,
  Text,
  Image,
  View,
  Icon,
} from '@/components'
import { getImageAlt, useIsMobile } from '@/utils'

export const TreeSection = () => {

  const isMobile = useIsMobile()

  return (
    <CenterWrapper
      variants={['marginVertical:4']}
    >
      <View
        variants={['padding:5', 'column', 'gap:1']}
        responsiveVariants={{
          mobile: ['padding:2', 'column', 'gap:1'],
        }}
        style={styles.innerWrapper}
      >
        <Image
          alt={getImageAlt('Trees for runs')}
          source={AppImages.Trees}
          objectFit='cover'
          style={styles.image}
        />

        <View
          variants={['center', 'column', 'gap:1']}
          responsiveVariants={{
            mobile: ['column', 'gap:1', 'alignStart', 'marginBottom:auto'],
          }}
          style={styles.content}
        >
          <View
            variants={['row', 'gap:1']}
            responsiveVariants={{
              mobile: ['column', 'gap:0'],
            }}
          >
            <Text
              text={'Trees'}
              variants={['h0', 'textCenter', 'color:neutral1']}
              responsiveVariants={{
                mobile: ['h1', 'color:neutral1', 'textLeft'],
              }}
            />
            <Text
              text={'for runs'}
              variants={['h0', 'textCenter', 'color:neutral1']}
              responsiveVariants={{
                mobile: ['h1', 'color:neutral1', 'textLeft'],
              }}
            />
          </View>
          <Text
            text={"That's what painting is all about. It should make you feel good when you paint. It looks so good, I might as well not stop. These trees are so much fun. I get started on them and I have a hard time stopping."}
            variants={['h5', 'textCenter', 'bold', 'color:neutral1']}
            responsiveVariants={{
              mobile: ['p2', 'color:neutral1', 'bold', 'textLeft'],
            }}
          />

        </View>

        <Icon
          debugName='Trees Section Icon'
          name='thunder'
          size={isMobile ? Theme.spacing.value(4) : Theme.spacing.value(6)}
          style={styles.icon}
        />
      </View>
    </CenterWrapper>
  )
}

const SECTION_HEIGHT = 400
const CONTENT_WIDTH = 600

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    overflow: 'hidden',
    ...theme.presets.relative,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    ...theme.presets.center,
    height: SECTION_HEIGHT,
    borderRadius: theme.borderRadius.medium,

    [theme.media.down('mobile')]: {
      height: 328,
      ...theme.spacing.padding(2),
    },
  },
  content: {
    zIndex: 1,
    ...theme.presets.fullWidth,
    maxWidth: CONTENT_WIDTH,
    ...theme.presets.alignSelfCenter,
  },
  image: {
    ...theme.presets.fullWidth,
    ...theme.presets.absolute,
    ...theme.presets.top,
    ...theme.presets.fullHeight,
  },
  icon: {
    ...theme.presets.absolute,
    bottom: theme.spacing.value(5),
    right: theme.spacing.value(5),
    zIndex: 1,
    color: theme.colors.neutral1,
    fill: theme.colors.neutral1,

    [theme.media.down('mobile')]: {
      bottom: theme.spacing.value(2),
      right: theme.spacing.value(2),
    },
  },
}), true)
