import {
  ActionIcon,
  CourseList,
  CourseSimpleDetails,
  CourseListProps,
  PlacesAutoComplete,
  PlacesAutoCompleteProps,
  View,
  Text,
} from '@/components'
import { ElementIDs, variantProvider } from '@/app'
import { useAnimatedStyle } from '@codeleap/web'
import { Course } from '@/types'
import { PropsOf, deepMerge, onUpdate } from '@codeleap/common'
import { ExploreMapRedux, useAppSelector } from '@/redux'
import { scrollToTop } from '@/utils'

export type ExploreSideBarProps = Partial<PropsOf<typeof View>> & {
  courses?: Course[]
  placesAutoCompleteProps?: PlacesAutoCompleteProps
  listProps?: Partial<Omit<CourseListProps, 'debugName'>>
  onBack?: () => void
}

const SIDE_BAR_WIDTH = 400

export const ExploreSideBar = ({
  courses = [],
  placesAutoCompleteProps,
  listProps,
  css,
  onBack,
  ...rest
}: ExploreSideBarProps) => {
  const sidebarVisible = useAppSelector(state => state.ExploreMapRedux.sidebarVisible)
  const sidebarCourse = useAppSelector(state => state.ExploreMapRedux.sidebarCourse)

  onUpdate(() => {
    if (!!sidebarCourse) {
      scrollToTop(document.getElementById(ElementIDs.exploreCourseList), 500)
      ExploreMapRedux.toggleSidebar(true)
    }
  }, [sidebarCourse])

  const animation = useAnimatedStyle(() => ({
    left: sidebarVisible ? 0 : -SIDE_BAR_WIDTH,
    width: sidebarVisible ? SIDE_BAR_WIDTH : 0,
    transition: {
      duration: 0.15,
    },
  }), [sidebarVisible])

  const HeaderComponent = () => {
    return (
      <View variants={['fullWidth', 'column']}>
        {!!sidebarCourse && <CourseSimpleDetails course={sidebarCourse} />}
        <Text
          text={!!sidebarCourse ? 'More like this' : 'Best runs nearby'}
          variants={['h3', 'neutral-9', 'marginBottom:3', 'paddingHorizontal:2']}
          component='h1'
        />
      </View>
    )
  }

  return (
    <View
      {...rest}
      css={deepMerge(css, styles.wrapper)}
      animated
      animatedProps={animation}
    >
      {!!placesAutoCompleteProps && (
        <View variants={['gap:1', 'paddingHorizontal:2', 'fullWidth', 'marginBottom:auto']}>
          {!!sidebarCourse && (
            <ActionIcon
              debugName='ExploreSideBar:Back'
              variants={['minimal', 'neutral10', 'marginTop:1', 'marginRight:1']}
              icon='arrow-left'
              onPress={onBack}
            />)
          }

          <PlacesAutoComplete
            {...placesAutoCompleteProps}
            variants={['small']}
          />
        </View>
      )}

      <CourseList
        ListHeaderComponent={HeaderComponent}
        {...listProps}
        style={{ width: SIDE_BAR_WIDTH }}
        as='scroll'
        courses={courses}
        variants={['marginTop:4']}
      />

      <View
        css={styles.icon}
        style={{
          left: SIDE_BAR_WIDTH,
        }}
      >
        <ActionIcon
          debugName='ExploreSideBar:Toggle'
          icon={sidebarVisible ? 'chevron-left' : 'chevron-right'}
          css={styles.toggleIcon}
          onPress={ExploreMapRedux.toggleSidebar} />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.relative,
    ...theme.presets.column,
    ...theme.presets.justifyCenter,
    ...theme.presets.elevated,
    ...theme.presets.flex,
    paddingTop: theme.spacing.value(2),
    zIndex: 1,
  },
  icon: {
    ...theme.presets.absolute,
    top: '45%',
    zIndex: 1,
  },
  toggleIcon: {
    height: theme.spacing.value(5),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ...theme.spacing.paddingVertical(1.25),
    ...theme.spacing.paddingLeft(0.5),
    ...theme.spacing.paddingRight(1),
  },
}), true)
