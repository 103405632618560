import { themeBreakpoints, variantProvider } from '@/app'
import {
  Button,
  CourseCardProps,
  CourseCarousel,
  Section,
  SectionHeaderProps,
} from '@/components'
import { Home, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { CourseFilters } from '@/types'
import { Navigation, useIsMobile } from '@/utils'
import { PropsOf, onUpdate } from '@codeleap/common'

export type CourseSectionProps = {
  slidesToShow?: number
  sectionFilters: CourseFilters & { enabled?: boolean }
  defaultCardWidth: number
  smallCardWidth: number
  title: SectionHeaderProps['title']
  courseCardProps?: Partial<CourseCardProps>
  buttonProps?: PropsOf<typeof Button>
  showButton?: boolean
  isLoading?: boolean
  readyToFetch?: boolean
  sectionId?: string
  sectionBlocker?: string
}

export const CourseSection = (props: CourseSectionProps) => {
  const {
    slidesToShow,
    sectionFilters = {},
    defaultCardWidth,
    smallCardWidth,
    title,
    courseCardProps,
    buttonProps = {},
    showButton = true,
    isLoading: _isLoading = false,
    sectionId,
    sectionBlocker = null,
  } = props

  const loadedSections = useAppSelector(store => store.Home.loadedSections)

  const isMobile = useIsMobile()

  const limit = isMobile ? (
    themeBreakpoints.mobile / smallCardWidth
  ) : (
    slidesToShow + 1
  )

  const readyToFetch = !sectionBlocker || loadedSections.includes(sectionBlocker)

  const { courses } = APIClient.Courses.useCourses({
    ...sectionFilters,
    // If is lacking courses in your area, uncomment this 2 lines
    // lat: MapDefaults.center.latitude,
    // lng: MapDefaults.center.longitude,
    limit,
    enabled: (sectionFilters?.enabled ?? true) && readyToFetch,
  })

  const isFetched = courses?.list?.query?.isFetched

  const isLoading = courses?.list?.query?.isLoading || _isLoading || !readyToFetch
  const isSectionVisible = courses?.items?.length > 0

  const handleCompleteInitialLoad = () => {
    Home.setLoadedSections(sectionId)
  }

  onUpdate(() => {
    if (isFetched) {
      handleCompleteInitialLoad()
    }
  }, [isFetched])

  return (
    <Section
      title={title}
      variants={['column', 'marginTop:4']}
      centerContent={!isMobile}
      headerStyle={isMobile ? styles.sectionHeader : null}
      isLoading={isLoading}
      isVisible={isSectionVisible}
      buttonProps={{
        debugName: 'View all button',
        text: 'View all',
        onPress: () => Navigation.Course.navigateToExplore(sectionFilters),
        ...buttonProps,
      }}
      showButton={showButton}
    >
      <CourseCarousel
        courses={courses?.items}
        defaultCardWidth={defaultCardWidth}
        smallCardWidth={smallCardWidth}
        courseCardProps={courseCardProps}
        slidesToShow={slidesToShow}
        carouselOptions={{
          dragFree: true,
          arrows: !isMobile,
          enablePagination: true,
          onEndReached: courses.getNextPage,
          hasNextPage: courses?.list?.query?.hasNextPage,
        }}
      />
    </Section>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  sectionHeader: {
    ...theme.spacing.marginHorizontal(2),
  },
  slider: {
    ...theme.spacing.paddingLeft(2),
  },
}), true)
