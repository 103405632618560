import { variantProvider } from '@/app'
import { HTMLAttributes } from 'react'
import { PropsOf } from '@codeleap/common'
import { Button } from '@/components'
import { MapText } from './MapText'
import { MapButton } from './MapButton'
import { MapIcon } from './MapIcon'

export type MapTooltipProps = {
  title: string
  description?: string
  buttonProps?: PropsOf<typeof Button>
  debugName?: string
} & HTMLAttributes<HTMLDivElement>

export const MapTooltip = ({ id, title, description, buttonProps, ...rest }: MapTooltipProps) => {
  return (
    <div id={id} style={styles.wrapper} {...rest}>
      <div style={styles.header}>
        <MapText text={title} style={styles.title} />
        <div style={styles.iconWrapper} id={`${id}:close`}>
          <MapIcon className={`icon-small`} name='x' />
        </div>
      </div>

      {!!description && <MapText text={description}/>}

      {!! buttonProps && <MapButton {...buttonProps} id={`${id}:button`} />}

      <div style={styles.tail} />
    </div>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    boxShadow: '0px 0px 16px 16px #00000020',
    position: 'relative',
    backgroundColor: theme.colors.background,
    padding: theme.spacing.value(1),
    borderRadius: theme.borderRadius.small,
    minWidth: 180,
  },
  header: {
    ...theme.presets.alignCenter,
    ...theme.presets.justifySpaceBetween,
    display: 'flex',
  },
  title: {
    marginBottom: theme.spacing.value(1),
  },
  iconWrapper: {
    marginBottom: 'auto',
  },
  tail: {
    ...theme.presets.absolute,
    bottom: '-10px',
    left: '45%',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${theme.colors.background}`,
    rotate: '180deg',
  },
}), true)
