import React, { PropsWithChildren, useMemo } from 'react'
import { OptionsDropDownStyles } from '@/app/stylesheets'
import { Button, View, Touchable, Text, Tooltip, CenterWrapper, Link } from '@/components'
import { ComponentVariants, getNestedStylesByKey, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { Icon, IconProps, TooltipProps } from '@codeleap/web'
import { AppIcon } from '@/app'
import { OptionsDropDownComposition } from '@/app/stylesheets/OptionsDropdown'
import { RoutePath } from '@/utils'
import { NavigationCategory } from '@/types'

export type OptionsDropDownProps = PropsWithChildren & ComponentVariants<typeof OptionsDropDownStyles>
  & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants'>
  & {
    styles?: StylesOf<OptionsDropDownComposition>
    icon?: AppIcon
    iconProps?: Partial<IconProps>
    text?: string
    options: Array<{
      header?: string
      items: NavigationCategory[]
    }>
    optionPress?: (param: { [x: string]: any }) => void
    tooltipProps?: Partial<TooltipProps>
  }

export const OptionsDropDown = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  icon,
  children,
  text,
  iconProps,
  options,
  optionPress,
  tooltipProps,
  ...rest
}: OptionsDropDownProps) => {
  const variantStyles = useDefaultComponentStyle<'u:OptionsDropDown', typeof OptionsDropDownStyles>('u:OptionsDropDown', {
    variants,
    rootElement: 'outerWrapper',
    styles,
    responsiveVariants,
  })

  const getStyles = (key: OptionsDropDownComposition) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const renderOptions = useMemo(() => {
    return (
      <CenterWrapper innerProps={{ variants: ['gap:5'] }}>
        {options?.map((optionGroup, groupIndex) => (
          <View key={groupIndex} variants={['column']}>
            {optionGroup.header && (
              <Text
                text={optionGroup.header}
                style={getStyles('optionHeader')}
              />
            )}
            <View variants={['gap:2', 'column']}>
              {optionGroup.items.map((option, index) => (
                <Link
                  key={index}
                  about={option?.alt}
                  text={option?.title}
                  route={option?.link as RoutePath}
                  to={option?.link}
                  css={getStyles('optionText')}
                />
              ))}
            </View>
          </View>
        ))}
      </CenterWrapper>
    )
  }, [options])

  return (
    <View {...rest}>
      <Tooltip
        content={tooltipProps?.content ?? renderOptions}
        open={tooltipProps?.open}
        side='bottom'
        styles={getStyles('tooltip')}
        closeOnClickOutside={false}
        debugName={`Options DropDown`}
        openOnHover={false}
        arrowProps={{ style: getStyles('tooltipArrow') }}
        variants={['headerDropdown'] as any}
        {...tooltipProps}
      >
        <View>
          {children}
          <View style={variantStyles.textIconWrapper}>
            {icon ? (
              <Icon
                name={icon as any}
                debugName={`Options Drop Down Icon`}
                style={getStyles('icon')}
                {...iconProps}
              />
            ) : null}
            {text ? (
              <Text
                text={text}
                style={getStyles('text')}
              />
            ) : null}
          </View>
        </View>
      </Tooltip>
    </View>
  )
}
