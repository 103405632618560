import { convertParamsToRange } from '@/utils'
import { AnyFunction, PropsOf } from '@codeleap/common'
import { Slider } from '@codeleap/web'
import { Dispatch, SetStateAction } from 'react'

type CourseFiltersModalFilters = string

interface Params {
  [key: string]: any
}

export type CourseFilterOption = Pick<
  PropsOf<typeof Slider>,
  'trackMarks' | 'defaultValue' | 'min' | 'max'
> & {
  key: CourseFiltersModalFilters
  value: MaxMinFilter
  suffix?: string
  label: string
  unlimited?: boolean
}

export type MaxMinFilter = [number, number]

export const getExploreFilterOptions = params => {
  const filterOptions: CourseFilterOption[] = [
    {
      label: 'Paved',
      key: 'paved',
      value: convertParamsToRange(params, 'paved'),
      defaultValue: [0, 100],
      min: 0,
      max: 100,
      suffix: '%',
    },
    {
      label: 'Hilly',
      key: 'hilly',
      value: convertParamsToRange(params, 'hilly'),
      min: 0,
      max: 5,
      defaultValue: [0, 5],
    },
    {
      label: 'Elevation',
      key: 'elevation',
      value: convertParamsToRange(params, 'elevation'),
      min: 0,
      max: 100,
      defaultValue: [0, 100],
      suffix: 'm',
      unlimited: true,
    },
    {
      label: 'Course distance',
      key: 'distance',
      value: convertParamsToRange(params, 'distance'),
      min: 0,
      max: 50,
      defaultValue: [0, 50],
      suffix: 'km',
      unlimited: true,
    },
  ]

  return filterOptions
}

export type CustomCourseFilterOption = {
  key: CourseFiltersModalFilters
  customFilter: (props: CustomFilterProps) => JSX.Element
}

export type CustomFilterProps = {
  filterVisible: CourseFiltersModalFilters | null
  setFilterVisible: Dispatch<any>
  params: Record<string, string>
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

export const getAllRunsFilterOptions = params => {
  const filterOptions: (CourseFilterOption | CustomCourseFilterOption)[] = [
    {
      label: 'Paved',
      key: 'paved',
      value: convertParamsToRange(params, 'paved'),
      defaultValue: [0, 100],
      min: 0,
      max: 100,
      suffix: '%',
    },
    {
      label: 'Hilly',
      key: 'hilly',
      value: convertParamsToRange(params, 'hilly'),
      min: 0,
      max: 5,
      defaultValue: [0, 5],
    },
    {
      label: 'Ascent',
      key: 'ascent',
      value: convertParamsToRange(params, 'ascent'),
      min: 0,
      max: 300,
      defaultValue: [0, 300],
      suffix: 'm',
      unlimited: true,
    },
    {
      label: 'Course distance',
      key: 'distance',
      value: convertParamsToRange(params, 'distance'),
      min: 0,
      max: 50,
      defaultValue: [0, 50],
      suffix: 'km',
      unlimited: true,
    },
  ]

  return filterOptions
}

export const clearAllFilters = (
  filterKeys: CourseFiltersModalFilters[],
  setParams: React.Dispatch<React.SetStateAction<Params>>
) => {
  setParams(state => {
    filterKeys.forEach(key => {
      state[`min_${key}`] = null
      state[`max_${key}`] = null
    })
    return { ...state }
  })
}

export const clearFilters = (
  key: CourseFiltersModalFilters,
  setParams: React.Dispatch<React.SetStateAction<Params>>
) => {
  setParams(state => {
    state[`min_${key}`] = null
    state[`max_${key}`] = null
    return { ...state }
  })
}

export function applyMobileFilters({
  sliderValues,
  setHasChanges,
  setParams,
  additionalParams,
  onApply,
}: {
  sliderValues: Record<string, string | number>
  setHasChanges: (value: SetStateAction<boolean>) => void
  setParams: (value: SetStateAction<Record<string, string>>) => void
  additionalParams?: Record<string, any>
  onApply?: AnyFunction
}) {
  const newParams = Object.entries(sliderValues).reduce((acc, [key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        const [min, max] = value
        if (min !== null) acc[`min_${key}`] = min
        if (max !== null) acc[`max_${key}`] = max
      }
    }
    return acc
  }, {} as Record<string, string>)

  Object.entries(additionalParams)?.forEach(([key, value]) => {
    newParams[key] = value as string
  })

  setParams(newParams)
  setHasChanges(false)
  if (onApply) onApply()
}
