import React from 'react'
import { RoutePath } from '@/utils'
import { Redirect } from './Navigation'
import { LoadingScreen } from './LoadingScreen'
import { UseQueryResult } from '@tanstack/react-query'

type ItemProps = {
  id: string | number
  [key: string]: any
}

export type DynamicPageHandlerProps = {
  item: ItemProps
  query?: UseQueryResult
  children: React.ReactNode
  // If is Server side rendering, use an different approach to handle the loading state
  isSSR?: boolean
  redirectTo?: RoutePath
}

export const DynamicPageHandler = (props?: DynamicPageHandlerProps) => {
  const { item, query, children, isSSR = false, redirectTo = '404' } = props
  const noItem = !item?.id

  if (isSSR && noItem) return <Redirect to={redirectTo} />

  if (noItem) {
    if (query?.isFetched || query?.isError) {
      return <Redirect to={redirectTo} />
    } else {
      // @ts-ignore
      return <LoadingScreen />
    }
  }

  return <>{children}</>
}

export default DynamicPageHandler
