import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'
import { ActionIconComposition } from '@codeleap/web'

export type CarouselComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | 'wrapper'
  | `arrow${Capitalize<ActionIconComposition>}`
  | 'footerWrapper'
  | 'dots'
  | 'dot'
  | 'dot:selected'

const createCarouselStyle = variantProvider.createVariantFactory<CarouselComposition>()

const DOT_SIZE = 8

export const CarouselStyles = {
  default: createCarouselStyle((theme) => ({
    wrapper: {
      display: 'block',
    },
    arrowIcon: {
      color: theme.colors.primary3,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    'arrowIcon:disabled': {
      color: theme.colors.neutral5,
    },
    arrowTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'arrowTouchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
    dots: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      gap: theme.spacing.value(1),
      ...theme.presets.justifyCenter,
      ...theme.spacing.paddingVertical(2),
    },
    dot: {
      height: DOT_SIZE,
      width: DOT_SIZE,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral3,
    },
    'dot:selected': {
      height: DOT_SIZE,
      width: DOT_SIZE,
      backgroundColor: theme.colors.primary3,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
    },
    'dot:disabled': {
      cursor: 'auto',
    },
  })),
}

