import { ListComposition } from '@codeleap/web'
import { Theme, variantProvider } from '../theme'

export type PaginationButtonsComposition =
  | 'wrapper'
  | 'itemWrapper'
  | 'itemWrapper:selected'
  | 'itemWrapper:hover'
  | 'itemWrapper:disabled'
  | 'text'
  | 'text:selected'
  | 'text:hover'
  | 'text:disabled'
  | `list${Capitalize<ListComposition>}`
  | `arrow`
  | `arrow:hover`
  | `arrow:selected`
  | `arrow:disabled`

const createPaginationButtonStyle = variantProvider.createVariantFactory<PaginationButtonsComposition>()

const ITEM_SIZE = Theme.values.itemHeight.small

export const AppPaginationButtonStyles = {
  default: createPaginationButtonStyle((theme) => ({
    listWrapper: {
      minHeight: 'auto',
    },
    wrapper: {
      ...theme.spacing.marginTop(5),
      [theme.media.down('laptop')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.center,
      },
    },
    itemWrapper: {
      width: ITEM_SIZE,
      height: ITEM_SIZE,
      backgroundColor: theme.colors.primary1,
      borderRadius: theme.borderRadius.xTiny,
      '&:hover': {
        backgroundColor: theme.colors.primary3,
        color: theme.colors.neutral1,
      },
    },
    'itemWrapper:selected': {
      backgroundColor: theme.colors.primary3,
    },
    'itemWrapper:hover': {
      backgroundColor: theme.colors.primary3,
    },
    'itemWrapper:disabled': {
      backgroundColor: theme.colors.neutral2,
      cursor: 'not-allowed',
    },
    text: {
      color: theme.colors.primary3,
    },
    'text:hover': {
      color: theme.colors.neutral1,
    },
    'text:selected': {
      color: theme.colors.neutral1,
    },
    'text:disabled': {
      color: theme.colors.neutral3,
    },
    arrow: {
      color: theme.colors.primary3,
    },
    'arrow:hover': {
      color: theme.colors.neutral1,
    },
    'arrow:selected': {
      color: theme.colors.neutral1,
    },
    'arrow:disabled': {
      color: theme.colors.neutral5,
    },
  })),
}
