import { Tag } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'web_tag/'

export async function list(params?: Record<string, any>) {
  const response = await api.get<PaginationResponse<Tag>>(BASE_URL, {
    params,
  })
  return response.data
}

export const tagManager = new QueryManager<Tag>({
  itemType: {} as Tag,
  name: 'tag',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
})

export type UseTagsProps = {}

export const useTags = (props?: UseTagsProps) => {
  const { ...rest } = props

  const tags = tagManager.use({
    filter: rest,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    },
  })

  const listProps = useFlatlistProps(tags)

  return { tags, listProps }
}
