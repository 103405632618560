import { useMemo, useRef, useState } from 'react'
import { Theme, variantProvider } from '@/app'
import { debounce, Navigation } from '@/utils'
import { useAnimatedStyle } from '@codeleap/web'
import { ExploreMapCourseCard } from '../ExploreMapCourseCard'
import { GetAppButton, MapFocusButton, MapInformationButton, Scroll, Tag, View } from '@/components'
import { ExploreMapFooterProps } from './types'
import { useAppSelector } from '@/redux'
import { onUpdate } from '@codeleap/common'

const EXPLORE_CARD_HEIGHT = 105

const SCROLL_DEBOUNCE = 200

export const MobileExploreMapFooter = ({ selectedCourse, onFollowUser, onChangeCourse }: ExploreMapFooterProps) => {
  const selectedCluster = useAppSelector(state => state.ExploreMapRedux.selectedCluster)
  const [currentIndex, setCurrentIndex] = useState(0)

  const courses = useMemo(() => {
    if (!!selectedCluster) return selectedCluster?.courses
    if (!!selectedCourse) return [selectedCourse]
    return []
  }, [selectedCluster, selectedCourse])

  onUpdate(() => {
    if (!!selectedCourse) {

      const index = courses?.findIndex(course => course?.id === selectedCourse?.id)

      if (index !== - 1) { setCurrentIndex(index) }
    } else {
      setCurrentIndex(0)
    }
  }, [selectedCourse])

  const hasCourses = !!courses?.length
  const scrollContainerRef = useRef(null)

  const animation = useAnimatedStyle(() => ({
    bottom: hasCourses ? Theme.spacing.value(2) : -EXPLORE_CARD_HEIGHT,
    transition: {
      duration: 0.15,
    },
  }), [hasCourses])

  const handleScroll = debounce(() => {
    if (scrollContainerRef.current) {
      const scrollX = scrollContainerRef.current.scrollLeft
      const index = Math.round(scrollX / itemWidth)
      onChangeCourse?.(courses[index])
    }
  }, SCROLL_DEBOUNCE)

  const itemWidth = window.innerWidth * 0.85

  const totalCourses = courses?.length

  return (
    <View
      variants={[
        'fullWidth',
        'justifyCenter',
        'fullWidth',
        'fixed',
        'column',
      ]}
      animated
      animatedProps={animation}>

      <View variants={['fullWidth', 'justifySpaceBetween', 'padding:2']}>
        <View variants={['alignCenter']}>
          <GetAppButton
            style={styles.getAppButton}
            variants={['card', 'exploreAppButton']}
            icon='new-logo'
            text={`Get the app`}
            styles={{
              icon: { backgroundColor: 'transparent', }
            }}
          />
          {totalCourses > 1 && (
            <Tag
              variants={['card', 'marginLeft:1']}
              debugName='ExploreMap:Pagination'
              text={`${currentIndex + 1} of ${totalCourses} `}
            />
          )}
        </View>
        <View variants={['column', 'gap:1']}>
          <MapFocusButton
            onPress={onFollowUser}
          />
          <MapInformationButton />
        </View>
      </View>

      {hasCourses ? (
        <Scroll
          variants={['paddingLeft:2']}
          ref={scrollContainerRef}
          onScroll={handleScroll}>
          {courses.map((course, index) => {
            const isLast = index === courses?.length - 1
            const isFirst = index === 0

            return (
              <ExploreMapCourseCard
                course={course}
                style={styles.exploreCard}
                wrapperStyle={{
                  ...styles.exploreCardWrapper,
                  marginLeft: !isFirst ? Theme.spacing.value(1) : 0,
                  marginRight: isLast ? Theme.spacing.value(2) : 0,
                }}
                onPress={() => Navigation.Course.navigateToDetails(course)}
              />)
          })}
        </Scroll>
      ) :
        <View style={styles.exploreCardPlaceholder} />
      }
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  getAppButton: {
    maxWidth: 180,
    maxHeight: 40,
    marginTop: `0px !important`,

    [theme.media.down('tablet')]: {
      transform: 'unset',
      minWidth: 'unset',
    },
  },

  exploreCardWrapper: {
    margin: 'auto',
    width: '100%',
    minWidth: '85%',
  },

  exploreCard: {
    width: '100%',
  },

  exploreCardPlaceholder: {
    height: EXPLORE_CARD_HEIGHT,
    width: '100%',
  },
}), true)
