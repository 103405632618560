import { AppImages, variantProvider } from '@/app'
import {
  View,
  Text,
  Touchable,
  Image,
} from '@/components'
import { CourseCategory } from '@/types'
import { TouchableProps } from '@codeleap/web'

type CourseTypeItemProps = {
  item: CourseCategory
  onPress: TouchableProps['onPress']
}

export const CourseTypeItem = (props: CourseTypeItemProps) => {
  const {
    item,
    onPress,
  } = props

  return (
    <Touchable
      debugName='Course Type Item'
      onPress={onPress}
      style={styles.wrapper}
    >
      <View style={styles.gradient} />
      <Image
        source={item.image || AppImages.CoursePhotoExample}
        style={styles.image}
        objectFit='cover'
      />
      <Text
        text={item.label}
        variants={['bold', 'h2', 'color:neutral1']}
        responsiveVariants={{
          mobile: ['p2', 'bold', 'color:neutral1'],
        }}
        style={styles.text}
        component='h3'
      />
    </Touchable>
  )
}

const ITEM_HEIGHT = 165

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    borderRadius: theme.borderRadius.medium,
    height: ITEM_HEIGHT,
    ...theme.presets.relative,
    overflow: 'hidden',

    [theme.media.down('mobile')]: {
      height: theme.spacing.value(10),
    },
  },
  gradient: {
    ...theme.presets.absolute,
    ...theme.presets.left,
    ...theme.presets.top,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    backgroundColor: '#0000004D',
    zIndex: 1,
  },
  image: {
    ...theme.presets.absolute,
    ...theme.presets.left,
    ...theme.presets.top,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
  },
  text: {
    zIndex: 1,
  },
}), true)

