import { PlaceRowComposition, PlaceRowStyles } from '@/app/stylesheets'
import { View, Touchable, Icon, Text } from '@/components'
import { AutocompleteUtils, Units } from '@/utils'
import { SearchPrediction } from '@/types'
import {
  ComponentVariants,
  PropsOf,
  StylesOf,
  getNestedStylesByKey,
  useDefaultComponentStyle,
} from '@codeleap/common'

export type PlaceRowProps = {
  place?: SearchPrediction
  styles?: StylesOf<PlaceRowComposition>
  onPress?: (place: SearchPrediction, type: SearchPrediction['type']) => void
  noDescription?: boolean
} & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants' | 'debugName'>
  & ComponentVariants<typeof PlaceRowStyles>

export const PlaceRow = ({
  variants,
  styles,
  responsiveVariants,
  place,
  noDescription = false,
  onPress,
  ...rest }: PlaceRowProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:PlaceRow',
    typeof PlaceRowStyles
  >('u:PlaceRow', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key: string) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const isMyLocation = place?.myLocation

  const title = place?.main_text
  const iconName = AutocompleteUtils.getPlaceRowIconName(place)
  const description = place?.secondary_text
  const kmAway = place?.distance?.toFixed(1)
  const distance = place?.type === 'course' ? 'See run' : (!!kmAway ? `${kmAway} km away` : 'Distance not available')

  return (
    <Touchable
      debugName={`PlaceRow:${place?.id}`}
      variants={['justifyStart']}
      onPress={() => {
        onPress(place, place?.type)
      }}
      css={variantStyles.touchableWrapper}
      {...rest}
    >
      <Icon
        name={iconName}
        variants={['medium', isMyLocation && 'colorPrimary']}
        css={getStyles('icon')}
        debugName='PlaceRow:LeftIcon'
      />
      <View
        css={getStyles('contentWrapper')}
      >
        <Text
          text={title}
          css={getStyles('title')}
        />

        {(!noDescription && !isMyLocation) && (
          <View css={getStyles('infoWrapper')}>
            <Text text={distance} css={getStyles('distance')} />
            <Text text={'•'} css={getStyles('separator')} />
            <Text text={description} css={getStyles('description')} />
          </View>)
        }
      </View>

      {place?.type == 'course' && (
        <Icon
          name='diagonal-arrow-left'
          variants={['medium']}
          css={getStyles('rightIcon')}
          debugName='PlaceRow:RightIcon'
        />)}
    </Touchable>
  )
}
