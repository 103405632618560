import { useCallback, useRef } from 'react'
import '@/app/stylesheets/css/slick.css'
import '@/app/stylesheets/css/slick-theme.css'
import { useIsMobile, useRefDimensions } from '@/utils'
import { View, AreaCard, AreaCardProps, EmblaCarousel, EmblaCustomOptionsType } from '@/components'
import { Theme, variantProvider } from '@/app'
import { Area } from '@/types'
import { PropsOf } from '@codeleap/common'
import { AREA_CARD_CONSTANTS } from '@/app/stylesheets/AreaCard'

export type AreaCarouselProps = {
  areas: Area[]
  areaCardProps?: Partial<AreaCardProps>
  cardWrapperProps?: PropsOf<typeof View>
  itemWidth?: number
  roundSliders?: boolean
  smallCardWidth?: number
  desktopCardWidth?: number
  slidesToShow?: number
  responsiveWrapper?: boolean
  defaultCardWidth?: number
  carouselOptions?: EmblaCustomOptionsType
} & PropsOf<typeof View>

export const AreaCarousel = (props: AreaCarouselProps) => {
  const {
    areas,
    areaCardProps = {},
    cardWrapperProps = {},
    itemWidth: _itemWidth,
    slidesToShow: _slidesToShow,
    roundSliders = false,
    smallCardWidth = AREA_CARD_CONSTANTS.mobile.size,
    defaultCardWidth = AREA_CARD_CONSTANTS.default.size,
    style,
    carouselOptions = {},
    ...wrapperProps
  } = props

  const isMobile = useIsMobile()
  const sliderRef = useRef(null)
  const { refWidth: sliderWidth } = useRefDimensions(sliderRef)
  const cardWidth = (isMobile ? smallCardWidth : defaultCardWidth)

  const slidesToShow = _slidesToShow ?? (roundSliders ? Math.floor(sliderWidth / cardWidth) : (sliderWidth / cardWidth))

  const renderItem = useCallback((item) => {
    const {
      onPress,
      ...otherItemProps
    } = areaCardProps

    const handleItemPress = (item: Area) => {
      areaCardProps?.onPress?.(item)
    }

    return (
      <View
        variants={['fullWidth']}
        {...cardWrapperProps}
      >
        <AreaCard
          area={item}
          onPress={() => handleItemPress(item)}
          {...otherItemProps}
        />
      </View>
    )
  }, [_slidesToShow])

  return (
    <View style={{ ...style, ...styles.wrapper }} {...wrapperProps}>
      <View variants={['fullWidth']} ref={sliderRef} />
      <EmblaCarousel
        items={areas}
        renderItem={({ item }) => renderItem(item)}
        options={{
          slidesToShow,
          fakeFirstPadding: isMobile,
          gap: isMobile ? Theme.spacing.value(1) : Theme.spacing.value(2),
          fixedWidth: isMobile ? cardWidth : null,
          slidesToScroll: 'auto',
          ...carouselOptions,
        }}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.block,
    },
  }),
  true,
)
