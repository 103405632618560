import {
  AreaSection, AreaSectionProps,
} from '@/components'
import { AreaFilters } from '@/types'
import { Navigation, UserLocationUtils, useIsMobile } from '@/utils'

export const AreasSection = (props: Partial<AreaSectionProps>) => {

  const isMobile = useIsMobile()
  const { location } = UserLocationUtils.useCurrentLocation()
  const sectionFilters: AreaFilters = {
    lat: location?.latitude,
    lng: location?.longitude,
  }

  return (
    <AreaSection
      title="Britain's Best Runs by Area"
      sectionFilters={sectionFilters}
      areaCardProps={{
        variants: [isMobile ? 'small' : null],
        onPress: (area) => Navigation.Area.navigateToDetails(area),
      }}
      sectionProps={{
        variants: ['areas'],
      }}
      {...props}
    />
  )
}

