import { View, Grid, SeeMoreButton } from '@/components'
import { PropsOf } from '@codeleap/common'

export type SeeMoreGridProps = PropsOf<typeof Grid> & {
  wrapperProps?: PropsOf<typeof View>
}

export const SeeMoreGrid = ({ wrapperProps, fetchNextPage, ...rest }: SeeMoreGridProps) => {
  const hasNextPage = !!rest.hasNextPage && !rest?.isFetchingNextPage && !!rest.data?.length

  return (
    <View variants={['column']} {...wrapperProps}>
      <Grid {...rest} />

      {!!hasNextPage && (
        <SeeMoreButton
          onPress={fetchNextPage}
          debugName='SeeMoreGrid:Button'
          variants={['margin:auto', 'marginTop:3']}
        />)}
    </View>
  )
}
