import {
  View,
  CenterWrapper,
} from '@/components'
import { useFeaturesItems, useIsMobile, useMediaQueryDown } from '@/utils'
import { useState } from 'react'
import { LargeSlider } from './LargeSlider'
import { SmallSlider } from './SmallSlider'

export const FeaturesSection = () => {

  const isMobile = useIsMobile()
  const isMobileLarge = useMediaQueryDown('mobileLarge')
  const showRightContent = !isMobileLarge

  const [slide, setSlide] = useState(0)
  const { items: _items, largeItems } = useFeaturesItems()
  const items = showRightContent ? largeItems : _items

  const Wrapper = isMobile ? View : CenterWrapper

  const pages = [
    items.runs,
    items.free,
    items.stats,
    items.follow,
    items.alerts,
    items.friends,
    items.milestone,
    items.insights,
  ]

  return (
    <Wrapper
      variants={['marginTop:4']}
      component='section'
    >
      {showRightContent ? (
        <LargeSlider
          pages={pages}
          setSlide={setSlide}
          slide={slide}
        />
      ) : (
        <SmallSlider
          pages={pages}
          setSlide={setSlide}
          slide={slide}
        />
      )}
    </Wrapper>
  )
}

