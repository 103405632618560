import { AppImages } from "@/app"
import { Shoe } from "@/types"

const getShoeImage = (shoe: Shoe) => {
  let image = ''

  switch (shoe.id) {
    case 2:
      image = AppImages.ShoeRoad
      break
    case 3:
      image = AppImages.ShoeTrail
      break
    case 4:
      image = AppImages.ShoeMud
      break
    default:
      image = AppImages.ShoeRoad
  }

  return image
}

export const ShoesUtils = {
  getShoeImage,
}
