import { React, variantProvider } from '@/app'
import { View, Image, SpotlightComponentProps, SpotlightComponent } from '.'
import { PropsOf, TypeGuards, useState } from '@codeleap/common'
import { Pager } from '@codeleap/web'
import { Course } from '@/types'

type ImageProps = PropsOf<typeof Image>

export type ImageSliderProps = {
  images: ImageProps[]
  imageProps?: ImageProps
  showSpotlightComponent?: boolean
  spotlightComponentProps?: SpotlightComponentProps
  course?: Course
  pagerProps?: Partial<PropsOf<typeof Pager>>
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  renderItem?: (image: string | Object, index: number) => JSX.Element
}

export const ImageSlider = (props: ImageSliderProps) => {

  const {
    images,
    showSpotlightComponent = true,
    spotlightComponentProps = {},
    imageProps,
    pagerProps,
  } = props

  const [internalPage, setInternalPage] = useState(0)

  const page = props?.hasOwnProperty('page') ? props?.page : internalPage
  const setPage = (props?.hasOwnProperty('setPage') ? props?.setPage : setInternalPage) as React.Dispatch<React.SetStateAction<number>>

  const shouldShowPager = images?.length > 1

  const Wrapper = (shouldShowPager ? Pager : React.Fragment) as unknown as () => JSX.Element
  let wrapperProps = null

  if (shouldShowPager) {
    wrapperProps = {
      debugName: 'pager:image-slider',
      page,
      onChange: (page) => {
        setPage(page)
        spotlightComponentProps?.modalProps?.setPage?.(page)
      },
      dots: true,
      variants: ['imageSlider'],
      ...pagerProps,
    }
  }

  const renderImageItem = ({ image, index }) => {

    if (TypeGuards.isFunction(props?.renderItem)) {
      return props?.renderItem(image, index)
    }

    return (
      <Image
        css={styles.image}
        objectFit='cover'
        {...image}
        {...imageProps}
        key={`${image.source}: ${index}`}
      />
    )
  }

  return (
    <View style={styles.wrapper}>

      <Wrapper {...wrapperProps}>
        {images?.map?.((image, index) => renderImageItem({ image, index }))}
      </Wrapper>

      {!showSpotlightComponent ? null : (
        <SpotlightComponent
          actionIconProps={{ style: [styles.icon] }}
          {...spotlightComponentProps}
        />
      )}

    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    position: 'relative',
    ...theme.presets.fullWidth,
    ...theme.presets.fullHeight,
  },
  image: {
    width: '100%',
    ...theme.presets.fullHeight,
  },
  icon: {
    ...theme.presets.absolute,
    bottom: theme.spacing.value(2),
    right: theme.spacing.value(2),
  },
}), true)
