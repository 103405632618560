import { useBreakpointMatch } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  CourseSection, CourseSectionProps,
} from '@/components'
import { useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { AreaFilters, CourseFilters } from '@/types'
import { Navigation, UserLocationUtils, useIsMobile } from '@/utils'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { useState } from 'react'

type CustomAreaSectionProps = Partial<CourseSectionProps> & {
  areaKey: AreaFilters['key']
}

export const CustomAreaSection = (props: CustomAreaSectionProps) => {
  const { areaKey, sectionBlocker, ...rest } = props

  const [areaLocation, setAreaLocation] = useState({
    latitude: null,
    longitude: null,
  })

  const { location: userLocation } = UserLocationUtils.useCurrentLocation()

  const isMobile = useIsMobile()

  const loadedSections = useAppSelector(store => store.Home.loadedSections)
  const readyToFetch = !sectionBlocker || loadedSections.includes(sectionBlocker)

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 2,
    desktopLarge: 2,
    desktopHuge: 3,
  })

  const { areas, listProps } = APIClient.Areas.useAreas({
    key: areaKey,
    lat: userLocation?.latitude,
    lng: userLocation?.longitude,
    enabled: readyToFetch,
  })

  const area = areas?.items?.[0]

  const sectionFilters: CourseFilters & { enabled?: boolean } = {
    lat: areaLocation?.latitude,
    lng: areaLocation?.longitude,
    radius: area?.radius,
    enabled: !!areaLocation?.latitude && !!areaLocation?.longitude,
  }

  const isLoading = listProps?.isLoading

  onUpdate(() => {
    if (area) {
      setAreaLocation({
        latitude: area?.latitude,
        longitude: area?.longitude,
      })
    }
  }, [area])

  if (!isLoading && TypeGuards.isUndefined(area)) return null

  return (
    <CourseSection
      title={`Make a weekend of it. Runs in beautiful areas`}
      defaultCardWidth={COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT}
      smallCardWidth={COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.MOBILE}
      sectionFilters={sectionFilters}
      slidesToShow={slidesToShow}
      isLoading={isLoading}
      courseCardProps={{
        variants: [!isMobile ? 'fullWidth' : null],
        onPress: (itemId) => Navigation.Course.navigateToDetails(itemId),
      }}
      sectionBlocker={sectionBlocker}
      {...rest}
    />
  )
}

