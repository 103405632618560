import {
  View,
  Modal,
  SearchBar,
  InputRef,
  SearchBarProps,
} from '@/components'
import { PlacesResults, PlacesResultsProps } from './PlacesResults'
import { ModalProps } from '@codeleap/web'
import { useRef } from 'react'
import { onUpdate, useBooleanToggle } from '@codeleap/common'

export type PlacesAutoCompleteModalProps = Pick<ModalProps, 'visible' | 'toggle'> & SearchBarProps & {
  placesResultsProps: PlacesResultsProps
  getStyles: (key: string) => any
}

export const PlacesAutoCompleteModal = ({
  visible,
  toggle,
  getStyles,
  placesResultsProps,
  ...rest
}: PlacesAutoCompleteModalProps) => {
  const [focused, toggleFocused] = useBooleanToggle(false)
  const searchBarRef = useRef<InputRef>(null)

  onUpdate(() => {
    if (visible && searchBarRef.current) {
      searchBarRef.current.focus()
      toggleFocused()
    }
  }, [visible, searchBarRef.current, toggleFocused])

  return (
    <Modal
      styles={getStyles('overlay')}
      visible={visible}
      toggle={toggle}
      showClose={false}
    >
      <View style={getStyles('overlayInputWrapper')}>
        <SearchBar
          {...rest}
          ref={searchBarRef}
          focused={focused}
          onBlur={() => toggleFocused(false)}
          leftIcon={{
            debugName: 'Search Locations Left icon',
            name: 'arrow-left',
            onPress: toggle,
          }}
        />
      </View>
      <PlacesResults
        {...placesResultsProps}
        noItemBottomBorder noItemTopBorder
        onItemPress={(props) => {
          placesResultsProps.onItemPress(props)
          toggle?.()
        }} />
    </Modal>
  )
}
