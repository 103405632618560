import React from 'react'
import { Page, View, Text, PaceCalculator } from '@/components'
import { variantProvider } from '@/app'
import { PaceCalculatorUtils } from '@/utils'

interface Instruction {
  title: string
  description: string
}

const instructions: Instruction[] = [
  {
    title: 'What is a Running Pace Calculator?',
    description:
      'A running pace calculator is a valuable tool for runners of all levels. It helps you calculate your average running pace based on the distance you plan to run and your target time. Whether you’re preparing for a marathon or just aiming to improve your daily run times, understanding your pace is crucial for effective training and performance.Knowing your running pace allows you to plan your runs more effectively, ensuring that you’re training at the right intensity to meet your goals. This is especially important for those who are focused on specific race times or those who want to manage their energy levels more efficiently during their runs.',
  },
  {
    title: 'How to Use the Running Pace Calculator',
    description:
      'Using our running pace calculator is straightforward. Start by entering the distance you plan to run, followed by the time you aim to complete it in. The calculator will then provide your average pace per kilometre or mile.\nDistance: Enter the total distance of your run.\nTime: Input your target completion time.\nResult: The calculator will display your average pace, helping you plan your runs with precision.\nTo get the most accurate results, ensure that your inputs are as precise as possible. This means using a consistent unit of measurement (either kilometres or miles) and aiming for realistic, achievable goals.',
  },
  {
    title: 'Applications in Training',
    description:
      'The running pace calculator can be applied in various training scenarios:Daily Runs: Use the calculator to set a consistent pace for your daily training sessions, helping you build endurance and speed over time.Long Runs: For long-distance runs, knowing your pace helps you manage your energy levels and maintain a steady speed, reducing the risk of burnout.Recovery Runs: On recovery days, use the calculator to ensure you’re running at a slower, more manageable pace to aid in recovery while still keeping active.',
  },
  {
    title: 'Benefits of Using a Pace Calculator',
    description:
      'Incorporating a pace calculator into your training routine offers several benefits:Tailored Pacing: Adjust your running pace to suit different types of runs, whether it’s for speed, endurance, or recovery.Improved Performance: By training at the correct pace, you can enhance your overall running efficiency and performance.\nGoal Achievement: Regular use of the calculator helps you stay on track with your training goals, whether that’s improving your race times or increasing your running distance.',
  },
  {
    title: 'Practical Tips for Using the Pace Calculator',
    description:
      'Here are some practical tips to get the most out of the running pace calculator:\nIntegrate into Your Routine: Make it a habit to calculate your pace before each run, allowing you to plan more effectively.\nAvoid Common Mistakes: Ensure that you’re using accurate distance and time measurements to get the best results. Also, remember to adjust your pace according to the type of run—whether it’s for speed or endurance.',
  },
  {
    title: 'Embed This Calculator',
    description:
      'Embed This Calculator\nWant to share this tool with others? You can easily embed our running pace calculator on your own website.\nInstructions: Simply copy the provided embed code and paste it into the HTML of your site.\nSharing Options: You can also share the calculator with friends or on social networks using the provided sharing buttons, making it easy for others to benefit from this tool.\nConclusion\nUsing a running pace calculator is essential for any runner looking to improve their performance and achieve their training goals. By regularly calculating your pace, you can ensure that you’re running at the right speed for your objectives, whether that’s completing a marathon or improving your daily run times. Start using our running pace calculator today to take your training to the next level',
  },
]

export const PaceCalculatorPage = () => {

  return (
    <Page
      SEOProps={PaceCalculatorUtils.getSEOProps()}
      variants={['column']}>
      <PaceCalculator
        variants={['marginTop:10']}
        responsiveVariants={{
          mobile: ['marginTop:3'],
        }} />

      <View
        variants={['wrap', 'flex', 'gap:4', 'paddingVertical:10']}
        responsiveVariants={{
          mobile: ['paddingVertical:5'],
        }}>
        {instructions.map((instruction) => {
          const { title, description } = instruction

          return (
            <View key={title} css={styles.textWrapper}>
              <Text
                text={title}
                variants={['h2', 'marginBottom:2']}
                component='h2'
                responsiveVariants={{
                  mobile: ['h3'],
                }}
              />
              <Text text={description} variants={['neutral-8']} />
            </View>)
        })}
      </View>

    </Page >
  )
}

const BREAKPOINT = 'mobile'

const styles = variantProvider.createComponentStyle((theme) => ({
  textWrapper: {
    ...theme.presets.column,
    width: '48%',

    [theme.media.down(BREAKPOINT)]: {
      width: '100%',
    },
  },
}), true)

