import { useCallback, useRef, useState } from 'react'
import '@/app/stylesheets/css/slick.css'
import '@/app/stylesheets/css/slick-theme.css'
import { useIsMobile, useRefDimensions } from '@/utils'
import { Carousel, CarouselProps, CourseCard, CourseCardProps, View } from '@/components'
import { Theme, variantProvider } from '@/app'
import { Course } from '@/types'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import { onMount, onUpdate, PropsOf } from '@codeleap/common'

export type CourseSliderProps = {
  courses: Course[]
  courseCardProps?: Partial<CourseCardProps>
  cardWrapperProps?: PropsOf<typeof View>
  itemWidth?: number
  roundSliders?: boolean
  smallCardWidth?: number
  desktopCardWidth?: number
  carouselProps?: Partial<CarouselProps>
  slidesToShow?: number
  responsiveWrapper?: boolean
  defaultCardWidth?: number
  gap?: number
  isFetching?: boolean
  limit?: number
} & PropsOf<typeof View>

const CourseItem = ({
  item,
  _slidesToShow,
  gapToPadding,
  gap,
  cardWrapperProps,
  handleItemPress,
  courses,
  limit,
  ...otherItemProps }) => {
  const [hidden, setHidden] = useState(courses?.length > limit)

  onMount(() => {
    setTimeout(() => {
      setHidden(false)
    }, 400)
  })

  return (
    <View
      key={item.title}
      variants={['center', _slidesToShow ? `paddingHorizontal:${gapToPadding(gap)}` : null]}
      css={{ display: 'flex', opacity: hidden ? 0 : 1 }}
      {...cardWrapperProps}
    >
      <CourseCard
        course={item}
        onPress={() => handleItemPress(item?.id)}
        {...otherItemProps}
      />
    </View>
  )
}

export const CourseSlider = (props: CourseSliderProps) => {
  const {
    courses,
    courseCardProps = {},
    cardWrapperProps = {},
    itemWidth: _itemWidth,
    slidesToShow: _slidesToShow,
    roundSliders = false,
    smallCardWidth = COURSE_CARD_CONSTANTS.SMALL.WIDTH.MOBILE,
    defaultCardWidth = COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT,
    carouselProps = {},
    gap = Theme.spacing.value(4),
    style,
    isFetching,
    limit = 10,
    ...wrapperProps
  } = props

  const isMobile = useIsMobile()
  const [maxHeight, setMaxHeight] = useState(null)

  const sliderRef = useRef(null)

  const { refWidth: sliderWidth } = useRefDimensions(sliderRef)

  const cardWidth = (isMobile ? smallCardWidth : defaultCardWidth) + gap
  const itemWidth = _itemWidth ?? cardWidth

  const slidesToShow = _slidesToShow ?? (roundSliders ? Math.floor(sliderWidth / itemWidth) : (sliderWidth / itemWidth))

  const renderItem = (item, index, dragging) => {
    const {
      onPress,
      ...otherItemProps
    } = courseCardProps

    const gapToPadding = (gap: number) => {
      return (gap / Theme.spacing.value(1)) / 2
    }

    const handleItemPress = () => {
      if (!dragging) courseCardProps.onPress?.(item)
    }

    return (
      <CourseItem
        {...{
          limit,
          item,
          _slidesToShow,
          gapToPadding,
          gap,
          courses,
          cardWrapperProps,
          handleItemPress,
        }} { ...otherItemProps }
      />)
  }

  onUpdate(() => {
    if (isFetching) { setMaxHeight(sliderRef.current.offsetHeight) }
  }, [isFetching, courses?.length])

  return (
    <View
      ref={sliderRef}
      style={{ ...styles.wrapper, ...style, maxHeight }}
      {...wrapperProps}
    >
      <Carousel
        items={courses}
        renderItem={renderItem}
        slidesToShow={slidesToShow}
        {...carouselProps}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.block,

      [theme.media.down('mobile')]: {
        overflow: 'hidden',
      },
    },
  }),
  true,
)
