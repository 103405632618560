import Slider, { Settings } from 'react-slick'
import '@/app/stylesheets/css/slick.css'
import '@/app/stylesheets/css/slick-theme.css'
import { ActionIcon, Touchable, View } from '@/components'
import { AppIcon, React, variantProvider } from '@/app'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import {
  AnyFunction,
  ComponentVariants,
  PropsOf,
  StylesOf,
  getNestedStylesByKey,
  onUpdate,
  useCallback,
  useDefaultComponentStyle,
  useImperativeHandle,
  useRef,
} from '@codeleap/common'
import { CarouselComposition, CarouselStyles } from '@/app/stylesheets/Carousel'
import { forwardRef, useState } from 'react'
import { useCarouselDragging } from '@/utils'

export type CarouselRef = {
  goTo: (page: number) => void
}

type ArrowProps = {
  icon: AppIcon
  getStyles: (tag: string) => any
  className?: string
  style?: React.CSSProperties
  onClick?: AnyFunction
}

const Arrow = (props: ArrowProps) => {
  const {
    icon,
    getStyles,
    className,
    style,
    onClick,
  } = props

  return (
    <div
      className={className}
      style={{ ...style, ...styles.arrowWrapper }}
      onClick={onClick}
    >
      <ActionIcon
        name={icon}
        styles={getStyles('arrow')}
        onPress={onClick}
        debugName='Slider Prev Arrow'
      />
    </div>
  )
}

type DotsProps = Pick<CarouselProps, 'slide' | 'dotsDisabled'> & {
  onPress?: (index: number) => void
  variantStyles?: StylesOf<CarouselComposition>
  items?: any[]
}

const Dots = ({ slide, items, onPress, variantStyles, dotsDisabled }: DotsProps) => {
  return (
    <View style={variantStyles.dots}>
      {items?.map((_, index) => {
        const isSelected = index === slide
        const css = [
          variantStyles[isSelected ? 'dot:selected' : 'dot'],
          dotsDisabled && variantStyles['dot:disabled'],
        ]

        return (
          <Touchable
            debugName={`Dot:${index}`}
            key={index}
            onPress={() => onPress?.(index)}
            css={css}
            disabled={dotsDisabled}
          />
        )
      })}
    </View>
  )
}

export type CarouselProps = Omit<PropsOf<typeof View>, 'variants' | 'styles'> & {
  styles?: StylesOf<CarouselComposition>
  items: any[]
  renderItem: (item: any, index: number, dragging: boolean) => ReactJSXElement
  speed?: number
  rows?: number
  slidesToScroll?: number
  dots?: boolean
  slidesToShow?: number
  infinite?: boolean
  arrows?: boolean
  swipeToSlide?: boolean
  leftArrowDynamicDisplay?: boolean
  rightArrowDynamicDisplay?: boolean
  onEndReached?: () => void
  hideArrowOnFirstSlider?: boolean
  hideArrowOnLastSlider?: boolean
  slide?: number
  dotsDisabled?: boolean
  dotsProps?: DotsProps
  onChangeSlide?: (slide: number) => void
  settings?: Settings
  sliderClassName?: 'overflow-visible' | 'focus' | null
  endReachedThreshold?: number
} & ComponentVariants<typeof CarouselStyles>

const CarouselComponent = (props: CarouselProps, ref: React.ForwardedRef<CarouselRef>) => {
  const {
    items,
    renderItem,
    slide = 0,
    variants = [],
    styles = {},
    responsiveVariants,
    speed = 500,
    rows = 1,
    slidesToScroll = 1,
    slidesToShow = 1,
    dots = false,
    infinite = false,
    arrows = true,
    onEndReached,
    hideArrowOnFirstSlider = true,
    hideArrowOnLastSlider,
    swipeToSlide = true,
    endReachedThreshold = 4,
    dotsDisabled = false,
    dotsProps,
    onChangeSlide,
    settings: _settings,
    sliderClassName,
    ...rest
  } = props

  const [currentSlide, setCurrentSlide] = useState(0)

  const isLastSlide = currentSlide + slidesToShow >= items.length
  const isFirstSlide = currentSlide === 0

  const variantStyles = useDefaultComponentStyle<
    'u:Carousel',
    typeof CarouselStyles
  >('u:Carousel', {
    variants,
    styles,
    responsiveVariants,
  })

  const { dragging, handleAfterChange, handleBeforeChange } = useCarouselDragging()

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const sliderRef = useRef<Slider>()
  const settings: Settings = {
    arrows,
    dots: false,
    infinite,
    speed,
    rows,
    slidesToShow,
    slidesToScroll,
    swipeToSlide,
    prevArrow: hideArrowOnFirstSlider && isFirstSlide ? null : <Arrow icon={'chevron-left'} getStyles={getStyles} />,
    nextArrow: hideArrowOnLastSlider && isLastSlide ? null : <Arrow icon={'chevron-right'} getStyles={getStyles} />,
    beforeChange: (slide) => {
      handleBeforeChange()
      const isEndReached = slide >= (items.length - slidesToShow - endReachedThreshold)
      if (isEndReached) {
        onEndReached?.()
      }
    },
    afterChange: (newSlide) => {
      onChangeSlide?.(newSlide)
      handleAfterChange()
      setCurrentSlide(newSlide)
    },
    ..._settings,
  }

  const goTo = useCallback(
    (slide: number) => {
      if (sliderRef.current) sliderRef.current.slickGoTo(slide)
    },
    [sliderRef?.current],
  )

  useImperativeHandle(ref, () => ({
    goTo,
    ...sliderRef.current,
  }), [!!sliderRef?.current])

  onUpdate(() => {
    goTo(slide)
  }, [slide])

  return (
    <View
      css={getStyles('wrapper')}

      {...rest}
    >
      <Slider
        ref={sliderRef}
        className={sliderClassName}
        {...settings}
      >
        {items?.map((item, index) => renderItem(item, index, dragging))}
      </Slider>
      <View css={getStyles('footerWrapper')}>
        {dots && (
          <Dots
            slide={slide}
            onPress={onChangeSlide}
            items={items}
            variantStyles={variantStyles}
            dotsDisabled={dotsDisabled}
            {...dotsProps}
          />
        )}
      </View>
    </View>
  )
}

//@ts-ignore
export const Carousel: (props: CarouselProps) => ReactJSXElement = forwardRef(CarouselComponent)

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    arrowWrapper: {
      zIndex: 1,
      height: '100%',
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.presets.center,
    },
  }),
  true,
)
