import { TextInput, View, Text } from '@/components'
import { capitalize, PropsOf, TypeGuards, useCallback, useValidate } from '@codeleap/common'
import { debounce } from '@/utils'

type TextInputProps = PropsOf<typeof TextInput>

export type TimeInputProps = Partial<TextInputProps> & {
  value: string
  onChangeDebounced?: (v: string) => void
  debounceTime?: number
  disabled?: boolean
  inputVariants?: TextInputProps['variants']
  wrapperProps?: PropsOf<typeof View>
  abbreviate?: boolean
}

const INITIAL_VALUE = ''

export const TimeInput = ({
  disabled,
  abbreviate,
  debounceTime = 500,
  value,
  label,
  debugName,
  variants = [],
  wrapperProps,
  responsiveVariants,
  validate,
  error,
  onChangeDebounced,
  onChangeText,
  ...rest

}: TimeInputProps) => {
  const currentTime = value

  const _onChangeDebounced = useCallback(
    debounce((timeString) => {
      onChangeDebounced?.(timeString)
    }, debounceTime),
    [onChangeDebounced, debounceTime],
  )

  const validation = useValidate(
    value,
    validate,
  )

  const handleChange = (e, type: 'hrs' | 'min' | 'sec') => {
    let v = e.target.value
    // Allow only numeric characters
    v = v.replace(/\D/g, '')

    const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward'

    let { hours, minutes, seconds } = getSplitTime(currentTime)

    switch (type) {
      case 'hrs':
        hours = isBackspace ? INITIAL_VALUE : handleTimeChange(hours, v)
        break
      case 'min':
        minutes = isBackspace ? INITIAL_VALUE : handleTimeChange(minutes, v)
        break
      case 'sec':
        seconds = isBackspace ? INITIAL_VALUE : handleTimeChange(seconds, v)
        break
    }

    // Ensure minutes and seconds do not exceed 60
    if (type === 'min' || type === 'sec') {
      if (parseInt(minutes, 10) > 60) minutes = '60'
      if (parseInt(seconds, 10) > 60) seconds = '60'
    }

    const isEmpty = !hours && !minutes && !seconds
    const timeString = isEmpty ? '' : `${hours || '00'}:${minutes || '00'}:${seconds || '00'}`

    _onChangeDebounced(timeString)
    onChangeText?.(timeString)
  }

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!value) {
      validation?.onInputBlurred()
    } else if (!validation.isValid) {
      validation?.onInputBlurred()
    }

    rest?.onBlur?.(e)
  }, [validation?.onInputBlurred, rest?.onBlur])

  const errorMessage = validation?.message

  const getInputProps = (type: 'hrs' | 'min' | 'sec') => {
    const { hours, minutes, seconds } = getSplitTime(currentTime)
    const _value = type === 'hrs' ? hours : type === 'min' ? minutes : seconds

    return {
      ...rest,
      inputmode: 'numeric',
      pattern: '[0-9]*',
      onBlur: handleBlur,
      onChange: (e) => handleChange(e, type),
      debugName: `TimeInput:${debugName}:${type}`,
      placeholder: capitalize(abbreviate ? type[0] : type),
      value: _value,
      variants: ['tiny', 'textCentered', !validation.isValid && 'error', ...variants] as TextInputProps['variants'],
      responsiveVariants,
    }
  }

  return (
    <View
      variants={['column', 'marginBottom:3']}
      {...wrapperProps}
      css={[wrapperProps?.css, disabled && { pointerEvents: 'none', opacity: 0.5 }]}
    >
      {!!label && (<Text text={label} variants={['p3', 'neutral-7', 'marginBottom:1']} />)}
      <View variants={['gap:0.5', 'alignEnd']} >
        <TextInput {...getInputProps('hrs')} />

        <Text variants={['marginBottom:1.5']} text=':' />

        <TextInput {...getInputProps('min')} />

        <Text variants={['marginBottom:1.5']} text=':' />

        <TextInput {...getInputProps('sec')} />
      </View>

      {!!errorMessage && (<Text text={errorMessage} variants={['error', 'marginLeft:2', 'marginTop:1']} />)}
    </View>
  )
}

function isNumberValid(v: string) { return v !== 'NaN' && v != 'undefined' }

function handleTimeChange(timeValue: string, v: string) {
  return (timeValue + v).slice(-2).padStart(2, '0')
}

function getSplitTime(currentTime?: string) {
  let hours = INITIAL_VALUE
  let minutes = INITIAL_VALUE
  let seconds = INITIAL_VALUE

  if (!!currentTime) {
    const [h, m, s] = currentTime.split(':')
    if (isNumberValid(h)) hours = h
    if (isNumberValid(m)) minutes = m
    if (isNumberValid(s)) seconds = s
  }

  return { hours, minutes, seconds }
}
