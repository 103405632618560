import { AppStatus } from '@/redux'
import { Profile } from '@/types'
import { getFirebase } from '../../firebaseApp'
import { AuthErrors } from './errors'
import { QueryKeys } from './queryKeys'
import { isEmailAvailable } from './views'

export function useLogin() {
  const login = async (data:Partial<Profile>) => {
    AppStatus.set('loading')

    try {
      const { email, password } = data

      const emailAvailable = await isEmailAvailable(email)

      if (emailAvailable) {
        AuthErrors.exception('auth/email-not-found')
      }

      const firebase = await getFirebase()
      await firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim())

      await QueryKeys.me.refresh()

      AppStatus.set('done')
      return true

    } catch (err) {
      AppStatus.set('idle')
      AuthErrors.onError(err, 'login')
      return false
    }
  }

  return login
}
