import { createSlice } from '@codeleap/common'

export type MapReduxState = {
  fullscreen: boolean
}

const initialState: MapReduxState = {
  fullscreen: false,
}

export const mapSlice = createSlice({
  name: 'MapRedux',
  initialState,
  reducers: {
    toggleFullscreen(state, isFullScreen?: boolean) {
      return { fullscreen: isFullScreen || !state.fullscreen }
    },
  },
  asyncReducers: {},
})
