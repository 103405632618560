import { ActionIcon, View } from '@/components'
import { onUpdate, useBooleanToggle, useCallback, useState } from '@codeleap/common'
import { EmblaCarouselType } from 'embla-carousel'

type UseArrowType = {
  prevArrowDisabled: boolean
  nextArrowDisabled: boolean
  onPrevArrowPress: () => void
  onNextArrowPress: () => void
  prevArrowVisible: boolean
  nextArrowVisible: boolean
}

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UseArrowType => {
  const [prevArrowDisabled, setPrevArrowDisabled] = useState(true)
  const [nextArrowDisabled, setNextArrowDisabled] = useState(true)
  const [prevArrowVisible, togglePrevArrowVisible] = useBooleanToggle(true)
  const [nextArrowVisible, toggleNextArrowVisible] = useBooleanToggle(true)

  const onPrevArrowPress = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextArrowPress = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevArrowDisabled(!emblaApi.canScrollPrev())
    setNextArrowDisabled(!emblaApi.canScrollNext())
    togglePrevArrowVisible(emblaApi.canScrollPrev())
    toggleNextArrowVisible(emblaApi.canScrollNext())
  }, [])

  onUpdate(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevArrowDisabled,
    nextArrowDisabled,
    onPrevArrowPress,
    onNextArrowPress,
    prevArrowVisible,
    nextArrowVisible,
  }
}

type ArrowProps = {
  type: 'next' | 'prev'
  getStyles: (tag: string) => any
  onPress: () => void
  disabled: boolean
  visible?: boolean
}

export const Arrow = (props: ArrowProps) => {
  const { onPress, getStyles, type, visible = true } = props

  const isPrev = type === 'prev'
  const icon = isPrev ? 'chevron-left' : 'chevron-right'
  const stylesKey = isPrev ? 'arrowWrapper:left' : 'arrowWrapper:right'

  return (
    <View css={getStyles(stylesKey)}>
      {visible ? (
        <ActionIcon
          name={icon}
          styles={getStyles('arrow')}
          onPress={onPress}
          debugName={`Carousel Arrow: ${type}`}
        />
      ) : null}
    </View>
  )
}
