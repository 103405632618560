import { Slider, Tooltip } from '@codeleap/web'
import { variantProvider } from '@/app'
import { PropsOf, TypeGuards, useRef } from '@codeleap/common'
import { Button, View } from '@/components'
import { useClickClosest } from '@/utils'

export type FilterButtonProps = Partial<PropsOf<typeof Tooltip>> & {
  id?: string
  buttonProps: PropsOf<typeof Button>
  sliderProps: PropsOf<typeof Slider> & {
    debounce?: number
    onValueChangeDebounced?: PropsOf<typeof Slider>['onValueChange']
  }
  onClear?: () => void
  onApply?: () => void
  disableApply?: boolean
}

export const FilterButton = ({
  id = 'filter-button',
  buttonProps,
  sliderProps,
  visible,
  toggle,
  onClear = () => { },
  onApply = () => { },
  disableApply = false,
  ...rest
}: FilterButtonProps) => {
  const setSearchTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleValueChange = (value) => {
    sliderProps?.onValueChange(value)

    if (TypeGuards.isNil(sliderProps?.debounce)) {
      sliderProps?.onValueChangeDebounced?.(value)
    } else {
      if (!TypeGuards.isNil(setSearchTimeout.current)) {
        clearTimeout(setSearchTimeout.current)
      }
      setSearchTimeout.current = setTimeout(() => {
        sliderProps?.onValueChangeDebounced?.(value)
      }, sliderProps?.debounce ?? 0)
    }
  }

  useClickClosest({
    elementIds: [id, `filter-content-${id}`],
    onNotClickClose: toggle,
    enabled: visible,
  })

  return (
    <Tooltip
      /* @ts-ignore */
      id={id}
      side='bottom'
      variants={['bare', 'overlap', 'small'] as any}
      debugName={'FilterButton'}
      openOnHover={false}
      closeOnClickOutside
      open={visible}
      content={
        <View css={styles.filterWrapper} id={`filter-content-${id}`}>
          <Slider variants={['noError']} {...sliderProps} onValueChange={handleValueChange} />

          <View css={styles.filterFooter}>
            <Button
              text='Clear'
              debugName='Filter:Clear'
              variants={['flex', 'primary6']}
              onPress={onClear}
              css={styles.clearButton}
            />

            <Button
              variants={['flex']}
              debugName='Filter:Apply'
              text={`Apply`}
              onPress={onApply}
              css={styles.seeResultsButton}
              disabled={disableApply}
            />
          </View>
        </View>
      }
      {...rest}
    >
      <Button
        debugName={`Filter:Button`}
        rightIcon='arrow-down'
        variants={[visible ? 'fitlerButton:selected' : 'fitlerButton']}
        style={{ flex: `0 0 auto` }}
        {...buttonProps}
        onPress={visible ? toggle : buttonProps.onPress}
      />
    </Tooltip>
  )
}

const ACTION_BTN_HEIGHT = 45

const styles = variantProvider.createComponentStyle((theme) => ({
  filterWrapper: {
    ...theme.presets.relative,
    ...theme.presets.elevated,
    borderRadius: theme.borderRadius.medium,
    ...theme.spacing.padding(2),
    ...theme.presets.column,
    width: 400,
    backgroundColor: theme.colors.neutral1,
    bottom: theme.spacing.value(1),

    [theme.media.down('mobile')]: {
      width: `90vw`,
    },
  },
  filterFooter: {
    ...theme.spacing.marginTop(2),
    gap: theme.spacing.value(2),

    [theme.media.down('mobile')]: {
      ...theme.presets.column,
    },
  },
  seeResultsButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 1,
    },
  },
  clearButton: {
    height: ACTION_BTN_HEIGHT,
    minHeight: ACTION_BTN_HEIGHT,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 2,
    },
  },
}), true)
