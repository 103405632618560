import { AppIcon } from '@/app'
import { Icon } from '@/components'
import { CheckpointStyles, CheckpointComposition } from '@/app/stylesheets'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

export type CheckpointProps = ComponentVariants<typeof CheckpointStyles> & {
  text?: string
  icon?: AppIcon
  styles?: StylesOf<CheckpointComposition>
}

export const Checkpoint = ({ text, variants, styles, icon, ...rest }: CheckpointProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:Checkpoint',
    typeof CheckpointStyles
  >('u:Checkpoint', {
    rootElement: 'wrapper',
    variants,
    styles,
  })

  const renderChildren = () => {
    if (icon) {
      return (
        <Icon
          name={icon}
          debugName='Checkpoint:icon'
          style={variantStyles.icon}
        />
      )
    }

    return !!text && <p style={variantStyles.text} >{text}</p>
  }

  return (
    <div style={variantStyles.wrapper}>
      {renderChildren()}
    </div>)
}

