import { useIsTablet } from '@/utils'
import { MobileExploreMapFooter } from './Mobile'
import { ExploreMapFooterProps } from './types'
import { WebExploreMapFooter } from './Web'

export const ExploreMapFooter = ({ ...rest }: ExploreMapFooterProps) => {
  const isTablet = useIsTablet()

  return isTablet ? <MobileExploreMapFooter {...rest} /> : <WebExploreMapFooter {...rest} />
}

export * from './types'
