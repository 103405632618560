import React from 'react'
import { View, Logo, CenterWrapper } from '@/components'
import { useDefaultComponentStyle, ComponentVariants, StylesOf, PropsOf, getNestedStylesByKey } from '@codeleap/common'
import { FooterComposition, AppFooterStyles } from '../../app/stylesheets/Footer'
import { FooterContact, FooterDisclaimers, FooterNavigate, FooterSocialMedia } from './Sections'
import { useFooterCategories } from '@/utils'

export type FooterProps =
  Omit<PropsOf<typeof CenterWrapper>, 'variants' | 'styles' | 'responsiveVariants'> &
  ComponentVariants<typeof AppFooterStyles> &
  {
    styles?: StylesOf<FooterComposition>
  }

const FooterComponent = (props: FooterProps) => {
  const { responsiveVariants, styles, variants, ...rest } = props

  const variantStyles = useDefaultComponentStyle<'u:Footer', typeof AppFooterStyles>('u:Footer', {
    responsiveVariants,
    styles,
    variants,
  })

  const logoStyles = getNestedStylesByKey('logo', variantStyles)

  const categories = useFooterCategories()

  return (
    <CenterWrapper
      styles={{
        wrapper: variantStyles.wrapper,
        innerWrapper: variantStyles.innerWrapper,
      }}
      {...rest}
      component='footer'
    >
      <Logo
        debugName='Footer logo'
        styles={logoStyles}
        responsive={false}
        switchServerOnPress
        debugName='footer-logo'
      />

      <View css={variantStyles.content}>
        <View css={variantStyles.topContent}>
          <FooterNavigate variantStyles={variantStyles} categories={categories} />
          <FooterContact variantStyles={variantStyles} />
        </View>

        <View css={variantStyles.separator} />

        <View css={variantStyles.bottomContent}>
          <FooterDisclaimers variantStyles={variantStyles} />
          <FooterSocialMedia variantStyles={variantStyles} />
        </View>
      </View>
    </CenterWrapper>
  )
}

export const Footer = React.memo(FooterComponent, () => true)
