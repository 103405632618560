import { AppImages, variantProvider } from '@/app'
import { ReactNode } from 'react'
import { Text, Image, View } from '@/components'
import { getImageAlt } from '../misc'

type FeatureItemProps = {
  children: ReactNode
}

const TITLE = 'Skamper Features'

const LargeItem = ({ index, image, title, topImage = false, firstSlide = false }) => {

  return (
    <View
      variants={['row', 'justifySpaceBetween', 'gap:7.5', 'paddingHorizontal:5']}
      responsiveVariants={{
        tabletSmall: ['gap:4'],
      }}
    >
      <Image
        alt={getImageAlt(TITLE, index + 1)}
        source={image}
        objectFit='cover'
        variants={['alignSelfStart', topImage ? null : 'marginTop:auto']}
        style={styles.largeItemImage}
      />

      <View
        variants={['fullHeight', 'fullWidth', 'center']}
        style={styles.largeTextWrapper}
      >
        <Text
          text={title}
          variants={[firstSlide ? 'h0' : 'h1', 'color:neutral1', 'textLeft']}
          responsiveVariants={{
            tabletSmall: [firstSlide ? 'h1' : 'h2', 'color:neutral1'],
          }}
          component='h2'
        />
      </View>
    </View>
  )
}

export const useFeaturesItems = () => {

  const items: Record<string, FeatureItemProps> = {
    runs: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0', 'relative', 'fullWidth']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Find the best runs wherever you are.'
            variants={['h1', 'color:neutral1']}
            style={styles.itemTitle}
            component='h2'
          />

          <Image
            alt={getImageAlt(TITLE, 1)}
            source={AppImages.HomeScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={{ ...styles.itemImage, ...styles.runsImage }}
          />
        </View>
      ),
    },
    free: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0', 'relative', 'fullWidth']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Completely free - full access to runs and features'
            variants={['h2', 'color:neutral1']}
          />

          <Image
            alt={getImageAlt(TITLE, 2)}
            source={AppImages.HomeScreenSliced}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={{ ...styles.itemImage, ...styles.homeSliced }}
          />

          <Image
            alt={getImageAlt(TITLE, 3)}
            source={AppImages.ExploreScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={{ ...styles.itemImage, ...styles.exploreImage }}
          />
        </View>
      ),
    },
    stats: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='See detailed stats for your run before you go.'
            variants={['h2', 'color:neutral1']}
          />

          <Image
            alt={getImageAlt(TITLE, 4)}
            source={AppImages.DetailsScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={styles.itemImage}
          />
        </View>
      ),
    },
    follow: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingTop:0']}
          style={styles.itemInnerWrapper}
        >
          <Image
            alt={getImageAlt(TITLE, 5)}
            source={AppImages.RunScreen}
            objectFit='cover'
            variants={['alignSelfCenter']}
            style={{ ...styles.itemImage, ...styles.topImage }}
          />
          <Text
            text='Follow every run with confidence'
            variants={['h2', 'color:neutral1']}
          />
        </View>
      ),
    },
    alerts: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingTop:0', 'relative']}
          style={styles.itemInnerWrapper}
        >
          <Image
            alt={getImageAlt(TITLE, 6)}
            source={AppImages.NotificationsScreen}
            objectFit='cover'
            variants={['alignSelfCenter']}
            style={{ ...styles.itemImage, ...styles.topImage }}
          />
          <Text
            text='Run safely with friend alerts'
            variants={['h2', 'color:neutral1']}
          />
        </View>
      ),
    },
    friends: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Compete with friends and other runners'
            variants={['h2', 'color:neutral1']}
          />

          <Image
            alt={getImageAlt(TITLE, 7)}
            source={AppImages.LeaderboardScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={styles.itemImage}
          />
        </View>
      ),
    },
    milestone: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Celebrate progress and milestones'
            variants={['h2', 'color:neutral1']}
          />

          <Image
            alt={getImageAlt(TITLE, 8)}
            source={AppImages.MilestoneScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={{ ...styles.itemImage, ...styles.milestoneImage }}
          />
        </View>
      ),
    },
    insights: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Stay motivated with personal insights'
            variants={['h2', 'color:neutral1']}
          />

          <Image
            alt={getImageAlt(TITLE, 9)}
            source={AppImages.DashboardScreen}
            objectFit='cover'
            variants={['alignSelfCenter', 'marginTop:auto']}
            style={styles.itemImage}
          />
        </View>
      ),
    },
  }

  const largeItems: Record<string, FeatureItemProps> = {
    runs: {
      children: LargeItem({
        index: 0,
        image: AppImages.HomeScreen2,
        title: 'Find the best runs wherever you are',
        firstSlide: true,
      }),
    },
    free: {
      children: LargeItem({
        index: 1,
        image: AppImages.ExploreScreen,
        title: 'Completely free - full access to runs and features',
      }),
    },
    stats: {
      children: LargeItem({
        index: 2,
        image: AppImages.DetailsScreen,
        title: 'See detailed stats for your run before you go',
      }),
    },
    follow: {
      children: LargeItem({
        index: 3,
        image: AppImages.RunScreen,
        title: 'Follow every run with confidence',
        topImage: true,
      }),
    },
    alerts: {
      children: LargeItem({
        index: 4,
        image: AppImages.NotificationsScreen,
        title: 'Run safely with friend alerts',
        topImage: true,
      }),
    },
    friends: {
      children: LargeItem({
        index: 5,
        image: AppImages.LeaderboardScreen,
        title: 'Compete with friends and other runners',
      }),
    },
    milestone: {
      children: LargeItem({
        index: 6,
        image: AppImages.ResultsScreen,
        title: 'Celebrate progress and milestones',
      }),
    },
    insights: {
      children: LargeItem({
        index: 7,
        image: AppImages.DashboardScreen,
        title: 'Stay motivated with personal insights',
      }),
    },
  }

  return {
    items,
    largeItems,
  }
}

const ITEM_HEIGHT = 400
const ITEM_IMAGE_WIDTH = 190
const NOTIFICATION_IMAGE_WIDTH = 275.26
const ITEM_TITLE_IMAGE = 178

export const FEATURE_SECTION_IMAGE_WIDTH = 218

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
  },
  item: {
    height: ITEM_HEIGHT,
    overflow: 'hidden',
  },
  itemInnerWrapper: {
    zIndex: 1,
  },
  itemTitle: {
    ...theme.presets.fullWidth,
    maxWidth: ITEM_TITLE_IMAGE,
  },
  itemImage: {
    maxWidth: '40%',
    minWidth: ITEM_IMAGE_WIDTH,
    ...theme.presets.fullWidth,
  },
  topImage: {
    maxWidth: 195,
  },
  notificationImage: {
    top: 171,
    maxWidth: NOTIFICATION_IMAGE_WIDTH,
  },
  milestoneImage: {
    minWidth: 170,
  },
  runsImage: {
    minWidth: 200,
    maxWidth: '80%',
    ...theme.presets.absolute,
    ...theme.presets.right,
    top: theme.spacing.value(12),

  },
  homeSliced: {
    minWidth: 68,
    width: '27%',
    ...theme.presets.absolute,
    ...theme.presets.left,
    top: theme.spacing.value(12),
  },
  exploreImage: {
    ...theme.spacing.marginLeft(8.5),
  },

  //Large Style
  largeItemImage: {
    maxWidth: FEATURE_SECTION_IMAGE_WIDTH,
    ...theme.presets.fullWidth,
  },
}), true)
