import { api } from '@/app'
import { LatestSectionItem } from '@/types'
import { QueryManager } from '@codeleap/common'
import { queryClient } from './queryClient'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'crm/web-latest-section/'

export const list = async (params?: Record<string, any>) => {
  const response = await api.get(BASE_URL, {
    params,
  })
  return response.data
}

export const latestSectionItemsManager = new QueryManager<LatestSectionItem>({
  itemType: {} as LatestSectionItem,
  name: 'latestSectionItem',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
})

type UseLatestSectionItemProps = {
  enabled?: boolean
}

export const useLatestSectionItems = (props: UseLatestSectionItemProps) => {
  const { enabled = true } = props
  const items = latestSectionItemsManager.use({
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled,
      },
    },
  })

  const listProps = useFlatlistProps(items)

  const isLoading = items?.list?.query?.isLoading || listProps?.isLoading
  const isFetched = items?.list?.query?.isFetched

  return {
    items,
    listProps,
    isLoading,
    isFetched,
  }
}
