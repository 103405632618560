import { AreaCardStyles } from '@/app/stylesheets'
import { View, Text, CardBaseProps, CardBase, Image } from '@/components'
import { Area } from '@/types'
import React from 'react'
import { ComponentVariants, StylesOf, arePropsEqual, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import { AppImages } from '@/app'
import { AreaCardComposition } from '@/app/stylesheets/AreaCard'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AreaUtils } from '@/utils'

export type AreaCardProps = Omit<CardBaseProps, 'variants' | 'styles' | 'style' | 'debugName'> &
  ComponentVariants<typeof AreaCardStyles> & {
    styles?: StylesOf<AreaCardComposition>
    area: Area
  }

const _AreaCard = (props: AreaCardProps) => {

  const {
    variants,
    responsiveVariants,
    styles,
    area,
    ...rest
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:AreaCard',
    typeof AreaCardStyles
  >('u:AreaCard', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  return (
    <CardBase
      debugName='Course Card'
      styles={getStyles('card')}
      {...rest}
    >
      <View css={variantStyles.gradient} />
      <Image
        source={area?.file || AppImages.AreaExample}
        alt={AreaUtils.getImageAlt(area)}
        css={variantStyles.image}
        objectFit='cover'
      />
      <View style={variantStyles.contentWrapper}>
        <Text
          text={area?.title}
          style={variantStyles.title}
          component='h3'
        />
      </View>
    </CardBase>
  )
}

//@ts-ignore
export const AreaCard: (props: AreaCardProps) => ReactJSXElement = React.memo(_AreaCard, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['area'],
  })
})

