import { OSAlert } from '@/app'
import { AppStatus } from '@/redux'
import { Profile } from '@/types'
import { getFirebase } from '../../firebaseApp'
import { AuthErrors } from './errors'
import { useSession } from './useSession'
import { isEmailAvailable } from './views'

export function useEdit() {
  const { profile, updateProfile } = useSession()

  const editProfile = async (data: Partial<Profile>) => {
    AppStatus.set('loading')

    try {
      const emailAddressChanged = data.email.trim() !== profile.email.trim()

      if (emailAddressChanged) {
        const emailAvailable = await isEmailAvailable(data.email)

        if (!emailAvailable) {
          AuthErrors.exception('auth/email-in-use')
        }

        try {
          const firebase = await getFirebase()

          await firebase.auth().currentUser.updateEmail(data.email)
        } catch (e) {
          AuthErrors.exception(e?.code)
        }
      }

      const response = await updateProfile.mutateAsync(data)

      setTimeout(() => {
        AppStatus.set('done')
      }, 1000)

      return response

    } catch (err) {
      AppStatus.set('idle')
      AuthErrors.onError(err)
      throw err
    }
  }

  return { profile, editProfile }
}
