import { themeBreakpoints, useBreakpointMatch, variantProvider } from '@/app'
import { AREA_CARD_CONSTANTS } from '@/app/stylesheets/AreaCard'
import {
  AreaCardProps,
  AreaCarousel,
  Button,
  Section,
} from '@/components'
import { Home, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { AreaFilters } from '@/types'
import { Navigation, useIsMobile } from '@/utils'
import { PropsOf, TypeGuards, onUpdate } from '@codeleap/common'

export type AreaSectionProps = {
  slidesToShow?: number
  sectionFilters?: AreaFilters
  title: string
  areaCardProps?: Partial<AreaCardProps>
  buttonProps?: PropsOf<typeof Button>
  sectionProps?: Partial<PropsOf<typeof Section>>
  showButton?: boolean
  sectionId?: string
  sectionBlocker?: string
  defaultCardWidth?: number
  smallCardWidth?: number
}

export const AreaSection = (props: AreaSectionProps) => {
  const {
    slidesToShow: _slidesToShow,
    sectionFilters = {},
    title,
    areaCardProps,
    buttonProps = {},
    showButton = true,
    sectionProps,
    sectionId,
    sectionBlocker = null,
    defaultCardWidth = AREA_CARD_CONSTANTS.default.size,
    smallCardWidth = AREA_CARD_CONSTANTS.mobile.size,
  } = props

  const isMobile = useIsMobile()

  const slidesToShow = useBreakpointMatch({
    desktopLarge: null,
    desktopHuge: 3,
  })

  const limit = isMobile ? (
    Math.ceil(themeBreakpoints.mobile / smallCardWidth)
  ) : (
    slidesToShow ? slidesToShow + 1 : Math.ceil(themeBreakpoints.desktopHuge / defaultCardWidth)
  )

  const { variants: sectionVariants, ...otherSectionProps } = sectionProps || {}

  const loadedSections = useAppSelector(store => store.Home.loadedSections)
  const readyToFetch = !sectionBlocker || loadedSections.includes(sectionBlocker)

  const { areas } = APIClient.Areas.useAreas({
    ...sectionFilters,
    limit,
    enabled: readyToFetch,
  })
  const isFetched = areas?.list?.query?.isFetched

  const isLoading = areas?.list?.query?.isLoading || !readyToFetch
  const isSectionVisible = areas?.items?.length > 0
  const hasSectionVariants = !TypeGuards.isNil(sectionVariants)

  const handleCompleteInitialLoad = () => {
    Home.setLoadedSections(sectionId)
  }

  onUpdate(() => {
    if (isFetched) {
      handleCompleteInitialLoad()
    }
  }, [isFetched])

  return (
    <Section
      title={title}
      variants={['column', 'marginTop:4', ...(hasSectionVariants ? sectionVariants : [])]}
      centerContent={!isMobile}
      headerStyle={isMobile ? styles.sectionHeader : null}
      isLoading={isLoading}
      isVisible={isSectionVisible}
      buttonProps={{
        debugName: 'View all button',
        text: 'View all',
        onPress: () => Navigation.Area.navigateToAreas(sectionFilters),
        ...buttonProps,
      }}
      showButton={showButton}
      {...otherSectionProps}
    >
      <AreaCarousel
        areas={areas?.items}
        areaCardProps={areaCardProps}
        slidesToShow={_slidesToShow ?? slidesToShow}
        carouselOptions={{
          dragFree: true,
          arrows: !isMobile,
          enablePagination: true,
          onEndReached: areas.getNextPage,
          hasNextPage: areas?.list?.query?.hasNextPage,
        }}
      />
    </Section>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  sectionHeader: {
    ...theme.spacing.marginHorizontal(2),
  },
  slider: {
    ...theme.spacing.paddingLeft(2),
  },
}), true)
