import { variantProvider } from '@/app'
import { MapIcon } from './MapIcon'
import { MapText } from './MapText'
import { CSSProperties } from 'react'
import { MapView } from './MapView'

export type MapArrowClusterProps = {
  total: number
  page: number
  style?: CSSProperties
  id: string
}

export const MapArrowCluster = ({ total, id = 'MapArrowCluster', style, page }: MapArrowClusterProps) => {
  const isNextDisabled = page >= total
  const isPrevDisabled = page <= 1

  const leftIcon = isPrevDisabled ? 'arrow-left-disabled' : 'arrow-left'
  const rightIcon = isNextDisabled ? 'arrow-right-disabled' : 'arrow-right'

  return (
    <MapView style={{ ...styles.wrapper, ...style }}>
      <MapIcon id={`${id}:prev`} className={'icon-small'} name={leftIcon} wrapperStyle={styles.leftIcon} />

      <MapView style={styles.separator} />
      <MapText text={`${page} of ${total}`} variants={['p2', 'neutral-9', 'semiBold']} style={styles.text} />
      <MapView style={styles.separator} />

      <MapIcon id={`${id}:next`} className={'icon-small'} name={rightIcon} wrapperStyle={styles.rightIcon} />
      <div style={styles.tail} />
    </MapView>)
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    boxShadow: '0px 0px 16px 16px #00000020',
    position: 'relative',
    backgroundColor: theme.colors.background,
    padding: theme.spacing.value(1),
    borderRadius: theme.borderRadius.small,
    width: 'fit-content',
    alignItems: 'center',
  },
  rightIcon: {
    paddingLeft: theme.spacing.value(1.5),
  },
  leftIcon: {
    paddingRight: theme.spacing.value(1.5),
  },
  separator: {
    backgroundColor: theme.colors.neutral4,
    height: 1,
    width: 16,
    position: 'relative',
    transform: 'rotate(90deg)',
  },
  text: {
    ...theme.spacing.marginHorizontal(1),
  },
  tail: {
    ...theme.presets.absolute,
    bottom: '-10px',
    left: '40%',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${theme.colors.background}`,
    rotate: '180deg',
  },
}), true)
