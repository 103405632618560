import { createForm, yup } from '@codeleap/common'

const miToKm = (mi: number) => mi * 1.60934

const distanceOptions = [
  { value: 5, label: 'Parkrun (5k)' },
  {
    value: miToKm(10),
    label: '10 miles',
  },
  { value: 10, label: '10k' },
  { value: 21.1, label: 'Half Marathon' },
  { value: miToKm(20), label: '20 miles' },
  { value: 42.2, label: 'Marathon' },
]

export const paceCalculatorForm = createForm('paceCalculator', {
  current_distance: {
    type: 'select',
    placeholder: 'Select a distance',
    label: 'Current distance',
    options: distanceOptions,
    defaultValue: '',
    validate: yup.string().required('This is a required field'),
  },
  current_time: {
    type: 'text',
    label: 'Time for current distance',
    placeholder: 'Type a time',
    validate: yup.string().required('This is a required field'),
  },
  target_distance: {
    type: 'select',
    label: 'Target distance',
    placeholder: 'Select a distance',
    options: distanceOptions,
    defaultValue: '',
    validate: yup.string().required('This is a required field'),
  },
  target_time: {
    type: 'text',
    label: 'Target time',
    placeholder: 'Select a time',
    defaultValue: null,
  },
  distance_unit: {
    type: 'text',
    defaultValue: 'km',
  },
})
