import {
  Page,
  Text,
  CenterWrapper,
  Section,
  View,
  AreaMap,
  DynamicPageHandler,
  CourseGrid,
  CourseFiltersProps,
  HTML,
  SearchInput,
  AllCoursesFilters,
  ImageSection,
} from '@/components'
import { variantProvider } from '@/app'
import { Navigation, useFlatlistProps, useIsMobile, AreaUtils, CourseUtils } from '@/utils'
import { APIClient } from '@/services'
import { Area } from '@/types'
import { useBreakpointMatch, useSearchParams } from '@codeleap/web'
import { onMount } from '@codeleap/common'
import { AppStatus } from '@/redux'
import { useState } from 'react'

type CoursesSectionsProps = {
  isMobile: boolean
  isLoading: boolean
  courses: ReturnType<typeof APIClient.Courses.useCourses>['courses']
  onPressViewAll?: () => void
  listProps: ReturnType<typeof useFlatlistProps>
}

const CoursesSections = (props: CoursesSectionsProps) => {

  const { courses, onPressViewAll, listProps } = props

  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 3,
    desktopHuge: 4,
  })

  return (

    <Section
      title={'Trails'}
      variants={['column', 'marginTop:2', 'marginBottom:4', 'title:h2'] as any}
      centerContent={false}
      isLoading={courses.list.query.isLoading}
      showButton
      buttonProps={{
        debugName: 'Trail section button',
        variants: ['minimal', 'small', 'padding:0'],
        onPress: onPressViewAll,
        text: 'View all',
      }}

    >
      <CourseGrid
        courses={courses?.items}
        numColumns={numColumns}
        {...listProps}
        debugName='Trail section grid'
      />
    </Section>
  )
}

export type AreaPageProps = {
  area: Area
}

export const AreaPage = ({ area }: AreaPageProps) => {

  const [params, setParams, reset] = useSearchParams()
  const [searchInput, setSearchInput] = useState(params?.title)

  const { courses } = APIClient.Courses.useCourses({
    enabled: !!area,
    lat: area?.latitude,
    lng: area?.longitude,
    radius: area?.radius,
    area_id: area?.use_radius ? null : area?.id,
    limit: 12,
    ...CourseUtils.getFiltersByParams(params),
  })

  const coverTitle = `Best runs in ${area?.title}`

  onMount(() => {
    AppStatus.set('idle')
  })

  const isMobile = useIsMobile()

  const listProps = useFlatlistProps(courses, {
    noMoreItemsText: '',
  })

  const coverImage = AreaUtils.getCoverImage(area)

  const courseFiltersProps: CourseFiltersProps = {
    params: params,
    setParams: setParams,
    results: listProps?.data?.length,
    isLoading: courses?.list?.query?.isLoading || courses?.list?.query?.isFetching || courses?.list?.query?.isRefetching,
    variants: ['noRightContent'],
  }

  async function onSearchChange(searchValue: string) {
    setParams({
      ...params,
      title: searchValue,
    })
  }

  return (
    <DynamicPageHandler item={area} isSSR>
      <Page
        centerContent={false}
        SEOProps={AreaUtils.getSEOProps(area)}
      >
        <ImageSection
          image={coverImage}
          imageProps={{
            alt: AreaUtils.getImageAlt(area),
          }}
          title={coverTitle}
          showBreadcrumbs
          breadcrumbsProps={{
            onNavigate: reset,
          }}
          variants={['marginTop:auto', 'marginBottom:3'] as any}
        />
        <CenterWrapper innerProps={{ variants: ['column'] }}>

          <View
            css={styles.sectionWrapper}
            variants={['gap:4', 'marginBottom:6']}
            responsiveVariants={{
              tabletSmall: ['marginBottom:4'],
              mobile: ['marginBottom:4'],
            }}
          >
            <View variants={['column', 'gap:2']}>
              {!isMobile ? <Text text={'Description'} /> : null}
              {area?.html_description ? (
                <HTML htmlContent={area.html_description} />
              ) : (
                <Text
                  text={`${area?.description}`}
                />
              )}
            </View>
            <AreaMap
              area={area}
              courses={courses?.items}
            />
          </View>

          <AllCoursesFilters
            showLoader={false}
            variants={['marginBottom:3']}
            courseFiltersProps={courseFiltersProps}
            preservedParams={['title']}
            SearchBar={
              <SearchInput
                debugName='search input: AreaPage'
                onSearchChange={onSearchChange}
                placeholder='Search for a run'
                debounce={800}
                value={searchInput}
                onValueChange={setSearchInput}
                variants={['noError', 'border:smallish', 'searchPlaces']}
              />
            }
          />

          <CoursesSections
            courses={courses}
            isMobile={isMobile}
            isLoading={courses.list.query.isLoading}
            onPressViewAll={() => (
              Navigation.Course.navigateToExplore({
                lat: area?.latitude,
                lng: area?.longitude,
                radius: area?.radius,
                ...(area.use_radius ? null : { area_id: area?.id }),
              })
            )}
            listProps={listProps}
          />

        </CenterWrapper>
      </Page>
    </DynamicPageHandler>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    imageSectionWrapper: {
      minHeight: 400,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    bottomRadius: {
      [theme.media.up('mobile')]: {
        borderBottomRightRadius: theme.borderRadius.medium,
        borderBottomLeftRadius: theme.borderRadius.medium,
      },
    },
    imageContent: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      ...theme.spacing.paddingHorizontal(2),
      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)`,
      zIndex: 1,
    },
    image: {
      objectFit: 'cover',
      ...theme.presets.absolute,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      zIndex: 0,
    },
    map: {
      borderRadius: theme.borderRadius.medium,
      overflow: 'hidden',
      width: '100%',
      height: 300,
    },
    sectionWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      [theme.media.down('mobile')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
  true,
)

