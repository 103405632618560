import { variantProvider } from '@/app'
import { MapFocusButton } from '@/components'
import { Course } from '@/types'
import { useIsMobile } from '@/utils'

export type ExploreMapControlsProps = {
  selectedCourse?: Course
  onFollowUser?: () => void
}

export const ExploreMapControls = ({ onFollowUser }: ExploreMapControlsProps) => {
  const isMobile = useIsMobile()

  if (isMobile) return null

  return (
    <MapFocusButton onPress={onFollowUser} style={styles.focusButton} />
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  focusButton: {
    ...theme.presets.absolute,
    top: 70,
    right: 0,
    zIndex: 1,
    margin: theme.spacing.value(3),
  },
}), true)
