import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

export type CourseStatsComposition =
  | 'wrapper'
  | 'itemWrapper'
  | 'itemIcon'
  | 'itemText'
  | 'itemLabel'
  | `itemIcon`

const createCourseCardStyle =
  variantProvider.createVariantFactory<CourseStatsComposition>()

export const CourseStatsStyles = {
  default: createCourseCardStyle(theme => {
    const color = theme.colors.primary3

    return {
      wrapper: {
        ...theme.presets.justifySpaceBetween,
        gap: theme.spacing.value(2),
      },
      itemWrapper: {
        flex: 1,
        ...theme.presets.column,
        ...theme.presets.alignCenter,
      },
      itemIcon: {
        color,
        ...theme.sized(3),
      },
      itemText: {
        marginTop: theme.spacing.value(0.5),
        ...assignTextStyle('h4')(theme).text,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        color,
      },
      itemLabel: {
        ...assignTextStyle('p3')(theme).text,
        color: theme.colors.neutral9,
      },
    }
  }),
}
