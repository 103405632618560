import { navigate } from 'gatsby'
import { getPlatformLink } from '../misc'

export const navigateToStore = (blank = false) => {
  const storeLink = getPlatformLink()

  if (blank) {
    window.open(storeLink, '_blank')
  } else {
    navigate(`${storeLink}`)
  }
}
