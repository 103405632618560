import { AppImages, Settings, variantProvider } from '@/app'
import { Image, Touchable } from '@/components'
import { PropsOf } from '@codeleap/common'
import { navigate } from 'gatsby'

type StoreButtonProps = Omit<PropsOf<typeof Touchable>, 'debugName'> & {
  store: 'apple' | 'play_store'
}

export const StoreButton = (props: StoreButtonProps) => {
  const { store, ...rest } = props
  const isApple = store === 'apple'
  const image = isApple ? AppImages.AppleStoreBadge : AppImages.PlayStoreBadge
  const storeLink = isApple ? Settings.Stores.Apple.url : Settings.Stores.Android.url

  return (
    <Touchable
      debugName={`Store Badge: ${store}`}
      onPress={() => navigate(storeLink)}
      style={styles.wrapper}
      {...rest}
    >
      <Image source={image} style={styles.badge} alt={
        isApple ? 'Download on the Apple Store' : 'Get it on Google Play'
      } />
    </Touchable>
  )
}

const BUTTON_HEIGHT = 40

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    transitionDuration: '0.2s',
    '&:hover': {
      opacity: 0.7,
    },
  },
  badge: {
    height: BUTTON_HEIGHT,
  },
}), true)
