import { PropsOf } from '@codeleap/common'
import { View } from '@/components'

export type HTMLProps = PropsOf<typeof View> & {
  htmlContent: string
}

export const HTML = (props: HTMLProps) => {
  const { htmlContent, ...rest } = props
  return <View className='html-content' dangerouslySetInnerHTML={{ __html: htmlContent }} {...rest} />
}
