import { onMount, onUpdate } from '@codeleap/common'
import { LocationUtils } from '../location'
import { UserLocation } from '@/redux'
import { CookiesKeys } from '@/app'
import { UserLocationUtils } from '../UserLocation'
import Cookies from 'js-cookie'

export const useUpdateLocation = () => {
  const { location } = UserLocationUtils.useCurrentLocation()

  onMount(() => {
    LocationUtils.getLocation((pos) => {
      const coords = pos?.coords
      const data = {
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        accuracy: coords?.accuracy,
        speed: coords?.speed,
        heading: coords?.heading,
        altitude: coords?.altitude,
      }
      UserLocation.setLocation(data)
      Cookies.set(CookiesKeys.USER_LOCATION, JSON.stringify(data))
    })
  })

  onMount(() => {
    LocationUtils.getIPInfo().then((response) => {
      const { data } = response
      const [latitude, longitude] = data.loc?.split(',')
      UserLocation.setIPInfo({ ...data, latitude, longitude })
      Cookies.set(CookiesKeys.IP_INFO, JSON.stringify({ ...data, latitude, longitude }))
    })
  })

  onUpdate(() => {
    if (location?.latitude && location?.longitude) {
      LocationUtils.getLocationDetails({ lat: location?.latitude, lng: location?.longitude }).then((response) => {
        const { data: details } = response
        const newLocation = {
          ...location,
          city: LocationUtils.getCityName(details),
        }
        UserLocation.setLocation(newLocation)
      })
    }
  }, [location?.latitude, location?.longitude])
}
