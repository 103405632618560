import { variantProvider } from '@/app'
import { useAnimatedStyle } from '@codeleap/web'
import { ExploreMapCourseCard } from '../ExploreMapCourseCard'
import { GetAppButton, View } from '@/components'
import { ExploreMapFooterProps } from './types'
import { ExploreMapRedux, useAppSelector } from '@/redux'

const EXPLORE_CARD_HEIGHT = 105

const Item = ({ course, selectedCourse, ...rest }) => {
  const isVisible = selectedCourse?.id === course.id

  const animation = useAnimatedStyle(() => ({
    bottom: isVisible ? 0 : -EXPLORE_CARD_HEIGHT,
    opacity: isVisible ? 1 : 0,
    display: isVisible ? 'block' : 'none',
    transition: {
      duration: 0.15,
    },
  }), [isVisible])

  return (
    <View
      style={styles.cardWrapper}
      animated
      animatedProps={animation}
      {...rest}
    >
      <ExploreMapCourseCard
        course={course}
        onPress={() => ExploreMapRedux.setSidebarCourse(course)}
      />
    </View>
  )

}

export const WebExploreMapFooter = ({ selectedCourse, onCardBlur, onCardHover }: ExploreMapFooterProps) => {
  const hoveredCourse = useAppSelector(state => state.ExploreMapRedux.hoveredCourse)

  const courseToShow = selectedCourse || hoveredCourse

  const courses = !courseToShow ? [] : [courseToShow]

  return (
    <View style={styles.wrapper}>
      <GetAppButton
        style={styles.getAppButton}
        variants={['card', 'exploreAppButton']}
        icon='new-logo'
        text={`Get the app`}
        styles={{
          icon: { backgroundColor: 'transparent', }
        }}
      />

      {courses?.map(course => (
        <Item
          key={course.id}
          course={course}
          selectedCourse={courseToShow}
          onHover={() => onCardHover?.(course)}
          onBlur={() => onCardBlur?.(course)}
        />
      ))}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.absolute,
    ...theme.presets.fullWidth,
    bottom: 0,
    left: 0,
    ...theme.spacing.paddingHorizontal(2),
  },

  cardWrapper: {
    ...theme.presets.absolute,
    left: '50%',
    transform: 'translateX(-50%)',
  },

  getAppButton: {
    maxWidth: `180px !important`,
    minWidth: `unset !important`,
    maxHeight: 48,
  },
}), true)
