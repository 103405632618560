import { ActivityIndicatorComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

export type EmblaCarouselComposition =
  | 'wrapper'
  | 'innerWrapper'
  | 'container'
  | 'slide'
  | 'arrowWrapper'
  | 'arrowWrapper:left'
  | 'arrowWrapper:right'
  | 'dots'
  | 'dot'
  | 'fakePadding'
  | `loader${Capitalize<ActivityIndicatorComposition>}`

const createEmblaCarouselStyle = variantProvider.createVariantFactory<EmblaCarouselComposition>()

const DOT_SIZE = 8

export const EmblaCarouselStyles = {
  default: createEmblaCarouselStyle((theme) => ({
    wrapper: {
      ...theme.presets.row,
      ...theme.presets.relative,
    },
    innerWrapper: {
      overflow: 'hidden',
      ...theme.presets.column,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    container: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
    },
    slide: {
      minWidth: 0,
    },
    arrowWrapper: {
      ...theme.presets.center,
      minWidth: theme.spacing.value(4),
    },
    'arrowWrapper:left': {
      ...theme.presets.absolute,
      left: -theme.spacing.value(4),
      top: 0,
      bottom: 0,
      ...theme.presets.center,
      minWidth: theme.spacing.value(4),
    },
    'arrowWrapper:right': {
      ...theme.presets.absolute,
      right: -theme.spacing.value(4),
      top: 0,
      bottom: 0,
      ...theme.presets.center,
      minWidth: theme.spacing.value(4),
    },
    arrowIcon: {
      color: theme.colors.primary3,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    'arrowIcon:disabled': {
      color: theme.colors.neutral5,
    },
    arrowTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'arrowTouchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
    dots: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      gap: theme.spacing.value(1),
      ...theme.presets.justifyCenter,
      ...theme.spacing.paddingVertical(2),
    },
    dot: {
      height: DOT_SIZE,
      width: DOT_SIZE,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral3,
    },
    'dot:selected': {
      height: DOT_SIZE,
      width: DOT_SIZE,
      backgroundColor: theme.colors.primary3,
      cursor: 'pointer',
      borderRadius: theme.borderRadius.rounded,
    },
    fakePadding: {
      width: theme.spacing.value(2),
    },
    'loaderWrapper': {
      ...theme.presets.fullHeight,
      ...theme.presets.center,
    },
  })),
  'slideLeftPadding:1': createEmblaCarouselStyle((theme) => ({
    slide: {
      ...theme.spacing.paddingLeft(1),
    },
  })),
  'slideLeftPadding:2': createEmblaCarouselStyle((theme) => ({
    slide: {
      ...theme.spacing.paddingLeft(2),
    },
  })),
  noUserSelect: createEmblaCarouselStyle((theme) => ({
    container: {
      userSelect: 'none',
    },
  })),
}

