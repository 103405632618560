import { onUpdate } from '@codeleap/common'
import { MutableRefObject, useState } from 'react'

export const useRefDimensions = (ref: MutableRefObject<any>) => {
  const [refWidth, setRefWidth] = useState(0)
  const [refHeight, setRefHeight] = useState(0)

  const handleResize = () => {
    if (ref.current) {
      setRefWidth(ref.current.clientWidth)
      setRefHeight(ref.current.clientHeight)
    }
  }

  onUpdate(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref.current])

  return {
    refWidth,
    refHeight,
  }
}
