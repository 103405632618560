import { IS_SSR, Theme } from '@/app'
import { BreadcrumbsStyles } from '@/app/stylesheets'
import { BreadcrumbsComposition } from '@/app/stylesheets/Breadcrumbs'
import { View, Text, Icon, Scroll } from '@/components'
import { BreadcrumbsUtils, useIsMobile } from '@/utils'
import { AnyFunction, ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { graphql, navigate as navigateGatsby, useStaticQuery } from 'gatsby'
import React from 'react'

type SeparatorProps = {
  isFirst: boolean
  isLast: boolean
}

type ItemProps = SeparatorProps & {
  item: {
    label: string
    path: string
  },
  fakePadding: boolean
}

export type BreadcrumbProps<P extends object = {}, T extends object = {}> =
  Omit<PropsOf<typeof View>, 'variants' | 'styles'> &
  ComponentVariants<typeof BreadcrumbsStyles> &
  {
    styles?: StylesOf<BreadcrumbsComposition>
    Separator?: (props: SeparatorProps) => JSX.Element
    RenderItem?: (props: ItemProps) => JSX.Element
    onNavigate?: AnyFunction
    disableFirstFakePadding?: boolean
  }

export const Breadcrumbs = <P extends object, T extends object>(props: BreadcrumbProps<P, T>) => {
  const {
    variants,
    styles,
    responsiveVariants,
    style,
    Separator = null,
    RenderItem = null,
    onNavigate,
    disableFirstFakePadding = false,
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:Breadcrumbs',
    typeof BreadcrumbsStyles
  >('u:Breadcrumbs', {
    variants,
    styles,
    responsiveVariants,
  })

  const data = useStaticQuery(query)
  const isMobile = useIsMobile()

  const paths = BreadcrumbsUtils.getPaths(data?.allSitePage?.nodes)

  const DefaultSeparator = (props: SeparatorProps) => {

    return (
      <Icon
        name='chevron-right'
        size={Theme.values.iconSize[2]}
        color={Theme.colors.light.neutral6}
        debugName='breadcrumb:icon'
      />
    )
  }

  const DefaultItem = (itemProps: ItemProps) => {
    const _itemProps = itemProps as any
    const { item, fakePadding, isFirst, isLast } = _itemProps
    const path = !!item?.path ? item.path : '/'
    const isFocused = IS_SSR ? false : window.location.pathname === `${item.path}/`
    const fakeFirstPadding = isFirst && fakePadding
    const fakeLastPadding = isLast && fakePadding

    return (
      <Text
        variants={['p1', (!!path) && 'cursor:pointer', isFocused && 'color:primary3', isFocused && 'medium', fakeFirstPadding && 'marginLeft:2', fakeLastPadding && 'marginRight:2']}
        css={[variantStyles.label]}
        text={item?.label}
        component='li'
        onPress={() => {
          if (!!path) {
            navigateGatsby(path)
            onNavigate?.()
          }
        }}
      />
    )
  }

  const Item = !TypeGuards.isNull(RenderItem) ? RenderItem : DefaultItem
  const _Separator = !TypeGuards.isNull(Separator) ? Separator : DefaultSeparator

  return (
    <View css={[variantStyles.wrapper, style]} component='nav'>
      <Scroll css={[variantStyles.innerWrapper]}>
        {paths.map((path, index) => {

          const isFirt = index === 0
          const isLast = index === paths?.length - 1
          const isFistSeparator = index === 1
          const fakePadding = isFirt ? (isMobile && !disableFirstFakePadding) : isMobile

          return (
            <View css={[variantStyles.itemWrapper]} key={`breadcrumbs-item:${path?.label}-${index}`}>
              {index == 0 ? null : <_Separator isFirst={isFistSeparator} isLast={isLast} />}

              <Item
                item={path}
                key={`${path.label}${index}`}
                isFirst={isFirt}
                isLast={isLast}
                fakePadding={fakePadding}
              />
            </View>
          )
        })}
      </Scroll>
    </View>
  )
}

const query = graphql`
  query {
    allSitePage {
      nodes {
        path
        pageContext
      }
    }
  }
`