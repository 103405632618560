import { View } from '@/components'
import { LineChartProps } from './types'
import { XYPlot, LineSeries } from 'react-vis'
import { LineChartStyles } from '@/app/stylesheets'
import { useDefaultComponentStyle } from '@codeleap/common'
import { useLineChartMetrics } from './utils/useLineChartMetrics'
import { LineChartXAxis, LineChartYAxis } from './components'
import { useIsTablet, useMaxContentWidth } from '@/utils'

export const LineChart = ({
  data,
  width = 400,
  forceMax,
  forceMin,
  height = 100,
  yData,
  xData,
  variants,
  responsiveVariants,
  styles,
  chartProps,
  ...rest
}: LineChartProps) => {
  const variantStyles = useDefaultComponentStyle<
  'u:LineChart',
  typeof LineChartStyles
  >('u:LineChart', {
    responsiveVariants,
    variants,
    styles,
  })

  const { width: maxContentWidth } = useMaxContentWidth()

  const isTablet = useIsTablet()

  const chartWidth = isTablet ? maxContentWidth - 60 : width

  const { formmatedXData } = useLineChartMetrics({ data, height, xData, forceMax, forceMin })

  return (
    <View css={variantStyles.wrapper} variants={variants} {...rest}>
      <View css={variantStyles.leftWrapper}>
        <XYPlot
          width={chartWidth}
          height={height}
          style={variantStyles.lineChartWrapper}
          margin={variantStyles.lineChartWrapper.margin}
        >
          <LineSeries
            curve={'curveMonotoneX'}
            data={data}
            {...variantStyles.lineChart}
            {...chartProps}
          />
        </XYPlot>
        {!!xData && <LineChartXAxis data={formmatedXData} width={chartWidth} variantStyles={variantStyles} />}
      </View>

      {!!yData && <LineChartYAxis data={yData} variantStyles={variantStyles} />}

    </View>
  )
}

export * from './components'
export * from './types'

