import { Course, CourseCluster } from '@/types'
import { createSlice } from '@codeleap/common'
import { store } from './index'

export type ExploreMapRedux = {
  hoveredCourse: Course
  courseClusters: CourseCluster[]
  selectedCluster: CourseCluster
  sidebarCourse: Course
  detailedCourses: Course[]
  sidebarVisible: boolean
}

const initialState: ExploreMapRedux = {
  hoveredCourse: null,
  courseClusters: [],
  detailedCourses: [],
  selectedCluster: null,
  sidebarCourse: null,
  sidebarVisible: true,
}

export const exploreMapSlice = createSlice({
  name: 'ExploreMapRedux',
  initialState,
  reducers: {
    setSidebarCourse: (_, sidebarCourse: Course) => ({ sidebarCourse }),
    setHoveredCourse: (_, hoveredCourse: Course) => ({ hoveredCourse }),
    setCourseClusters: (_, courseClusters: CourseCluster[]) => ({
      courseClusters,
    }),
    setSelectedCluster: (_, selectedCluster: CourseCluster) => ({
      selectedCluster,
    }),
    setDetailedCourses: (_, detailedCourses: Course[]) => ({ detailedCourses }),
    addDetailedCourse: (state, detailedCourse: Course) => ({
      detailedCourses: [...state.detailedCourses, detailedCourse],
    }),
    toggleSidebar: (state, visibility?: boolean) => ({
      sidebarVisible: visibility || !state.sidebarVisible,
    }),
  },
  asyncReducers: {},
})

const findDetailedCourse = (id: Course['id']) => {
  const detailedCourses = store.getState().ExploreMapRedux.detailedCourses
  return detailedCourses.find((course) => course.id === id)
}

export const ExploreMapActions = {
  findDetailedCourse,
}
