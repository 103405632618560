import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { CenterWrapperComposition } from './CenterWrapper'
import { FooterComposition } from './Footer'
import { HeaderComposition } from './Header'

export type PageComposition =
  'wrapper' |
  'innerWrapper' |
  'backgroundImage' |
  'innerWrapper:backgroundImage' |
  `content${Capitalize<CenterWrapperComposition>}` |
  `footer${Capitalize<FooterComposition>}` |
  `header${Capitalize<HeaderComposition>}`

const createPageStyle = variantProvider.createVariantFactory<PageComposition>()

const presets = includePresets((s) => createPageStyle(() => ({ wrapper: s })))

export const PageStyles = {
  ...presets,
  default: createPageStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
      minHeight: '100svh',
      width: '100%',
      backgroundColor: theme.colors.background,
    },
    'contentWrapper': {
      flex: 1,
      minHeight: '90svh',
      zIndex: 1,
    },
    'contentInnerWrapper': {
      ...theme.presets.column,
    },
    'innerWrapper': {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
    },
    'innerWrapper:backgroundImage': {
      ...theme.presets.absolute,
    },
    backgroundImage: {
      width: '100vw',
      minHeight: '100svh',
      position: 'relative',
      objectFit: 'cover',
    },
  })),
  fullContentViewport: createPageStyle((theme) => ({
    contentWrapper: {
      minHeight: '100svh',
    },
    contentInnerWrapper: {
      minHeight: '100svh',
    },
  })),

  explore: createPageStyle((theme) => ({
    headerInnerWrapper: {
      paddingLeft: `${theme.spacing.value(2)}px !important`,
      paddingRight: `${theme.spacing.value(2)}px !important`,
      maxWidth: 'unset',
      alignItems: 'center',
    },
    headerLogo: {
      marginTop: theme.spacing.value(1),
    },
  })),
}
