import {
  AreaSection, AreaSectionProps,
} from '@/components'
import { Navigation, UserLocationUtils, useIsMobile } from '@/utils'
import { AreaFilters } from '@/types'

export const NationalParksSection = (props: Partial<AreaSectionProps>) => {
  const { location } = UserLocationUtils.useCurrentLocation()

  const isMobile = useIsMobile()
  const sectionFilters: AreaFilters = {
    title: 'National Park',
    lat: location?.latitude,
    lng: location?.longitude,
  }

  return (
    <AreaSection
      title='Best Runs in National Parks'
      sectionFilters={sectionFilters}
      areaCardProps={{
        variants: [isMobile ? 'small' : null],
        onPress: (area) => Navigation.Area.navigateToDetails(area),
      }}
      {...props}
    />
  )
}

