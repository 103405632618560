import { BottomSheet, BottomSheetProps } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useDefaultComponentStyle, ComponentVariants, StylesOf } from '@codeleap/common'
import { BottomDrawerStyles, BottomDrawerComposition } from '@/app/stylesheets'
import { Scroll, View } from '@/components'
import React from 'react'

export type BottomDrawerBaseProps = {
  styles?: StylesOf<BottomDrawerComposition>
  children?: React.ReactNode
} & BottomSheetProps & ComponentVariants<typeof BottomDrawerStyles>

export const BottomDrawerBase = (props?: BottomDrawerBaseProps) => {

  const { open, variants, responsiveVariants, children, styles, blocking = true, ...rest } = props

  const variantStyles = useDefaultComponentStyle<
    'u:BottomDrawer',
    typeof BottomDrawerStyles
  >('u:BottomDrawer', {
    variants,
    styles,
    responsiveVariants,
  })

  return (
    <BottomSheet
      open={!!open}
      scrollLocking={true}
      sibling={blocking ? (
        <View
          data-rsbs-backdrop='true'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onDismiss?.();
          }}
        />
      ) : null}
      blocking={false}
      {...rest}
    >
      <Scroll variants={['column', 'padding:2']} style={variantStyles.innerWrapper}>
        {children}
      </Scroll>
    </BottomSheet>
  )
}
