import { Theme, variantProvider } from '@/app'
import {
  CourseDistance,
  View,
  Text,
  CourseStats,
  CourseTags,
  CourseAscentChart,
  Button,
  GetAppButton,
} from '@/components'
import { Course } from '@/types'
import { useMediaQueryDown } from '@/utils'
import { useWindowSize } from '@codeleap/web'
import { useState } from 'react'
import { COURSE_EMBED_HEADER_HEIGHT, COURSE_EMBED_MOBILE_HEADER_HEIGHT } from './CourseEmbedHeader'
import { onUpdate } from '@codeleap/common'

export type CourseEmbedInfoBarProps = {
  course: Course
}

export const CourseEmbedInfoBar = ({ course }: CourseEmbedInfoBarProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [width, _] = useWindowSize()
  const wrapperWidth = width > MAX_WIDTH ? MAX_WIDTH : width
  const isTablet = useMediaQueryDown('tablet')

  onUpdate(() => {
    if (!isTablet) setIsExpanded(false)
  }, [isTablet])

  const chart_width = ((wrapperWidth - Theme.spacing.value(18)) * 0.33) - Theme.spacing.value(8)

  return (
    <View css={[styles.wrapper, isExpanded ? styles['wrapper:expanded'] : null]}>
      <View css={[styles.card, isExpanded ? styles['card:expanded'] : null]}>
        {!isExpanded && (
          <GetAppButton
            blank
            variants={['card', 'exploreAppButton']}
            icon='new-logo'
            text={`Get the app`}
            style={styles.getAppButton}
          />
        )}
        <View variants={['column']} css={styles.column}>
          {isTablet && isExpanded && (
            <Button
              debugName='Show less'
              text='Show less'
              rightIcon='minus'
              variants={['secondary', 'marginBottom:3']}
              style={{ maxWidth: 136 }}
              onPress={() => setIsExpanded(false)}
            />
          )}

          <View>
            <CourseDistance
              course={course}
              variants={['small']}
            />
            <Text
              variants={[`h4`, 'marginLeft:2']}
              text={course?.title}
              component='h4'
              className='truncate-1'
            />
          </View>

          <CourseStats
            course={course}
            variants={['marginTop:2'] as any}
            responsiveVariants={{ tabletSmall: ['marginVertical:2'] } as any}
          />

          {isTablet && !isExpanded && (
            <Button
              debugName='Show more'
              text='Show more'
              rightIcon='plus'
              variants={['secondary', 'margin:auto', 'marginTop:1']}
              style={{ maxWidth: 136 }}
              onPress={() => setIsExpanded(true)}
            />
          )}

        </View>

        {(!isTablet || isExpanded) && (
          <View variants={['column']} css={styles.column}>
            <Text text='Description' variants={['h5', 'marginBottom:0.5']} />
            <Text text={course?.description} className='truncate-3' />
            <View variants={['marginTop:2', 'wrap', 'gap:1']} component='ul'>
              {/* @ts-ignore */}
              <CourseTags course={course} maxTags={3} />
            </View>
          </View>
        )}

        {(!isTablet || isExpanded) && (
          <View variants={['flex']} css={styles.column} >
            <CourseAscentChart
              title={null}
              course={course}
              width={chart_width}
            />
          </View>)
        }
      </View>
    </View >
  )
}

const MAX_WIDTH = 1600

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.absolute,
    ...theme.presets.justifyCenter,
    bottom: 0,
    ...theme.spacing.padding(4),

    [theme.media.down('tablet')]: {
      ...theme.spacing.padding(2),
    },
  },
  'wrapper:expanded': {
    [theme.media.down('tablet')]: {
      ...theme.spacing.padding(0),
    },
  },
  card: {
    height: 216,
    maxWidth: MAX_WIDTH,
    gap: theme.spacing.value(5),
    ...theme.presets.fullWidth,

    //@ts-ignore
    borderRadius: theme.borderRadius.large,
    backgroundColor: theme.colors.neutral1,
    padding: theme.spacing.value(3),

    [theme.media.down('tablet')]: {
      transition: 'border-radius 0.5s ease-out, height 0.3s ease-out',
      ...theme.presets.relative,
      maxWidth: 500,
      maxHeight: 'unset',
      padding: theme.spacing.value(2),
    },
  },
  'card:expanded': {
    [theme.media.down('tablet')]: {
      transition: 'border-radius 0.5s ease-out, height 0.5s ease-out',
      ...theme.presets.column,
      maxHeight: 'unset',
      maxWidth: 'unset',
      height: `calc(100vh - ${COURSE_EMBED_MOBILE_HEADER_HEIGHT}px)`,
      borderRadius: 0,
    },
  },
  column: {
    width: '33.33%',

    [theme.media.down('tablet')]: {
      width: '100%',
    },
  },
  getAppButton: {
    ...theme.presets.fixed,
    top: COURSE_EMBED_HEADER_HEIGHT,
    left: 0,
    maxWidth: `180px !important`,
    minWidth: `unset !important`,
    margin: theme.spacing.value(4),

    [theme.media.down('tablet')]: {
      ...theme.presets.absolute,
      margin: 'unset',
      top: -(48 + theme.spacing.value(1)),
      left: 0,
      marginBottom: theme.spacing.value(1),
    },
  },
}), true)

