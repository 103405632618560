import { store, useAppSelector } from '@/redux'

const getLocation = () => store.getState().UserLocation.location

const useLocation = () => useAppSelector((state) => state.UserLocation)

const getCurrentLocation = () => store.getState().UserLocation.location || store.getState().UserLocation.ipInfo

const useCurrentLocation = () => {
  const { ipInfo, location } = useLocation()
  return { location: location || ipInfo }
}

export const UserLocationUtils = {
  getLocation,
  useLocation,
  useCurrentLocation,
  getCurrentLocation,
}
