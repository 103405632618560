import { TooltipPresets, TooltipComposition } from '@codeleap/web'
import { keyframes } from '@emotion/react'
import { variantProvider } from '../theme'

const createTooltipStyle = variantProvider.createVariantFactory<TooltipComposition>()

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }    
`
const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
export const AppTooltipStyles = {
  ...TooltipPresets,
  default: createTooltipStyle((theme) => {

    const contentDefaultStyle = {
      position: 'relative',
      ...theme.spacing.padding(2),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral2,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      fill: theme.colors.neutral2,
    }

    return {
      content: {
        ...contentDefaultStyle,
      },
      'content:left': {
        ...contentDefaultStyle,
        animationName: slideRightAndFade,
      },
      'content:right': {
        ...contentDefaultStyle,
        animationName: slideLeftAndFade,
      },
      'content:top': {
        ...contentDefaultStyle,
        animationName: slideDownAndFade,
      },
      'content:bottom': {
        ...contentDefaultStyle,
        animationName: slideUpAndFade,
      },
    }
  }),
  rounded: createTooltipStyle((theme) => ({
    content: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  bare: createTooltipStyle((theme) => ({
    content: {
      ...theme.spacing.padding(0),
      background: 'none',
    },
  })),
  small: createTooltipStyle((theme) => ({
    content: {
      ...theme.spacing.padding(1),
    },
  })),
  large: createTooltipStyle((theme) => ({
    content: {
      ...theme.spacing.padding(4),
    },
  })),
  success: createTooltipStyle((theme) => ({
    content: {
      backgroundColor: theme.colors.positive1,
    },
  })),
  headerDropdown: createTooltipStyle((theme) => ({
    wrapper: {
      width: '100vw',
      borderBottom: `1px solid ${theme.colors.neutral3}`,
      backdropFilter: 'blur(4px)',
    },
    content: {
      backgroundColor: theme.colors.neutral1,
      width: '100vw',
      zIndex: 9999999,
      top: 14,
      boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    },
    'content:bottom': {
      top: 14,
    },
  })),
  overlap: createTooltipStyle(theme => ({
    content: {
      zIndex: 9999,
    },
  })),

}
