import { AppIcon, variantProvider } from '@/app'
import { Modal, Icon, Text, View, Touchable } from '@/components'
import { PropsOf } from '@codeleap/common'

export type ItemModalProps = {
  leftIcon?: AppIcon
  rightIcon?: AppIcon
  text: string
  onPress: () => void
}

export type ItemsModalProps = PropsOf<typeof Modal> & {
  items: ItemModalProps[]
}

const Item = ({ leftIcon, rightIcon, text }: ItemModalProps) => {
  return (
    <View variants={['row', 'paddingVertical:1']}>
      {!!leftIcon && <Icon debugName='Item' name={leftIcon} variants={['marginRight:1']} />}
      <Text text={text} />
      {!!rightIcon && <Icon debugName='RightIcon' name={'check-circle'} variants={['marginLeft:auto']} />}
    </View>
  )
}

export const ItemsModal = ({ items, ...rest }: ItemsModalProps) => {
  return (
    <Modal
      variants={['centered', 'titleLeft']}
      css={styles.modalWrapper}
      {...rest}

    >
      <View variants={['column', 'marginTop:1']}>
        {items.map((item, index) => (
          <Touchable key={item.text} debugName={item.text} onPress={item?.onPress}>
            <View variants={['column', 'fullWidth']}>
              <Item {...item} />
              {index !== items.length - 1 && (
                <View variants={['separator']} />
              )}
            </View>
          </Touchable>
        ))}
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  modalWrapper: {
    maxWidth: 480,
    width: '100%',
  },
  text: {
    ...theme.border.neutral6(theme.values.borderWidth.small),
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing.value(1),
  },
  button: {
    ...theme.presets.fullWidth,
    marginTop: theme.spacing.value(3),
    maxWidth: 320,
  },
}), true)

