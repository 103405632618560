/* eslint-disable max-lines */
import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'
import { TagComposition } from '@codeleap/web'
import { CourseDistanceComposition } from './CourseDistance'

export type CourseCardComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | 'contentWrapper'
  | 'mainContent'
  | 'title'
  | 'awayText'
  | `tag${Capitalize<TagComposition>}`
  | `distance${Capitalize<CourseDistanceComposition>}`
  | 'image'
  | 'gradient'
  | 'infosWrapper'
  | 'location'
  | 'statsWrapper'
  | 'statsItem'
  | 'statsTitle'
  | 'statsSubtitle'
  | 'statsDot'
  | 'description'
  | 'bottomTitle'
  | 'bottomAwayText'
  | 'bottomCardContentWrapper'
  | 'bottomCardContentTitle'
  | 'bottomCardContentDistanceAway'

const createCourseCardStyle = variantProvider.createVariantFactory<CourseCardComposition>()

export const COURSE_CARD_CONSTANTS = {
  DEFAULT: {
    WIDTH: {
      DEFAULT: 402,
      MOBILE: 245,
    },
    HEIGHT: {
      DEFAULT: 250,
      MOBILE: 150,
    },
    DISTANCE_WRAPPER_SIZE: {
      DEFAULT: 54,
      MOBILE: 36,
    },
  },
  WIDE: {
    WIDTH: {
      DEFAULT: 343,
      MOBILE: 343,
    },
    HEIGHT: {
      DEFAULT: 238,
      MOBILE: 180,
    },
    DISTANCE_WRAPPER_SIZE: 48,
  },
  HIGH: {
    WIDTH: {
      DEFAULT: 240,
      MOBILE: 150,
    },
    HEIGHT: {
      DEFAULT: 400,
      MOBILE: 250,
    },
    DISTANCE_WRAPPER_SIZE: {
      DEFAULT: 54,
      MOBILE: 36,
    },
  },
  LARGE: {
    WIDTH: 235,
    HEIGHT: 200,
    DISTANCE_WRAPPER_SIZE: {
      WIDTH: 36,
      HEIGHT: 42,
    }
  },
  SMALL: {
    WIDTH: {
      DEFAULT: 193,
      MOBILE: 100,
    },
    HEIGHT: {
      DEFAULT: 300,
      MOBILE: 150,
    },
    DISTANCE_WRAPPER_SIZE: {
      DEFAULT: 54,
      MOBILE: 36,
    },
  },
  SQUARE: {
    WIDTH: {
      DEFAULT: 235,
      MOBILE: 100,
    },
    HEIGHT: {
      DEFAULT: 200,
      MOBILE: 100,
    },
    DISTANCE_WRAPPER_SIZE: 36,
  },
  BANNER: {
    WIDTH: '100%',
    HEIGHT: 400,
    DISTANCE_WRAPPER_SIZE_DESKTOP: 80,
    DISTANCE_WRAPPER_SIZE_MOBILE: 60,
  },
}

const BREAKPOINT = 'mobile'

export const CourseCardStyles = {
  default: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT,
      width: COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT,
      ...theme.presets.relative,
      overflow: 'hidden',
      ...theme.spacing.padding(0),
      borderRadius: 0,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.MOBILE,
        width: COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.MOBILE,
      },
    },
    cardInnerWrapper: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
    },
    contentWrapper: {
      ...theme.spacing.gap(1),
      ...theme.presets.column,
      ...theme.presets.fullHeight,
      ...theme.spacing.padding(2),
      ...theme.spacing.paddingTop(1),
      ...theme.presets.relative,
      height: COURSE_CARD_CONSTANTS.DEFAULT.HEIGHT.DEFAULT,
      backgroundColor: theme.colors.primary2,
      borderRadius: theme.borderRadius.smallish,
      overflow: 'hidden',

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.DEFAULT.HEIGHT.MOBILE,
      },
    },
    distanceWrapper: {
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.paddingVertical(1.685),
      ...theme.spacing.paddingHorizontal(1.625),
      backgroundColor: theme.colors.lightWhite,
      minWidth: COURSE_CARD_CONSTANTS.DEFAULT.DISTANCE_WRAPPER_SIZE.DEFAULT,
      ...theme.presets.absolute,
      ...theme.presets.left,
      ...theme.presets.top,
      borderRadius: 0,
      borderBottomRightRadius: theme.spacing.value(1),
      width: 'auto',
      height: theme.spacing.value(8),
      zIndex: 1,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.DEFAULT.DISTANCE_WRAPPER_SIZE.MOBILE,
        height: theme.spacing.value(5.25),
        ...theme.spacing.padding(1),
      },
    },
    distanceValue: {
      ...assignTextStyle('h3')(theme).text,
      color: theme.colors.neutral10,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p2')(theme).text,
        fontWeight: '700',
        lineHeight: '16.8px',
      },
    },
    distanceUnit: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.neutral10,
      fontWeight: '700',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p5')(theme).text,
        fontWeight: '500',
        lineHeight: '12px',
      },
    },
    mainContent: {
      ...theme.presets.column,
      ...theme.spacing.marginTop('auto'),
      zIndex: 1,
      ...theme.spacing.gap(0.5),
    },
    title: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral1,
      lineHeight: 1.2,
      fontSize: 16,
      fontWeight: '700',
    },
    infosWrapper: {
      ...theme.presets.hidden,
    },
    awayText: {
      ...assignTextStyle('p5')(theme).text,
      color: theme.colors.neutral1,
      fontWeight: '500',
    },
    location: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.neutral1,
      fontWeight: '500',
      lineHeight: '15.62px',
    },
    tagWrapper: {
      backgroundColor: theme.colors.transparent,
      height: theme.values.itemHeight.tiny,
      ...theme.spacing.padding(0),
      zIndex: 1,
      ...theme.spacing.marginLeft('auto'),
    },
    tagText: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.neutral1,
      fontWeight: '500',
    },
    tagRightIcon: {
      color: theme.colors.neutral1,
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
    },
    image: {
      ...theme.presets.absolute,
      ...theme.presets.left,
      ...theme.presets.top,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
    },
    gradient: {
      ...theme.presets.absolute,
      ...theme.presets.left,
      ...theme.presets.top,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))',
      zIndex: 1,
    },
    bottomContent: {
      ...theme.presets.hidden,
      ...theme.presets.column,
      ...theme.spacing.gap(0.25),
    },
    statsWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.wrap,
    },
    statsItem: {
      ...theme.presets.center,
    },
    statsSubtitle: {
      ...assignTextStyle('p4')(theme).text,
      fontWeight: '500',
      fontSize: 12,
      letterSpacing: 0,
      color: theme.colors.neutral9,
    },
    statsTitle: {
      ...assignTextStyle('p4')(theme).text,
      fontWeight: '500',
      fontSize: 12,
      letterSpacing: 0,
      ...theme.spacing.marginLeft(0.5),
      color: theme.colors.neutral9,
    },
    statsDot: {
      ...theme.spacing.marginHorizontal(0.5),
      color: theme.colors.neutral9,
    },
    description: {
      ...assignTextStyle('p4')(theme).text,
      lineHeight: '15.62px',
      letterSpacing: 0,
      '-webkit-line-clamp': '2',
      display: '-webkit-box',
      ' -webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    bottomTitle: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: '500',
      letterSpacing: 0,
      color: theme.colors.neutral9,
    },
    bottomAwayText: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: '500',
      letterSpacing: 0,
      color: theme.colors.neutral6,
    },
    bottomCardContentTitle: {
      ...theme.presets.hidden,
    },
    bottomCardContentDistanceAway: {
      ...theme.presets.hidden,
    },
  })),
  large: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.LARGE.WIDTH,
      width: COURSE_CARD_CONSTANTS.LARGE.WIDTH,
      minHeight: COURSE_CARD_CONSTANTS.LARGE.HEIGHT,
    },
    contentWrapper: {
      height: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.DEFAULT,

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.MOBILE,
      },
    },
    distanceWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.LARGE.DISTANCE_WRAPPER_SIZE.WIDTH,
      height: COURSE_CARD_CONSTANTS.LARGE.DISTANCE_WRAPPER_SIZE.HEIGHT,
      borderBottomRightRadius: theme.borderRadius.smallish,
      ...theme.spacing.padding(1),
    },
    distanceValue: {
      ...assignTextStyle('p2')(theme).text,
      fontWeight: 'bold',
      lineHeight: '16.8px',
      color: theme.colors.overlay,
    },
    distanceUnit: {
      ...assignTextStyle('p5')(theme).text,
      fontWeight: 500,
      lineHeight: '12px',
      color: theme.colors.overlay,
    },
    bottomCardContentWrapper: {
      ...theme.presets.column,
      maxWidth: COURSE_CARD_CONSTANTS.LARGE.WIDTH,
    },
  })),
  wide: createCourseCardStyle((theme) => ({
    cardWrapper: {
      width: '100%',
      minWidth: COURSE_CARD_CONSTANTS.WIDE.WIDTH.DEFAULT,
      minHeight: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.DEFAULT,
      height: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.DEFAULT,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.WIDE.WIDTH.MOBILE,
        width: COURSE_CARD_CONSTANTS.WIDE.WIDTH.MOBILE,
      },
    },
    contentWrapper: {
      height: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.DEFAULT,

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.WIDE.HEIGHT.MOBILE,
      },
    },
    distanceWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.WIDE.DISTANCE_WRAPPER_SIZE,
      height: COURSE_CARD_CONSTANTS.WIDE.DISTANCE_WRAPPER_SIZE,
      borderBottomRightRadius: theme.borderRadius.smallish,
    },
    distanceValue: {
      ...assignTextStyle('h4')(theme).text,
      fontSize: 16,
      fontWeight: '700',
      lineHeight: '19.2px',
      letterSpacing: 0,
    },
    distanceUnit: {
      ...assignTextStyle('p4')(theme).text,
      letterSpacing: 0,
    },
    infosWrapper: {
      ...theme.presets.flex,
    },
    awayText: {
      ...theme.presets.hidden,
    },
    tagWrapper: {
      ...theme.presets.hidden,
    },
    image: {
      ...theme.presets.fullWidth,
      minHeight: 180,
    },
    bottomContent: {
      ...theme.presets.flex,
    },
    bottomTitle: {
      ...theme.presets.hidden,
    },
    bottomAwayText: {
      ...theme.presets.hidden,
    },
  })),
  high: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.HIGH.WIDTH.DEFAULT,
      ...theme.presets.fullWidth,
      borderRadius: theme.borderRadius.smallish,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.HIGH.WIDTH.MOBILE,
        width: COURSE_CARD_CONSTANTS.HIGH.WIDTH.MOBILE,
      },
    },
    contentWrapper: {
      height: COURSE_CARD_CONSTANTS.HIGH.HEIGHT.DEFAULT,
      ...theme.spacing.padding(2),

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.HIGH.HEIGHT.MOBILE,
        ...theme.spacing.padding(1),
      },
    },
    distanceWrapper: {
      width: COURSE_CARD_CONSTANTS.HIGH.DISTANCE_WRAPPER_SIZE.DEFAULT,
      minWidth: COURSE_CARD_CONSTANTS.HIGH.DISTANCE_WRAPPER_SIZE.DEFAULT,
      ...theme.spacing.paddingVertical(1.685),
      ...theme.spacing.paddingHorizontal(1.625),
      height: theme.spacing.value(8),

      [theme.media.down(BREAKPOINT)]: {
        width: COURSE_CARD_CONSTANTS.HIGH.DISTANCE_WRAPPER_SIZE.MOBILE,
        minWidth: COURSE_CARD_CONSTANTS.HIGH.DISTANCE_WRAPPER_SIZE.MOBILE,
        ...theme.spacing.padding(1),
        height: theme.spacing.value(5.25),
      },
    },
    distanceValue: {
      ...assignTextStyle('h3')(theme).text,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p2')(theme).text,
        fontWeight: '700',
        lineHeight: '16.8px',
      },
    },
    distanceUnit: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: '700',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p5')(theme).text,
        fontWeight: '500',
        lineHeight: '12px',
      },
    },
    infosWrapper: {
      ...theme.presets.flex,
    },
    title: {
      ...assignTextStyle('h3')(theme).text,
      lineHeight: 1.2,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('h4')(theme).text,
        color: theme.colors.neutral1,
        lineHeight: 1.2,
        fontSize: 16,
        fontWeight: '700',
      },
    },
    awayText: {
      ...theme.presets.flex,
      ...assignTextStyle('p1')(theme).text,
      fontWeight: '500',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p5')(theme).text,
        color: theme.colors.neutral1,
        fontWeight: '500',
      },
    },
    tagWrapper: {
      ...theme.presets.hidden,
    },
    image: {
      ...theme.presets.fullWidth,
    },
    bottomContent: {
      ...theme.presets.hidden,
    },
    location: {
      ...theme.presets.hidden,
    },
  })),
  small: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.SMALL.WIDTH.DEFAULT,
      ...theme.presets.fullWidth,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.SMALL.WIDTH.MOBILE,
        width: COURSE_CARD_CONSTANTS.SMALL.WIDTH.MOBILE,
      },
    },
    contentWrapper: {
      height: COURSE_CARD_CONSTANTS.SMALL.HEIGHT.DEFAULT,
      ...theme.spacing.padding(1),

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.SMALL.HEIGHT.MOBILE,
      },
    },
    distanceWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.SMALL.DISTANCE_WRAPPER_SIZE.DEFAULT,
      height: theme.spacing.value(8),
      ...theme.spacing.paddingVertical(1.685),
      ...theme.spacing.paddingHorizontal(1.625),

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.SMALL.DISTANCE_WRAPPER_SIZE.MOBILE,
        height: theme.spacing.value(5.25),
      },
    },
    distanceValue: {
      ...assignTextStyle('h3')(theme).text,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p2')(theme).text,
        fontWeight: '700',
        lineHeight: '16.8px',
      },
    },
    distanceUnit: {
      ...assignTextStyle('h5')(theme).text,
      fontWeight: '700',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p5')(theme).text,
        fontWeight: '500',
        lineHeight: '12px',
      },
    },
    infosWrapper: {
      ...theme.presets.hidden,
    },
    tagWrapper: {
      ...theme.presets.hidden,
    },
    image: {
      ...theme.presets.fullWidth,
    },
    location: {
      ...theme.presets.hidden,
    },
    bottomContent: {
      ...theme.presets.flex,
    },
    statsWrapper: {
      ...theme.presets.hidden,
    },
    description: {
      ...theme.presets.hidden,
    },
    title: {
      ...theme.presets.hidden,
    },
    bottomTitle: {
      ...assignTextStyle('p2')(theme).text,
      fontWeight: '700',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
        fontWeight: '500',
      },
    },
    bottomAwayText: {
      ...assignTextStyle('p3')(theme).text,
      letterSpacing: 0,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
        fontWeight: '500',
      },
    },
  })),
  square: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.SQUARE.WIDTH.DEFAULT,
      ...theme.presets.fullWidth,

      [theme.media.down(BREAKPOINT)]: {
        minWidth: COURSE_CARD_CONSTANTS.SQUARE.WIDTH.MOBILE,
        width: COURSE_CARD_CONSTANTS.SQUARE.WIDTH.MOBILE,
      },
    },
    contentWrapper: {
      height: COURSE_CARD_CONSTANTS.SQUARE.HEIGHT.DEFAULT,
      ...theme.spacing.padding(1),

      [theme.media.down(BREAKPOINT)]: {
        height: COURSE_CARD_CONSTANTS.SQUARE.HEIGHT.MOBILE,
      },
    },
    distanceWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.SQUARE.DISTANCE_WRAPPER_SIZE,
    },
    infosWrapper: {
      ...theme.presets.hidden,
    },
    tagWrapper: {
      ...theme.presets.hidden,
    },
    image: {
      ...theme.presets.fullWidth,
    },
    location: {
      ...theme.presets.hidden,
    },
    bottomContent: {
      ...theme.presets.flex,
    },
    statsWrapper: {
      ...theme.presets.hidden,
    },
    description: {
      ...theme.presets.hidden,
    },
    title: {
      ...theme.presets.hidden,
    },
    bottomTitle: {
      ...assignTextStyle('p2')(theme).text,
      fontWeight: '700',

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
        fontWeight: '500',
      },
    },
    bottomAwayText: {
      ...assignTextStyle('p3')(theme).text,
      fontWeight: '400',
      letterSpacing: 0,

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
        fontWeight: '500',
      },
    },
  })),
  banner: createCourseCardStyle((theme) => ({
    cardWrapper: {
      minWidth: COURSE_CARD_CONSTANTS.BANNER.WIDTH,
      width: COURSE_CARD_CONSTANTS.BANNER.HEIGHT,
      borderRadius: 0,
      [theme.media.down('mobile')]: {
        minWidth: '100vw',
      },
    },
    image: {
      ...theme.presets.fullWidth,
      height: COURSE_CARD_CONSTANTS.BANNER.HEIGHT,
    },
    contentWrapper: {
      ...theme.spacing.gap(1.6),
      borderRadius: 0,
      height: COURSE_CARD_CONSTANTS.BANNER.HEIGHT,
      zIndex: 1,
      [theme.media.down('tabletSmall')]: {
        minHeight: COURSE_CARD_CONSTANTS.SMALL.HEIGHT.DEFAULT,
      },
    },
    rightContent: {
      flex: 1,
    },
    infosWrapper: {
      display: 'none',
    },
    distanceWrapper: {
      display: 'none',
    },
    distanceUnit: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral10,
      [theme.media.down('tabletSmall')]: {
        ...assignTextStyle('p2')(theme).text,
      },
    },
    distanceValue: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.neutral10,
      [theme.media.down('tabletSmall')]: {
        ...assignTextStyle('h3')(theme).text,
      },
    },
    tagWrapper: {
      ...theme.presets.hidden,
    },
    title: {
      display: 'none',
    },
    awayText: {
      ...assignTextStyle('p4')(theme).text,
      fontWeight: '500',
      lineHeight: 1.3,
      [theme.media.down('tabletSmall')]: {
        ...theme.presets.hidden,
      },
    },
  })),
  'wrapperFullWidth': createCourseCardStyle((theme) => ({
    cardWrapper: {
      width: '100%',
      minWidth: '100%',
      [theme.media.down(BREAKPOINT)]: {
        minWidth: '100%',
        width: '100%',
      },
    },

  })),
  bottomCardContentVisible: createCourseCardStyle((theme) => ({
    bottomContent: {
      ...theme.presets.flex,
    },
    statsWrapper: {
      ...theme.presets.hidden,
    },
    description: {
      ...theme.presets.hidden,
    },
    bottomTitle: {
      color: theme.colors.neutral9,
      ...assignTextStyle('p2')(theme).text,
      fontWeight: 'bold',
    },
    bottomAwayText: {
      ...assignTextStyle('p3')(theme).text,
      fontWeight: '400',
      color: theme.colors.neutral7,
    },
  })),
  hideCardContent: createCourseCardStyle((theme) => ({
    mainContent: {
      ...theme.presets.hidden,
    },
    tagWrapper: {
      ...theme.presets.hidden,

    },
  })),
  fullWidth: createCourseCardStyle((theme) => ({
    cardWrapper: {
      ...theme.presets.fullWidth,
      minWidth: '100%',

      [theme.media.down(BREAKPOINT)]: {
        minWidth: '100%',
        width: '100%',
      },
    },
    cardInnerWrapper: {
      ...theme.presets.fullWidth,
      minWidth: '100%',
    },
  })),
  'grid:wide': createCourseCardStyle(() => {
    const CARD_HEIGHT = 258
    const IMAGE_HEIGHT = 180
    return {
      cardWrapper: {
        height: CARD_HEIGHT,
      },
      contentWrapper: {
        height: IMAGE_HEIGHT,
      },
    }
  }),
}
