import { Units } from './units'

function formatSecondsIntoClockTime(seconds, withHours = false) {

  const SECONDS_PER_MINUTE = Units.Multipliers['min->s']

  const hours = Math.floor(Units.convert({ from: 's', to: 'h', value: seconds }))
  const minutes = Math.floor(Units.convert({ from: 's', to: 'min', value: seconds }))

  const remainingSeconds = seconds % SECONDS_PER_MINUTE
  const remainingMinutes = minutes % SECONDS_PER_MINUTE

  const hasHours = withHours ? true : hours > 0

  const result = `${remainingMinutes
    .toString()
    .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`

  return `${hasHours ? `${hours}:` : ''}${result}`
}

export const Time = {
  formatSecondsIntoClockTime,
}
