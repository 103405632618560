import { api } from '@/app'
import { Place, PlaceAdminType, SearchPrediction } from '@/types'
import { queryClient } from './queryClient'
import {
  PaginationResponse,
  QueryManager,
  ReactQuery,
  UsePaginationTypes,
} from '@codeleap/common'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'places/'

type SearchProps = {
  search: string
  origin_lat: number
  origin_lng: number
  // If true will fetch all countries from search
  all_countries?: boolean
  // If false will remove courses from search
  search_courses?: boolean
}

export async function searchPlaces(params: SearchProps) {
  const response = await api.get<SearchPrediction[]>(`${BASE_URL}search/`, {
    params: { ...params, web: true },
  })
  return response.data
}

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Place>>(BASE_URL, {
    params,
  })
  return response.data
}

export async function retrieve(
  slug: Place['slug'],
  params = { original_points: true }
) {
  const response = await api.get<Place>(`${BASE_URL}${slug}/`, {
    params: {
      ...params,
    },
  })
  return response.data
}

export const placesManager = new QueryManager<Place>({
  itemType: {} as Place,
  name: 'places',
  queryClient: queryClient.client,
  limit: 30,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit: limit, offset, ...filter })
    return response
  },
  //@ts-ignore
  retrieveItem: async (slug: Place['slug']) => {
    const response = await retrieve(slug)
    return response || null
  },
})

export type UsePlacesProps = Partial<
  Pick<
    Place,
    | 'slug'
    | 'country_slug'
    | 'state_slug'
    | 'county_slug'
    | 'city_slug'
    | 'name'
  >
> & {
  admin_type: PlaceAdminType
  enabled?: boolean
  limit?: number
}

export const usePlaces = (props?: UsePlacesProps) => {
  const { enabled = true, ...filters } = props || {}

  const places = placesManager.use({
    filter: filters,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled,
      },
    },
  })

  const listProps = useFlatlistProps(places, {
    noMoreItemsText: null,
    forceHasNextPage: false,
  })

  return {
    places,
    listProps,
  }
}

export type UsePlacesSearchProps = SearchProps

export const useSearchPlaces = (props: UsePlacesSearchProps) => {
  const query = ReactQuery.useQuery({
    queryKey: ['searchPlaces', props?.search],
    queryFn: () => searchPlaces(props),
    refetchOnMount: false,
    enabled: props?.search?.length > 0,
    retry: false,
  })

  return {
    places: query.data,
    query,
  }
}
