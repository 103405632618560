import { IconComposition, IconPresets } from '@codeleap/web'
import { variantProvider } from '..'

const createIconStyle = variantProvider.createVariantFactory<IconComposition>()

export const AppIconStyles = {
  ...IconPresets,
  default: createIconStyle(theme => ({
    icon: {
      color: theme.colors.icon,
    },
  })),
  white: createIconStyle(theme => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),

  colorPrimary: createIconStyle(theme => ({
    icon: {
      color: theme.colors.primary3,
    },
  })),

  primary: createIconStyle(theme => ({
    icon: {
      color: theme.colors.primary3,
      fill: theme.colors.primary3,
    },
  })),
  negative: createIconStyle(theme => ({
    icon: {
      color: theme.colors.neutral1,
      fill: theme.colors.neutral1,
    },
  })),
  positive: createIconStyle(theme => ({
    icon: {
      color: theme.colors.neutral10,
      fill: theme.colors.neutral10,
    },
  })),

  huge: createIconStyle(theme => ({
    icon: {
      ...theme.sized(8),
    },
  })),
  large: createIconStyle(theme => ({
    icon: {
      ...theme.sized(6),
    },
  })),
  largeish: createIconStyle(theme => ({
    icon: {
      ...theme.sized(4),
    },
  })),
  medium: createIconStyle(theme => ({
    icon: {
      ...theme.sized(3),
    },
  })),
  small: createIconStyle(theme => ({
    icon: {
      ...theme.sized(2.5),
    },
  })),
  smaller: createIconStyle(theme => ({
    icon: {
      ...theme.sized(2),
    },
  })),
  'size:2': createIconStyle(theme => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  'size:3': createIconStyle(theme => ({
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  'size:4': createIconStyle(theme => ({
    icon: {
      width: theme.values.iconSize[4],
      height: theme.values.iconSize[4],
    },
  })),
  primary3: createIconStyle(theme => ({
    icon: {
      color: theme.colors.primary3,
    },
  })),
  neutral3: createIconStyle(theme => ({
    icon: {
      color: theme.colors.neutral3,
      fill: theme.colors.neutral3,
    },
  })),
  orange1: createIconStyle(theme => ({
    icon: {
      color: theme.colors.orange1,
      fill: theme.colors.orange1,
    },
  })),
}
