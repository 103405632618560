import { Slug_Names } from "@/app"
import { Navigation } from "./navigation"



function getPathSlugName(slug: string, path: string, nodes?: any[]) {
  if (Object.keys(Slug_Names).includes(slug)) return Slug_Names[`${slug}`]

  if (nodes?.length > 0) {
    const node = nodes?.find(obj => obj?.path === `${path}/`)
    if (node) {
      return node?.pageContext?.pathLabel
    }
  }

  const parts = slug.split('-').filter(part => part.length > 0)
  const formattedName = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ')
  return formattedName
}

function getPaths(nodes?: any[], path?: string) {
  const _path = !!path ? (path.endsWith('/') ? path : `${path}/`) : null
  const _pathSlugs = Navigation.getPathSlugs(_path)
  const pathSlugs = ['home', ..._pathSlugs]
  let accumulatedPath = ''

  return pathSlugs.map(slug => {
    accumulatedPath += slug === 'home' ? '' : `/${slug}`
    return {
      label: getPathSlugName(slug, accumulatedPath, nodes),
      path: accumulatedPath,
    }
  })
}

type SchemaPathItem = {
  label: string
  path: string
}

function getSchemaListItem(pathItem: SchemaPathItem, index: number) {
  const url = `${process.env.GATSBY_SITE_URL.slice(0, -1)}${pathItem.path}`
  return {
    "@type": "ListItem",
    "position": index + 1,
    "item": {
      "@id": url,
      "name": pathItem.label,
    },
  }
}

function getSchemaList(path: string) {
  const accumulatedPaths = getPaths([], path) 
  return JSON.stringify(accumulatedPaths.map((accumulatedPath, index) => getSchemaListItem(accumulatedPath, index)))
}

export const BreadcrumbsUtils = {
  getPaths,
  getSchemaList
}