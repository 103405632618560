import { AnyFunction, PropsOf, useBooleanToggle, useState } from '@codeleap/common'
import { ActionIcon, BannerImageProps, Image, Modal, Pager, View } from '..'
import { ModalProps } from '@codeleap/web'
import { variantProvider } from '@/app'
import { useIsTablet } from '@/utils'

export type SpotlightModalProps = {
  pictures: BannerImageProps[]
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  onCloseModal?: AnyFunction
} & Omit<PropsOf<ModalProps>, 'debugName'>

export const SpotlightModal = (props: SpotlightModalProps) => {

  const { pictures = [], onCloseModal, ...modalProps } = props

  const [internalVisible, internalToggle] = useBooleanToggle(false)
  const [internalPage, setInternalPage] = useState(0)

  const page = modalProps?.hasOwnProperty('page') ? modalProps?.page : internalPage
  const setPage = modalProps?.hasOwnProperty('setPage') ? modalProps?.setPage : setInternalPage

  const visible = modalProps?.hasOwnProperty?.('visible') ? modalProps?.visible : internalVisible
  const toggle = modalProps?.hasOwnProperty?.('toggle') ? modalProps?.toggle : internalToggle

  const isTablet = useIsTablet()

  const showPrevArrow = page !== 0
  const showNextArrow = page !== pictures?.length - 1

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      onClose={onCloseModal?.()}
      debugName={'Profile reauthentication modal'}
      showClose={isTablet}
      variants={[isTablet ? 'fullscreen' : 'centered', 'spotlight']}
      {...modalProps}
    >
      <View variants={['row']}>

        {(showPrevArrow && !isTablet) && (
          <View variants={['center']}>
            <ActionIcon
              icon={'chevron-left'}
              style={styles.prevArrow}
              variants={['transparent', 'iconSize:4']}
              onPress={() => setPage((state) => state - 1)}
              debugName='Spotlight Pager - next arrow on press'
            />
          </View>
        )}

        <Pager
          debugName='pager:spolight-modal'
          disableSwipe={!isTablet}
          dots={pictures?.length > 1}
          variants={['spotlight']}
          page={page}
          onChange={(page) => setPage(page)}
          styles={{
            'wrapper': styles.pager,
          }}
          css={[styles.pagerWrapper]}
        >
          {pictures?.map?.((item, index) => (
            <View key={`${item?.source}:${index}`} style={styles.imageWrapper} variants={['center', 'paddingTop:8']}>
              <Image source={item?.source} style={styles.image} objectFit={isTablet ? 'contain' : 'contain'} />
            </View>
          ))}
        </Pager>

        {(showNextArrow && !isTablet) && (
          <View variants={['center']}>
            <ActionIcon
              icon={'chevron-right'}
              style={styles.nextArrow}
              variants={['transparent', 'iconSize:4']}
              onPress={() => setPage((state) => state + 1)}
              debugName='Spotlight Pager - next arrow on press'
            />
          </View>
        )}

      </View>

    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  pager: {
    paddingBottom: theme.spacing.value(4),
    [theme.media.down('mobile')]: {
      ...theme.presets.justifySpaceBetween,
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
      paddingBottom: theme.spacing.value(2),
    },
  },
  pagerWrapper: {
    [theme.media.down('mobile')]: {
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
    },
  },
  imageWrapper: {
    [theme.media.down('tabletSmall')]: {
      width: '100dvw',
      height: '100%',
    },
  },
  image: {
    width: `90%`,
    height: '75vh',
    borderRadius: theme.borderRadius.medium,
    [theme.media.down('desktop')]: {
      width: `90%`,
    },
    [theme.media.down('tabletSmall')]: {
      width: `100%`,
    },
  },
  prevArrow: {
    position: 'absolute',
    left: 0,
  },
  nextArrow: {
    position: 'absolute',
    right: 0,
  },
  closeModalIcon: {
    position: 'absolute',
    top: 50,
    right: theme.spacing.value(2),
  },
}), true)
