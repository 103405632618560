import { View, Text } from '@/components'

import { LineChartXAxisProps } from '../types'
export const LineChartXAxis = ({ data = [], width, variantStyles }: LineChartXAxisProps) => {
  return (
    <View style={{ width }} css={variantStyles.xAxisWrapper}>
      <View css={{ ...variantStyles.xAxisLine, width }} />
      {data.map((d, index) => {
        const isFirst = index === 0
        const isLast = index === data.length - 1
        const displacement = `calc(100% - ${variantStyles.lineChartWrapper.margin}px)`

        return (
          <View key={index} css={variantStyles.xAxisTextWrapper}>
            <View
              css={{
                ...variantStyles.xAxisDashedLine,
                height: d.height,
                top: -d.height,
                left: isLast ? displacement : 'unset',
                right: isFirst ? displacement : 'unset',
              }}
            />
            <Text css={variantStyles.xAxisText} text={d.value} />
          </View>)
      })}
    </View>
  )
}
