import { Extensions } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Heading from '@tiptap/extension-heading'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

export const TextEditorExtensions: Extensions = [
  Underline,
  Superscript,
  SubScript,
  TextStyle,
  Image,
  Highlight.configure({ multicolor: true }),
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
  TextAlign.configure({ types: ['heading', 'paragraph', 'image'] }),
  FontFamily.configure({ types: ['textStyle'] }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Link.configure({
    protocols: ['mailto'],
    HTMLAttributes: { rel: 'noopener noreferrer', target: '_blank' },
    validate: (href) => /^(?:https?:\/\/|www\.)\S+/.test(href),
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
]
