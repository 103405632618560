import { AppImages, Settings } from '@/app'
import { Area, AreaFilters } from '@/types'
import { area } from '@turf/turf'
import { removeLeadingSlash } from '../misc'
import { SEOProps } from '@/components'
import { TypeGuards } from '@codeleap/common'

const areaFilters: (keyof AreaFilters)[] = [
  'area_type_id',
  'key',
  'limit',
  'offset',
  'title',
]

const getFiltersByParams = (params?: AreaFilters) => {
  if (!params) return {}

  const filters = {}

  Object.entries(params).map(([key, value]) => {
    if (!value || !areaFilters.includes(key as keyof AreaFilters)) return null

    filters[key] = value
  })

  return filters
}

const getCoverImage = (
  area: Area,
  options = {
    attachSiteURL: false,
  },
) => {
  return area.file ? area.file : options?.attachSiteURL
    ? `${process.env.GATSBY_SITE_URL}${removeLeadingSlash(
      AppImages.AreaExample,
    )}`
    : AppImages.AreaExample
}

const getImageAlt = (area: Area, imageNumber = 1) => `${area?.title} - Photo ${imageNumber}`

const getAreaMetaTitle = (area: Area) => !!area?.meta_title ? area?.meta_title : area?.title

const getAreaMetaDescription = (area: Area) => !!area?.meta_description ? area?.meta_description : area?.description

const getSEOProps = (area: Area): Partial<SEOProps> => {
  const metaTitle = getAreaMetaTitle(area)

  return {
    pathname: `/${area.slug}`,
    title: metaTitle?.replace(`| ${Settings.AppName}`, ''),
    description: getAreaMetaDescription(area),
    image: AreaUtils.getCoverImage(area, { attachSiteURL: true }),
    appendNameToPageTitle: true,
  }
}

const getPathName = (area: Area) => {
  return `${area?.slug}`
}

function getSEOAreaItem(area: Area, index: number) {
  const url = `${process.env.GATSBY_SITE_URL}${getPathName(area)}`
  return {
    "@type": "ListItem",
    "position": index,
    "item": {
      "@type": "TouristDestination",
      "name": area?.title,
      "description": area?.description,
      "image": getImageAlt(area),
      "url": url,
      "@id": url,
      "touristType": "Runners, Outdoor Enthusiasts, Fitness Enthusiasts",
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": area?.latitude,
        "longitude": area?.longitude
      },
    },
  }
}

function getSEOAreas(areas: Area[]) {
  if (TypeGuards.isNil(areas)) return null
  return JSON.stringify(areas.slice(0, 3).map((area, index) => getSEOAreaItem(area, index)))
}

export const AreaUtils = {
  getSEOProps,
  getFiltersByParams,
  getCoverImage,
  getImageAlt,
  getAreaMetaTitle,
  getAreaMetaDescription,
  getPathName,
  getSEOAreaItem,
  getSEOAreas,
}
