import { TouchableComposition, assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type MapComposition =
  | `wrapper`
  | `loadingWrapper`
  | `mapButtonWrapper`
  | `mapButtonIcon`

const createMapStyle =
  variantProvider.createVariantFactory<MapComposition>()

export const MapStyles = {
  default: createMapStyle((theme) => ({
    wrapper: {
      ...theme.presets.relative,
    },
    loadingWrapper: {
      flex: 1,
      backgroundColor: theme.colors.primary1,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
    },
    mapButtonWrapper: {
      width: theme.spacing.value(5),
      height: theme.spacing.value(5),
      borderRadius: theme.borderRadius.smallish,
      ...theme.presets.absolute,
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
    mapButtonIcon: {
      color: theme.colors.neutral8,
    },
  })),
}
