import { View, CourseMap } from '@/components'
import { COURSE_EMBED_HEADER_HEIGHT, CourseEmbedHeader, CourseEmbedInfoBar } from './components'
import { variantProvider } from '@/app'
import { Course } from '@/types'

export type CourseEmbedProps = {
  course: Course
}

export const CourseEmbed = ({ course }: CourseEmbedProps) => {
  return (
    <View css={styles.wrapper}>
      <CourseEmbedHeader course={course} />
      <View css={styles.mapWrapper}>
        <CourseMap
          course={course}
          style={styles.map}
        />
      </View>

      <CourseEmbedInfoBar course={course} />
    </View>
  )
}

const CONTENT_HEIGHT = `calc(100vh - ${COURSE_EMBED_HEADER_HEIGHT}px)`
const MOBILE_CONTENT_HEIGHT = `calc(100svh - ${COURSE_EMBED_HEADER_HEIGHT}px)`

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    flex: 1,
    height: '100vh',
    ...theme.presets.column,
  },
  mapWrapper: {
    flex: 1,
    height: CONTENT_HEIGHT,

    [theme.media.down('tablet')]: {
      height: MOBILE_CONTENT_HEIGHT,
    },
  },

  map: {
    overflow: 'hidden',
    width: '100%',
  },
}), true)

