import { Icon } from '@/components'
import { AppIcon, AppImages } from '@/app'
import { Location, ShortCoords } from '@/types'
import { NavigationMarkerComposition, NavigationMarkerStyle } from '@/app/stylesheets'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

export type NavigationMarkerProps = ComponentVariants<typeof NavigationMarkerStyle> & Omit<Location, 'latitude' | 'longitude'> & ShortCoords & {
  text?: string
  icon?: AppIcon
  styles?: StylesOf<NavigationMarkerComposition>
}

/** This component is used inside Map, so we need to use raw components */
export const NavigationMarker = ({ variants, styles, heading }: NavigationMarkerProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:NavigationMarker',
    typeof NavigationMarkerStyle
  >('u:NavigationMarker', {
    rootElement: 'wrapper',
    variants,
    styles,
  })

  const markerStyle = {
    ...variantStyles.wrapper,
    transform: !!heading ? `rotate(${heading}deg)` : undefined,
  }

  return (
    <div style={markerStyle}>
      {/* @ts-ignore */}
      <image src={AppImages.MarkerGradient} style={variantStyles.gradient} />

      <div
        style={variantStyles.accuracyCircle}
      />

      <div style={variantStyles.circle}>
        <div style={variantStyles.iconWrapper}>
          <Icon
            debugName='NavigationMarker:Icon'
            name='navigation'
            style={variantStyles.icon}
          />
        </div>
      </div>
    </div>
  )
}

