import { IsDevEnv } from '@/app/Settings'
import { createForm, yup } from '@codeleap/common'
import { testerOptions, testers } from '../testers'

const initialState = IsDevEnv ? testers.default : undefined

export const login = () => createForm('login', {
  email: {
    type: 'text',
    placeholder: 'email@email.com',
    validate: yup
      .string()
      .required()
      .email(),

    defaultValue: initialState?.email,
  },
  password: {
    type: 'text',
    password: true,
    validate: yup
      .string()
      .required()
      .min(6)
      .max(128),

    placeholder: 'My secret password',
    defaultValue: initialState?.password,

  },
  remember: {
    type: 'checkbox',
    label: 'Remember information',
    defaultValue: false,
  },
  tester: {
    type: 'select',
    label: 'Tester',
    options: testerOptions,
    defaultValue: 'default',
  },
})
