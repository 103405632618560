import { variantProvider } from '@/app'
import { MapComponent } from '../Maps/MapComponent'
import { Area, Course, DrawedCourse, ShortCoords } from '@/types'
import { PropsOf, useRef, useState } from '@codeleap/common'
import { CourseUtils, MapUtils, Navigation } from '@/utils'
import { View } from '@/components'

export type AreaMapProps = {
  area?: Area
  isLoading?: boolean
  mapProps?: PropsOf<typeof MapComponent>
  courses: Course[]
}

export const AreaMap = (props?: AreaMapProps) => {
  const { area, mapProps, courses = [] } = props
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const { mapRefs: { mapRef, mapsRef }} = MapUtils.useMap()
  const coursesRef = useRef<DrawedCourse[]>([])

  const position: ShortCoords = {
    lat: area?.latitude,
    lng: area?.longitude,
  }

  const mapInstances = { map: mapRef.current, maps: mapsRef.current }

  const areaRadius = area?.radius || 100

  const { circlesRef } = MapUtils.useDrawCircles({
    ...mapInstances,
    isMapLoaded,
    circles: [{ center: position, radius: areaRadius * 1000 }],
  })

  CourseUtils.Maps.useDrawCourses({
    ...mapInstances,
    isMapLoaded,
    courses,
    coursesRef,
    selectedCourse: null,
  })

  const handleApiLoaded = () => {
    setIsMapLoaded(true)
  }

  MapUtils.onMapUpdate({
    ...mapInstances,
    isMapLoaded,
    callback: () => {
      const circle = circlesRef?.current?.[0]?.circle
      if (!!circle) MapUtils.fitToCircle({ ...mapInstances, circle })
    },
  }, [circlesRef?.current])

  return (
    <View variants={['flex']}>
      <MapComponent
        onGoogleApiLoaded={handleApiLoaded}
        mapRef={mapRef}
        mapsRef={mapsRef}
        isLoading={!area}
        style={styles.map}
        defaultCenter={position}
        mapButtonProps={{
          icon: 'maximize',
          onPress: () => Navigation.Course.navigateToExplore({ radius: areaRadius, ...position }),
        }}
        {...mapProps}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    map: {
      borderRadius: theme.borderRadius.medium,
      overflow: 'hidden',
      width: '100%',
      height: 300,
    },
  }),
  true,
)
