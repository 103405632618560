import { Theme } from '@/app'
import { Course } from '@/types'
import { Navigation } from '@/utils'
import { PropsOf } from '@codeleap/common'
import { useCallback } from 'react'
import { EmptyPlaceholder } from '@codeleap/web'
import { ActivityIndicator, CourseCardProps, View, CourseCard, Grid, SeeMoreGrid } from '@/components'

type GridProps = Omit<PropsOf<typeof Grid>, 'data' | 'style' | 'placeholder'>

export type CourseGridProps = Partial<GridProps> & {
  courses: Course[]
  cardVariants?: CourseCardProps['variants']
  onPress?: (Course: Course) => void
  style?: PropsOf<typeof View>
}

export const CourseGrid = ({
  courses,
  cardVariants,
  onPress,
  numColumns = 2,
  rowItemsSpacing = Theme.spacing.value(2),
  columnItemsSpacing = Theme.spacing.value(2),
  style,
  ...rest }: CourseGridProps) => {
  const renderItem = useCallback(({ item }) => {

    return (
      <CourseCard
        course={item}
        variants={['wide', 'fullWidth', 'grid:wide']}
        onPress={() => Navigation.Course.navigateToDetails(item)}
      />
    )
  }, [cardVariants, onPress])

  if (rest.isLoading) {
    return (
      <View variants={['justifyCenter', 'flex', 'alignCenter']}>
        <ActivityIndicator debugName='CourseList:Loading' />
      </View>)
  }

  if (!courses?.length) {
    return (
      <EmptyPlaceholder
        title='No Courses'
        description='There are no Courses on this location'
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  return (
    <SeeMoreGrid
      {...rest}
      debugName='CourseGrid'
      numColumns={numColumns}
      renderItem={renderItem}
      rowItemsSpacing={rowItemsSpacing}
      data={courses as unknown as string[]}
      columnItemsSpacing={columnItemsSpacing}
    />
  )
}

