import { assignTextStyle } from './Text'
import { ProgressCircleComposition, ProgressCirclePresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createProgressCircleStyle =
  variantProvider.createVariantFactory<ProgressCircleComposition>()

export const AppProgressCircleStyles = {
  ...ProgressCirclePresets,
  default: createProgressCircleStyle((theme) => ({
    wrapper: {},
    circle: {
      size: 160,
    },
    line: {
      borderColor: theme.colors.primary3,
      backgroundColor: theme.colors.neutral3,
    },
    image: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    innerWrapper: {
      ...theme.presets.column,
      ...theme.presets.alignCenter,
    },
    text: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.neutral7,
    },
    icon: {
      size: theme.values.iconSize[4],
      color: theme.colors.neutral7,
    },
  })),
}
