import { DatePickerPresets, DatePickerComposition } from '@codeleap/web'
import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

const createDatePickerStyle = variantProvider.createVariantFactory<DatePickerComposition>()

const datePickerHeight = 190

export const AppDatePickerStyles = {
  ...DatePickerPresets,
  default: createDatePickerStyle((theme) => ({
    wrapper: {
      position: 'relative',

      '.react-datepicker-wrapper': {
        ...theme.presets.fullWidth,
        ...theme.presets.inline,
        ...theme.spacing.padding(0),
        border: 0,
      },
      '.react-datepicker': {
        ...theme.presets.fullWidth,
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.tiny,
        ...theme.presets.inline,
        position: 'relative',
        ...theme.spacing.padding(2),
        ...theme.effects.thin,
      },
      '.react-datepicker-popper': {
        zIndex: 1,
        ...theme.presets.fullWidth,
      },
      '.react-datepicker__month-container': {
        ...theme.presets.fullWidth,
      },
      '.react-datepicker__day-names': {
        display: 'flex',
        ...theme.presets.justifySpaceBetween,
        ...theme.presets.alignCenter,
        ...theme.spacing.marginBottom(1),
      },
      '.react-datepicker__day-name': {
        display: 'flex',
        ...theme.presets.center,
        ...theme.sized(3),
        ...assignTextStyle('p2')(theme).text,
        color: theme.colors.neutral10,
        textDecoration: 'none',
        cursor: 'pointer',
      },
      '.react-datepicker__week': {
        display: 'flex',
        ...theme.presets.justifySpaceBetween,
      },
      '.react-datepicker__month': {
        display: 'flex',
        ...theme.presets.column,
        ...theme.spacing.gap(1),
      },
      '.react-datepicker__day--outside-month': {
        visibility: 'hidden',
      },
      '.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text':
        {
          ...theme.sized(3),
          display: 'flex',
          ...theme.presets.center,
          cursor: 'pointer',
        },
      // eslint-disable-next-line max-len
      '.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled': {
        cursor: 'default',
        color: theme.colors.border,
      },
      // eslint-disable-next-line max-len
      '.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover': {
        backgroundColor: 'transparent',
      },
      '.react-datepicker__portal': {
        ...theme.presets.fixed,
        ...theme.presets.fullView,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        ...theme.presets.top,
        ...theme.presets.left,
        ...theme.presets.center,
        display: 'flex',
        zIndex: 9999,
      },
      '.react-datepicker__year-text': {
        margin: '0 auto',
      },
      '.react-datepicker__year-text--disabled': {
        height: 0,
        width: 0,
        visibility: 'hidden',
      },
      '.react-datepicker__aria-live': {
        ...theme.presets.absolute,
        clipPath: 'circle(0)',
        border: 0,
        height: 1,
        margin: -1,
        ...theme.presets.hidden,
        padding: theme.spacing.value(0),
        width: 1,
        whiteSpace: 'nowrap',
      },
      '.react-datepicker__year-wrapper': {
        display: 'grid',
        maxHeight: datePickerHeight,
        overflowY: 'auto',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: theme.spacing.value(1),
        ...theme.spacing.paddingTop(1),
      },
    },
    outerInputInnerWrapper: {
      cursor: 'pointer',
    },
    outerInputInput: {
      cursor: 'pointer',
    },
    'outerInputInput:focus': {
      caretColor: theme.colors.primary3,
    },
    outerInputRightIconIcon: {
      color: theme.colors.neutral10,
    },
    headerWrapper: {
      ...theme.presets.full,
      display: 'flex',
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignCenter,
      ...theme.spacing.marginBottom(1.5),
      background: theme.colors.neutral1,
    },

    headerTitle: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.neutral10,
      cursor: 'pointer',
    },

    headerButtonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...theme.spacing.gap(0.5),
    },
    headerPrevButtonTouchableWrapper: {
      ...theme.spacing.margin(0),
      backgroundColor: 'none',
    },
    headerPrevButtonIcon: {
      color: theme.colors.neutral5,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    headerNextButtonTouchableWrapper: {
      ...theme.spacing.margin(0),
      backgroundColor: 'none',
    },
    headerNextButtonIcon: {
      color: theme.colors.neutral5,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    dayWrapper: {
      ...theme.sized(3),
      display: 'flex',
      ...theme.presets.center,
    },
    'dayWrapper:selected': {
      background: theme.colors.primary2,
      borderRadius: theme.borderRadius.rounded,
    },
    day: {
      textAlign: 'center',
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
    },
    'day:selected': {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.neutral10,
      background: theme.colors.primary2,
      borderRadius: theme.borderRadius.rounded,
    },
    'day:disabled': {
      cursor: 'not-allowed',
      color: theme.colors.neutral3,
    },
    yearWrapper: {
      display: 'flex',
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(0.5),
      ...theme.presets.center,
    },
    'yearWrapper:selected': {
      background: theme.colors.primary2,
      borderRadius: theme.borderRadius.rounded,
    },
    year: {
      textAlign: 'center',
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
    },
    'year:selected': {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.neutral10,
      background: theme.colors.primary2,
      borderRadius: theme.borderRadius.rounded,
    },
  })),
}
