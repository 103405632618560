import { View, List, SeeMoreButton } from '@/components'
import { PropsOf } from '@codeleap/common'

export type SeeMoreListProps = PropsOf<typeof List> & {
  wrapperProps?: PropsOf<typeof View>
}

export const SeeMoreList = ({ fetchNextPage, wrapperProps, ...rest }: SeeMoreListProps) => {
  const hasNextPage = !!rest.hasNextPage && !rest?.isFetchingNextPage && !!rest.data?.length

  return (
    <View variants={['column', 'fullWidth', 'paddingBottom:2']} {...wrapperProps}>
      <List {...rest} />

      {!!hasNextPage && (
        <SeeMoreButton
          debugName='SeeMoreList:Button'
          onPress={fetchNextPage}
          variants={['margin:auto', 'marginTop:3']}
        />)}
    </View>
  )
}

